import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation/navigation.component';
import { FeatureFlagModule } from '../feature-flag/feature-flag.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { HistoryModule } from '../history/history.module';
import { ExportModule } from '../isolate/components/export/export.module';
import { PricingModule } from '../pricing/pricing.module';
import { NotificationModule } from '../notification/notification.module';
import { RouterModule } from '@angular/router';
import { BrandlandscapeNavigationBarComponent } from './brandlandscape-navigation-bar/brandlandscape-navigation-bar.component';
import { MatTabsModule } from '@angular/material/tabs';
import { OwnerNavigationBarComponent } from './owner-navigation-bar/owner-navigation-bar.component';
import { FlagModule } from '../flag/flag.module';
import { SearchStrategyModule } from '../search-strategy/search-strategy.module';
import { MatBadgeModule } from '@angular/material/badge';
import { ImageNavigationBarComponent } from './image-navigation-bar/image-navigation-bar.component';
import { ChooseProductComponent } from './choose-product/choose-product.component';

@NgModule({
  declarations: [
    NavigationComponent,
    BrandlandscapeNavigationBarComponent,
    OwnerNavigationBarComponent,
    ImageNavigationBarComponent,
    ChooseProductComponent,
  ],
  imports: [
    CommonModule,
    FeatureFlagModule,
    HistoryModule,
    ExportModule,
    PricingModule,
    NotificationModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTabsModule,
    RouterModule,
    FlagModule,
    SearchStrategyModule,
    MatBadgeModule,
    MatMenuModule,
  ],
  exports: [NavigationComponent, ChooseProductComponent],
})
export class NavigationModule {}
