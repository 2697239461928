import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ClusterInfo,
  ExportService,
  ResultsDelivery,
  SelectForExportRequest,
  SelectionType,
} from '@compumark/bla-backend-client';

import _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-risk-name-renderer',
  templateUrl: './risk-name-renderer.component.html',
  styleUrls: ['./risk-name-renderer.component.scss'],
})
export class RiskNameRendererComponent {
  resultsDelivery$: Observable<ResultsDelivery>;
  clusterInfo?: ClusterInfo;
  name?: string;
  id!: string;
  selectedForExport = false;
  row!: ICellRendererParams;
  reputation = false;

  constructor(
    private router: Router,
    route: ActivatedRoute,
    private exportService: ExportService,
  ) {
    this.resultsDelivery$ = route.data.pipe(
      map((data) => data.resultsDelivery),
    );
  }

  agInit(params: ICellRendererParams): void {
    this.id = params.data.id;
    this.name = params.value;
    this.clusterInfo = params.data.clusterInfo;
    this.selectedForExport = params.data.selectedForExport;
    this.row = params;
    this.reputation = params.data.reputation;
  }

  get tooltip(): string {
    return (
      '<strong>Top Citations:</strong> ' +
      this.clusterInfo!.includedInTopThreatsCount +
      '<br><strong>Other Citations:</strong> ' +
      this.clusterInfo!.otherThreatsCount +
      '<br><strong>Markets:</strong> ' +
      '<span class="tooltip-markets">' +
      this.clusterInfo!.markets.join(', ') +
      '</span>' +
      '<br><strong>Owner:</strong> ' +
      this.clusterInfo!.owner +
      '</strong>'
    );
  }

  get exportTooltip(): string {
    return this.selectedForExport
      ? 'Remove from export report'
      : 'Add to export report';
  }

  get reputationTooltip(): string {
    return 'Trademark has reputation';
  }

  selectForExport(resultsDelivery: ResultsDelivery): void {
    this.selectedForExport = !this.selectedForExport;
    this.row.data.selectedForExport = this.selectedForExport;
    this.row.api.refreshCells({
      rowNodes: [this.row.node],
      columns: [this.row.column!],
    });
    this.exportService
      .selectForExport(
        {
          ids: [this.id],
          parentId: resultsDelivery.id,
          selectionType: this.getSelectionType(),
          selectedForExport: this.selectedForExport,
        } as SelectForExportRequest,
        resultsDelivery.id,
      )
      .subscribe();
  }

  getSelectionType(): SelectionType | undefined {
    let type: SelectionType | undefined;
    switch (this.router.url.split('/').pop()!) {
      case 'pharma-threats': {
        type = SelectionType.PHARMA_THREAT;
        break;
      }
      case 'domain-names': {
        type = SelectionType.DOMAIN_NAME;
        break;
      }
      case 'company-names': {
        type = SelectionType.COMPANY_NAME;
        break;
      }
    }

    return type;
  }
}
