import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchStrategyModel } from 'src/app/search-strategy/components/search-strategy-button/search-strategy-button.component';

@Component({
  selector: 'app-search-strategy-dialog',
  templateUrl: './search-strategy-dialog.component.html',
  styleUrls: ['./search-strategy-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchStrategyDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: SearchStrategyModel) {}
}
