import { Component } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';

@Component({
  selector: 'app-select-all-header',
  templateUrl: './select-all-header.component.html',
  styleUrls: ['./select-all-header.component.scss'],
})
export class SelectAllHeaderComponent implements IHeaderAngularComp {
  maxHitsRequired = 100;
  isChecked = false;
  isIndeterminate = false;
  params: any;

  constructor() {}

  agInit(params: IHeaderParams | any): void {
    this.params = params;
    this.params.api.addEventListener('selectionChanged', () => {
      this.setCheckboxState();
    });
  }

  toggleSelect(event: any): void {
    this.params.api.forEachNode((node: any) => {
      node.selectThisNode(event.checked);
    });

    this.setCheckboxState();
    this.params.onHeaderButtonClick();
  }

  refresh(): boolean {
    return false;
  }

  setCheckboxState(): void {
    const selectedNodesLength = this.params.api.getSelectedNodes().length;
    const renderedNodesLength = this.getHitCount();

    this.isChecked =
      selectedNodesLength > 0 && selectedNodesLength === renderedNodesLength;
    this.isIndeterminate =
      selectedNodesLength > 0 && selectedNodesLength < renderedNodesLength;
  }

  getHitCount(): number {
    return this.params.api.getDisplayedRowAtIndex(0)?.data
      ? this.params.api.getDisplayedRowCount()
      : 0;
  }

  showCheckbox(): boolean {
    this.setCheckboxState();
    return this.getHitCount() > 0 && this.getHitCount() <= this.maxHitsRequired;
  }
}
