import { ThreatsService } from '@compumark/bla-backend-client';
import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { GlasspaneService } from '@compumark/brand-context-components';
import { ThreatDatasource } from './interfaces/threat-datasource.interface';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonLawThreatsDatasource implements ThreatDatasource {
  constructor(
    private threatsService: ThreatsService,
    private glasspaneService: GlasspaneService,
  ) {}

  getThreatTableColumnsName(): string {
    return 'commonLawThreatTableColumns';
  }

  getTotalThreatCount(resultsDeliveryId: string): Observable<number> {
    return this.threatsService
      .getCommonLawThreatsAssociatedWithResultsDelivery(resultsDeliveryId, 0, 1)
      .pipe(map((r) => r.totalCount));
  }

  createDatasource(resultsDeliveryId: string): IServerSideDatasource {
    const threatsService = this.threatsService;
    const glasspaneService = this.glasspaneService;

    return {
      getRows(params: IServerSideGetRowsParams<any>): void {
        glasspaneService.showGlasspane();

        threatsService
          .getCommonLawThreatsAssociatedWithResultsDelivery(
            resultsDeliveryId,
            params.request.startRow!,
            params.request.endRow!,
            params.request.filterModel?.name?.filter,
            params.request.filterModel?.applicantName?.filter,
            params.request.filterModel?.goodsAndServices?.filter,
            params.request.filterModel?.jurisdictionName?.values
              ? [params.request.filterModel?.jurisdictionName?.values]
              : undefined,
            params.request.filterModel?.status?.filter
              ? [params.request.filterModel?.status?.filter]
              : undefined,
            params.request.filterModel?.source?.filter
              ? [params.request.filterModel?.source?.filter]
              : undefined,
            params.request.filterModel?.nameRiskLevel?.values,
            params.request.filterModel?.name?.type,
            params.request.filterModel?.verbalCaseLawRiskLevel?.values,
            params.request.filterModel?.pocaScoreRiskLevel?.values,
            params.request.filterModel?.flags?.ids,
            params.request.filterModel?.classes?.values,
            params.request.filterModel?.actions?.filter,
          )
          .pipe(first())
          .subscribe((threats) => {
            params.success({
              rowData: threats.threats,
              rowCount: threats.totalCount,
            });
            glasspaneService.hideGlasspane();
          });
      },
    };
  }
}
