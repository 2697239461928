<div class="title">Include graphics</div>
<div class="select-all checkbox-padding" *ngIf="customize">
  <mat-checkbox
    color="primary"
    [checked]="allChecked"
    (change)="selectAll($event.checked)"
  >
    Select all
  </mat-checkbox>
</div>
<div class="graphics-select">
  <app-graphic-select
    (click)="isAllChecked()"
    [(value)]="data.verbalSimilarity!"
    risk="VERBAL"
    label="Verbal Similarity"
    graphImageName="verbal-similarity"
  >
  </app-graphic-select>
  <app-graphic-select
    (click)="isAllChecked()"
    [(value)]="data.verbalCaseLaw!"
    risk="VERBAL"
    label="Case Law Prediction"
    graphImageName="case-law-prediction-score"
  >
  </app-graphic-select>
  <app-graphic-select
    (click)="isAllChecked()"
    [(value)]="data.pocaScore!"
    risk="VERBAL"
    label="POCA Score"
    graphImageName="poca"
  >
  </app-graphic-select>
  <app-graphic-select
    (click)="isAllChecked()"
    [(value)]="data.riskIndication!"
    risk="GOODS"
    label="Risk Indication"
    graphImageName="risk-indicator"
  >
  </app-graphic-select>
  <ng-container *ngIf="isExpert">
    <app-graphic-select
      (click)="isAllChecked()"
      [(value)]="data.goodsCaseLaw!"
      risk="GOODS"
      label="Case Law Prediction"
      graphImageName="goods-case-law"
    >
    </app-graphic-select>
  </ng-container>
  <app-graphic-select
    (click)="isAllChecked()"
    [(value)]="data.image!"
    label="Image"
    graphImageName="image"
  >
  </app-graphic-select>
  <ng-container *ngIf="isExpert">
    <app-graphic-select
      (click)="isAllChecked()"
      [(value)]="data.geographicPresence!"
      label="Geographic Presence"
      graphImageName="geographic-presence"
    >
    </app-graphic-select>
    <app-graphic-select
      (click)="isAllChecked()"
      [(value)]="data.competitor!"
      risk="OWNER"
      label="Competitor"
      graphImageName="competitor"
    >
    </app-graphic-select>
  </ng-container>
  <app-graphic-select
    [featureLevel]="featureLevel"
    (click)="isAllChecked()"
    [(value)]="data.sizeOfTotalPortfolio!"
    risk="OWNER"
    label="Size of Total Portfolio"
    graphImageName="size-of-total-portfolio"
  >
  </app-graphic-select>
  <app-graphic-select
    [featureLevel]="featureLevel"
    (click)="isAllChecked()"
    [(value)]="data.globality!"
    risk="OWNER"
    label="Globality"
    graphImageName="owner-globality"
  >
  </app-graphic-select>
  <app-graphic-select
    (click)="isAllChecked()"
    [(value)]="data.directCaseHistory!"
    risk="LITIGATION"
    label="Direct Case History"
    graphImageName="direct-case-history"
  >
  </app-graphic-select>
  <app-graphic-select
    (click)="isAllChecked()"
    [(value)]="data.reputationFame!"
    risk="LITIGATION"
    label="Reputation/ Fame"
    graphImageName="reputation-fame"
  >
  </app-graphic-select>
  <app-graphic-select
    (click)="isAllChecked()"
    [(value)]="data.litigiousnessOfOwner!"
    risk="LITIGATION"
    label="Litigiousness of Owner"
    graphImageName="Litigiousness-of-owner"
  >
  </app-graphic-select>
  <app-graphic-select
    (click)="isAllChecked()"
    [(value)]="data.internetPresence!"
    label="Internet Presence"
    graphImageName="internet-presence"
  >
  </app-graphic-select>
</div>
