import { Component } from '@angular/core';
import { CustomTextFilterComponent } from '../../../custom-text-filter/customTextFilter.component';
import { ClassesOptions, NameFilterType } from '@compumark/bla-backend-client';
import { IDoesFilterPassParams, IFilterParams } from '@ag-grid-community/core';

@Component({
  selector: 'app-classes-filter',
  templateUrl: './classes-filter.component.html',
  styleUrls: ['./classes-filter.component.scss'],
})
export class ClassesFilterComponent extends CustomTextFilterComponent {
  readonly classesSearchTypes = {
    includedSearch: {
      id: ClassesOptions.INCLUDED_SEARCH,
      label: 'In class(es) searched',
      checked: false,
    },
    excludedSearch: {
      id: ClassesOptions.EXCLUDED_SEARCH,
      label: 'Out of class(es) searched',
      checked: false,
    },
    inputSearch: {
      id: 'INPUT_SEARCH',
    },
  };

  filterType = 'customTextWithOptions';
  type: NameFilterType = NameFilterType.CONTAINS;
  chipText: string | null = '';
  filterId: string | null = '';

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  isFilterActive(): boolean {
    return (
      (this.filterText != null &&
        this.filterText !== undefined &&
        this.filterText !== '') ||
      this.optionSelected()
    );
  }

  getModel(): any {
    return {
      filter: this.optionSelected() ? this.chipText : this.filterText,
      filterId: this.classesSearchTypes.includedSearch.checked
        ? this.classesSearchTypes.includedSearch.id
        : this.classesSearchTypes.excludedSearch.checked
        ? this.classesSearchTypes.excludedSearch.id
        : this.classesSearchTypes.inputSearch.id,
      type: this.type,
      subFilter: this.subFilter,
      filterType: this.filterType,
    };
  }

  setModel(model: any): void {
    this.reset();
    this.filterText = model?.filter!;
    this.chipText = model?.filter!;
    this.filterType = model?.filterType!;
    this.type = model?.type!;
    this.subFilter = model?.subFilter;
    this.filterId = model?.filterId!;
  }

  update(event: boolean, classType: any): void {
    this.reset();
    if (classType === this.classesSearchTypes.includedSearch.label) {
      this.classesSearchTypes.includedSearch.checked = event;
      this.chipText = this.classesSearchTypes.includedSearch.label;
    } else {
      this.classesSearchTypes.excludedSearch.checked = event;
      this.chipText = this.classesSearchTypes.excludedSearch.label;
    }
    if (this.optionSelected()) {
      this.type = NameFilterType.EXACT;
    } else {
      this.type = NameFilterType.CONTAINS;
    }
  }

  reset(): void {
    this.classesSearchTypes.includedSearch.checked = false;
    this.classesSearchTypes.excludedSearch.checked = false;
    this.filterText = null;
    this.chipText = null;
    this.type = NameFilterType.CONTAINS;
    this.subFilter = '';
    this.filterId = '';
  }

  onApply(): void {
    this.params!.filterChangedCallback();
    this.hidePopup();
  }

  onClear(): void {
    this.reset();
  }

  optionSelected(): boolean {
    return (
      this.classesSearchTypes.includedSearch.checked ||
      this.classesSearchTypes.excludedSearch.checked
    );
  }

  afterGuiAttached(params: any): void {
    this.hidePopup = params?.hidePopup;
    this.inputElement?.nativeElement.focus();
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    if (Array.isArray(this.valueAccessor!(params)!)) {
      return (
        this.valueAccessor!(params)![0]
          .toLowerCase()
          .indexOf(this.filterText!.toLowerCase()) >= 0
      );
    }

    return (
      this.valueAccessor!(params)!
        .toLowerCase()
        .indexOf(this.filterText!.toLowerCase()) >= 0
    );
  }
}
