<div class="filterPopup">
  <div class="filterboxContainer">
    <input
      #input
      class="filterbox"
      type="text"
      (keyup.enter)="onEnter()"
      [(ngModel)]="filterText"
      placeholder="Filter class(es)"
      [disabled]="optionSelected()"
    />
  </div>
  <label class="or-label"> OR </label>
  <div>
    <section class="class-section">
      <p>
        <mat-checkbox
          color="primary"
          [checked]="classesSearchTypes.includedSearch.checked"
          (change)="
            update($event.checked, classesSearchTypes.includedSearch.label)
          "
          >{{ classesSearchTypes.includedSearch.label }}</mat-checkbox
        >
      </p>
      <p>
        <mat-checkbox
          color="primary"
          [checked]="classesSearchTypes.excludedSearch.checked"
          (change)="
            update($event.checked, classesSearchTypes.excludedSearch.label)
          "
          >{{ classesSearchTypes.excludedSearch.label }}</mat-checkbox
        >
      </p>
    </section>
  </div>
  <div class="ag-filter-apply-panel">
    <button
      type="button"
      class="ag-standard-button ag-filter-apply-panel-button"
      (click)="onClear()"
    >
      Clear</button
    ><button
      type="button"
      class="ag-standard-button ag-filter-apply-panel-button"
      (click)="onApply()"
    >
      Apply
    </button>
  </div>
</div>
