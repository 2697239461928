<div>
  <div class="ag-filter-body-wrapper ag-simple-filter-body-wrapper">
    <div class="ag-filter-body">
      <input
        id="filter"
        class="ag-input-field-input ag-text-field-input"
        type="text"
        placeholder="Filter..."
        aria-label="Filter Value"
        [(ngModel)]="filter"
      />
    </div>
  </div>

  <ng-container *ngIf="resultsDelivery$ | async as resultsDelivery">
    <ng-container
      *ngIf="
        !!resultsDelivery?.strategy?.competitorNames?.length &&
        resultsDelivery?.strategy?.featureLevel === 'EXPERT'
      "
    >
      <div class="show-competitors-checkbox">
        <mat-checkbox
          type="checkbox"
          color="primary"
          [(ngModel)]="showCompetitors"
        >
          Show only competitors
        </mat-checkbox>
        <mat-icon style="vertical-align: text-bottom">person</mat-icon>
      </div>
    </ng-container>
  </ng-container>
  <div class="ag-filter-apply-panel">
    <button
      type="button"
      ref="clearFilterButton"
      class="ag-standard-button ag-filter-clear-panel-button"
      (click)="clearFilter()"
    >
      Clear</button
    ><button
      type="button"
      ref="applyFilterButton"
      class="ag-standard-button ag-filter-apply-panel-button"
      (click)="updateFilter()"
    >
      Apply
    </button>
  </div>
</div>
