import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentencecase',
})
export class SentencecasePipe implements PipeTransform {
  transform(data: any): any {
    if (!!data) {
      return data[0].toUpperCase() + data.substr(1).toLowerCase();
    }
    return data;
  }
}
