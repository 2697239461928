<ng-template
  [cdkPortalOutlet]="portal"
  (attached)="
    setInput(
      $event,
      row,
      exportTemplate,
      topThreats,
      resultsDeliveryId,
      featureLevel,
      customize,
      disableSubsection
    )
  "
></ng-template>
