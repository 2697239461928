<div class="select-container" [ngClass]="{ disabled: !isFilteringActive }">
  <div class="save-icon-wrapper">
    <span
      class="material-symbols-outlined save-icon"
      matTooltipPosition="below"
      [matTooltip]="'Save active filter(s) as a template'"
      (click)="openSaveFilterDialog()"
      [matTooltipDisabled]="!isFilteringActive"
      >save</span
    >
  </div>

  <mat-form-field appearance="outline" class="select-size rest-margin">
    <mat-select
      [panelClass]="'save-filter-select'"
      appearance="outline"
      (valueChange)="changeValue($event)"
      [placeholder]="placeHolderMessage"
      [(value)]="this.selectedValue"
      disableOptionCentering
    >
      <mat-select-trigger>
        {{
          this.selectedValue === 'None'
            ? placeHolderMessage
            : this.selectedValue
        }}</mat-select-trigger
      >
      <mat-option
        selected
        *ngFor="let templateName of savedFilterTemplatesNames; let i = index"
        [value]="templateName"
      >
        <div class="option-container">
          <span class="option-name">
            {{ templateName }}
          </span>

          <div class="option-buttons" *ngIf="templateName !== 'None'">
            <span
              mat-button
              (click)="openEditFilterDialog(templateName)"
              class="material-symbols-outlined edit-icon"
            >
              edit
            </span>
            <span
              mat-button
              class="material-symbols-outlined delete-icon"
              (click)="removeSavedFilter(templateName)"
              >delete
            </span>
          </div>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
