import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { FeatureFlagModule } from '../feature-flag/feature-flag.module';

import { ConfirmationDialogComponent } from './components/confirmation-dialog/confimation-dialog.component';
import { ResetTopThreatsLinkComponent } from './components/reset-top-threats-link/reset-top-threats-link.component';

@NgModule({
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    CommonModule,
    FeatureFlagModule,
  ],
  declarations: [ConfirmationDialogComponent, ResetTopThreatsLinkComponent],
  exports: [ResetTopThreatsLinkComponent],
})
export class ResetTopThreatsModule {}
