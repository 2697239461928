<app-widget-header
  [title]="'Absolute Grounds - Case Law'"
  (removeEvent)="remove()"
></app-widget-header>

<div class="bubbles">
  <ng-container *ngIf="resultsDelivery">
    <span
      class="not-found"
      *ngIf="resultsDelivery!.absoluteGrounds!.caseLawDataPoints!.length === 0"
    >
      No data found
    </span>
    <ng-container
      *ngIf="resultsDelivery!.absoluteGrounds!.caseLawDataPoints!.length > 0"
    >
      <ng-container
        *ngFor="
          let caseLawTitle of getCaseLawBubbles(
            resultsDelivery!.absoluteGrounds!.caseLawDataPoints!
          )
        "
      >
        <app-bubble
          [label]="caseLawTitle | sentencecase"
          [numberOfCases]="
            getCaseLawResultsLength(
              resultsDelivery!.absoluteGrounds!.caseLawDataPoints!,
              caseLawTitle
            )
          "
          (click)="
            openCaseLawDialog(
              resultsDelivery.id,
              resultsDelivery!.absoluteGrounds!.caseLawDataPoints!,
              caseLawTitle
            )
          "
          type="HIGHLIGHTED"
          [appCustomTooltip]="
            getCaseLawTooltip(
              resultsDelivery!.absoluteGrounds!.caseLawDataPoints!,
              caseLawTitle
            )
          "
        ></app-bubble>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
