import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-domain-names-dailog',
  templateUrl: './domain-names-dailog.component.html',
  styleUrls: ['./domain-names-dailog.component.scss'],
})
export class DomainNamesDailogComponent {
  threat: any;
  contentType: string;
  resultsDeliveryId: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.threat = data?.threat || {};
    this.contentType = data?.contentType;
    this.resultsDeliveryId = data?.resultsDeliveryId;
  }
}
