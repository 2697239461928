import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommentService } from '@compumark/brand-context-components-backend-client';
import { UserService } from 'src/app/security/services/user.service';
import { ActionRendererService } from 'src/app/threats-tables/services/action-renderer.service';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
})
export class ActionsComponent {
  id!: string;
  comments!: any[];
  name?: string;
  resultsDeliveryId!: string;
  gridApi: any;
  selectedForExport = false;
  params: any;

  constructor(
    public userService: UserService,
    private actionRenderService: ActionRendererService,
    public commentService: CommentService,
    private route: ActivatedRoute,
  ) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.id = params.data.id;
    this.name = params.data.trademarkName || params.data.name;
    this.resultsDeliveryId =
      params.colDef?.cellRendererParams.resultsDelivery.id;
    this.gridApi = params.api;
    this.comments = params.data.comments;
    this.selectedForExport = params.data.selectedForExport;
  }

  get userName(): string {
    return this.userService.getCurrentUser()?.name!;
  }

  get userId(): string {
    return this.userService.getCurrentUser()?.id!;
  }
  onIconClick(event: any): void {
    event.stopPropagation();
    this.params?.onClick?.(this.params);
  }
  onSelectionChanged(): void {
    const selectionType =
      this.route.snapshot.data.selectionType ||
      this.route.snapshot.data.contentType;
    this.actionRenderService
      .toggleSelectForExport(
        this.id,
        this.resultsDeliveryId,
        this.selectedForExport,
        this.gridApi,
        false,
        selectionType,
      )
      .subscribe();
    this.selectedForExport = !this.selectedForExport;
  }
}
