<span [appCustomTooltip]="threat.trademarkName!">{{
  threat.trademarkName
}}</span>
<mat-icon
  *ngIf="
    threat.clusterInfo!.includedInTopThreatsCount! === 1 &&
    threat.clusterInfo!.otherThreatsCount! > 0
  "
  [appCustomTooltip]="tooltip!"
  class="icon cdx-mat-icon-20"
>
  error_outline
</mat-icon>
