import {
  AfterContentInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CoexistencePerMarketColumnStateService } from '../../services/coexistence-per-market-state-service';
import _ from 'lodash';
import { MatMenuTrigger } from '@angular/material/menu';

export interface CheckBoxFilter {
  key: string;
  value: boolean;
}

@Component({
  selector: 'app-coexistence-per-market-keywords-filter',
  templateUrl: './coexistence-per-market-keywords-filter.component.html',
  styleUrls: ['./coexistence-per-market-keywords-filter.component.scss'],
})
export class CoexistencePerMarketKeywordsFilterComponent
  implements AfterContentInit, OnInit {
  @ViewChild('keywordsFilterTrigger') keywordsFilterTrigger!: MatMenuTrigger;
  currentInput = 0;

  @Input()
  resultsDeliveryId?: string;
  @Input()
  keywords?: string[];

  selectedKeywords: string[] = [];
  selectAllKeywords = false;
  keywordsFilters!: CheckBoxFilter[];

  menuOpened = false;

  constructor(private stateService: CoexistencePerMarketColumnStateService) {}

  ngAfterContentInit(): void {
    this.stateService.filter.subscribe((f) => {
      this.keywordsFilters?.forEach((kf) => {
        kf.value = f.keywords!.includes(kf.key);
      });
      this.selectAllKeywords =
        this.keywordsFilters?.find((c) => c.value === false) === undefined;

      this.selectedKeywords = [...this.getSelectedKeywords()];
    });
  }

  ngOnInit(): void {
    this.keywordsFilters = this.keywords!.map((c) => {
      return { key: c, value: true } as CheckBoxFilter;
    });
  }

  onFilterChange(value: boolean, filter: CheckBoxFilter): void {
    filter.value = value;
    this.selectAllKeywords =
      this.keywordsFilters?.find((c) => c.value === false) === undefined;
  }

  onSelectAllFilterChange(value: boolean): void {
    this.keywordsFilters?.forEach((cf) => {
      cf.value = value;
    });
  }

  getSelectedKeywords(): string[] {
    return this.keywordsFilters!.filter((c) => c.value).map((c) => c.key);
  }

  onApply(): void {
    const filter = _.clone(this.stateService.curentFilter);
    filter.keywords = this.keywordsFilters
      ?.filter((c) => !!c.value)
      .map((c) => c.key);
    this.stateService.loadInitialData(this.resultsDeliveryId!, filter);
    this.keywordsFilterTrigger.closeMenu();
  }
}
