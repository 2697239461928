import { Component, Input } from '@angular/core';
import { Strategy } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-bla-search-strategy',
  templateUrl: './bla-search-strategy.component.html',
  styleUrls: ['./bla-search-strategy.component.scss'],
})
export class BlaSearchStrategyComponent {
  @Input() strategy?: Strategy;
  @Input() packageName?: string;

  webCommonLawsDictionary: { [key: string]: any } = {
    US: 'US Web Common Law',
    CA: 'Canada Web Common Law',
    AU: 'Australia Web Common Law',
    UK: 'UK Web Common Law',
  };

  webCommonLaws(webCommonLaws: Array<string>): Array<string> {
    return webCommonLaws.map(
      (webCommonLaw) => this.webCommonLawsDictionary[webCommonLaw],
    );
  }

  classes(classes: Array<string>): Array<number> {
    return classes.map((data) => Number(data!));
  }
}
