import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FooterModule } from '@cdx/ngx-branding';

import { FooterComponent } from './components/footer/footer.component';
import { CustomFooterDirective } from './directives/custom-footer.directive';

@NgModule({
  declarations: [FooterComponent, CustomFooterDirective],
  imports: [CommonModule, FooterModule],
  exports: [FooterComponent],
})
export class AppFooterModule {}
