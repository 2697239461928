<dl>
  <dt>Candidate Name</dt>
  <dd>{{ strategy?.name }}</dd>
  <dt *ngIf="(strategy?.goodsAndServices)!.length > 0">Goods and Services</dt>
  <dd *ngIf="(strategy?.goodsAndServices)!.length > 0">
    {{ strategy?.goodsAndServices | concat: ', ' }}
  </dd>
  <dt *ngIf="strategy?.classes">Classes</dt>
  <dd *ngIf="strategy?.classes">
    {{ classes(strategy?.classes!) | formatClasses: ', ' }}
  </dd>
  <dt>Markets</dt>
  <dd [innerHTML]="strategy!.markets | formatMarkets"></dd>
  <ng-container *ngIf="webCommonLaws(strategy?.commonLawCountries!).length > 0">
    <dt>Web Common Law</dt>
    <dd>
      {{ webCommonLaws(strategy?.commonLawCountries!) | concat: ', ' }}
    </dd>
  </ng-container>
  <ng-container
    *ngIf="strategy?.competitorNames && (strategy?.competitorNames)!.length > 0"
  >
    <dt>Competitors</dt>
    <dd>{{ strategy?.competitorNames | concat: ', ' }}</dd>
  </ng-container>
  <ng-container *ngIf="!!packageName">
    <dt>Search Package</dt>
    <dd>{{ packageName }}</dd>
  </ng-container>
</dl>
