<button mat-button [matMenuTriggerFor]="menu" class="menu" *ngIf="isAdmin()">
  ADMIN
</button>

<mat-menu #menu="matMenu">
  <button mat-button routerLink="/admin/results-deliveries" class="menu-item">
    ORDERS
  </button>
  <button mat-button routerLink="/admin/export" class="menu-item">
    EXPORT
  </button>
  <button mat-button routerLink="/admin/roles" class="menu-item">
    USER ROLES
  </button>
</mat-menu>
