import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import {
  ResultsDelivery,
  ThreatsFilter,
  ThreatsService,
  ThreatSummary,
  User,
} from '@compumark/bla-backend-client';
import _ from 'lodash';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { first } from 'rxjs';

@Component({
  selector: 'app-tile-view',
  templateUrl: './tile-view.component.html',
  styleUrls: ['./tile-view.component.scss'],
})
export class TileViewComponent implements OnInit {
  @Input() resultsDelivery?: ResultsDelivery;
  @Input() user?: User;
  @ViewChild('viewContainer', { static: false, read: VirtualScrollerComponent })
  virtualScroller?: VirtualScrollerComponent;
  items: ThreatSummary[] = [];
  pageSize = 20;
  currentOffset = 0;
  loading = false;
  tileSize = 10;
  imgSize = 40;
  imgSizeStyle?: string;
  totalCount = Infinity;
  tileStyle?: SafeStyle;

  constructor(
    private threatsService: ThreatsService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.setImgSize(this.imgSize);
    this.virtualScroller?.invalidateAllCachedMeasurements();
  }

  onScroll(viewPortItems: ThreatSummary[]): void {
    const lastIndex = this.items.indexOf(
      viewPortItems[viewPortItems.length - 1],
    );
    const threshold = this.items.length - 10;

    if (
      lastIndex >= threshold &&
      !this.loading &&
      this.items.length < this.totalCount
    ) {
      this.loadMore();
    }
  }

  onTileSizeSliderSlide(value: number | null): void {
    if (value !== null) {
      this.setImgSize(value);
      this.virtualScroller?.invalidateAllCachedMeasurements();
    }
  }

  setImgSize(imgSize: number): void {
    this.imgSize = imgSize;
    this.imgSizeStyle = this.imgSize * 1.2 + 150 + 'px';
    this.tileStyle = this.sanitizer.bypassSecurityTrustStyle(
      `repeat(auto-fill, minmax(${this.imgSizeStyle}, 1fr))`,
    );
  }

  loadMore(): void {
    this.loading = true;
    const filter: ThreatsFilter = {
      contentType: 'IMAGE',
    };

    this.threatsService
      .getImageThreatsAssociatedWithResultsDelivery(
        filter,
        this.resultsDelivery?.id!,
        this.currentOffset,
        this.currentOffset + this.pageSize,
      )
      .pipe(first())
      .subscribe((threats) => {
        this.items = [...this.items, ...threats.threats];
        this.totalCount = threats.totalCount;
        this.currentOffset += this.pageSize;
        this.loading = false;
      });
  }
}
