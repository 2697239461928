<app-widget-header [title]="'Risk per Market'" (removeEvent)="remove()">
  <span class="show-all">
    <div class="button" *ngIf="hasData" (click)="expand()">
      <mat-icon fontSet="material-symbols-outlined"> open_in_full </mat-icon>
    </div>
  </span>
</app-widget-header>

<ng-container *ngIf="resultsDelivery">
  <ng-container *ngIf="hasData; else noData">
    <app-risk-per-market-filter
      [resultsDeliveryId]="resultsDelivery.id"
      [classes]="classes"
    ></app-risk-per-market-filter>
  </ng-container>

  <app-risk-per-market
    [resultsDeliveryId]="resultsDelivery.id"
    [classes]="classes"
    [limitNumberOfRows]="true"
    [loadInitialData]="true"
    [selectable]="true"
    (selectedItemsEvent)="selectedItemsEvent($event)"
    (hasDataEvent)="hasDataEvent($event)"
  ></app-risk-per-market>
  <ng-container *ngIf="hasData">
    <app-risk-per-market-description></app-risk-per-market-description>
  </ng-container>
</ng-container>

<ng-template #noData>
  <span class="no-data-available">No data available </span>
</ng-template>
