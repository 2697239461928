<cdx-notification
  *ngIf="isContractInError | async"
  severity="info"
  presentation="banner"
  [dismissable]="true"
  title="We can’t sign into your subscription account."
>
  <div>
    Please contact
    <a
      class="customer-service-link"
      href="https://clarivate.com/contact-us/support/"
      >customer service</a
    >
    for help. You can still run transactional priced searches.
  </div>
</cdx-notification>

<ng-content *ngIf="isContractInError | async"></ng-content>
