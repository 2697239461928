import { Component, Input, OnInit } from '@angular/core';
import { IncludeFulltext, Threat } from '@compumark/bla-backend-client';
import _ from 'lodash';

@Component({
  selector: 'app-include-fulltext',
  templateUrl: './include-fulltext.component.html',
  styleUrls: ['./include-fulltext.component.scss'],
})
export class IncludeFulltextComponent implements OnInit {
  @Input() data!: IncludeFulltext;
  @Input() topThreats!: Threat[];
  allChecked: boolean | undefined = false;

  ngOnInit(): void {
    this.allChecked = this.data.threatIds?.length === this.topThreats.length;
  }

  isChecked(id: string): boolean {
    return _.includes(this.data.threatIds, id);
  }

  onChange(checked: boolean, id: string): void {
    if (checked) {
      this.data.threatIds!.push(id);
    } else {
      _.pull(this.data.threatIds!, id);
    }
    this.allChecked = this.data.threatIds?.length === this.topThreats.length;
  }

  selectAll(checked: boolean): void {
    this.allChecked = checked;
    if (checked) {
      this.topThreats.forEach((tt) =>
        !_.includes(this.data.threatIds, tt.id)
          ? this.data.threatIds!.push(tt.id)
          : undefined,
      );
    } else {
      this.data.threatIds?.splice(0, this.data.threatIds.length);
    }
  }
}
