import { GridApi, ICellRendererParams } from '@ag-grid-community/core';
import { Component, OnInit } from '@angular/core';
import {
  OrderHistory,
  ResultsDeliveryService,
} from '@compumark/bla-backend-client';

@Component({
  selector: 'app-delete-search',
  templateUrl: './delete-search.component.html',
  styleUrls: ['./delete-search.component.scss'],
})
export class DeleteSearchComponent implements OnInit {
  orderHistory!: OrderHistory;
  gridApi!: GridApi;

  constructor(private resultsDeliveryService: ResultsDeliveryService) {}

  agInit(params: ICellRendererParams): void {
    this.orderHistory = params.data;
    this.gridApi = params.api;
  }

  ngOnInit(): void {}

  deleteSearch(): void {
    if (confirm('The order will be deleted. Do you want to continue?')) {
      this.resultsDeliveryService
        .deleteByResultsDeliveryId(this.orderHistory.id)
        .subscribe(() => {
          this.gridApi.refreshServerSideStore({ purge: true });
        });
    }
  }
}
