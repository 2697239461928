<span
  class="keywords-filter filter"
  #keywordsFilterTrigger="matMenuTrigger"
  [matMenuTriggerFor]="marketsFilter"
  (menuOpened)="menuOpened = true"
  (menuClosed)="menuOpened = false"
  [ngClass]="{ menuOpened }"
>
  <span class="chip-label">Keywords</span>
  <mat-icon *ngIf="menuOpened">arrow_drop_up</mat-icon>
  <mat-icon *ngIf="!menuOpened">arrow_drop_down</mat-icon>
</span>

<mat-menu
  #marketsFilter="matMenu"
  class="coexistence-keywords-filter-menu coexistence-filter-menu"
  (click)="$event.stopPropagation()"
>
  <div class="filter-content" (click)="$event.stopPropagation()">
    <div class="select-all" (click)="$event.stopPropagation()">
      <mat-checkbox
        color="primary"
        [checked]="selectAllKeywords"
        (change)="onSelectAllFilterChange($event.checked)"
      >
        Select All</mat-checkbox
      >
      <div class="keywords"></div>
    </div>
    <div class="classes-container" (click)="$event.stopPropagation()">
      <div *ngFor="let keyword of keywordsFilters">
        <div class="checkbox" (click)="$event.stopPropagation()">
          <mat-checkbox
            color="primary"
            [checked]="keyword.value"
            (change)="onFilterChange($event.checked, keyword)"
          >
            {{ keyword.key }}</mat-checkbox
          >
        </div>
      </div>
    </div>
    <div class="actions" (click)="$event.stopPropagation()">
      <button
        mat-raised-button
        [disabled]="getSelectedKeywords().length === 0"
        color="primary"
        class="apply"
        (click)="onApply()"
      >
        Apply
      </button>
    </div>
  </div>
</mat-menu>
