import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { from } from 'rxjs';
import { first } from 'rxjs/operators';

export function shouldRefreshBrandLandscape(
  _: ActivatedRouteSnapshot,
  to: ActivatedRouteSnapshot,
): boolean {
  return !!to.queryParamMap.get('refresh') || _.root.data.refresh;
}

@Injectable()
export class BrandLandscapeRefreshService {
  constructor(private router: Router) {}

  refresh(
    relativeToRoute: ActivatedRoute,
    redirectPath: string[],
    showGlasspane: boolean = false,
  ): void {
    from(
      this.router.navigate(redirectPath, {
        queryParams: {
          refresh: true,
        },
        queryParamsHandling: 'merge',
        relativeTo: relativeToRoute,
        replaceUrl: true,
        state: { glasspane: showGlasspane },
      }),
    )
      .pipe(first())
      .subscribe(() => {
        this.router.navigate([], {
          queryParams: {
            refresh: null,
          },
          queryParamsHandling: 'merge',
          relativeTo: relativeToRoute,
          state: { glasspane: showGlasspane },
        });
      });
  }
}
