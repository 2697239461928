import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-expand-all-header',
  templateUrl: './expand-all-header.component.html',
  styleUrls: ['./expand-all-header.component.scss'],
})
export class ExpandAllHeaderComponent implements IHeaderAngularComp {
  public params!: IHeaderParams;
  expanded = true;

  constructor() {}

  refresh(): boolean {
    return false;
  }
  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  toggleExpanded(): void {
    this.expanded ? this.params.api.collapseAll() : this.params.api.expandAll();
    this.expanded = !this.expanded;
  }

  get icon(): string {
    return this.expanded ? 'expand_more' : 'chevron_right';
  }
}
