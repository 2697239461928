export const EXPORT_TEMPLATE_DEFAULT_SECTIONS_JSON = {
  sections: [
    {
      name: 'openingPages',
      label: 'Opening Pages',
      subSections: [
        {
          name: 'coverPage',
          label: 'Cover Page',
          include: true,
          rows: [
            {
              name: 'showClarivateLogo',
              label: 'Show Clarivate logo on cover page',
              type: 'CHECKBOX',
              value: 'true',
            },
            {
              name: 'customLogo',
              label: 'Include a custom logo',
              type: 'CUSTOM_LOGO_UPLOAD',
            },
          ],
        },
        {
          name: 'tableOfContents',
          label: 'Table of Contents',
          include: true,
          rows: [
            {
              name: 'tableOfContents',
              label: 'Include Table of Contents',
              type: 'CHECKBOX',
              value: 'true',
            },
          ],
        },
      ],
    },
    {
      name: 'general',
      label: 'General',
      subSections: [
        {
          name: 'contact',
          label: 'Contact Information',
          include: true,
          rows: [
            {
              name: 'searchReporter',
              label: 'Search Reporter',
              type: 'TEXT_INPUT',
              include: true,
            },
            {
              name: 'phone',
              label: 'Phone',
              type: 'TEXT_INPUT',
              include: true,
            },
            {
              name: 'email',
              label: 'Email',
              type: 'TEXT_INPUT',
              include: true,
            },
          ],
        },
        {
          name: 'resultsDelivery',
          label: 'Order Information',
          include: true,
          rows: [
            {
              name: 'searchStrategy',
              label: 'Search strategy',
              searchStrategy: { label: 'Search strategy', value: true },
              candidateName: { label: 'Candidate Name', value: true },
              markets: { label: 'Market(s)', value: true },
              goodsAndServices: { label: 'Goods and Services', value: true },
              classes: { label: 'Class(es)', value: true },
              searchDate: { label: 'Search Date', value: true },
              type: 'INPUT_SEARCH_STRATEGY',
              require: true,
            },
            {
              name: 'reportDate',
              label: 'Report Date',
              reportDate: { label: 'Report Date' },
              type: 'INPUT_DATEPICKER',
              require: true,
            },
            {
              name: 'searchRequestor',
              label: 'Search Requestor',
              type: 'TEXT_INPUT',
              include: true,
            },
            {
              name: 'reference',
              label: 'Reference',
              type: 'TEXT_AREA',
              include: true,
            },
          ],
        },
        {
          name: 'scopeAndMethodology',
          label: 'Scope & Methodology',
          include: true,
          rows: [
            {
              name: 'databasesAndSources',
              label: 'Databases & Sources',
              filingDates: { label: 'Filing dates', value: true },
              type: 'DATABASES_AND_SOURCES',
              require: true,
            },
            {
              name: 'limitationsOfLegalAdvice',
              label: 'Limitations of legal advice',
              type: 'TEXT_AREA_WITH_BOILERPLATE',
              include: true,
            },
            {
              name: 'ptoRegulations',
              label: 'PTO regulations',
              type: 'TEXT_AREA_WITH_BOILERPLATE',
              include: true,
            },
            {
              name: 'riskAssessmentFactorsAndLegends',
              label: 'Risk assessment factors and legends',
              type: 'TEXT_AREA_WITH_BOILERPLATE',
              include: true,
            },
          ],
        },
      ],
    },
    {
      name: 'summary',
      label: 'Summary',
      subSections: [
        {
          name: 'inherentRegistrability',
          label: 'Inherent Registrability',
          include: true,
          rows: [
            {
              name: 'includeAbsoluteGroundsIPdecisions',
              label: 'Export selected Absolute Grounds IP decisions',
              type: 'CHECKBOX',
              value: 'true',
              require: true,
            },
            {
              name: 'inherentRegistrabilityRow',
              label: '',
              type: 'TEXT_AREA',
              require: true,
            },
          ],
        },
        {
          name: 'semanticAnalysis',
          label: 'Semantic Analysis',
          include: true,
          rows: [
            {
              name: 'includeAbsoluteGroundsSemanticDefinitions',
              label: 'Export selected Absolute Grounds semantic definitions',
              type: 'CHECKBOX',
              value: 'true',
              require: true,
            },
            {
              name: 'semanticAnalysisRow',
              label: '',
              type: 'TEXT_AREA',
              require: true,
            },
          ],
        },
        {
          name: 'overallAvailability',
          label: 'Overall Availability',
          include: true,
          rows: [
            {
              name: 'overallAvailabilityRow',
              label: '',
              type: 'TEXT_AREA',
              require: true,
            },
          ],
        },
        {
          name: 'includeGraphics',
          label: 'Include Graphics',
          include: true,
          rows: [
            {
              name: 'summaryGraphicSelect',
              label: '',
              type: 'SUMMARY_GRAPHIC_SELECT',
              totalHits: true,
              topThreats: true,
              riskPerMarket: true,
              coexistencePerMarket: true,
              require: true,
            },
          ],
        },
      ],
    },
    {
      name: 'results',
      label: 'Results',
      subSections: [
        {
          name: 'topThreatsOverview',
          label: 'Top Citations Overview',
          include: true,
          rows: [
            {
              name: 'tableSummary',
              label: '',
              type: 'TABLE_SUMMARY',
              threatsColumn: true,
              ownerColumn: true,
              marketColumn: true,
              statusColumn: true,
              verbalSimilarityColumn: true,
              goodsSimilarityColumn: true,
              require: true,
              applicationNumberColumn: true,
              registrationNumberColumn: true,
              flagsColumn: true,
            },
            {
              name: 'topThreatsOverviewComment',
              label: '',
              type: 'TEXT_AREA',
              require: true,
            },
          ],
        },

        {
          name: 'topThreats',
          label: 'Top Citations',
          require: true,
          rows: [
            {
              name: 'topThreats',
              label: '',
              type: 'TOP_THREATS',
              riskAssesment: true,
              nonUseGracePeriod: true,
              inUseCheck: true,
              fullGoodsCaseLawData: true,
              flags: true,
              comments: true,
              reputation: true,
              includesTtab: true,
              refusalInformation: true,
              verbalSimilarity: true,
              goodsSimilarity: true,
              require: true,
            },
            {
              name: 'resultGraphicSelect',
              label: 'Include graphics',
              type: 'RESULT_GRAPH_SELECT',
              verbalSimilarity: true,
              verbalCaseLaw: true,
              pocaScore: true,
              riskIndication: true,
              goodsCaseLaw: true,
              image: true,
              geographicPresence: true,
              competitor: true,
              sizeOfTotalPortfolio: true,
              globality: true,
              directCaseHistory: true,
              reputationFame: true,
              litigiousnessOfOwner: true,
              internetPresence: true,
              require: true,
            },
          ],
        },
        {
          name: 'nonTrademarkResults',
          label: 'Non-Trademark Results',
          require: true,
          rows: [
            {
              name: 'webcommonlaw',
              label: 'Web Common Law',
              type: 'CHECKBOX',
              value: 'true',
              require: true,
            },
            {
              name: 'includeAllIndividualCommentsOfSelectedWclRecords',
              label: 'Include all individual comments of selected records',
              type: 'CHECKBOX',
              value: 'true',
              paddingLeft: true,
              require: true,
            },
            {
              name: 'includeAllFlagsOfSelectedWclRecords',
              label: 'Include all flags of selected records',
              type: 'CHECKBOX',
              value: 'true',
              paddingLeft: true,
              require: true,
            },
            {
              name: 'nonTrademarkResultsComment',
              label: '',
              type: 'TEXT_AREA',
              require: true,
            },
            {
              name: 'commonLaw',
              label: 'Common Law',
              type: 'CHECKBOX',
              value: 'true',
              require: true,
            },
            {
              name: 'includeAllIndividualCommentsOfSelectedCommonLawRecords',
              label: 'Include all individual comments of selected records',
              type: 'CHECKBOX',
              value: 'true',
              paddingLeft: true,
              require: true,
            },
            {
              name: 'includeAllFlagsOfSelectedCommonLawRecords',
              label: 'Include all flags of selected records',
              type: 'CHECKBOX',
              value: 'true',
              paddingLeft: true,
              require: true,
            },
            {
              name: 'commonLawComment',
              label: '',
              type: 'TEXT_AREA',
              require: true,
            },
            {
              name: 'pharmaNames',
              label: 'Pharma Names',
              type: 'CHECKBOX',
              value: 'true',
              require: true,
            },
            {
              name: 'includeAllIndividualCommentsOfSelectedPharmaRecords',
              label: 'Include all individual comments of selected records',
              type: 'CHECKBOX',
              value: 'true',
              paddingLeft: true,
            },
            {
              name: 'includeAllFlagsOfSelectedPharmaRecords',
              label: 'Include all flags of selected records',
              type: 'CHECKBOX',
              value: 'true',
              paddingLeft: true,
            },
            {
              name: 'pharmaNamesComment',
              label: '',
              type: 'TEXT_AREA',
              require: true,
            },
            {
              name: 'companyNames',
              label: 'Company Names',
              type: 'CHECKBOX',
              value: 'true',
              require: true,
            },
            {
              name: 'includeAllIndividualCommentsOfSelectedCompanyNameRecords',
              label: 'Include all individual comments of selected records',
              type: 'CHECKBOX',
              value: 'true',
              paddingLeft: true,
              require: true,
            },
            {
              name: 'includeAllFlagsOfSelectedCompanyNameRecords',
              label: 'Include all flags of selected records',
              type: 'CHECKBOX',
              value: 'true',
              paddingLeft: true,
              require: true,
            },
            {
              name: 'companyNamesComment',
              label: '',
              type: 'TEXT_AREA',
              require: true,
            },
            {
              name: 'domainNames',
              label: 'Domain Names',
              type: 'CHECKBOX',
              value: 'true',
              require: true,
            },
            {
              name: 'includeAllIndividualCommentsOfSelectedDomainNameRecords',
              label: 'Include all individual comments of selected records',
              type: 'CHECKBOX',
              value: 'true',
              paddingLeft: true,
              require: true,
            },
            {
              name: 'includeAllFlagsOfSelectedDomainNameRecords',
              label: 'Include all flags of selected records',
              type: 'CHECKBOX',
              value: 'true',
              paddingLeft: true,
              require: true,
            },
            {
              name: 'domainNamesComment',
              label: '',
              type: 'TEXT_AREA',
              require: true,
            },
          ],
        },
      ],
    },
    {
      name: 'conclusion',
      label: 'Conclusion',
      subSections: [
        {
          name: 'conclusionComment',
          label: '',
          require: true,
          rows: [
            {
              name: 'conclusionCommentRow',
              label: '',
              type: 'TEXT_AREA_WITH_BOILERPLATE',
              require: true,
            },
          ],
        },
      ],
    },
    {
      name: 'appendix',
      label: 'Appendix',
      subSections: [
        {
          name: 'includeFulltextRecordFor',
          require: true,
          rows: [
            {
              name: 'includeFulltext',
              label: '',
              type: 'INCLUDE_FULLTEXT',
              threatIds: [],
              require: true,
            },
            {
              name: 'includeFulltextCommentRow',
              label: '',
              type: 'TEXT_AREA',
              require: true,
            },
          ],
        },
      ],
    },
    {
      name: 'reportSetup',
      label: 'Report Setup',
      subSections: [
        {
          name: 'reportSetupSubSection',
          require: true,
          rows: [
            {
              name: 'reportNameRow',
              label: 'Report Name',
              type: 'TEXT_INPUT',
              require: true,
            },
            {
              name: 'reportFormatRow',
              label: 'Format',
              type: 'TOGGLE_FORMAT',
              value: 'docx',
              require: true,
            },
            {
              name: 'saveTemplateRow',
              label: 'Save this report setup as a template',
              type: 'SAVE_TEMPLATE',
              require: true,
            },
          ],
        },
      ],
    },
  ],
};

export const EXPORT_TEMPLATE_NEW_ADDED_SECTION_JSON = {
  name: 'customSection',
  label: '',
  subSections: [
    {
      name: 'customSubSection',
      label: '',
      include: true,
      rows: [
        {
          name: 'comment',
          label: '',
          type: 'TEXT_AREA',
          include: true,
        },
      ],
    },
  ],
};

export const EXPORT_TEMPLATE_VALUES = {
  id: '635f9b6fff02971e39edfbe2',
  resultsDeliveryId: '635276a2604b9f71360b9309',
  sections: [
    {
      name: 'general',
      label: 'General',
      subSections: [
        {
          name: 'contact',
          label: 'Contact Information',
          rows: [
            {
              name: 'searchReporter',
              label: 'Search Reporter',
              value: '212132',
            },
            {
              name: 'phone',
              label: 'Phone',
            },
            {
              name: 'email',
              label: 'Email',
            },
          ],
        },
      ],
    },
  ],
};

export const EXPORT_TAMPLATE_VALUES_WITH_NEW_ADDED_SECTIONS = {
  sections: [
    {
      name: 'general',
      label: 'General',
      subSections: [
        {
          name: 'contact',
          label: 'Contact Information',
          rows: [
            {
              name: 'searchReporter',
              label: 'Search Reporter',
              type: 'TEXT_INPUT',
            },
            {
              name: 'phone',
              label: 'Phone',
              type: 'TEXT_INPUT',
            },
            {
              name: 'email',
              label: 'Email',
              type: 'TEXT_INPUT',
            },
          ],
        },
        {
          name: 'resultsDelivery',
          label: 'Order Information',
          rows: [
            {
              name: 'searchStrategy',
              label: 'Search strategy',
              searchStrategy: { label: 'Search strategy', value: true },
              candidateName: { label: 'Candidate Name', value: true },
              markets: { label: 'Market(s)', value: true },
              goodsAndServices: { label: 'Goods and Services', value: true },
              classes: { label: 'Class(es)', value: true },
              searchDate: { label: 'Search Date', value: true },
              type: 'INPUT_SEARCH_STRATEGY',
            },
            {
              name: 'reportDate',
              label: 'Report Date',
              reportDate: { label: 'Report Date' },
              type: 'INPUT_DATEPICKER',
            },
            {
              name: 'searchRequestor',
              label: 'Search Requestor',
              type: 'TEXT_INPUT',
            },
            {
              name: 'reference',
              label: 'Reference',
              type: 'TEXT_AREA',
            },
          ],
        },
        {
          name: 'scopeAndMethodology',
          label: 'Scope & Methodology',
          rows: [
            {
              name: 'databasesAndSources',
              label: 'Databases & Sources',
              filingDates: { label: 'Filing dates', value: true },
              type: 'DATABASES_AND_SOURCES',
            },
            {
              name: 'limitationsOfLegalAdvice',
              label: 'Limitations of legal advice',
              type: 'TEXT_AREA',
            },
            {
              name: 'ptoRegulations',
              label: 'PTO regulations',
              type: 'TEXT_AREA',
            },
            {
              name: 'riskAssessmentFactorsAndLegends',
              label: 'Risk assessment factors and legends',
              type: 'TEXT_AREA',
            },
          ],
        },
      ],
    },
    {
      name: 'summary',
      label: 'Summary',
      subSections: [
        {
          name: 'inherentRegistrability',
          label: 'Inherent Registrability',
          rows: [
            {
              name: 'inherentRegistrabilityRow',
              label: '',
              type: 'TEXT_AREA',
            },
          ],
        },
        {
          name: 'semanticAnalysis',
          label: 'Semantic Analysis',
          rows: [
            {
              name: 'semanticAnalysisRow',
              label: '',
              type: 'TEXT_AREA',
            },
          ],
        },
        {
          name: 'overallAvailability',
          label: 'Overall Availability',
          rows: [
            {
              name: 'overallAvailabilityRow',
              label: '',
              type: 'TEXT_AREA',
            },
          ],
        },
        {
          name: 'includeGraphics',
          label: 'Include Graphics',
          rows: [
            {
              name: 'summaryGraphicSelect',
              label: '',
              type: 'SUMMARY_GRAPHIC_SELECT',
              totalHits: true,
              topThreats: true,
              riskPerMarket: true,
              coexistencePerMarket: true,
            },
          ],
        },
      ],
    },
    {
      name: 'results',
      label: 'Results',
      subSections: [
        {
          name: 'topThreatsOverview',
          label: 'Top Threats Overview',
          rows: [
            {
              name: 'tableSummary',
              label: '',
              type: 'TABLE_SUMMARY',
              threatsColumn: true,
              ownerColumn: true,
              marketColumn: true,
              statusColumn: true,
              verbalSimilarityColumn: true,
              goodsSimilarityColumn: true,
              applicationNumberColumn: true,
              registrationNumberColumn: true,
              flagsColumn: true,
            },
            {
              name: 'topThreatsOverviewComment',
              label: '',
              type: 'TEXT_AREA',
            },
          ],
        },

        {
          name: 'topThreats',
          label: 'Top Threats',
          rows: [
            {
              name: 'topThreats',
              label: '',
              type: 'TOP_THREATS',
              riskAssesment: true,
              nonUseGracePeriod: true,
              inUseCheck: true,
              flags: true,
              comments: true,
            },
            {
              name: 'resultGraphicSelect',
              label: 'Include graphics',
              type: 'RESULT_GRAPH_SELECT',
              verbalSimilarity: true,
              verbalCaseLaw: true,
              pocaScore: true,
              riskIndication: true,
              goodsCaseLaw: true,
              image: true,
              geographicPresence: true,
              competitor: true,
              sizeOfTotalPortfolio: true,
              globality: true,
              directCaseHistory: true,
              reputationFame: true,
              litigiousnessOfOwner: true,
              internetPresence: true,
            },
          ],
        },
        {
          name: 'nonTrademarkResults',
          label: 'Non-Trademark Results',
          rows: [
            {
              name: 'webcommonlaw',
              label: 'Web Common Law',
              type: 'CHECKBOX',
              value: 'true',
            },
            {
              name: 'nonTrademarkResultsComment',
              label: '',
              type: 'TEXT_AREA',
            },
          ],
        },
      ],
    },
    {
      name: 'conclusion',
      label: 'Conclusion',
      subSections: [
        {
          name: 'conclusionComment',
          label: '',
          rows: [
            {
              name: 'conclusionCommentRow',
              label: '',
              type: 'TEXT_AREA',
            },
          ],
        },
      ],
    },
    {
      name: 'appendix',
      label: 'Appendix',
      subSections: [
        {
          name: 'includeFulltextRecordFor',
          rows: [
            {
              name: 'includeFulltext',
              label: '',
              type: 'INCLUDE_FULLTEXT',
              threatIds: [],
            },
            {
              name: 'includeFulltextCommentRow',
              label: '',
              type: 'TEXT_AREA',
            },
          ],
        },
      ],
    },
    {
      name: 'reportSetup',
      label: 'Report Setup',
      subSections: [
        {
          name: 'reportSetupSubSection',
          rows: [
            {
              name: 'reportNameRow',
              label: 'Report Name',
              type: 'TEXT_INPUT',
            },
            {
              name: 'reportFormatRow',
              label: 'Format',
              type: 'TOGGLE_FORMAT',
              value: 'docx',
            },
          ],
        },
      ],
    },
    {
      name: 'newAddedSection',
      label: 'New Added Section',
      subSections: [
        {
          name: 'newAddedSubSection',
          label: 'New Added SubSection',
          rows: [
            {
              name: 'comment',
              label: 'Comment',
              type: 'TEXT_AREA',
            },
          ],
        },
      ],
    },
  ],
};
