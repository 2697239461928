import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  GoodsAndServicesText,
  ThreatsService,
} from '@compumark/bla-backend-client';
import { GlasspaneService } from '@compumark/brand-context-components';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import {
  GoodsAndServicesData,
  GoodsAndServicesDialogComponent,
} from '../components/goods-and-services-dialog/goods-and-services-dialog.component';

/* istanbul ignore next */
@Injectable()
export class GoodsAndServicesDialogOpeningService {
  constructor(
    public dialog: MatDialog,
    public threatsService: ThreatsService,
    private glasspaneService: GlasspaneService,
  ) {}

  showGoodsAndServicesText(
    name: string,
    threatId: string,
    resultsDeliveryId: string,
  ): Observable<GoodsAndServicesText[]> {
    return this.handleResult(
      name,
      this.threatsService.getLatestGoodsAndServicesTextForThreatAssociatedWithResultsDelivery(
        resultsDeliveryId,
        threatId,
      ),
    );
  }

  private showDialog(
    goodsAndServicesData: GoodsAndServicesData,
  ): Observable<any> {
    return this.dialog
      .open(GoodsAndServicesDialogComponent, {
        data: goodsAndServicesData,
        autoFocus: false,
        width: '30%',
        height: 'calc(100vh - 6.5rem)',
        position: { right: '0', top: '2.5rem' },
      })
      .afterClosed();
  }

  private handleResult(
    name: string,
    goodsAndServicesText: Observable<GoodsAndServicesText[]>,
  ): Observable<GoodsAndServicesText[]> {
    this.glasspaneService.showGlasspane();

    const pt = goodsAndServicesText.pipe(first());

    pt.subscribe((text) => {
      this.glasspaneService.hideGlasspane();
      this.showDialog({
        name,
        goodsAndServicesText: text,
      } as GoodsAndServicesData);
    });

    return pt;
  }
}
