<span
  class="all-threats-custom-filter filter"
  #insights
  FilterTrigger="matMenuTrigger"
  [matMenuTriggerFor]="insightsFilter"
  (menuOpened)="menuOpened = true"
  (menuClosed)="menuOpened = false"
  [ngClass]="{ menuOpened: menuOpened || selectedItems.length > 0 }"
>
  <span class="chip-label">Risk Per Market</span>

  <mat-icon *ngIf="menuOpened">arrow_drop_up</mat-icon>
  <mat-icon *ngIf="!menuOpened">arrow_drop_down</mat-icon>
</span>

<mat-menu
  #insightsFilter="matMenu"
  class="all-threats-custom-filter-menu"
  (click)="$event.stopPropagation()"
>
  <app-risk-per-market-filter
    [resultsDeliveryId]="resultsDelivery.id"
    [classes]="classes"
    (click)="$event.stopPropagation()"
  ></app-risk-per-market-filter>
  <app-risk-per-market
    [resultsDeliveryId]="resultsDelivery.id"
    [classes]="classes"
    [limitNumberOfRows]="false"
    [loadInitialData]="true"
    [selectable]="true"
    [selectedItems]="selectedItems"
    (selectedItemsEvent)="selectedItemsEvent($event)"
  ></app-risk-per-market>
</mat-menu>
