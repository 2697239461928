<app-widget-header
  [title]="'All Citations - Insights'"
  (removeEvent)="remove()"
></app-widget-header>
<ng-container *ngIf="resultsDelivery">
  <ng-container *ngIf="allThreatsHeatMap$ | async as allThreatsHeatMap">
    <div class="heat-map">
      <lib-heat-map
        [heatMap]="allThreatsHeatMap.heatMap!"
        [ownedBrandsCount]="allThreatsHeatMap.ownedBrandsCount!"
        [competitorsCount]="allThreatsHeatMap.knownCompetitorsCount!"
        [ownCompanies]="resultsDelivery!.strategy!.ownCompanies!"
        [competitorNames]="resultsDelivery!.strategy!.competitorNames!"
        [clickable]="true"
        (selectedHeatMapItemsEvent)="selectedHeatMapItemsEventHandler($event)"
      ></lib-heat-map>
    </div>
  </ng-container>
</ng-container>
