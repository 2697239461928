<ng-container *ngIf="isExpert(); else standart">
  <a
    [routerLink]="summaryRoute"
    *ngIf="isCompleted() && orderHistory.type === 'BRAND_LANDSCAPE_ANALYZER'"
    >Go to summary</a
  >
  <a
    [routerLink]="ownerRoute"
    *ngIf="isCompleted() && orderHistory.type === 'OWNER_ANALYZER'"
    >Go to Insights</a
  >
</ng-container>
<ng-template #standart>
  <a [routerLink]="topThreatsRoute" *ngIf="isCompleted()">Go to top threats</a>
</ng-template>
<span *ngIf="inProgress()"
  ><label class="label-progress">In progress...</label>
</span>
<span *ngIf="hasFailed()"
  ><label class="label-failed">Search has failed</label></span
>
