<app-all-threats-tab-nav-bar
  *ngIf="!fullscreen.getValue() && showNavBar"
></app-all-threats-tab-nav-bar>

<ng-container
  *ngIf="totalThreatCount$ | async as totalThreatCount; else notfound"
>
  <div
    *ngIf="totalThreatCount"
    [ngClass]="fullscreen.getValue() ? 'fullscreen' : ''"
  >
    <div class="actions">
      <div class="filters">
        <app-custom-filters-panel
          [resultsDeliveryId]="resultsDeliveryId"
          [gridApi]="gridApi!"
          [flagsEnabled]="flagsEnabled"
          [selectedThreads]="selectedThreats"
          [showAddToTopThreats]="false"
          (actionSelected)="bulkAction($event)"
        >
        </app-custom-filters-panel>
      </div>
      <div class="actionbar-right-container">
        <div class="separator-line"></div>

        <div class="view-selector" *ngIf="showViewSelection">
          <mat-button-toggle-group
            name="switcher"
            aria-label="Switcher"
            [value]="selectedView"
          >
            <mat-button-toggle
              class="tile"
              value="TILE"
              role="button"
              (click)="toggleView('TILE')"
            >
              <mat-icon fontSet="material-symbols-outlined">grid_on</mat-icon>
              Tile
            </mat-button-toggle>

            <mat-button-toggle
              class="table"
              value="TABLE"
              role="button"
              (click)="toggleView('TABLE')"
              ><mat-icon
                fontSet="material-symbols-outlined"
                style="font-variation-settings: 'FILL' 1"
                >list</mat-icon
              >
              Table</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
        <button mat-icon-button (click)="toggleFullscreen()" class="btn">
          <span
            class="material-symbols-outlined"
            [matTooltip]="fullscreenTooltip"
            >{{ screenIcon }}</span
          >
        </button>
        <app-table-settings
          *appIfFeatureEnabled="'allThreatsTableSettings'"
          (tableSetting)="applyTableSetting($event)"
          (triggerRefreshTable)="refreshTable()"
          [contentType]="contentType"
          [userPreference]="userPreference"
        ></app-table-settings>
      </div>
    </div>

    <lib-filter-chips
      (allFiltersRemoved)="removeAllFilters()"
      (filterRemoved)="removeFilter($event)"
      [filterChips]="(filterChips$ | async)!"
    ></lib-filter-chips>

    <div
      *ngIf="selectedView === 'TABLE'"
      class="splitviewtable"
      [ngClass]="{ 'split-view-enabled': splitViewEnabled }"
    >
      <ag-grid-angular
        [sideBar]="sideBar"
        [gridOptions]="gridOptions"
        class="ag-theme-cdx content-view"
        (rowClicked)="onRowClicked($event)"
        (rowSelected)="onRowSelected($event)"
      ></ag-grid-angular>

      <app-full-text
        *ngIf="splitViewEnabled"
        class="content-view"
        [splitViewData]="splitViewData"
        (navigate)="nextselection($event)"
        [contentType]="contentType"
        [threat]="contentType === 'DOMAIN_NAMES' ? splitViewData?.threat : null"
      ></app-full-text>
    </div>
    <div *ngIf="selectedView === 'TILE'">
      <app-tile-view
        [resultsDelivery]="(resultsDelivery$ | async)!"
        [user]="(currentUser$ | async)!"
      ></app-tile-view>
    </div>
  </div>
</ng-container>

<ng-template #notfound>
  <div class="imageContainer">
    <img src="/assets/img/no-results.svg" />
    <p>
      {{ noResultsMessage }}
    </p>
  </div>
</ng-template>

<router-outlet></router-outlet>
<app-footer></app-footer>
