import { Injectable } from '@angular/core';
import { FeatureLevel, SubSection } from '@compumark/bla-backend-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeatureLevelSubSectionFilterService {
  filteredSubsection(
    subSections: SubSection[],
    featureLevel: FeatureLevel,
  ): SubSection[] {
    const subSectionsToSkip: string[] = [];
    if (featureLevel !== 'EXPERT') {
      subSectionsToSkip.push(
        ...environment.skipExportSubSectionsForStandartFeatureLevel,
      );
    }

    return subSections.filter((ss) => !subSectionsToSkip.includes(ss.name!));
  }
}
