import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
@Component({
  selector: 'app-application-header',
  templateUrl: './application-header.component.html',
  styleUrls: ['./application-header.component.scss'],
})
export class ApplicationHeaderComponent {
  showHistoryLink$: Observable<boolean>;
  showAdminConsole$: Observable<boolean>;
  showBreadcrumb$: Observable<boolean>;

  constructor(private router: Router) {
    this.showHistoryLink$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => {
        const url = (event as NavigationEnd).urlAfterRedirects;
        return url.indexOf('summary') < 0 && url.indexOf('access-denied') < 0;
      }),
    );

    this.showAdminConsole$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => {
        const url = (event as NavigationEnd).urlAfterRedirects;
        return url.indexOf('summary') < 0 && url.indexOf('access-denied') < 0;
      }),
    );

    this.showBreadcrumb$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => {
        const url = (event as NavigationEnd).urlAfterRedirects;
        return (
          url.indexOf('admin') > 0 ||
          url.indexOf('history') > 0 ||
          url.indexOf('account') > 0
        );
      }),
    );
  }

  get isNewSearchentry(): boolean {
    return this.router.url.indexOf('new-search-entry') >= 0;
  }
}
