<div class="account-statistics">
  <h1 class="title">Uncapped Subscription - {{ featureLevelDescription() }}</h1>
  <button
    mat-stroked-button
    color="primary"
    class="cdx-but-md back"
    routerLink="/"
  >
    Back to Initiate
  </button>
  <div class="uncapped-cards">
    <mat-card class="searches-run">
      <app-searches-run-uncapped [stats]="stats"> </app-searches-run-uncapped>
    </mat-card>

    <mat-card class="chart-panel">
      <app-subscription-expiry
        [contract]="stats.contract"
      ></app-subscription-expiry>
    </mat-card>
  </div>
</div>
<app-footer></app-footer>
