<mat-label
  *ngIf="!!data.label && !customize && data.include"
  [ngClass]="{ 'report-editor-disabled-div': disableSubsection }"
  >{{ data.label }}</mat-label
>
<div
  [ngClass]="{
    'report-editor-row-content': !!data.require ? false : customize,
    'report-editor-highlight-div':
      customize && !disableSubsection && data.include,
    'padding-on-customize': customize && data.require
  }"
  class="content"
>
  <div
    [ngClass]="{
      left: !!data.require ? false : customize,
      'report-editor-disabled-div':
        !(data.require || data.include) && !disableSubsection
    }"
    *ngIf="!!data.require || (!customize && data.include) || customize"
  >
    <mat-form-field
      *ngIf="customize"
      appearance="outline"
      class="custom-label cdx-input-default"
    >
      <input
        matInput
        #input
        placeholder="Enter a label (optional)"
        [(ngModel)]="data.label"
        [disabled]="disableSubsection"
        [ngClass]="{
          'report-editor-disabled-div': disableSubsection
        }"
      />
    </mat-form-field>

    <div class="select-template">
      <mat-form-field appearance="outline">
        <mat-label
          class="templates-label"
          [ngClass]="{
            'report-editor-disabled-div': disableSubsection
          }"
          >{{ InLineText }}</mat-label
        >
        <mat-select
          [(ngModel)]="selectedTemplate"
          name="template"
          (openedChange)="isDropdownOpen = $event"
          class="template-dropdown"
          [ngClass]="{
            'report-editor-disabled-div': disableSubsection
          }"
          [placeholder]="displayTemplateName()"
          [disabled]="disableSubsection"
          disableOptionCentering
        >
          <mat-select-trigger>
            {{ displayTemplateName() }}
          </mat-select-trigger>
          <mat-option value="" (click)="setSelectedTemplate(blankTemplate)"
            >None</mat-option
          >
          <mat-option
            *ngFor="let template of data.templates"
            [value]="template"
            (click)="setSelectedTemplate(template)"
          >
            <div class="template-label">
              {{ template.name }}
              <span
                class="material-symbols-outlined delete-button"
                (click)="deleteTemplate(template)"
                >delete</span
              >
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      class="rich-text"
      [ngClass]="{ 'report-editor-disabled-div': disableSubsection }"
      *ngIf="customize || !isTemplateSelected()"
    >
      <quill-editor
        placeholder="Enter a comment"
        [modules]="quillConfiguration"
        [(ngModel)]="data.value"
        (onSelectionChanged)="onSelectionChanged($event)"
        [disabled]="disableSubsection"
      >
      </quill-editor>
    </div>

    <div
      *ngIf="!customize && selectedTemplate"
      class="selected-template-content"
    >
      <span [innerHTML]="selectedTemplate.value"></span>
    </div>

    <div *ngIf="customize" class="save-boilerplate">
      <button
        mat-stroked-button
        color="primary"
        class="cdx-but-md back"
        [ngClass]="{
          'report-editor-disabled-div': disableSubsection
        }"
        (click)="openNameDialog()"
        [disabled]="disableSave(data.value)"
      >
        Save as boilerplate text
      </button>
    </div>
  </div>
  <div
    [ngClass]="{
      right: customize,
      'report-editor-disabled-div': disableSubsection
    }"
    class="customize-toggle"
    *ngIf="!data.require && customize"
  >
    <mat-slide-toggle
      role="button"
      color="primary"
      checked="data.include"
      [(ngModel)]="data.include"
    ></mat-slide-toggle>
  </div>
</div>
