import { GridApi } from '@ag-grid-community/core';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeatMapItem } from '@compumark/bla-backend-client';
import {
  FilterChip,
  FilterChipStateService,
  HeatmapConfirmationOpeningService,
} from '@compumark/brand-context-components';
import _ from 'lodash';
import { Observable, map } from 'rxjs';
import { AllThreatsWithBulkActionsComponent } from '../../bulk-actions/components/all-threats/all-threats.component';

@Component({
  selector: 'app-insights-filter',
  templateUrl: './insights-filter.component.html',
  styleUrls: ['./insights-filter.component.scss'],
})
export class InsightsFilterComponent implements OnChanges {
  @Input()
  gridApi!: GridApi;
  @Input()
  selectedHeatMapFilterChips: FilterChip[] = [];
  @Input() ownedBrandsOnly?: boolean;
  heatmap$: Observable<HeatMapItem[]>;
  menuOpened = false;

  selectedHeatMapItems: HeatMapItem[] = [];

  constructor(
    private route: ActivatedRoute,
    private filterChipStateService: FilterChipStateService,
    private heatmapConfirmationOpeningService: HeatmapConfirmationOpeningService,
  ) {
    this.heatmap$ = route.data.pipe(map((data) => data.heatmap));

    this.getFilterChipsEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.gridApi.currentValue) {
      const queryParams = this.route.snapshot.queryParams;
      if (!!queryParams.nameRiskLevel && !!queryParams.goodsAndServices) {
        this.filterByHeatMap([
          {
            verbal: queryParams.nameRiskLevel,
            goodsAndServices: queryParams.goodsAndServices,
          },
        ]);
      }
    }
  }

  /* istanbul ignore next */
  getFilterChipsEvents(): void {
    this.filterChipStateService
      .getfilterChipChanges()
      .pipe(
        map((filterChips) =>
          filterChips.filter(
            (fc) =>
              fc.field ===
              AllThreatsWithBulkActionsComponent.insightHeatMapItemChipName,
          ),
        ),
        map((filterChips) =>
          filterChips.map(
            (fc) =>
              ({
                goodsAndServices: fc.additionalFilter?.value,
                verbal: fc.value,
              } as HeatMapItem),
          ),
        ),
      )
      .subscribe(
        (selectedItems) => (this.selectedHeatMapItems = selectedItems),
      );
  }

  selectedHeatMapItemsEventHandler(selectedHeatMapItems: HeatMapItem[]): void {
    this.filterByHeatMap(selectedHeatMapItems);
  }

  private filterByHeatMap(selectedHeatMapItems: HeatMapItem[]): void {
    const filterModel = this.gridApi!.getFilterModel();

    const riskPerMarketFilters = this.filterChipStateService
      .getCurrentFilterChips()
      .filter(
        (fc) =>
          fc.field ===
          AllThreatsWithBulkActionsComponent.riskPerMarketHeatMapItemChipName,
      );

    if (
      !!filterModel.nameRiskLevel ||
      !!filterModel.goodsAndServicesRiskLevel ||
      riskPerMarketFilters.length > 0
    ) {
      this.heatmapConfirmationOpeningService
        .openConfirmationPopup('Your current filters will be cleared')
        .subscribe((closeState) => {
          if (closeState === 'CONTINUE') {
            delete filterModel['nameRiskLevel'.toString()];
            delete filterModel['goodsAndServicesRiskLevel'.toString()];
            this.addSelected(selectedHeatMapItems);
          } else {
            this.clearSelected();
          }
          this.updateFilterModel(filterModel);
        });
    } else {
      this.addSelected(selectedHeatMapItems);
      this.updateFilterModel(filterModel);
    }
  }

  /* istanbul ignore next */
  private clearSelected(): void {
    this.selectedHeatMapItems = [];
  }

  private addSelected(selectedHeatMapItems: HeatMapItem[]): void {
    this.filterChipStateService
      .getCurrentFilterChips()
      .filter(
        (fc) =>
          fc.field ===
            AllThreatsWithBulkActionsComponent.insightHeatMapItemChipName ||
          fc.field ===
            AllThreatsWithBulkActionsComponent.riskPerMarketHeatMapItemChipName,
      )
      .forEach((fc) => this.filterChipStateService.remove(fc));

    for (const item of selectedHeatMapItems) {
      const fc = {
        field: AllThreatsWithBulkActionsComponent.insightHeatMapItemChipName,
        label: 'Verbal Risk',
        value: item.verbal,
        graph: AllThreatsWithBulkActionsComponent.insightHeatMapItemChipName,
        additionalFilter: {
          field: 'goodsAndServicesRisk',
          label: 'Goods and Services Risk',
          value: item.goodsAndServices,
        },
      } as FilterChip;

      if (!this.filterChipStateService.exists(fc)) {
        this.filterChipStateService.add(fc);
      }
    }
  }

  private updateFilterModel(filterModel: any): void {
    this.gridApi!.setFilterModel(filterModel);
    this.gridApi!.onFilterChanged();
  }
}
