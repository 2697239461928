import {
  AfterContentInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import _ from 'lodash';
import { MatMenuTrigger } from '@angular/material/menu';
import { RiskPerMarketColumnStateService } from '../services/risk-per-market-state-service';

export interface CheckBoxFilter {
  key: string;
  value: boolean;
}

@Component({
  selector: 'app-risk-per-market-classes-filter',
  templateUrl: './risk-per-market-classes-filter.component.html',
  styleUrls: ['./risk-per-market-classes-filter.component.scss'],
})
export class RiskPerMarketClassesFilterComponent
  implements OnInit, AfterContentInit {
  @ViewChild('classesFilterTrigger') classesFilterTrigger!: MatMenuTrigger;

  @Input()
  resultsDeliveryId?: string;

  @Input()
  classes?: string[];

  menuOpened = false;

  indeterminate = false;
  isInDefaultState = false;
  selectAllClasses = false;
  classesFilters?: CheckBoxFilter[];
  selectedClasses: number[] = [];

  constructor(private stateService: RiskPerMarketColumnStateService) {}

  ngAfterContentInit(): void {
    this.stateService.filter.subscribe((f) => {
      this.classesFilters?.forEach((cf) => {
        cf.value = f.classes!.includes(cf.key);
      });
      this.selectAllClasses =
        this.classesFilters?.find((c) => c.value === false) === undefined;

      this.selectedClasses = [...this.getSelectedClasses()];

      this.isInDefaultState = this.selectAllClasses;
    });
  }

  ngOnInit(): void {
    this.classesFilters = this.classes!.map((c) => {
      return { key: c, value: true } as CheckBoxFilter;
    });
    this.updateSelectAllState();
  }

  updateSelectAllState(): void {
    const selectedCount =
      this.classesFilters?.filter((c) => c.value).length ?? 0;
    this.selectAllClasses =
      selectedCount === (this.classesFilters?.length ?? 0);
    this.indeterminate =
      selectedCount > 0 && selectedCount < (this.classesFilters?.length ?? 0);
  }

  onFilterChange(value: boolean, filter: CheckBoxFilter): void {
    filter.value = value;
    this.selectAllClasses =
      this.classesFilters?.find((c) => c.value === false) === undefined;
    this.updateSelectAllState();
  }

  onSelectAllFilterChange(value: boolean): void {
    this.selectAllClasses = value;
    this.classesFilters?.forEach((cf) => {
      cf.value = value;
    });
    this.updateSelectAllState();
  }

  getSelectedClasses(): number[] {
    return this.classesFilters!.filter((c) => !!c.value).map((c) =>
      parseInt(c.key, 10),
    );
  }

  onApply(): void {
    const filter = _.clone(this.stateService.curentFilter);
    filter.classes = this.classesFilters
      ?.filter((c) => !!c.value)
      .map((c) => c.key);
    this.selectedClasses = [...this.getSelectedClasses()];
    this.isInDefaultState = this.selectAllClasses;
    this.stateService.loadInitialData(this.resultsDeliveryId!, filter);
    this.classesFilterTrigger.closeMenu();
  }
}
