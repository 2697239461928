import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContractType, Price } from '@compumark/bla-backend-client';
import { UserService } from 'src/app/security/services/user.service';

import { PriceFormattingService } from '../../services/price-formatting.service';

@Component({
  selector: 'app-price-confirmation-dialog',
  templateUrl: './price-confirmation-dialog.component.html',
  styleUrls: ['./price-confirmation-dialog.component.scss'],
})
export class PriceConfirmationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public price: Price,
    public priceFormattingService: PriceFormattingService,
    private userService: UserService,
  ) {}

  getSubscriptionMessage(): string {
    const user = this.userService.getCurrentUser();
    if (user.contract?.type === ContractType.CAPPED) {
      return 'This search is covered by your capped subscription.';
    } else if (user.contract?.type === ContractType.UNCAPPED) {
      return 'This search is covered by your uncapped subscription.';
    } else {
      return '';
    }
  }
}
