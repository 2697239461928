import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FullTextService } from '@compumark/bla-backend-client';
import { GlasspaneService } from '@compumark/brand-context-components';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { FullTextDialogComponent } from '../components/full-text-dialog/full-text-dialog.component';

/* istanbul ignore next */
@Injectable()
export class FullTextOpeningService {
  constructor(
    public dialog: MatDialog,
    public fullText: FullTextService,
    private glasspaneService: GlasspaneService,
  ) {}

  showFulltext(threatId: string, resultsDeliveryId: string): void {
    this.handleResult(
      this.fullText.getFulltextForThreatAssociatedWithResultsDelivery(
        threatId,
        resultsDeliveryId,
      ),
    );
  }

  showFulltextForTrademark(
    threatId: string,
    resultsDeliveryId: string,
    trademarkId: string,
  ): void {
    this.handleResult(
      this.fullText.getFulltextByTrademarkIdAsReviewed(
        threatId,
        resultsDeliveryId,
        trademarkId,
      ),
    );
  }

  showDialog(fullText: string): void {
    this.dialog
      .open(FullTextDialogComponent, {
        data: fullText,
        autoFocus: false,
        width: '100%',
      })
      .afterClosed()
      .pipe(first())
      .subscribe();
  }

  private handleResult(fulltext: Observable<string>): void {
    this.glasspaneService.showGlasspane();

    fulltext
      .pipe(
        first(),
        map((fullText) => fullText),
        filter((fullText) => !!fullText),
      )
      .subscribe((fullText) => {
        this.glasspaneService.hideGlasspane();
        this.showDialog(fullText);
      });
  }
}
