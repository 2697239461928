import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxDropzonePreviewComponent } from 'ngx-dropzone';

/* istanbul ignore next */
@Component({
  selector: 'app-custom-dropzone-preview',
  template: `
    <ng-content select="ngx-dropzone-label"></ng-content>
    <div class="description" (click)="$event.stopPropagation()">
      <img [src]="value" />
      <div class="file">
        <span class="filename">{{ file.name }}</span>
        <mat-icon *ngIf="removable" (click)="_remove($event)">delete</mat-icon>
      </div>
    </div>
  `,
  styleUrls: ['./custom-dropzone-preview.component.scss'],
  providers: [
    {
      provide: NgxDropzonePreviewComponent,
      useExisting: CustomDropzonePreviewComponent,
    },
  ],
})
export class CustomDropzonePreviewComponent extends NgxDropzonePreviewComponent {
  @Input()
  value?: string;

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }
}
