import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'concat',
})
export class ConcatPipe implements PipeTransform {
  transform(values: string[] | undefined, separator: string): string {
    if (!!values && values.length > 0) {
      return values.join(separator);
    }

    return '';
  }
}
