import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FlagDefinitionDialogComponent } from '../flag-definitions-dialog/flag-definitions-dialog.component';
import { ResultsDelivery } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-flag-definitions-button',
  templateUrl: './flag-definitions-button.component.html',
})
export class FlagDefinitionsButtonComponent implements OnInit {
  @Input() resultsDelivery?: ResultsDelivery;
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openDialog(): void {
    this.dialog.open(FlagDefinitionDialogComponent, {
      data: {
        resultsDelivery: this.resultsDelivery,
      },
      width: '29.75rem',
      height: '43rem',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
    });
  }
}
