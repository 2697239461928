<div class="title" mat-dialog-title>
  <span>Reset citations?</span>
  <mat-icon mat-dialog-close>close</mat-icon>
</div>

<mat-dialog-content>
  Resetting the citations to the original selection can’t be undone.
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    class="cdx-but-md cdx-but-link"
    color="primary"
    mat-button
    mat-dialog-close
  >
    Cancel
  </button>
  <button
    class="cdx-but-md cdx-but-link"
    color="primary"
    mat-button
    mat-dialog-close="CONTINUE"
  >
    Continue
  </button>
</mat-dialog-actions>
