import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
import { ExportService, ExportTemplate } from '@compumark/bla-backend-client';
import { NotificationService } from '@compumark/brand-context-components';

@Component({
  selector: 'app-save-template',
  templateUrl: './save-template.component.html',
  styleUrls: ['./save-template.component.scss'],
})
export class SaveTemplateComponent implements OnInit {
  @Input() data!: any;
  @Input() resultsDeliveryId!: string;
  @Input() exportTemplate!: ExportTemplate;

  templateNames: string[] = [];
  disabledSubmit = true;

  templateForm!: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private exportService: ExportService,
    private notificationService: NotificationService,
  ) {
    this.populateTemplateNames();
  }

  ngOnInit(): void {
    this.initForm();
  }

  private get form(): UntypedFormGroup {
    return this.fb.group({
      templateName: ['', [this.templateNameExistsValidator()]],
    });
  }

  public get templateNameControl(): AbstractControl {
    return this.templateForm.get('templateName')!;
  }

  hasError(): boolean {
    return this.templateNameControl.hasError('nameAlreadyExists');
  }

  templateNameExistsValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      return this.doesTemplateNameExists(control)
        ? { nameAlreadyExists: true }
        : null;
    };
  }

  doesTemplateNameExists(control: AbstractControl): boolean {
    const nameAlreadyExists =
      this.templateNames.filter((n) => n === control?.value).length > 0;

    return nameAlreadyExists;
  }

  saveTemplate(): void {
    const template = this.exportTemplate;
    template.name = this.templateNameControl.value;
    template.id = undefined;
    template.resultsDeliveryId = undefined;
    this.exportService
      .saveExportTemplate(template, this.resultsDeliveryId)
      .subscribe((exportTemplate) => {
        this.onComplete(exportTemplate);
      });
  }

  private onComplete(exportTemplate: ExportTemplate): void {
    this.populateTemplateNames();
    this.initForm();
    this.disabledSubmit = true;
    this.notificationService.showSnackbarWithTimeout(
      'Your template "' + exportTemplate.name + '" has been saved',
      5000,
    );
  }

  populateTemplateNames(): void {
    this.exportService
      .getExportTemplates()
      .subscribe(
        (data: any) =>
          (this.templateNames = data.map(
            (template: ExportTemplate) => template.name,
          )),
      );
  }

  initForm(): void {
    this.templateForm = this.form;
    this.templateForm.valueChanges.subscribe(() => {
      this.disabledSubmit =
        this.templateNameControl.value === null ||
        !this.templateNameControl.value.trim() ||
        this.doesTemplateNameExists(this.templateNameControl);
    });
  }
}
