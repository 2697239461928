<a
  [routerLink]="summaryRoute"
  *ngIf="isCompleted() && resultsDelivery.type === 'BRAND_LANDSCAPE_ANALYZER'"
  >Go to summary</a
>
<a
  [routerLink]="ownerRoute"
  *ngIf="isCompleted() && resultsDelivery.type === 'OWNER_ANALYZER'"
  >Go to trademarks</a
>
