import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-threat-name-renderer',
  templateUrl: './threat-name-renderer.component.html',
  styleUrls: ['./threat-name-renderer.component.scss'],
})
export class ThreatNameRendererComponent {
  public threat!: any;

  agInit(params: ICellRendererParams): void {
    this.threat = params.data;
  }

  get tooltip(): string {
    return (
      '<strong>Top citations:</strong> ' +
      this.threat.clusterInfo!.includedInTopThreatsCount +
      '<br><strong>Other citations:</strong> ' +
      this.threat.clusterInfo!.otherThreatsCount +
      '<br><strong>Markets:</strong> ' +
      '<span class="tooltip-markets">' +
      this.threat.clusterInfo!.markets.join(', ') +
      '</span>' +
      '<br><strong>Owner:</strong> ' +
      this.threat.clusterInfo!.owner +
      '</strong>'
    );
  }
}
