<div class="dictionary-meaning-dialog">
  <div class="title" mat-dialog-title>
    Absolute Grounds - Dictionary Meaning
  </div>

  <mat-dialog-content>
    <ng-container *ngFor="let item of data.dictionaryMeanings">
      <app-absolute-grounds-item
        *ngFor="let dictionaryLanguage of item.languages"
        [item]="dictionaryLanguage"
        [resultsDeliveryId]="data.resultsDeliveryId"
      >
        <mat-card-header>
          <mat-card-title>
            {{ dictionaryLanguage.word }}: {{ dictionaryLanguage.language }}
          </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <div
            class="row"
            *ngFor="let category of dictionaryLanguage.lexicalCategories"
          >
            <span class="label">{{
              category.lexicalCategory | sentencecase
            }}</span
            ><br />
            <span
              class="value"
              *ngFor="let definition of category.definitions; let i = index"
            >
              {{ i + 1 }}. {{ definition | sentencecase }}<br
            /></span>
          </div>
        </mat-card-content>
      </app-absolute-grounds-item>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      mat-stroked-button
      color="primary"
      class="cdx-but-lg"
      mat-dialog-close
    >
      Close
    </button>
  </mat-dialog-actions>
</div>
