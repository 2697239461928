import { Component } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';

@Component({
  selector: 'app-select-all-checkbox-grouped-rows',
  templateUrl: './select-all-checkbox-grouped-rows.component.html',
  styleUrls: ['./select-all-checkbox-grouped-rows.component.scss'],
})
export class SelectAllCheckboxGroupedRowsComponent
  implements IHeaderAngularComp {
  isAllSelected = false;
  isIndeterminate = false;
  params: any;

  agInit(params: IHeaderParams | any): void {
    this.params = params;

    this.params.api.addEventListener('selectionChanged', () => {
      this.setCheckboxState();
    });
  }

  refresh(): boolean {
    return false;
  }

  setCheckboxState(): void {
    let selectedCount = 0;
    let totalCount = 0;

    this.params.api.forEachNodeAfterFilter((node: any) => {
      if (!node.group) {
        totalCount++;
        if (node.isSelected()) {
          selectedCount++;
        }
      }
    });

    if (selectedCount === 0) {
      this.isAllSelected = false;
      this.isIndeterminate = false;
    } else if (selectedCount === totalCount) {
      this.isAllSelected = true;
      this.isIndeterminate = false;
    } else {
      this.isAllSelected = false;
      this.isIndeterminate = true;
    }
  }

  getHitCount(): number {
    return this.params.api.getDisplayedRowCount();
  }

  toggleSelect(event: any): void {
    this.params.api.forEachNode((node: any) => {
      node.selectThisNode(event.checked);
    });

    this.setCheckboxState();
    this.params.onHeaderButtonClick();
  }
}
