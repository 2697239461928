<mat-slider
  class="slider"
  min="40"
  max="300"
  step="10"
  (change)="onTileSizeSliderSlide($event.value)"
  thumbLabel
>
</mat-slider>

<virtual-scroller
  (vsUpdate)="onScroll($event)"
  class="tile-view-wrapper"
  view
  #viewContainer
  [items]="items"
>
  <div #container [style.grid-template-columns]="tileStyle" class="tile-view">
    <app-tile
      *ngFor="let threat of viewContainer.viewPortItems"
      [threat]="threat"
      [imgSize]="imgSizeStyle"
      [resultsDelivery]="resultsDelivery"
      [user]="user"
    ></app-tile>
  </div>
</virtual-scroller>
