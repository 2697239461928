import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import {
  AccountService,
  AccountStatistics,
} from '@compumark/bla-backend-client';
import { Observable } from 'rxjs';

@Injectable()
export class AccountStatisticsResolver implements Resolve<AccountStatistics> {
  constructor(private accountService: AccountService) {}

  resolve(): Observable<AccountStatistics> {
    return this.accountService.getAccountStatistics();
  }
}
