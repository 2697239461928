<app-widget-header
  [title]="'Top Citations – Summary'"
  (removeEvent)="remove()"
></app-widget-header>

<ng-container *ngIf="resultsDelivery">
  <section
    class="table"
    #widgetContent
    *ngIf="topThreats$ | async as topThreats"
  >
    <app-top-threats
      [resultsDelivery]="resultsDelivery"
      [topThreats]="topThreats!.threats"
      (topThreatsInitEvent)="topThreatsInitEvent()"
    ></app-top-threats>
  </section>
</ng-container>
