<div class="title" mat-dialog-title>
  <mat-icon mat-dialog-close>close</mat-icon>
</div>

<mat-dialog-content>
  <div [innerHTML]="data | safeHtml" class="full-text"></div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-stroked-button
    color="primary"
    class="cdx-but-lg"
    mat-dialog-close
  >
    Close
  </button>
</mat-dialog-actions>
