<ng-container
  *ngIf="{
    packages: packages$ | async,
    selectedPackage: selectedPackage$ | async
  } as data"
>
  <app-package-panel [packages]="data.packages">
    <div class="package-history">
      <div class="header">
        <div class="package-info">
          <div class="name">
            {{ data.selectedPackage?.name }}
            <button
              mat-stroked-button
              color="primary"
              class="cdx-but-md back"
              routerLink="/"
            >
              Back to Initiate
            </button>
          </div>
          <div class="details">
            <label>Package details</label>
            <p>
              {{ data.selectedPackage?.numberOfCandidates }} candidate names,
              {{ marketsDescription(data.selectedPackage!) }},
              {{ featureLevelDescription(data.selectedPackage!) }}
            </p>
            <p>
              Created on
              {{
                data.selectedPackage?.creationDate! * 1000 | date: 'd/MM/yyyy'
              }}
              at
              {{ data.selectedPackage?.creationDate! * 1000 | date: 'h:mm a' }}
            </p>
          </div>
        </div>

        <div class="info">
          {{
            data.selectedPackage?.numberOfCandidates! -
              data.selectedPackage?.numberOfCandidatesRemaining! +
              ' completed / ' +
              data.selectedPackage?.numberOfCandidatesRemaining! +
              ' remaining'
          }}
        </div>
        <div class="body">
          <mat-card
            ><app-history
              [packageId]="data.selectedPackage?.id!"
              [displayFooter]="false"
              packageColumn="YOUR_PRICE"
              class="account-history"
            ></app-history
          ></mat-card>
        </div>
      </div></div
  ></app-package-panel>
</ng-container>
