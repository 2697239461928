<ng-container
  *ngIf="{
    ownerData: ownerData$ | async,
    showLoadingIndicator: showLoadingIndicator$ | async
  } as data"
>
  <app-widget-header
    [title]="'Owner | Portfolio Size'"
    tooltip="Risk associated with this owner based on its number of filings."
    (removeEvent)="remove()"
  ></app-widget-header>

  <div class="body" *ngIf="!data.showLoadingIndicator">
    <lib-risk-measure
      noDataLabel="No cases found"
      *ngIf="data.ownerData!.portfolioSizeScale"
      [exploreResultsLink]="undefined"
      [scaleAndValue]="data.ownerData!.portfolioSizeScale!"
      class="globality"
    ></lib-risk-measure>
    <div class="map-container">
      <lib-portfolio-size
        *ngIf="!!data.ownerData"
        [height]="'360px'"
        [portfolioSize]="data.ownerData"
        [selectable]="false"
      >
      </lib-portfolio-size>
    </div>
    <a
      class="explore-results-link"
      [routerLink]="'../deep-dive/owner/portfolio-size'"
      *ngIf="data.ownerData?.portfolioSize !== 0"
      queryParamsHandling="preserve"
    >
      Explore results
    </a>
  </div>
  <lib-loading-indicator
    *ngIf="data.showLoadingIndicator"
    [showLoadingIndicator]="true"
    [type]="'GRAPH'"
  ></lib-loading-indicator>
</ng-container>
