import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ResultsDelivery } from '@compumark/bla-backend-client';
import { NotificationService } from '@compumark/brand-context-components';
import { Observable, map } from 'rxjs';
import { FullscreenService } from 'src/app/util/services/fullscreen.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  resultsDelivery$: Observable<ResultsDelivery>;
  fullscreen = this.fullscreenService.fullscreenStatus;

  constructor(
    route: ActivatedRoute,
    private router: Router,
    private notification: NotificationService,
    private fullscreenService: FullscreenService,
  ) {
    this.resultsDelivery$ = route.data.pipe(
      map((data) => data.resultsDelivery),
    );
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.notification.closeNotifications();
      }
    });
  }

  isEnabled(): boolean {
    return this.router.url.indexOf('deep-dive') === -1;
  }

  ngOnInit(): void {
    this.notification.closeNotifications();
  }

  isBLA(): boolean {
    return this.router.url.includes('summary');
  }

  isOwnerAnalyzer(): boolean {
    return this.router.url.includes('owner-results');
  }

  isImageSearch(): boolean {
    return this.router.url.includes('image-results');
  }
}
