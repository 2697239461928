import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import {
  ResultsDelivery,
  ResultsDeliveryStatus,
} from '@compumark/bla-backend-client';

@Component({
  selector: 'app-got-to-summary-renderer',
  templateUrl: './go-to-summary-renderer.component.html',
  styleUrls: ['./go-to-summary-renderer.component.scss'],
})
export class GoToSummaryRendererComponent {
  resultsDelivery!: ResultsDelivery;

  agInit(params: ICellRendererParams): void {
    this.resultsDelivery = params.data;
  }

  isCompleted(): boolean {
    return this.resultsDelivery.status!.includes(
      ResultsDeliveryStatus.COMPLETED,
    );
  }

  get summaryRoute(): string[] {
    return ['/', 'summary', 'results-delivery', this.resultsDelivery.id];
  }

  get ownerRoute(): string[] {
    return [
      '/',
      'owner-results',
      'results-delivery',
      this.resultsDelivery.id!,
      'trademarks',
    ];
  }
}
