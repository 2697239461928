import { Component, Input, OnInit } from '@angular/core';
import { Contract } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-subscription-expiry',
  templateUrl: './subscription-expiry.component.html',
  styleUrls: ['./subscription-expiry.component.scss'],
})
export class SubscriptionExpiryComponent implements OnInit {
  @Input() contract?: Contract;
  constructor() {}

  ngOnInit(): void {}

  getDaysRemaining(): any {
    const endDate = parseInt(this.contract?.endDate!, 10) * 1000;
    return Math.round(
      Math.abs((endDate - new Date().valueOf()) / (24 * 60 * 60 * 1000)),
    );
  }
  convertDate(date: any): Date {
    return new Date(parseInt(date, 10) * 1000);
  }
}
