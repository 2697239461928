import { GridApi, GridOptions } from '@ag-grid-community/core';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ResultsDelivery,
  ThreatsService,
  ThreatSummary,
  UpdateThreatSelectionAction,
} from '@compumark/bla-backend-client';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MaterialTooltipComponent } from 'src/app/data-grid/components/material-tooltip/material-tooltip.component';
import { environment } from 'src/environments/environment';

import { MaterialLoadingCellRendererComponent } from '../../../data-grid/components/material-loading-cell-renderer/material-loading-cell-renderer.component';
import { ClusterRendererComponent } from '../cluster-renderer/cluster-renderer.component';
import { ExpandAllHeaderComponent } from '../expand-all-header/expand-all-header.component';
import { RiskLevelRendererComponent } from '../risk-level-renderer/risk-level-renderer.component';
import { ThreatNameRendererComponent } from '../threat-name-renderer/threat-name-renderer.component';
import { ActivatedRoute } from '@angular/router';
import { FlagsComponent } from 'src/app/flag/components/flags/flags.component';

@Component({
  selector: 'app-top-threats',
  templateUrl: './top-threats.component.html',
  styleUrls: ['./top-threats.component.scss'],
})
export class TopThreatsComponent implements OnInit, OnDestroy {
  @Output() selectedThreatsEvent = new EventEmitter<any>();
  @Output() topThreatsInitEvent = new EventEmitter<boolean>(false);

  @Input()
  resultsDelivery?: ResultsDelivery;
  @Input()
  topThreats?: ThreatSummary[];
  selectedThreats: string[] = [];
  gridOptions?: GridOptions;

  ngOnInit(): void {
    this.gridOptions = {
      getRowNodeId: (t) => t.id,
      defaultColDef: {
        resizable: true,
        enableRowGroup: true,
      },
      autoGroupColumnDef: { sortable: true },
      colResizeDefault: 'shift',
      groupMaintainOrder: false,
      groupRemoveSingleChildren: true,
      columnDefs: [
        {
          field: 'clusterRank',
          rowDrag: false,
          rowGroup: true,
          hide: true,
        },
        {
          headerName: 'Citation',
          checkboxSelection: true,
          field: 'trademarkName',
          cellRenderer: 'threatNameRenderer',
          cellClass: 'trademark-name',
          minWidth: 240,
          rowDrag: false,
          suppressMenu: true,
          headerComponent: 'expandAllHeaderComponent',
        },
        {
          headerName: 'Flags',
          field: 'flags',
          cellRenderer: 'flagsRenderer',
          cellRendererParams: {
            resultsDelivery: this.route.snapshot.data.resultsDelivery,
          },
          suppressSizeToFit: true,
          suppressMenu: true,
        },
        {
          headerName: 'Owner',
          field: 'trademarkApplicantName',
          cellRenderer: (params: any) => {
            return params.data.knownCompetitor
              ? params.data.trademarkApplicantName +
                  '<mat-icon class="mat-icon material-icons" style="vertical-align: top; padding-left: 0.5rem; line-height: 1.5; font-size: 1.5rem">person</mat-icon>'
              : params.data.trademarkApplicantName;
          },
          tooltipComponent: 'materialTooltip',
          tooltipValueGetter: (params) => params.data.trademarkApplicantName,
          suppressMenu: true,
        },
        {
          headerName: 'Market',
          field: 'trademarkJurisdictionCode',
          tooltipComponent: 'materialTooltip',
          tooltipValueGetter: (params) => params.data.trademarkJurisdictionName,
          width: 130,
          suppressSizeToFit: true,
          suppressMenu: true,
        },
        {
          headerName: 'Class(es)',
          field: 'classes',
          tooltipComponent: 'materialTooltip',
          tooltipValueGetter: (params) => params.data.classes,
          width: 130,
          suppressSizeToFit: true,
          suppressMenu: true,
        },
        {
          headerName: 'Goods and Services',
          field: 'goodsAndServices',
          tooltipComponent: 'materialTooltip',
          tooltipValueGetter: (params) => params.data.goodsAndServices,
          suppressMenu: true,
        },
        {
          headerName: 'Status',
          field: 'trademarkStatus',
          width: 130,
          suppressSizeToFit: true,
          suppressMenu: true,
        },
        {
          headerName: 'Verbal',
          headerClass: 'verbal-header-text',
          headerTooltip: 'Verbal',
          tooltipComponent: 'materialTooltip',
          children: [
            {
              headerName: 'V',
              headerClass: ['verbal-header-text', 'centered-header-text'],
              headerTooltip: 'Verbal Similarity',
              field: 'nameRiskLevel',
              cellRenderer: 'riskColumnRenderer',
              cellClass: 'centered-cell-content',
              width: 90,
              suppressSizeToFit: true,
              tooltipComponent: 'materialTooltip',
              tooltipValueGetter: (params) => {
                return (
                  'Verbal Similarity: ' +
                  params.data.nameRiskLevel[0] +
                  params.data.nameRiskLevel.substr(1).toLowerCase()
                );
              },
              suppressMenu: true,
            },
            {
              headerName: 'C',
              headerClass: ['verbal-header-text', 'centered-header-text'],
              headerTooltip: 'Verbal Case Law Prediction Score',
              field: 'verbalCaseLawRiskLevel',
              cellRenderer: 'riskColumnRenderer',
              cellClass: 'centered-cell-content',
              width: 90,
              suppressSizeToFit: true,
              tooltipComponent: 'materialTooltip',
              tooltipValueGetter: (params) => {
                return (
                  'Verbal Case Law Prediction Score: ' +
                  params.data.verbalCaseLawRiskLevel[0] +
                  params.data.verbalCaseLawRiskLevel.substr(1).toLowerCase()
                );
              },
              suppressMenu: true,
            },
            {
              headerName: 'P',
              headerClass: ['verbal-header-text', 'centered-header-text'],
              headerTooltip: 'POCA Score',
              field: 'pocaScoreRiskLevel',
              cellRenderer: 'riskColumnRenderer',
              cellClass: 'centered-cell-content',
              width: 90,
              suppressSizeToFit: true,
              tooltipComponent: 'materialTooltip',
              tooltipValueGetter: (params) => {
                return (
                  'Poca Score: ' +
                  params.data.pocaScoreRiskLevel[0] +
                  params.data.pocaScoreRiskLevel.substr(1).toLowerCase()
                );
              },
              suppressMenu: true,
            },
          ],
        },
        {
          headerName: 'Goods',
          headerTooltip: 'Goods and Services',
          tooltipComponent: 'materialTooltip',
          children: [
            {
              headerName: 'G',
              headerClass: 'centered-header-text',
              field: 'goodsAndServicesRiskLevel',
              cellRenderer: 'riskColumnRenderer',
              headerTooltip: 'Goods and Services Similarity',
              cellClass: 'centered-cell-content',
              width: 90,
              suppressSizeToFit: true,
              tooltipComponent: 'materialTooltip',
              tooltipValueGetter: (params) => {
                return (
                  'Goods and Services Similarity: ' +
                  params.data.goodsAndServicesRiskLevel[0] +
                  params.data.goodsAndServicesRiskLevel.substr(1).toLowerCase()
                );
              },
              suppressMenu: true,
            },
          ],
        },
      ],
      frameworkComponents: {
        riskColumnRenderer: RiskLevelRendererComponent,
        materialTooltip: MaterialTooltipComponent,
        clusterRenderer: ClusterRendererComponent,
        expandAllHeaderComponent: ExpandAllHeaderComponent,
        threatNameRenderer: ThreatNameRendererComponent,
        flagsRenderer: FlagsComponent,
      },

      groupDisplayType: 'groupRows',
      groupRowRendererParams: {
        innerRenderer: 'clusterRenderer',
        suppressDoubleClickExpand: true,
        suppressEnterExpand: true,
        rowDrag: false,
        suppressCount: true,
      },
      rowClassRules: {
        child: '!!data.clusterRank',
        hasOneChild: 'data.clusterInfo.includedInTopThreatsCount === 1',
      },
      rowData: this.topThreats,
      loadingCellRendererFramework: MaterialLoadingCellRendererComponent,
      overlayLoadingTemplate: '<span></span>',
      isGroupOpenByDefault: () => true,
      onGridReady: (event) => {
        event.api.sizeColumnsToFit();

        fromEvent(window, 'resize')
          .pipe(untilDestroyed(this), debounceTime(250))
          .subscribe(() => {
            event.api.sizeColumnsToFit();
          });

        this.topThreatsInitEvent.emit(true);

        event.api.forEachNode((row) => {
          if (!!row.id && !row.group) {
            this.selectedThreats.push(row.id);
            row.setSelected(true, false, true);
          }
        });
      },

      onPaginationChanged: (event) => {
        this.refreshSelectedThreats(this.getSelectedNodes(event.api));
      },

      onFirstDataRendered: (event) => {
        event.api.sizeColumnsToFit();
      },

      onSelectionChanged: (event) => {
        const currentSelectedThreats = this.getSelectedNodes(event.api);

        const addedThreats = currentSelectedThreats.filter(
          (threat) => !this.selectedThreats.includes(threat),
        );

        this.updateThreatsSelection(
          addedThreats,
          UpdateThreatSelectionAction.ADD,
        );

        const removedThreats = this.selectedThreats.filter(
          (threat) => !currentSelectedThreats.includes(threat),
        );

        this.updateThreatsSelection(
          removedThreats,
          UpdateThreatSelectionAction.REMOVE,
        );

        this.refreshSelectedThreats(currentSelectedThreats);
        this.selectedThreatsEvent.emit(
          event.api.getSelectedNodes().map((node) => node.data),
        );
      },

      suppressHorizontalScroll: true,
      suppressMovableColumns: false,
      suppressDragLeaveHidesColumns: true,
      suppressCellSelection: true,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      tooltipMouseTrack: true,

      debug: !environment.production,
    };
  }

  constructor(
    private threatsService: ThreatsService,
    private route: ActivatedRoute,
  ) {}

  private getSelectedNodes(gridapi: GridApi): string[] {
    const selectedNodes: string[] = [];
    gridapi.getSelectedNodes().map((selectedNode) => {
      if (selectedNode.id !== undefined) {
        selectedNodes.push(selectedNode.id);
      }
    });
    return selectedNodes;
  }

  private refreshSelectedThreats(threats: string[]): void {
    this.selectedThreats.splice(0, this.selectedThreats.length);
    this.selectedThreats.push(...threats);
  }

  private updateThreatsSelection(
    threats: string[],
    action: UpdateThreatSelectionAction,
  ): void {
    if (threats.length > 0) {
      const updateThreatsSelection = threats.map((t) => ({
        threatId: t,
        action,
      }));
      this.threatsService
        .updateTopThreatsForResultsDelivery(
          this.resultsDelivery?.id!,
          updateThreatsSelection,
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {}
}
