import { FilterChangedEvent } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import {
  FilterChangeService,
  FilterChip,
  FilterChipStateService,
  HeatmapConfirmationOpeningService,
} from '@compumark/brand-context-components';
import _ from 'lodash';
import { AllThreatsWithBulkActionsComponent } from '../bulk-actions/components/all-threats/all-threats.component';

/* istanbul ignore next */
@Injectable()
export class AllThreatsFilterChangeService extends FilterChangeService {
  constructor(
    protected filterChipStateService: FilterChipStateService,
    private heatmapConfirmationOpeningService: HeatmapConfirmationOpeningService,
  ) {
    super(filterChipStateService);
  }

  onFilterChange(event: FilterChangedEvent): void {
    const currentFilters = event.api.getFilterModel();

    const insigthsFilterChips = this.filterChipStateService
      .getCurrentFilterChips()
      .filter(
        (fc) =>
          fc.field ===
          AllThreatsWithBulkActionsComponent.insightHeatMapItemChipName,
      );

    const riskPerMarketFilterChips = this.filterChipStateService
      .getCurrentFilterChips()
      .filter(
        (fc) =>
          fc.field ===
          AllThreatsWithBulkActionsComponent.riskPerMarketHeatMapItemChipName,
      );

    if (
      this.hasConfictedFiltersForInsights(currentFilters, insigthsFilterChips)
    ) {
      this.handleInsights(event, currentFilters, insigthsFilterChips);
      return;
    } else if (
      this.hasConfictedFiltersForRiskPerMarket(
        currentFilters,
        riskPerMarketFilterChips,
      )
    ) {
      this.handleRiskPerMarket(event, currentFilters, riskPerMarketFilterChips);
      return;
    } else {
      if (!_.isEqual(this.appliedFilters, currentFilters)) {
        this.appliedFilters = currentFilters;
      }

      const filterChips = this.buildFilterChipsFromTableFilters(
        currentFilters,
        event,
      );

      if (!!filterChips) {
        this.filterChipStateService.updateTableFilters(filterChips);
      }
    }
  }

  hasConfictedFiltersForRiskPerMarket(
    currentFilters: any,
    filterChips: FilterChip[],
  ): boolean {
    return (
      filterChips.length > 0 &&
      (!!currentFilters.goodsAndServicesRiskLevel ||
        !!currentFilters.nameRiskLevel ||
        !!currentFilters.verbalCaseLawRiskLevel ||
        (!!currentFilters.classes && !!currentFilters.classes.filter) ||
        !!currentFilters.trademarkJurisdictionCode)
    );
  }

  handleRiskPerMarket(
    event: FilterChangedEvent,
    currentFilters: any,
    filterChips: FilterChip[],
  ): void {
    const currentFiltersSnaphot = _.cloneDeep(currentFilters);

    delete currentFilters.goodsAndServicesRiskLevel;
    delete currentFilters.nameRiskLevel;
    delete currentFilters.verbalCaseLawRiskLevel;
    delete currentFilters.classesFilter;
    delete currentFilters.trademarkJurisdictionCode;

    event.api.setFilterModel(currentFilters);

    this.heatmapConfirmationOpeningService
      .openConfirmationPopup(
        'Your All Threats - Risk Per Market filter will be cleared. ',
      )
      .subscribe((closeState) => {
        if (closeState === 'CONTINUE') {
          filterChips.forEach((fc) => {
            this.filterChipStateService.remove(fc);
          });

          this.filterChipStateService.updateTableFilters(
            this.buildFilterChipsFromTableFilters(currentFilters, event),
          );

          event.api.setFilterModel(currentFiltersSnaphot);
        }
      });
  }

  hasConfictedFiltersForInsights(
    currentFilters: any,
    insigthsFilterChips: FilterChip[],
  ): boolean {
    return (
      insigthsFilterChips.length > 0 &&
      (!!currentFilters.goodsAndServicesRiskLevel ||
        !!currentFilters.nameRiskLevel)
    );
  }

  handleInsights(
    event: FilterChangedEvent,
    currentFilters: any,
    insigthsFilterChips: FilterChip[],
  ): void {
    const currentFiltersSnaphot = _.cloneDeep(currentFilters);

    delete currentFilters.goodsAndServicesRiskLevel;
    delete currentFilters.nameRiskLevel;

    event.api.setFilterModel(currentFilters);

    this.heatmapConfirmationOpeningService
      .openConfirmationPopup(
        'Your All Threats - Insights filter will be cleared. ',
      )
      .subscribe((closeState) => {
        if (closeState === 'CONTINUE') {
          insigthsFilterChips.forEach((fc) => {
            this.filterChipStateService.remove(fc);
          });

          this.filterChipStateService.updateTableFilters(
            this.buildFilterChipsFromTableFilters(currentFilters, event),
          );

          event.api.setFilterModel(currentFiltersSnaphot);
        }
      });
  }
}
