import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { InitiateService, UserRole } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-user-roles-admin',
  templateUrl: './user-roles-admin.component.html',
  styleUrls: ['./user-roles-admin.component.scss'],
})
export class UserRolesAdminComponent implements OnInit {
  userId = '';
  dataSource: UserRole[] = [];
  rolesToAdd: string[] = [];
  allRoles: string[] = [];
  userSelected = false;
  selectedRole = '';

  displayedColumns: string[] = ['role', 'delete'];

  constructor(private initiateService: InitiateService) {}

  ngOnInit(): void {
    this.getAllRoles();
  }

  getAllRoles(): void {
    this.initiateService.getAllRoles().subscribe((result: string[]) => {
      this.allRoles = result;
    });
  }

  onSearch(): void {
    if (this.userId !== '') {
      this.refreshDataSourceAndRolesToAdd();
      this.userSelected = true;
    }
  }

  deleteRole(role: string): void {
    if (confirm('The user role will be deleted. Do you want to continue?')) {
      this.initiateService.deleteUserRole(this.userId, role).subscribe(() => {
        this.refreshDataSourceAndRolesToAdd();
      });
    }
  }

  onAddRole(event: MatSelectChange): void {
    this.initiateService.addUserRole(this.userId, event.value).subscribe(() => {
      this.refreshDataSourceAndRolesToAdd();
    });
  }

  refreshDataSourceAndRolesToAdd(): void {
    this.initiateService
      .getUserRoles(this.userId)
      .subscribe((result: UserRole[]) => {
        this.dataSource = result;
        this.rolesToAdd = this.allRoles.filter(
          (item) => !this.dataSource.map((d) => d.role).includes(item),
        );
        this.selectedRole = '';
      });
  }
}
