<div
  class="graph"
  [ngClass]="{
    selected: value,
    standard: isStandard,
    disabled: disableSubsection
  }"
  (click)="toggle(value)"
>
  <span class="risk"> {{ risk }}</span>
  <span class="label"> {{ label }} </span>
  <div class="graph-image" [ngClass]="graphImageName"></div>
</div>
