import { Component } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BreadcrumbService } from './breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class CustomBreadcrumbComponent {
  data$: Observable<Data>;
  items$: Observable<[]> = of([]);
  enabled$: Observable<boolean> = of(true);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
  ) {
    this.data$ = this.route.data;
    this.detectRouteChanges();
  }

  private detectRouteChanges(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.data$ = this.getActivatedRoute(this.route).data;
        this.items$ = this.data$.pipe(
          map((data) =>
            data.breadcrumb?.info?.items?.filter((item: any) =>
              this.showItem(data, item),
            ),
          ),
        );
        this.enabled$ = this.data$.pipe(
          map((data) =>
            'disableBreadcrumb' in data ? !data.disableBreadcrumb : true,
          ),
        );
      });
  }

  showItem(data: Data, item: any): boolean {
    return !item.condition
      ? true
      : new Function('data', 'return ' + item.condition)(data);
  }

  private getActivatedRoute(activatedRoute: ActivatedRoute): ActivatedRoute {
    return activatedRoute.firstChild
      ? this.getActivatedRoute(activatedRoute.firstChild)
      : activatedRoute;
  }

  resolvePath(path: string): string {
    return this.breadcrumbService.resolvePath(path);
  }

  isImageSearch(): boolean {
    return this.router.url.includes('image-search');
  }
}
