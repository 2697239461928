<ng-container class="filter-template dialog">
  <form [formGroup]="filterPresetForm" novalidate>
    <div class="title" mat-dialog-title>
      <div>Edit filter template</div>
      <button mat-icon-button>
        <mat-icon mat-dialog-close>close</mat-icon>
      </button>
    </div>

    <mat-dialog-content class="filter-template">
      <div class="col-5 mb-3">
        <mat-form-field appearance="outline">
          <mat-label>Template Name</mat-label>
          <input
            type="text"
            matInput
            placeholder="Template name"
            formControlName="name"
            maxlength="50"
            #filterTextInput
            [(ngModel)]="templateName"
          />
          <mat-error
            *ngIf="
              getFilterPresetForm.name?.hasError('required') &&
              (getFilterPresetForm.name?.touched ||
                getFilterPresetForm.name?.dirty)
            "
          >
            Name is required.
          </mat-error>

          <mat-error *ngIf="duplicateTemplateName">
            That name is already taken. Please choose another name.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="active-filter-chips">
        <label class="form-label">Active Filters</label>
        <mat-chip-list>
          <div>
            <mat-chip
              *ngFor="let filterChip of filterChips"
              (removed)="removeChip(filterChip)"
              selectable="false"
            >
              <span class="header-name">{{ filterChip.label }}:</span>
              {{ getValueLabel(filterChip) }}
              <ng-container *ngIf="!!filterChip.additionalFilter">
                <pre> - </pre>
                <span
                  *ngIf="!!filterChip.additionalFilter.label"
                  class="header-name"
                  >{{ filterChip.additionalFilter.label }}:</span
                >
                {{ getValueLabel(filterChip.additionalFilter) }}
              </ng-container>

              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </div>
        </mat-chip-list>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button
        class="cdx-but-lg"
        color="primary"
        mat-stroked-button
        mat-dialog-close
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        class="submitBtn"
        [disabled]="!saveFilterBtnEnabled()"
        (click)="onSubmit()"
      >
        Save template
      </button>
    </mat-dialog-actions>
  </form>
</ng-container>
