import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import {
  OrderHistory,
  ResultsDeliveryStatus,
} from '@compumark/bla-backend-client';
import {
  isErrorStatus,
  isTerminalStatus,
} from 'src/app/summary/services/results-delivery-status-follower.service';

@Component({
  selector: 'app-go-to-summary-renderer',
  templateUrl: './go-to-summary-renderer.component.html',
  styleUrls: ['./go-to-summary-renderer.component.scss'],
})
export class GoToSummaryRendererComponent {
  orderHistory!: OrderHistory;

  agInit(params: ICellRendererParams): void {
    this.orderHistory = params.data;
  }

  isCompleted(): boolean {
    return this.orderHistory.status! === ResultsDeliveryStatus.COMPLETED;
  }

  inProgress(): boolean {
    return !isTerminalStatus(this.orderHistory.status!);
  }

  hasFailed(): boolean {
    return isErrorStatus(this.orderHistory.status!);
  }

  isExpert(): boolean {
    return this.orderHistory.featureLevel === 'EXPERT';
  }

  get summaryRoute(): string[] {
    return ['/', 'summary', 'results-delivery', this.orderHistory.id!];
  }

  get topThreatsRoute(): string[] {
    return [
      '/',
      'summary',
      'isolate',
      'results-delivery',
      this.orderHistory.id!,
    ];
  }

  get ownerRoute(): string[] {
    return [
      '/',
      'owner-results',
      'results-delivery',
      this.orderHistory.id!,
      'insights',
      'overview',
    ];
  }

  get imageRoute(): string[] {
    return [
      '/',
      'image-results',
      'results-delivery',
      this.orderHistory.id!,
      'trademarks',
    ];
  }
}
