import { DOCUMENT } from '@angular/common';
import { Directive, Host, Inject, Injector } from '@angular/core';
import { FooterComponent } from '@cdx/ngx-branding';

@Directive({
  selector: '[appCustomFooter]',
})
export class CustomFooterDirective {
  constructor(
    @Host() public host: FooterComponent,
    @Inject(Injector) private injector: Injector,
  ) {
    host.isCookieManagementEnabled = (): boolean => {
      const scripts: HTMLCollectionOf<HTMLScriptElement> = this.document
        .scripts;

      for (const src in scripts) {
        if (
          scripts[src].outerHTML &&
          scripts[src].outerHTML.indexOf('otSDKStub.js') > -1
        ) {
          return true;
        }
      }
      return false;
    };
  }

  private get document(): Document {
    return this.injector.get(DOCUMENT);
  }
}
