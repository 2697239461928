<div class="filter-container">
  <mat-form-field appearance="outline" class="filter-select">
    <mat-select
      [(ngModel)]="conditionOne"
      color="primary"
      name="conditionOne"
      placeholder="Contains"
      disableOptionCentering
      panelClass="dropdown-style"
      tabindex="-1"
    >
      <mat-option class="ag-custom-component-popup" value="CONTAINS"
        >Contains</mat-option
      >
      <mat-option class="ag-custom-component-popup" value="NOT_CONTAINS"
        >Does not contain</mat-option
      >
    </mat-select>
  </mat-form-field>

  <div class="filterboxContainer">
    <input
      #input
      class="filterbox"
      type="text"
      (keyup.enter)="onEnter()"
      [(ngModel)]="filterTextOne"
      placeholder="Filter..."
    />
  </div>

  <div class="operator-row">
    <mat-radio-group [(ngModel)]="operator">
      <mat-radio-button color="primary" value="AND">AND</mat-radio-button>
      <mat-radio-button color="primary" value="OR">OR</mat-radio-button>
    </mat-radio-group>
  </div>

  <mat-form-field appearance="outline" class="filter-select">
    <mat-select
      [(ngModel)]="conditionTwo"
      color="primary"
      name="conditionTwo"
      placeholder="Contains"
      disableOptionCentering
      panelClass="dropdown-style"
    >
      <mat-option class="ag-custom-component-popup" value="CONTAINS"
        >Contains</mat-option
      >
      <mat-option class="ag-custom-component-popup" value="NOT_CONTAINS"
        >Does not contain</mat-option
      >
    </mat-select>
  </mat-form-field>

  <div class="filterboxContainer">
    <input
      #input
      class="filterbox"
      type="text"
      (keyup.enter)="onEnter()"
      [(ngModel)]="filterTextTwo"
      placeholder="Filter..."
    />
  </div>

  <div class="filter-actions">
    <button mat-button color="primary" (click)="onClear()">CLEAR</button>
    <button mat-button color="primary" (click)="onApply()">APPLY</button>
  </div>
</div>
