<ng-container *ngIf="displayAsButton; else anchor">
  <button
    mat-stroked-button
    class="cdx-but-lg"
    color="primary"
    (click)="openConfirmationPopup()"
  >
    {{ resetLabel }}
  </button>
</ng-container>

<ng-template #anchor>
  <a
    #matAnchor
    mat-button
    class="mat-primary cdx-but-link cdx-but-md"
    (click)="openConfirmationPopup()"
    >RESET</a
  >
</ng-template>
