<div class="action-filter">
  <section class="section-options">
    <span>
      <ng-container *ngFor="let option of actionFilterOptions">
        <div>
          <mat-checkbox
            [checked]="option.checked"
            (change)="toggleChecked(option)"
          >
            {{ option.label }} </mat-checkbox
          ><br />
        </div>
      </ng-container>
    </span>
  </section>

  <div class="ag-filter-apply-panel">
    <button
      type="button"
      class="ag-standard-button ag-filter-clear-panel-button"
      (click)="onClear()"
    >
      Clear</button
    ><button
      type="button"
      class="ag-standard-button ag-filter-apply-panel-button"
      (click)="onApply()"
    >
      Apply
    </button>
  </div>
</div>
