import { GridApi } from '@ag-grid-community/core';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-bulk-action-menu',
  templateUrl: './bulk-action-menu.component.html',
  styleUrls: ['./bulk-action-menu.component.scss'],
})
export class BulkActionMenuComponent implements OnChanges {
  @Input() showAddToTopThreats = true;
  @Input()
  disabled = false;
  @Input()
  flagsEnabled!: boolean;
  @Input()
  selectedThreats: Set<string> = new Set();
  @Input()
  gridApi!: GridApi;
  @Output()
  actionSelected: EventEmitter<{
    type: string;
    value?: any;
  }> = new EventEmitter();
  includedInTopThreats = false;
  selectedForExport = false;
  ResultsDelivery = this.route.snapshot.data.resultsDelivery;
  flags: any;

  constructor(private route: ActivatedRoute) {}

  ngOnChanges(): void {
    if (!!this.ResultsDelivery) {
      this.flags = JSON.parse(
        JSON.stringify(this.ResultsDelivery.flagDefinitions.flags),
      );
      this.selectedForExport = false;
    }
  }

  onclick(action: string): void {
    this.actionSelected.emit({ type: action });
  }

  onExport(action: string): void {
    this.actionSelected.emit({
      type: action,
      value: true,
    });
  }

  flagClick(flagValue: any): void {
    flagValue.checked = !flagValue.checked;
    this.actionSelected.emit({ type: 'flag', value: flagValue });
  }
}
