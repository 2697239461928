import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CoexistencePerMarketFilter } from '@compumark/bla-backend-client';
import _ from 'lodash';
import {
  RiskPerMarketColumnStateService,
  RiskPerMarketFilters,
} from '../services/risk-per-market-state-service';

export interface CheckBoxFilter {
  key: string;
  value: boolean;
}

@Component({
  selector: 'app-risk-per-market-filter',
  templateUrl: './risk-per-market-filter.component.html',
  styleUrls: ['./risk-per-market-filter.component.scss'],
})
export class RiskPerMarketFilterComponent implements OnInit {
  @Input()
  resultsDeliveryId?: string;
  @Input()
  classes?: string[];
  @Input()
  showMarketsFilter = true;

  currentFilter$?: Observable<CoexistencePerMarketFilter>;
  defaultFilter?: RiskPerMarketFilters;

  constructor(private stateService: RiskPerMarketColumnStateService) {
    this.currentFilter$ = this.stateService.filter;
  }

  ngOnInit(): void {
    this.defaultFilter = {
      classes: this.classes,
    };
  }

  clearAll(): void {
    this.stateService.loadInitialData(
      this.resultsDeliveryId!,
      _.clone(this.defaultFilter!),
    );
  }

  isFilterSame(currentFilter: RiskPerMarketFilters): boolean {
    return _.isEqual(this.defaultFilter, currentFilter);
  }
}
