<div class="brandlandscape-navigation">
  <nav mat-tab-nav-bar class="navigation-tabs">
    <a mat-icon-button mat-tab-link class="home">
      <app-choose-product></app-choose-product>
    </a>
    <a mat-tab-link [disabled]="true">
      <div class="delimiter">|</div>
      <div class="owner-tab">Image Search</div>
    </a>

    <a
      mat-tab-link
      routerLinkActive
      #rla="routerLinkActive"
      [active]="isActiveTab('trademarks')"
      [routerLinkActiveOptions]="{ exact: false }"
      queryParamsHandling="preserve"
      (click)="openTrademarkPage()"
    >
      All Citations
    </a>
  </nav>
</div>
