import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { HideDirective } from './directives/hide';
import { SafeHtmlPipe } from './directives/safe-html.pipe';
import { SafeUrlPipe } from './directives/safe-url.pipe';
import { FilingDatesComponent } from './components/filing-dates/filing-dates.component';
import { FormatClassesPipe } from './pipes/format-classes.pipe';
import { CommentsRendererComponent } from './components/comments-renderer/comments-renderer.component';
import { BrandContextComponentsModule } from '@compumark/brand-context-components';
import { TableColumnService } from './services/table-column-service';
import { CountryRendererComponent } from './components/country-renderer/country-renderer.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SaveFilterDialogComponent } from './components/save-filter-dialog/save-filter-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { OwnerComparatorService } from './services/owner-comparator.service';
import { EditFilterDialogComponent } from './components/edit-filter-dialog/edit-filter-dialog.component';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { SelectAllHeaderComponent } from './components/select-all-header/select-all-header.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [
    BrandContextComponentsModule,
    CommonModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatChipsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    FilingDatesComponent,
    HideDirective,
    ProgressSpinnerComponent,
    SafeHtmlPipe,
    SafeUrlPipe,
    FormatClassesPipe,
    CommentsRendererComponent,
    CountryRendererComponent,
    SaveFilterDialogComponent,
    EditFilterDialogComponent,
    SelectAllHeaderComponent,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard',
        floatLabel: 'always',
      } as MatFormFieldDefaultOptions,
    },
    TableColumnService,
    OwnerComparatorService,
  ],
  exports: [
    FilingDatesComponent,
    HideDirective,
    ProgressSpinnerComponent,
    SafeHtmlPipe,
    SafeUrlPipe,
    FormatClassesPipe,
  ],
})
export class UtilModule {}
