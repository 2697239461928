import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ResultsDelivery,
  UpdateThreatSelection,
} from '@compumark/bla-backend-client';

@Component({
  selector: 'app-image-navigation-bar',
  templateUrl: './image-navigation-bar.component.html',
  styleUrls: ['./image-navigation-bar.component.scss'],
})
export class ImageNavigationBarComponent {
  @Input() resultsDelivery?: ResultsDelivery | undefined;

  updatedThreatsSelection: UpdateThreatSelection[] = [];

  constructor(private route: ActivatedRoute, private router: Router) {}

  isActiveTab(tab: string): boolean {
    return this.router.url.includes(tab);
  }

  get resultsDeliveryId(): string {
    return this.route.snapshot.params.resultsDeliveryId;
  }

  openTrademarkPage(): void {
    this.router.navigate([
      '/image-results/results-delivery/' +
        this.resultsDeliveryId +
        '/trademarks',
    ]);
  }
}
