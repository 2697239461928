<ng-container *ngIf="result$ | async as result">
  <table
    *ngIf="!!result.table.rows && getTable(result).rows!.length > 0"
    [ngClass]="{ selectable: selectable }"
  >
    <tr *ngIf="!limitNumberOfRows" class="sticky">
      <td class="label-y"></td>
      <td class="label-x">Verbal Similarity</td>
      <td class="label-x">Goods Similarity</td>
      <td class="label-x">Verbal Case Law Score</td>
    </tr>

    <tr *ngFor="let row of getTable(result).rows">
      <td class="label-y">
        {{ row.jurisdiction }}
      </td>
      <td
        class="count"
        [ngClass]="[
          getBackgroundClass(row.nameRisk),
          getSelectionClass('nameRisk', row.jurisdiction!)
        ]"
        (click)="toggleSelection('nameRisk', row.nameRisk!, row.jurisdiction!)"
      >
        {{ row.nameRiskCount }}
      </td>
      <td
        class="count"
        [ngClass]="[
          getBackgroundClass(row.goodsAndServicesRisk),
          getSelectionClass('goodsAndServicesRisk', row.jurisdiction!)
        ]"
        (click)="
          toggleSelection(
            'goodsAndServicesRisk',
            row.goodsAndServicesRisk!,
            row.jurisdiction!
          )
        "
      >
        {{ row.goodsAndServicesRiskCount }}
      </td>
      <td
        class="count"
        [ngClass]="[
          getBackgroundClass(row.verbalCaseLawRisk),
          getSelectionClass('verbalCaseLawRisk', row.jurisdiction!)
        ]"
        (click)="
          toggleSelection(
            'verbalCaseLawRisk',
            row.verbalCaseLawRisk!,
            row.jurisdiction!
          )
        "
      >
        {{ row.verbalCaseLawRiskCount }}
      </td>
    </tr>

    <tr *ngIf="limitNumberOfRows">
      <td class="label-y"></td>
      <td class="label-x">Verbal Similarity</td>
      <td class="label-x">Goods Similarity</td>
      <td class="label-x">Verbal Case Law Score</td>
    </tr>
  </table>
</ng-container>
