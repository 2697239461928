import { Component, Input, OnInit } from '@angular/core';
import { FeatureLevel, TopThreats } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-top-threats',
  templateUrl: './top-threats.component.html',
  styleUrls: ['./top-threats.component.scss'],
})
export class TopThreatsComponent implements OnInit {
  @Input() data!: TopThreats;
  @Input() featureLevel!: FeatureLevel;

  constructor() {}

  ngOnInit(): void {
    if (!this.isExpert) {
      this.data.inUseCheck = false;
      this.data.nonUseGracePeriod = false;
      this.data.fullGoodsCaseLawData = false;
    }
  }

  get isExpert(): boolean {
    return this.featureLevel === 'EXPERT';
  }
}
