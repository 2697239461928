import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PriceOverview } from '@compumark/bla-backend-client';

import { PriceFormattingService } from '../../services/price-formatting.service';

@Component({
  selector: 'app-price-overview-dialog',
  templateUrl: './price-overview-dialog.component.html',
  styleUrls: ['./price-overview-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PriceOverviewDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PriceOverview,
    private priceFormattingService: PriceFormattingService,
  ) {}

  formatPrice(price: number): string {
    return this.priceFormattingService.formatPrice(price!);
  }

  print(): void {
    window.print();
  }
}
