<ng-container *ngIf="resultsDelivery$ | async as resultsDelivery">
  <div class="header" *ngIf="!fullscreen.getValue()">
    <app-brandlandscape-navigation-bar
      *ngIf="isBLA()"
      [resultsDelivery]="resultsDelivery"
    ></app-brandlandscape-navigation-bar>
    <app-owner-navigation-bar
      *ngIf="isOwnerAnalyzer()"
      [resultsDelivery]="resultsDelivery"
    ></app-owner-navigation-bar>
    <app-image-navigation-bar
      *ngIf="isImageSearch()"
      [resultsDelivery]="resultsDelivery"
    ></app-image-navigation-bar>
    <div class="header-button">
      <app-search-strategy-button
        [strategy]="resultsDelivery.strategy"
        [packageName]="resultsDelivery.pricePackage?.name"
        [orderType]="resultsDelivery.type"
      ></app-search-strategy-button>
      <app-export
        *appIfFeatureEnabled="'export'"
        [resultsDelivery]="resultsDelivery"
      ></app-export>

      <button mat-button [matMenuTriggerFor]="menu" class="cdx-but-menu menu">
        <mat-icon fontSet="material-symbols-outlined">menu</mat-icon> Menu
      </button>
      <mat-menu #menu="matMenu">
        <div mat-menu-item>
          <app-history-link class="menu-item"></app-history-link>
        </div>

        <div mat-menu-item>
          <app-account-link class="menu-item"></app-account-link>
        </div>

        <div mat-menu-item>
          <app-flag-definitions-button
            [resultsDelivery]="resultsDelivery"
            class="menu-item"
          ></app-flag-definitions-button>
        </div>
      </mat-menu>

      <app-notification-dropdown></app-notification-dropdown>
    </div>
  </div>
  <section class="content">
    <router-outlet></router-outlet>
  </section>
</ng-container>
