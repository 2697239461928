import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ResultsDelivery,
  SummaryService,
  TopThreatsPerCountry,
} from '@compumark/bla-backend-client';
import { Observable } from 'rxjs';
import { WIDGETS } from 'src/app/widgets/widget/widget.component';

@Component({
  selector: 'app-geographic-presece-of-top-threats',
  templateUrl: './geographic-presece-of-top-threats.component.html',
  styleUrls: ['./geographic-presece-of-top-threats.component.scss'],
})
export class GeographicPresenceOfTopThreatsWidgetComponent {
  @Output() removeEvent = new EventEmitter<string>();
  resultsDelivery: ResultsDelivery;
  topThreatsPerCountry$: Observable<TopThreatsPerCountry[]>;

  constructor(route: ActivatedRoute, summaryService: SummaryService) {
    this.resultsDelivery = route.snapshot.data.resultsDelivery;
    this.topThreatsPerCountry$ = summaryService.getGeographicPresence(
      this.resultsDelivery.id,
    );
  }

  remove(): void {
    this.removeEvent.emit(WIDGETS.GEOGRAPHIC_PRESENCE_OF_TOP_THREATS);
  }
}
