import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-order-history-details',
  templateUrl: './order-history-details.component.html',
  styleUrls: ['./order-history-details.component.scss'],
})
export class OrderHistoryDetailsComponent {
  orderMetrics?: any;
  kibanaUrl?: string;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.orderMetrics = params.data.orderMetrics;
    this.kibanaUrl = params.data.kibanaUrl;
  }
}
