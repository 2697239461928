import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TemplateDataService } from '../../services/template-data.service';
import Quill from 'quill';
import { NameInputDialogComponent } from '../name-input-dialog/name-input-dialog.component';
import { Row, Template } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-text-area-with-boilerplate',
  templateUrl: './text-area-with-boilerplate.component.html',
  styleUrls: ['./text-area-with-boilerplate.component.scss'],
})
export class TextAreaWithBoilerplateComponent implements OnInit {
  readonly BOILERPLATE_TEXT_TEMPLATE = 'Boilerplate text templates';
  @Input() data!: Row;
  @Input() customize = false;
  @Input() disableSubsection = false;
  selectedTemplate!: any;
  isDropdownOpen = false;

  blankTemplate: Template = {
    name: this.BOILERPLATE_TEXT_TEMPLATE,
    value: '',
    defaultTemplate: true,
  };

  quillConfiguration = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    },
  };

  constructor(
    public dialog: MatDialog,
    private templateDataService: TemplateDataService,
  ) {}

  onSelectionChanged = (event: any) => {
    if (event.oldRange == null) {
      this.onFocus(event);
    }
    if (event.range == null) {
      this.onBlur(event);
    }
  };

  onFocus(event: any): void {
    event.editor.theme.modules.toolbar.container.style.display = 'block';
    event.editor.theme.options.container.style.borderRadius =
      '0rem 0rem 0.3rem 0.3rem';
  }

  onBlur(event: any): void {
    event.editor.theme.modules.toolbar.container.style.display = 'none';
    event.editor.theme.options.container.style.borderRadius = '0.3rem';
  }

  ngOnInit(): void {
    const bold = Quill.import('formats/bold');
    bold.tagName = 'B';
    Quill.register(bold, true);

    const italic = Quill.import('formats/italic');
    italic.tagName = 'i';
    Quill.register(italic, true);

    this.data.templates?.find((t) => {
      if (t.defaultTemplate) {
        this.selectedTemplate = t;
      }
    });

    if (!this.selectedTemplate) {
      this.selectedTemplate = this.blankTemplate;
    }
  }

  isTemplateSelected(): boolean {
    return !!this.selectedTemplate && this.selectedTemplate.value !== '';
  }

  openNameDialog(): void {
    const dialogName = this.dialog.open(NameInputDialogComponent, {
      data: this.data.templates,
    });
    dialogName.afterClosed().subscribe((result) => {
      if (result && result.data) {
        const template: Template = {
          name: result.data,
          value: this.data.value?.trim() || '',
          defaultTemplate: true,
        };
        this.data.templates = this.data.templates || [];
        this.data.templates?.push(template);
        this.templateDataService.saveReportTemplate();
        this.selectedTemplate = template;
      }
    });
  }

  disableSave(value: any): boolean {
    if (!this.data.value) {
      return true;
    }
    return this.data.templates?.some((t) => t.value === value) ?? false;
  }

  setSelectedTemplate(template: Template): void {
    this.data.templates?.forEach((t) => {
      t.defaultTemplate = false;
    });
    template.defaultTemplate = true;
    this.data.value = template.value;
    this.templateDataService.saveReportTemplate();
  }

  displayTemplateName(): string {
    if (this.isDropdownOpen) {
      return '';
    }
    return this.selectedTemplate?.name
      ? this.selectedTemplate?.name
      : this.BOILERPLATE_TEXT_TEMPLATE;
  }

  deleteTemplate(template: Template): void {
    this.data.templates = this.data.templates?.filter((t) => t !== template);
    this.templateDataService.saveReportTemplate();
  }

  get InLineText(): string {
    if (this.isTemplateSelected() || this.isDropdownOpen) {
      return this.BOILERPLATE_TEXT_TEMPLATE;
    }
    return '';
  }
}
