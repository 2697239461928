<div class="content">
  <div class="label">{{ data.label }}</div>
  <form [formGroup]="templateForm">
    <mat-form-field appearance="outline">
      <mat-label>Template Name</mat-label>
      <input matInput formControlName="templateName" />
      <mat-error *ngIf="templateNameControl.hasError('nameAlreadyExists')">
        This template name is already in use. Please choose a new name.
      </mat-error>
    </mat-form-field>
  </form>
  <div class="action">
    <button
      mat-stroked-button
      color="primary"
      class="cdx-but-md back"
      [disabled]="disabledSubmit"
      (click)="saveTemplate()"
    >
      Save Template
    </button>
  </div>
</div>
