import { Component, Input } from '@angular/core';
import {
  ExportTemplate,
  FeatureLevel,
  Section,
  SubSection,
  Threat,
} from '@compumark/bla-backend-client';

import { FeatureLevelSubSectionFilterService } from '../../services/feature-level-subsection-filter.service';

@Component({
  selector: 'app-report-section-content',
  templateUrl: './report-section-content.component.html',
  styleUrls: ['./report-section-content.component.scss'],
})
export class ReportSectionContentComponent {
  readonly OpeningPagesNoSubsectionsMessage = '';
  @Input() exportTemplate!: ExportTemplate;
  @Input() selectedSection!: Section;
  @Input() selectedSubSection!: SubSection;
  @Input() topThreats: Threat[] = [];
  @Input() resultsDeliveryId!: string;
  @Input() featureLevel!: FeatureLevel;
  @Input() customizeMode = false;
  subSection!: SubSection;

  constructor(
    public featureLevelSubSectionFilterService: FeatureLevelSubSectionFilterService,
  ) {}

  getId(subSection: SubSection): string {
    let index = '';
    this.selectedSection.subSections?.forEach((ss, i) => {
      if (ss === subSection) {
        index = i.toString();
      }
    });
    return subSection.name! + index;
  }

  showSubSection(sub: SubSection): boolean {
    return this.selectedSection && this.selectedSection.name !== 'reportSetup'
      ? sub?.require || this.customizeMode || !!sub?.include
      : true;
  }

  showSubsectionAbsenceMessage(sec: Section): boolean {
    return !!(
      !this.customizeMode &&
      sec?.subSections?.every((sub) => !sub.require && !sub.include)
    );
  }

  disableSubsection(item: any): boolean {
    return item?.require ? false : !item.include;
  }
}
