import { Component, Input, OnInit } from '@angular/core';
import { Data } from '@angular/router';
import { WclService } from '@compumark/bla-backend-client';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-wcl-menu-item',
  templateUrl: './wcl-menu-item.component.html',
  styleUrls: ['./wcl-menu-item.component.scss'],
})
export class WCLMenuItemComponent implements OnInit {
  @Input()
  path?: string;
  @Input()
  item?: any;
  @Input()
  data?: Data;

  loaded = false;
  checkStatus$?: Subscription;

  constructor(private wclService: WclService) {}
  ngOnInit(): void {
    this.checkStatus$ = timer(0, 10000)
      .pipe(
        switchMap(() =>
          this.wclService.getWCLThreatsStatusWithResultsDelivery(
            this.data!.resultsDelivery.id,
          ),
        ),
      )
      .subscribe((result) => {
        if (result.loaded) {
          this.loaded = result.loaded;
          this.checkStatus$?.unsubscribe();
        }
      });
  }
}
