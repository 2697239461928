<ng-container class="custom-header">
  <mat-checkbox
    color="primary"
    [ngModel]="isAllSelected"
    [indeterminate]="isIndeterminate"
    (change)="toggleSelect($event)"
    [checked]="isAllSelected"
  >
  </mat-checkbox>
  <span class="owner-title">Owner</span>
</ng-container>
