import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanDeactivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
  anyChanges: () => boolean | Observable<boolean>;
  getPopup: () => any;
  isForSave: (action: string) => boolean;
  save: () => Observable<any>;
}

@Injectable()
export class PendingChangesService
  implements CanDeactivate<ComponentCanDeactivate> {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) {}
  canDeactivate(
    component: ComponentCanDeactivate,
    _: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    if (component.canDeactivate()) {
      return this.dialog
        .open(component.getPopup(), {
          autoFocus: false,
        })
        .afterClosed()
        .pipe(
          map((e) => {
            if (component.isForSave(e)) {
              component.save().subscribe({
                complete: () => {
                  currentState!.root.data = { refresh: true };
                  this.router.navigate([nextState!.url], {
                    relativeTo: this.route,
                  });
                },
              });
              return false;
            }
            return true;
          }),
        );
    } else if (component.anyChanges()) {
      component.save().subscribe({
        complete: () => {
          currentState!.root.data = { refresh: true };
          this.router.navigate([nextState!.url], {
            relativeTo: this.route,
          });
        },
      });
      return false;
    }
    return true;
  }
}
