import { Component, EventEmitter, Input, Output } from '@angular/core';
import _ from 'lodash';
import { WIDGETS } from '../widget/widget.component';

export interface WidgetBody {
  title: string;
  description: string;
}

@Component({
  selector: 'app-widget-card',
  templateUrl: './widget-card.component.html',
  styleUrls: ['./widget-card.component.scss'],
})
export class WidgetCardComponent {
  @Input()
  widgetId?: string;
  @Output()
  addWidgetEvent: EventEmitter<string> = new EventEmitter<string>();
  isHovered = false;

  onMouseEnter(): void {
    this.isHovered = true;
  }

  onLeave(): void {
    this.isHovered = false;
  }

  add(widgetId: string): void {
    this.addWidgetEvent.emit(widgetId);
  }

  widgetDescription(widgetId: string): WidgetBody {
    let result: WidgetBody;

    switch (widgetId) {
      case WIDGETS.TOP_THREATS_SUMMARY:
        result = {
          title: 'Top citations summary',
          description: 'Summary of the system calculated top citations',
        };
        break;
      case WIDGETS.ABSOLUTE_GROUNDS_CASE_LAW:
        result = {
          title: 'Absolute grounds - Case law',
          description: 'Potential risk factors based on existing case law',
        };

        break;
      case WIDGETS.ABSOLUTE_GROUNDS_DATA_POINTS:
        result = {
          title: 'Absolute grounds - Data points',
          description:
            'Potential risk factors based on geographic, pharmaceutical and linguistic data points',
        };
        break;
      case WIDGETS.ALL_THREATS_INSIGHTS:
        result = {
          title: 'All citations - Insights',
          description:
            'Risk calculations of verbal similarity and goods and services similarity',
        };
        break;
      case WIDGETS.GEOGRAPHIC_PRESENCE_OF_TOP_THREATS:
        result = {
          title: 'Geographic presence of top citations',
          description: 'Geographic presence of returned top citations',
        };
        break;
      case WIDGETS.RISK_PER_MARKET:
        result = {
          title: 'Risk per Market',
          description:
            'Quick overview of the risk assessments for different datapoints for top markets.',
        };
        break;
      case WIDGETS.COEXISTENCE_PER_MARKET:
        result = {
          title: 'Coexistence per Market',
          description: 'Coexistence of linguistic components for top markets.',
        };
        break;
      case WIDGETS.OWNER_GLOBALITY:
        result = {
          title: 'Globality',
          description: 'Globality',
        };
        break;
      case WIDGETS.OWNER_PORTFOLIO_SIZE:
        result = {
          title: 'Portfolio size',
          description: 'Portfolio size',
        };
        break;
      case WIDGETS.OWNER_LITIGATIONS:
        result = {
          title: 'Litigiousness of Owner',
          description: 'Litigiousness of Owner',
        };
        break;
    }

    return result!;
  }
}
