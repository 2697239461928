<mat-label
  *ngIf="!!data.label && !customize && data.include"
  [ngClass]="{ 'report-editor-disabled-div': disableSubsection }"
  >{{ data.label }}</mat-label
>
<div
  [ngClass]="{
    'report-editor-row-content': !!data.require ? false : customize,
    'report-editor-highlight-div':
      customize && !disableSubsection && data.include,
    'padding-on-customize': customize && data.require
  }"
>
  <div
    [ngClass]="{
      left: !!data.require ? false : customize,
      'report-editor-disabled-div':
        !(data.require || data.include) && !disableSubsection
    }"
    *ngIf="!!data.require || (!customize && data.include) || customize"
  >
    <mat-form-field
      *ngIf="customize"
      appearance="outline"
      class="custom-label cdx-input-default"
    >
      <input
        matInput
        #input
        placeholder="Enter a label (optional)"
        [(ngModel)]="data.label"
        [disabled]="disableSubsection"
        [ngClass]="{
          'report-editor-disabled-div': disableSubsection
        }"
      />
    </mat-form-field>

    <div
      class="rich-text"
      [ngClass]="{ 'report-editor-disabled-div': disableSubsection }"
    >
      <quill-editor
        placeholder="Enter a comment"
        [modules]="quillConfiguration"
        [(ngModel)]="data.value"
        (onSelectionChanged)="onSelectionChanged($event)"
        [disabled]="disableSubsection"
      >
      </quill-editor>
    </div>
  </div>
  <div
    [ngClass]="{
      right: customize,
      'report-editor-disabled-div': disableSubsection
    }"
    class="customize-toggle"
    *ngIf="!data.require && customize"
  >
    <mat-slide-toggle
      role="button"
      color="primary"
      checked="data.include"
      [(ngModel)]="data.include"
    ></mat-slide-toggle>
  </div>
</div>
