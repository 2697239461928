import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { CountryInfo } from '@compumark/bla-backend-client/model/country-info';

@Component({
  selector: 'app-country-renderer',
  templateUrl: './country-renderer.component.html',
  styleUrls: ['./country-renderer.component.scss'],
})
export class CountryRendererComponent implements ICellRendererAngularComp {
  countries: Array<CountryInfo> = [];

  agInit(params: ICellRendererParams<any, any>): void {
    this.countries = !params.data?.protectedCountriesWIPO
      ? []
      : params.data?.protectedCountriesWIPO;
  }

  refresh(): boolean {
    return true;
  }

  allCountryCodes(): string {
    return this.countries
      .sort((a, b) => String(a.shortCode)?.localeCompare(String(b.shortCode)))
      .map((c) => c.shortCode)
      .join(', ');
  }

  allCountryNames(): string {
    return this.countries
      .sort((a, b) => String(a.name)?.localeCompare(String(b.name)))
      .map((c) => c.name)
      .join(', ');
  }
}
