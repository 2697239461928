import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { FlagsService, ResultsDelivery } from '@compumark/bla-backend-client';
import _ from 'lodash';

@Component({
  selector: 'app-flags',
  templateUrl: '../flag-shared-template/flag-template.html',
  styleUrls: ['../flag-shared-template/flag-template.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FlagsComponent implements OnChanges {
  flags = [] as any[];
  color = '#FF00FF';
  @Input() resultsDelivery!: ResultsDelivery;
  @Input() threat!: any;
  params: any;

  constructor(private flagsService: FlagsService) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.resultsDelivery = params.colDef?.cellRendererParams.resultsDelivery;
    this.threat = params.data;
    this.ngOnChanges();
  }
  onIconClick(event: any): void {
    event.stopPropagation();
    this.params?.onClick?.(this.params);
  }
  ngOnChanges(): void {
    this.flags = _.cloneDeep(this.resultsDelivery?.flagDefinitions?.flags!);
    const selectedFlagsIds = this.threat.flags?.map((f: any) => {
      if (f.selected) {
        return f.id;
      }
    });
    this.flags?.forEach((f) => {
      if (selectedFlagsIds?.includes(f.id)) {
        f.selected = true;
      }
    });
  }

  toggleActive(id: string): void {
    const flag = this.flags?.filter((f) => f.id === id)[0];
    flag.selected = !flag.selected;

    this.flagsService
      .setThreatFlagAssociatedWithResultsDelivery(
        flag,
        this.resultsDelivery.id,
        this.threat.id,
      )
      .subscribe(() => this.addThreatsFlag(flag));
  }

  addThreatsFlag(flag: any): void {
    if (!!this.threat.flags) {
      const selectedFlagIndex = this.threat.flags.findIndex(
        (f: any) => f.id === flag.id,
      );
      if (selectedFlagIndex !== -1) {
        this.threat.flags[selectedFlagIndex] = flag;
      } else {
        this.threat.flags.push(flag);
      }
    } else {
      this.threat.flags = [];
      this.threat.flags.push(flag);
    }
  }
}
