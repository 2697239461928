import { Injectable } from '@angular/core';
import { OwnerNode } from '../../owner-results/owner-filter/owner-filter.component';
import { RowNode } from '@ag-grid-community/core';

@Injectable({
  providedIn: 'root',
})
export class OwnerComparatorService {
  compareOwnerNodes(a: OwnerNode, b: OwnerNode): number {
    if (!!a.count && !!b.count) {
      return b.count - a.count;
    }

    return 0;
  }

  compareOwnerNodesChildren(a: OwnerNode, b: OwnerNode): number {
    if (!!a.trademarksCount && !!b.trademarksCount) {
      return b.trademarksCount - a.trademarksCount;
    }

    return 0;
  }

  compareOnwerRowNodes(
    valueA: any,
    valueB: any,
    nodeA: RowNode<any>,
    nodeB: RowNode<any>,
  ): number {
    if (nodeA.allChildrenCount && nodeB.allChildrenCount) {
      const countA = nodeA.allLeafChildren
        .map((c) => c.data.trademarksCount)
        .reduce((a, b) => a + b, 0);

      const countB = nodeB.allLeafChildren
        .map((c) => c.data.trademarksCount)
        .reduce((a, b) => a + b, 0);

      return countA - countB;
    } else {
      if (!!nodeA.data && !!nodeB.data) {
        return nodeA.data.trademarksCount - nodeB.data.trademarksCount;
      }
    }

    if (valueA && valueB) {
      return 0;
    }

    return 0;
  }
}
