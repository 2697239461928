import { Injectable } from '@angular/core';
import { OptimizedOwner } from '@compumark/bla-backend-client';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OwnerFilterService {
  private selectedOwners$: BehaviorSubject<
    OptimizedOwner[] | undefined
  > = new BehaviorSubject(undefined as OptimizedOwner[] | undefined);
  private expanded$: BehaviorSubject<boolean> = new BehaviorSubject(
    true as boolean,
  );
  private resultsDeliveryId$: BehaviorSubject<
    string | undefined
  > = new BehaviorSubject(undefined as string | undefined);

  owners(): Observable<OptimizedOwner[] | undefined> {
    return this.selectedOwners$.asObservable();
  }

  get value(): OptimizedOwner[] | undefined {
    return this.selectedOwners$.value;
  }

  public updateOwners(owners: OptimizedOwner[] | undefined): void {
    this.selectedOwners$.next(owners);
  }

  get expanded(): Observable<boolean> {
    return this.expanded$.asObservable();
  }

  public isExpanded(): boolean {
    return this.expanded$.value;
  }

  public setExpanded(expanded: boolean): void {
    this.expanded$.next(expanded);
  }

  get resultsDeliveryIdValue(): string | undefined {
    return this.resultsDeliveryId$.value;
  }

  public updateResultsDeliveryId(resultsDeliveryId: string): void {
    this.resultsDeliveryId$.next(resultsDeliveryId);
  }
}
