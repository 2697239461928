<div class="coexistence-per-market-dialog">
  <div class="title" mat-dialog-title>
    <span>Coexistence Per Market</span>
    <button mat-icon-button><mat-icon mat-dialog-close>close</mat-icon></button>
  </div>

  <app-coexistence-per-market-filter
    [resultsDeliveryId]="data.resultsDeliveryId"
    [keywords]="data.keywords"
    [classes]="data.classes"
    [showMarketsFilter]="false"
  ></app-coexistence-per-market-filter>

  <section>
    <app-coexistence-per-market
      class="dialog-content"
      [resultsDeliveryId]="data.resultsDeliveryId"
      [keywords]="data.keywords"
      [classes]="data.classes"
    ></app-coexistence-per-market>
    <div class="legend">
      <app-coexistence-per-market-description></app-coexistence-per-market-description>
    </div>
  </section>

  <mat-dialog-actions>
    <button
      mat-stroked-button
      color="primary"
      class="cdx-but-lg"
      mat-dialog-close
    >
      Close
    </button>
  </mat-dialog-actions>
</div>
