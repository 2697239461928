<div class="case-law-dialog">
  <div class="title" mat-dialog-title>
    Absolute Grounds - {{ data.terms.length }} identical match(es) -
    {{ data.label }}
  </div>

  <mat-dialog-content>
    <app-absolute-grounds-item
      *ngFor="let item of data.terms"
      [item]="item"
      [resultsDeliveryId]="data.resultsDeliveryId"
    >
      <mat-card-header>
        <mat-card-title [innerHTML]="item.candidateNameWithMatch">
        </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <span class="term">
          {{ item.term }}
        </span>
        <span class="description">
          {{ item.description }}
        </span>
      </mat-card-content>
    </app-absolute-grounds-item>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      mat-stroked-button
      color="primary"
      class="cdx-but-lg"
      mat-dialog-close
    >
      Close
    </button>
  </mat-dialog-actions>
</div>
