import { Component, Input } from '@angular/core';
import {
  ResultsDelivery,
  ThreatSummary,
  User,
} from '@compumark/bla-backend-client';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
})
export class TileComponent {
  @Input() threat?: ThreatSummary;
  @Input() imgSize?: string;
  @Input() resultsDelivery?: ResultsDelivery;
  @Input() user?: User;

  readonly inactiveStatuses = ['ABANDONED', 'CANCELLED', 'EXPIRED'];

  get imageId(): string {
    return (this.threat! as any).logoId;
  }

  onSelect(): void {}

  checkSelected(): boolean {
    return false;
  }

  isInactive(status: string): boolean {
    return this.inactiveStatuses.includes(status!.toLocaleUpperCase());
  }
}
