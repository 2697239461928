import { formatDate } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { HIGHCHARTS_CDX_THEME } from '@cdx/highcharts';
import { AccountStatistics } from '@compumark/bla-backend-client';
import Highcharts, { Options } from 'highcharts/highmaps';

@Component({
  selector: 'app-searches-run-capped',
  templateUrl: './searches-run-capped.component.html',
  styleUrls: ['./searches-run-capped.component.scss'],
})
export class SearchesRunCappedComponent implements OnInit {
  options?: Options;
  @Input() stats?: AccountStatistics;
  readonly Highcharts = Highcharts;

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  ngOnInit(): void {
    Highcharts.setOptions(HIGHCHARTS_CDX_THEME);
    this.options = {
      chart: {
        type: 'column',
        height: '100%',
        style: {
          fontFamily: 'Open Sans',
        },
      },
      title: {
        text: 'Searches Run: ' + this.stats?.totalCount,
        style: {
          fontSize: '1.25rem',
          fontWeight: '700',
        },
        margin: 32,
      },

      subtitle: {
        text:
          this.getDate(this.stats?.contract?.startDate!) +
          '-' +
          this.getDate(this.stats?.contract?.endDate!),
        style: {
          fontSize: '0.875rem',
          color: 'rgba(0,0,0,0.87)',
        },
      },
      xAxis: {
        categories: ['Multi-Country', 'Single-Country'],
      },
      yAxis: {
        title: {
          text: null,
        },
      },
      tooltip: {
        pointFormatter(): string {
          return 'Searches Run: ' + '<b>' + this.y + '</b>';
        },
      },
      legend: { enabled: false },
      series: [
        {
          name: 'Searches Run',
          type: 'column',
          color: '#5E33BF',
          data: [
            this.stats?.multiCountryCount!,
            this.stats?.singleCountryCount!,
          ],
        },
      ],
    };
  }

  getDate(date: any): string {
    return formatDate(
      new Date(parseInt(date, 10) * 1000),
      'dd/MM/yyyy',
      this.locale,
    );
  }
}
