<div class="title" mat-dialog-title>
  <span>Widgets</span>
  <button mat-icon-button><mat-icon mat-dialog-close>close</mat-icon></button>
</div>

<div class="add-widget-dialog">
  <div class="widgets">
    <ng-container *ngFor="let widget of widgets">
      <app-widget-card
        [widgetId]="widget.id"
        (addWidgetEvent)="addWidget($event)"
      ></app-widget-card
    ></ng-container>
  </div>
</div>
