<div class="title" mat-dialog-title>Order Confirmation</div>

<mat-dialog-content>
  You will be charged
  <strong>
    {{
      this.priceFormattingService.formatPrice(price.yourPrice!, price.currency!)
    }}
  </strong>
  for this package.
  <p class="subscription-message">{{ getSubscriptionMessage() }}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    class="cdx-but-md cdx-but-link"
    color="primary"
    mat-button
    mat-dialog-close
  >
    Cancel
  </button>
  <button
    class="cdx-but-md cdx-but-link"
    color="primary"
    mat-button
    mat-dialog-close="CONTINUE"
  >
    Continue
  </button>
</mat-dialog-actions>
