<div class="content" id="sectionTop">
  <div class="header">
    <h2 class="section-label" id="sectionLabel" *ngIf="!customizeMode">
      {{ selectedSection.label }}
    </h2>
    <div class="section-label-input" *ngIf="customizeMode">
      <mat-form-field appearance="outline" class="cdx-input-default">
        <input matInput #input [(ngModel)]="selectedSection.label" />
      </mat-form-field>
    </div>
  </div>
  <label class="note" *ngIf="showSubsectionAbsenceMessage(selectedSection)">
    The {{ selectedSection.label }} will not be included in the report. This can
    be changed in “Customize” mode.</label
  >
  <section
    class="item"
    *ngFor="
      let subSection of featureLevelSubSectionFilterService.filteredSubsection(
        selectedSection.subSections!,
        featureLevel
      )
    "
    [id]="getId(subSection)"
    [attr.data-section-id]="subSection.name"
  >
    <div
      class="customize-toggle left"
      *ngIf="customizeMode && !subSection.require"
    >
      <mat-slide-toggle
        role="button"
        color="primary"
        checked="subSection.include"
        [(ngModel)]="subSection.include"
      >
        Include section for
        {{ subSection.label | lowercase }}</mat-slide-toggle
      >
    </div>

    <mat-card *ngIf="showSubSection(subSection)" class="card-shadow">
      <mat-card-header *ngIf="!!subSection.label || customizeMode">
        <mat-card-title-group>
          <h3
            class="item-label"
            *ngIf="!customizeMode"
            [ngClass]="{
              'report-editor-disabled-div': disableSubsection(subSection)
            }"
          >
            {{ subSection.label }}
          </h3>
          <div class="item-label-input" *ngIf="customizeMode">
            <mat-form-field appearance="outline" class="cdx-input-default">
              <input
                [disabled]="disableSubsection(subSection)"
                matInput
                #input
                placeholder="Enter a title (optional)"
                [(ngModel)]="subSection.label"
              />
            </mat-form-field>
          </div> </mat-card-title-group
      ></mat-card-header>
      <mat-card-content>
        <div>
          <div *ngFor="let row of subSection.rows!">
            <app-dynamic-property
              [row]="row"
              [exportTemplate]="exportTemplate"
              [topThreats]="topThreats"
              [resultsDeliveryId]="resultsDeliveryId"
              [featureLevel]="featureLevel"
              [customize]="customizeMode"
              [disableSubsection]="disableSubsection(subSection)"
            ></app-dynamic-property>
          </div>
        </div> </mat-card-content
    ></mat-card>
  </section>
</div>
