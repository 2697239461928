import { NgModule } from '@angular/core';

import { MaterialLoadingCellRendererComponent } from './components/material-loading-cell-renderer/material-loading-cell-renderer.component';
import { MaterialTooltipComponent } from './components/material-tooltip/material-tooltip.component';

@NgModule({
  declarations: [
    MaterialLoadingCellRendererComponent,
    MaterialTooltipComponent,
  ],
})
export class DataGridModule {}
