import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-custom-tooltip',
  styleUrls: ['./custom-tooltip.component.scss'],
  templateUrl: './custom-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTooltipComponent {
  @Input() value?: string | TemplateRef<any>;
  @Input() type = 'COMMON';

  get isValueAString(): boolean {
    return typeof this.value === 'string';
  }

  get asTemplate(): TemplateRef<any> {
    return this.value as TemplateRef<any>;
  }

  get asString(): string {
    return this.value as string;
  }
}
