import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

import { Component } from '@angular/core';

@Component({
  selector: 'app-ttab-renderer',
  templateUrl: './ttab-renderer.component.html',
})
export class TtabRendererComponent implements ICellRendererAngularComp {
  isIncludeTtab!: boolean;
  agInit(params: ICellRendererParams<any, any>): void {
    this.isIncludeTtab = params.data.includesTtab;
  }
  refresh(): boolean {
    return true;
  }
}
