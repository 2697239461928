<div class="flags-wrapper">
  <ng-container *ngFor="let flag of flags">
    <button
      class="round-button"
      [matTooltip]="flag.label"
      [ngStyle]="{
        'background-color': flag.selected ? flag.color : 'white',
        'border-color': flag.selected ? flag.color : '#888888',
        '--hover-color': flag.color
      }"
      (click)="
        toggleActive(flag.id); $event.stopPropagation(); $event.preventDefault()
      "
      (click)="onIconClick($event)"
    ></button>
  </ng-container>
</div>
