import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';

import { AccessDeniedComponent } from './security/components/access-denied/access-denied.component';
import { BrandLandscapeAccessDeniedComponent } from './security/components/brandlandscape-access-denied/brandlandscape-access-denied.component';
import { OwnerAnalyzerAccessService } from './owner-analyzer/services/owner-analyzer-access.service';
import { OwnerAnalyzerAccessDeniedComponent } from './security/components/owner-analyzer-access-denied/owner-analyzer-access-denied.component';
import { ImageSearchAccessService } from './owner-analyzer/services/image-search-access.service';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'initiate',
    data: {
      breadcrumb: {
        label: 'Home',
        info: { icon: 'home' },
      },
    },
  },

  {
    path: 'initiate',
    loadChildren: () =>
      import('./initiate/initiate.module').then((m) => m.InitiateModule),
    data: {
      breadcrumb: {
        label: 'Initiate',
      },
    },
  },

  {
    path: 'summary',
    loadChildren: () =>
      import('./summary/summary.module').then((m) => m.SummaryModule),
    data: {
      breadcrumb: {
        label: 'Summary',
        alias: 'summary',
        routeInterceptor(): string {
          const url = window.location.href.split('/');
          const resultsDeliveryId = url[url.indexOf('results-delivery') + 1];
          return 'summary/results-delivery/' + resultsDeliveryId;
        },
      },
    },
  },
  {
    path: 'owner-results',
    canActivate: [OwnerAnalyzerAccessService],
    loadChildren: () =>
      import('./owner-results/owner-results.module').then(
        (m) => m.OwnerResultsModule,
      ),
  },
  {
    path: 'image-results',
    canActivate: [ImageSearchAccessService],
    loadChildren: () =>
      import('./image-results/image-results.module').then(
        (m) => m.ImageResultsModule,
      ),
  },
  {
    path: 'access-denied',
    pathMatch: 'full',
    component: AccessDeniedComponent,
    data: {
      disableBreadcrumb: true,
    },
  },
  {
    path: 'brandlandscape-access-denied',
    pathMatch: 'full',
    component: BrandLandscapeAccessDeniedComponent,
    data: {
      disableBreadcrumb: true,
    },
  },
  {
    path: 'owner-analyzer-access-denied',
    pathMatch: 'full',
    component: OwnerAnalyzerAccessDeniedComponent,
    data: {
      disableBreadcrumb: true,
    },
  },
  {
    path: 'history',
    loadChildren: () =>
      import('./history/history.module').then((m) => m.HistoryModule),
    data: {
      breadcrumb: {
        label: 'History',
      },
    },
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    data: {
      breadcrumb: {
        label: 'Admin',
        disable: true,
      },
    },
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./pricing/pricing.module').then((m) => m.PricingModule),
    data: {
      breadcrumb: {
        label: 'Account',
      },
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: !environment.production,
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'disabled',
      urlUpdateStrategy: 'eager',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
