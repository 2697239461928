import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { HighchartsChartModule } from 'highcharts-angular';

import { PendingChangesService } from '../all-threats/services/pending-changes-service';
import { AppFooterModule } from '../footer/footer.module';

import { HistoryModule } from './../history/history.module';
import { AccountLinkComponent } from './components/account-link/account-link.component';
import { CappedAccountComponent } from './components/capped-account/capped-account.component';
import { LeaveConfirmationDialogComponent } from './components/leave-confirmation-dialog/leave-confirmation-dialog.component';
import { NavigateDialogComponent } from './components/navigate-dialog/navigate-dialog.component';
import { NumberPickerComponent } from './components/number-picker/number-picker.component';
import { PackagePanelComponent } from './components/package-panel/package-panel.component';
import { PackageSelectComponent } from './components/package-select/package-select.component';
import { PackagesHistoryComponent } from './components/packages-history/packages-history.component';
import { PackagesListComponent } from './components/packages-list/packages-list.component';
import { PriceConfirmationDialogComponent } from './components/price-confirmation-dialog/price-confirmation-dialog.component';
import { PriceOverviewDialogComponent } from './components/price-overview/price-overview-dialog.component';
import { PurchasePackageComponent } from './components/purchase-package/purchase-package.component';
import { RedirectToFirstPackageComponent } from './components/redirect-to-first-package/redirect-to-first-package.component';
import { RemainingSearchesComponent } from './components/remaining-searches/remaining-searches.component';
import { SearchesRunCappedComponent } from './components/searches-run-capped/searches-run-capped.component';
import { SearchesRunUncappedComponent } from './components/searches-run-uncapped/searches-run-uncapped.component';
import { SubscriptionExpiryComponent } from './components/subscription-expiry/subscription-expiry.component';
import { SubscriptionValueComponent } from './components/subscription-value/subscription-value.component';
import { UncappedAccountComponent } from './components/uncapped-account/uncapped-account.component';
import { LeaveGuard } from './guards/leave.guard';
import { PricingRoutingModule } from './pricing-routing.module';
import { NavigateDialogService } from './services/navigate-dialog.service';
import { PriceConfirmationOpeningService } from './services/price-confirmation-opening.service';
import { PriceFormattingService } from './services/price-formatting.service';
import { PriceOverviewOpeningService } from './services/price-overview-opening-service';
@NgModule({
  declarations: [
    AccountLinkComponent,
    CappedAccountComponent,
    LeaveConfirmationDialogComponent,
    PackagesHistoryComponent,
    PackagesListComponent,
    PackagePanelComponent,
    PackageSelectComponent,
    PriceOverviewDialogComponent,
    PriceConfirmationDialogComponent,
    NavigateDialogComponent,
    PurchasePackageComponent,
    NumberPickerComponent,
    RedirectToFirstPackageComponent,
    UncappedAccountComponent,
    SearchesRunCappedComponent,
    RemainingSearchesComponent,
    SubscriptionExpiryComponent,
    SubscriptionValueComponent,
    SearchesRunUncappedComponent,
  ],
  imports: [
    AppFooterModule,
    CommonModule,
    FormsModule,
    PricingRoutingModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    HistoryModule,
  ],
  exports: [
    PackageSelectComponent,
    PriceConfirmationDialogComponent,
    AccountLinkComponent,
  ],
  providers: [
    PriceOverviewOpeningService,
    PriceConfirmationOpeningService,
    PriceFormattingService,
    PendingChangesService,
    NavigateDialogService,
    LeaveGuard,
    CurrencyPipe,
  ],
})
export class PricingModule {}
