import { Injectable } from '@angular/core';
import { FilterChip } from '@compumark/brand-context-components';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class FilterChipsCallbackHandlerService {
  refreshTableAfterRemoveFilterChip(
    filterChip: FilterChip,
    gridApi: any,
  ): void {
    const filterModel = gridApi?.getFilterModel();
    const filter = filterModel[filterChip.field];
    if (filter?.filterType === 'custom') {
      if (filterChip.value === filter.filter) {
        filterModel[filterChip.field].showCompetitors = false;
        filterModel[filterChip.field].value = null;
        filterModel[filterChip.field].filter = null;
      } else {
        filterModel[filterChip.field].filter = null;
      }
      gridApi?.setFilterModel(filterModel);
    } else {
      if (filter?.filterType === 'set') {
        const newValues = filter.values.filter(
          (value: string) => value !== filterChip.value,
        );
        if (newValues.length) {
          filterModel[filterChip.field].values = newValues;
        } else {
          delete filterModel[filterChip.field];
        }
      } else if (filter?.filterType === 'customCheckboxArray') {
        const newValues = filter.filter.filter(
          (value: string) => value !== filterChip.value,
        );
        if (newValues.length) {
          filterModel[filterChip.field].filter = newValues;
        } else {
          delete filterModel[filterChip.field];
        }
      } else {
        delete filterModel[filterChip.field];
      }
    }

    gridApi?.onFilterChanged();
    gridApi?.setFilterModel(null);
    gridApi?.setFilterModel(filterModel);
  }

  filterChipComparator(objValue: FilterChip, othValue: FilterChip): boolean {
    if (!objValue || !othValue) {
      return false;
    }
    const objValueClone = _.cloneDeep(objValue);
    const othValueClone = _.cloneDeep(othValue);
    objValueClone.graph = undefined;
    othValueClone.graph = undefined;
    return _.isEqual(objValueClone, othValueClone);
  }
}
