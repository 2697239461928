import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Price } from '@compumark/bla-backend-client';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { PriceConfirmationDialogComponent } from '../components/price-confirmation-dialog/price-confirmation-dialog.component';

/* istanbul ignore next */
@Injectable()
export class PriceConfirmationOpeningService {
  constructor(public dialog: MatDialog) {}

  openConfirmationPopup(price: Price): Observable<any> {
    return this.dialog
      .open(PriceConfirmationDialogComponent, {
        autoFocus: false,
        data: price,
        width: '350px',
      })
      .afterClosed()
      .pipe(filter((result) => result === 'CONTINUE'));
  }
}
