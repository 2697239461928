import { Component } from '@angular/core';
import { ContractType } from '@compumark/bla-backend-client';
import { UserService } from 'src/app/security/services/user.service';

@Component({
  selector: 'app-account-link',
  templateUrl: './account-link.component.html',
  styleUrls: ['./account-link.component.scss'],
})
export class AccountLinkComponent {
  constructor(private userService: UserService) {}

  getLink(): string {
    const currentUser = this.userService.getCurrentUser();
    const contractType = currentUser?.contract?.type;

    switch (contractType) {
      case ContractType.CAPPED: {
        return '/account/capped';
      }
      case ContractType.UNCAPPED: {
        return '/account/uncapped';
      }
      default: {
        return '/account/packages';
      }
    }
  }
}
