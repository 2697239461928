import { ICellRendererParams } from '@ag-grid-community/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'app-reputation-icon-renderer',
  templateUrl: './reputation-icon-renderer.component.html',
  styleUrls: ['./reputation-icon-renderer.component.scss'],
})
export class ReputationIconRendererComponent
  implements ICellRendererAngularComp {
  constructor() {}
  reputation = false;

  agInit(params: ICellRendererParams<any, any>): void {
    this.reputation = params.data.reputation;
  }

  getReputationTooltip(): string {
    return 'Trademark has reputation';
  }
  refresh(): boolean {
    return true;
  }
}
