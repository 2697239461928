<div class="flag-row">
  <div>
    <button class="color-picker-button">
      <ngx-colors
        tabindex="1"
        class="app-color-picker"
        ngx-colors-trigger
        [palette]="flagColors"
        (ngModelChange)="flagColorChange($event)"
        [(ngModel)]="flag.color"
      >
      </ngx-colors>
    </button>
  </div>

  <mat-form-field class="cdx-input-default flag-name" appearance="outline">
    <input [(ngModel)]="flag.label" matInput autocomplete="doNotAutoComplete" />
  </mat-form-field>
  <button mat-icon-button class="delete-button">
    <mat-icon
      fontSet="material-symbols-outlined"
      class="delete-icon"
      (click)="deleteFlag(flag.id)"
    >
      delete
    </mat-icon>
  </button>
</div>
