import { GridApi } from '@ag-grid-community/core';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResultsDelivery } from '@compumark/bla-backend-client';

import _ from 'lodash';
import { RiskPerMarketItem } from 'src/app/widgets/risk-per-market/risk-per-market.component';
import {
  FilterChipStateService,
  HeatmapConfirmationOpeningService,
} from '@compumark/brand-context-components';
import { RiskPerMarketChipConverterService } from 'src/app/widgets/services/risk-per-market-chip-converter-service';
import { map } from 'rxjs';
import { AllThreatsWithBulkActionsComponent } from '../../bulk-actions/components/all-threats/all-threats.component';

@Component({
  selector: 'app-risk-per-market-table-filter',
  templateUrl: './risk-per-market-table-filter.component.html',
  styleUrls: ['./risk-per-market-table-filter.component.scss'],
})
export class RiskPerMarketTableFilterComponent implements OnChanges {
  resultsDelivery: ResultsDelivery;
  @Input()
  gridApi!: GridApi;
  menuOpened = false;

  selectedItems: RiskPerMarketItem[] = [];

  constructor(
    private route: ActivatedRoute,
    private filterChipStateService: FilterChipStateService,
    private riskPerMarketChipConverterService: RiskPerMarketChipConverterService,
    private heatmapConfirmationOpeningService: HeatmapConfirmationOpeningService,
  ) {
    this.resultsDelivery = route.snapshot.data.resultsDelivery;
    this.getFilterChipsEvents();
  }

  get classes(): string[] {
    return this.resultsDelivery.strategy!.classes!.sort(
      (a, b) => parseInt(a, 10) - parseInt(b, 10),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.gridApi.currentValue) {
      const queryParams = this.route.snapshot.queryParams;
      if (
        !!queryParams.jurisdiction &&
        !!queryParams.riskType &&
        !!queryParams.classes &&
        !!queryParams.riskLevel
      ) {
        this.filterByHeatMap([
          {
            jurisdiction: queryParams.jurisdiction,
            riskType: queryParams.riskType,
            riskLevel: queryParams.riskLevel,
            classes: [...queryParams.classes],
          },
        ]);
      }
    }
  }

  selectedItemsEvent(selectedItems: RiskPerMarketItem[]): void {
    this.filterByHeatMap(selectedItems);
  }

  getFilterChipsEvents(): void {
    this.filterChipStateService
      .getfilterChipChanges()
      .pipe(
        map((filterChips) =>
          filterChips.filter(
            (fc) =>
              fc.field ===
              AllThreatsWithBulkActionsComponent.riskPerMarketHeatMapItemChipName,
          ),
        ),
        map((filterChips) =>
          filterChips.map(
            (fc) =>
              ({
                classes: fc.additionalFilter?.additionalFilter!.value,
                riskType: fc.additionalFilter?.field,
                riskLevel: fc.additionalFilter?.value,
                jurisdiction: fc.value,
              } as RiskPerMarketItem),
          ),
        ),
      )
      .subscribe((selectedItems) => (this.selectedItems = selectedItems));
  }

  private filterByHeatMap(selectedItems: RiskPerMarketItem[]): void {
    const filterModel = this.gridApi!.getFilterModel();

    const insightsFilters = this.filterChipStateService
      .getCurrentFilterChips()
      .filter(
        (fc) =>
          fc.field ===
          AllThreatsWithBulkActionsComponent.insightHeatMapItemChipName,
      );

    if (
      !!filterModel.nameRiskLevel ||
      !!filterModel.goodsAndServicesRiskLevel ||
      !!filterModel.verbalCaseLawRiskLevel ||
      (!!filterModel.classes && !!filterModel.classes.filter) ||
      !!filterModel.trademarkJurisdictionCode ||
      insightsFilters.length > 0
    ) {
      this.heatmapConfirmationOpeningService
        .openConfirmationPopup('Your current filters will be cleared')
        .subscribe((closeState) => {
          if (closeState === 'CONTINUE') {
            delete filterModel['nameRiskLevel'.toString()];
            delete filterModel['goodsAndServicesRiskLevel'.toString()];
            delete filterModel['verbalCaseLawRiskLevel'.toString()];
            delete filterModel['classes'.toString()];
            delete filterModel['trademarkJurisdictionCode'.toString()];
            this.addSelected(selectedItems);
          } else {
            this.clearSelected();
          }
          this.updateFilterModel(filterModel);
        });
    } else {
      this.addSelected(selectedItems);
      this.updateFilterModel(filterModel);
    }
  }

  private clearSelected(): void {
    this.selectedItems = [];
  }

  private addSelected(selectedItems: RiskPerMarketItem[]): void {
    this.filterChipStateService
      .getCurrentFilterChips()
      .filter(
        (fc) =>
          fc.field ===
            AllThreatsWithBulkActionsComponent.insightHeatMapItemChipName ||
          fc.field ===
            AllThreatsWithBulkActionsComponent.riskPerMarketHeatMapItemChipName,
      )
      .forEach((fc) => this.filterChipStateService.remove(fc));

    for (const fc of this.riskPerMarketChipConverterService.convertToChips(
      selectedItems,
    )) {
      this.filterChipStateService.add(fc);
    }
  }

  private updateFilterModel(filterModel: any): void {
    this.gridApi!.setFilterModel(filterModel);
    this.gridApi!.onFilterChanged();
  }
}
