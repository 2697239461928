import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
@Injectable()
export class CommonPreferencesService {
  userPreference$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  clearFilterChipsChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  getUserPreference(): Observable<boolean> {
    return this.userPreference$.asObservable();
  }

  setUserPreference(user: boolean): void {
    this.userPreference$.next(user);
  }

  getClearFilterChipsChange(): Observable<boolean> {
    return this.clearFilterChipsChange$.asObservable();
  }

  setClearFilterChipsChange(value: boolean): void {
    this.clearFilterChipsChange$.next(value);
  }
}
