import { AgFilterComponent } from '@ag-grid-community/angular';
import { IFilterParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResultsDelivery } from '@compumark/bla-backend-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-owner-filter',
  templateUrl: './owner-filter.component.html',
  styleUrls: ['./owner-filter.component.scss'],
})
export class OwnerFilterComponent implements AgFilterComponent {
  private params?: IFilterParams;
  resultsDelivery$: Observable<ResultsDelivery>;
  filter: any;
  showCompetitors = false;

  filterModel = {
    filter: null,
    field: 'trademarkApplicantName',
    filterType: 'custom',
    showCompetitors: false,
    value: '',
  };

  constructor(route: ActivatedRoute) {
    this.resultsDelivery$ = route.data.pipe(
      map((data) => data.resultsDelivery),
    );
  }
  agInit(params: IFilterParams): void {
    this.params = params;
  }

  isFilterActive(): boolean {
    return this.filterModel?.showCompetitors || !!this.filterModel?.filter;
  }

  doesFilterPass(): boolean {
    return false;
  }

  getModel(): any {
    return this.filterModel;
  }

  setModel(model: any): void {
    this.filter = model?.filter;
    this.showCompetitors = model?.showCompetitors;
    this.filterModel = model;
  }

  updateFilter(): void {
    if (!this.filterModel) {
      this.filterModel = {
        filter: null,
        field: 'trademarkApplicantName',
        filterType: 'custom',
        showCompetitors: false,
        value: '',
      };
    }
    this.filterModel.filter = this.filter;
    this.filterModel.showCompetitors = this.showCompetitors;
    this.filterModel.value = this.showCompetitors
      ? 'Show only competitors'
      : '';
    this.params?.filterChangedCallback();
    this.params?.api.refreshHeader();
  }

  clearFilter(): void {
    this.showCompetitors = false;
    this.filter = null;
    this.filterModel.value = '';
  }
}
