import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import {
  ThreatsService,
  ThreatsFilter,
  ClassesFilter,
  NameFilterType,
  GoodsAndServicesFilter,
} from '@compumark/bla-backend-client';
import {
  FilterChipStateService,
  GlasspaneService,
} from '@compumark/brand-context-components';
import { first } from 'rxjs/operators';
import { RiskPerMarketChipConverterService } from 'src/app/widgets/services/risk-per-market-chip-converter-service';
import { AllThreatsWithBulkActionsComponent } from '../bulk-actions/components/all-threats/all-threats.component';

/* istanbul ignore next */
@Injectable()
export class AllThreatsDatasource {
  constructor(
    private threatsService: ThreatsService,
    private glasspaneService: GlasspaneService,
    private filterChipStateService: FilterChipStateService,
    private riskPerMarketChipConverterService: RiskPerMarketChipConverterService,
  ) {}

  private getClassesFilter(filterModel: any): ClassesFilter | undefined {
    if (
      !!filterModel?.classes &&
      filterModel?.classes?.type === NameFilterType.EXACT &&
      filterModel?.classes?.filter !== 'In class(es) searched' &&
      filterModel?.classes?.filter !== 'Out of class(es) searched'
    ) {
      return { classesOptions: filterModel?.classes?.filterId };
    } else if (filterModel?.classes?.filter === 'In class(es) searched') {
      return { classesOptions: 'INCLUDED_SEARCH' };
    } else if (filterModel?.classes?.filter === 'Out of class(es) searched') {
      return { classesOptions: 'EXCLUDED_SEARCH' };
    }

    return {
      classes: filterModel?.classes?.filter?.split(','),
      joinOperator: 'OR',
    };
  }

  private getGoodsAndServiceFilter(
    filterModel: any,
  ): GoodsAndServicesFilter | undefined {
    if (
      filterModel.goodsAndServices?.filterTextOne ||
      filterModel.goodsAndServices?.filterTextTwo
    ) {
      const fields = [];
      if (filterModel.goodsAndServices.filterTextOne) {
        fields.push({
          value: filterModel.goodsAndServices.filterTextOne,
          operator: filterModel.goodsAndServices.conditionOne,
        });
      }
      if (filterModel.goodsAndServices.filterTextTwo) {
        fields.push({
          value: filterModel.goodsAndServices.filterTextTwo,
          operator: filterModel.goodsAndServices.conditionTwo,
        });
      }
      return {
        fields,
        joinOperator: filterModel?.goodsAndServices?.operator,
      };
    } else {
      return undefined;
    }
  }

  createFor(
    resultsDeliveryId: string,
    isOwnedBrand: boolean,
  ): IServerSideDatasource {
    const r = this.threatsService;
    const o = this.glasspaneService;
    const fc = this.filterChipStateService;
    const rpmfc = this.riskPerMarketChipConverterService;
    const self = this;
    return {
      getRows(params: IServerSideGetRowsParams): void {
        const insigthsFilterChips = fc
          .getCurrentFilterChips()
          .filter(
            (f) =>
              f.field ===
              AllThreatsWithBulkActionsComponent.insightHeatMapItemChipName,
          );

        const riskPerMarketFilterChips = fc
          .getCurrentFilterChips()
          .filter(
            (f) =>
              f.field ===
              AllThreatsWithBulkActionsComponent.riskPerMarketHeatMapItemChipName,
          );

        const producktRisksTableFilter =
          params.request.filterModel?.goodsAndServicesRiskLevel?.values;
        const verbalRiskTableFilter =
          params.request.filterModel?.nameRiskLevel?.values;

        const goodsAndServicesRiskHeatMap =
          insigthsFilterChips.length > 0
            ? insigthsFilterChips.map((hi) => hi.additionalFilter?.value)
            : undefined;
        const verbalRiskHeatMap =
          insigthsFilterChips.length > 0
            ? insigthsFilterChips.map((hi) => hi.value)
            : undefined;

        const shouldApplyHeatMapFilter =
          !producktRisksTableFilter &&
          !verbalRiskTableFilter &&
          !!insigthsFilterChips &&
          insigthsFilterChips.length > 0;

        r.getThreatsAssociatedWithResultsDelivery(
          {
            name: params.request.filterModel?.trademarkName?.filter,
            actions: params.request.filterModel?.actions?.filter,
            applicantName:
              params.request.filterModel?.trademarkApplicantName?.filter,
            knownCompetitor:
              params.request.filterModel?.trademarkApplicantName
                ?.showCompetitors,
            jurisdictionNames:
              params.request.filterModel?.trademarkJurisdictionCode?.values,
            classesFilter: self.getClassesFilter(params.request.filterModel),
            goodsAndServices: self.getGoodsAndServiceFilter(
              params.request.filterModel,
            ),
            statuses: params.request.filterModel?.trademarkStatus?.filter,
            goodsAndServicesRisk: !!producktRisksTableFilter
              ? producktRisksTableFilter
              : goodsAndServicesRiskHeatMap,
            nameRisk: !!verbalRiskTableFilter
              ? verbalRiskTableFilter
              : verbalRiskHeatMap,
            verbalCaseLawPrediction:
              params.request.filterModel?.verbalCaseLawRiskLevel?.values,
            pocaScore: params.request.filterModel?.pocaScoreRiskLevel?.values,
            ownedBrand: isOwnedBrand,
            heatMapFilter: shouldApplyHeatMapFilter,
            riskPerMarketFilter: rpmfc.convertFromChips(
              riskPerMarketFilterChips,
            ),
            contentType: 'TRADEMARK',
            freeText: params.request.filterModel?.freeText?.filter,
            flagIds: params.request.filterModel?.flags?.ids,
          } as ThreatsFilter,
          resultsDeliveryId,
          params.request.startRow!,
          params.request.endRow!,
        )
          .pipe(first())
          .subscribe((threats) => {
            params.success({
              rowData: threats.threats,
              rowCount: threats.totalCount,
            });

            o.hideGlasspane();
          });
      },
    };
  }
}
