import { Injectable } from '@angular/core';
import { Row } from '@compumark/bla-backend-client';

import { CheckboxComponent } from '../components/checkbox/checkbox.component';
import { CustomLogoUploadComponent } from '../components/custom-logo-upload/custom-logo-upload.component';
import { DatabasesAndSourcesComponent } from '../components/databases-and-sources/databases-and-sources.component';
import { IncludeFulltextComponent } from '../components/include-fulltext/include-fulltext.component';
import { InputDatePickerComponent } from '../components/input-datepicker/input-datepicker.component';
import { InputSearchStrategyComponent } from '../components/input-search-strategy/input-search-strategy.component';
import { PropertyInputComponent } from '../components/property-input/property-input.component';
import { ResultGraphicSelectComponent } from '../components/result-graphic-select/result-graphic-select.component';
import { SummaryGraphicSelectComponent } from '../components/summary-graphic-select/summary-graphic-select.component';
import { TableSummaryComponent } from '../components/table-summary/table-summary.component';
import { TextAreaComponent } from '../components/text-area/text-area.component';
import { ToggleFormatComponent } from '../components/toggle-format/toggle-format.component';
import { TopThreatsComponent } from '../components/top-threats/top-threats.component';
import { SaveTemplateComponent } from '../components/save-template/save-template.component';
import { TextAreaWithBoilerplateComponent } from '../components/text-area-with-boilerplate/text-area-with-boilerplate.component';

/* istanbul ignore next */
@Injectable({
  providedIn: 'root',
})
export class PropertyProviderService {
  getProperty(data: Row): any {
    switch (data.type) {
      case 'TEXT_INPUT':
        return PropertyInputComponent;

      case 'TEXT_AREA':
        return TextAreaComponent;

      case 'TEXT_AREA_WITH_BOILERPLATE':
        return TextAreaWithBoilerplateComponent;

      case 'SUMMARY_GRAPHIC_SELECT':
        return SummaryGraphicSelectComponent;

      case 'RESULT_GRAPH_SELECT':
        return ResultGraphicSelectComponent;

      case 'DATABASES_AND_SOURCES':
        return DatabasesAndSourcesComponent;

      case 'TABLE_SUMMARY':
        return TableSummaryComponent;

      case 'CHECKBOX':
        return CheckboxComponent;

      case 'TOP_THREATS':
        return TopThreatsComponent;

      case 'INCLUDE_FULLTEXT':
        return IncludeFulltextComponent;

      case 'TOGGLE_FORMAT':
        return ToggleFormatComponent;

      case 'CUSTOM_LOGO_UPLOAD':
        return CustomLogoUploadComponent;

      case 'INPUT_DATEPICKER':
        return InputDatePickerComponent;

      case 'INPUT_SEARCH_STRATEGY':
        return InputSearchStrategyComponent;

      case 'SAVE_TEMPLATE':
        return SaveTemplateComponent;

      default:
        return undefined;
    }
  }
}
