<div class="label" *ngIf="!!data.label">{{ data.label }}</div>
<div class="toggle">
  <mat-button-toggle-group (change)="onFormatChange($event.value)">
    <mat-button-toggle value="docx" [checked]="isChecked('docx')" role="button"
      >Word</mat-button-toggle
    >
    <mat-button-toggle value="pdf" [checked]="isChecked('pdf')" role="button"
      >PDF</mat-button-toggle
    >
  </mat-button-toggle-group>
</div>
