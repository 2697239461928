<dl>
  <dt class="owners-label">Owners</dt>
  <dd class="owners-content">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <button mat-icon-button disabled></button>
        {{ node.item }}
      </mat-tree-node>
      <mat-tree-node
        class="parent"
        *matTreeNodeDef="let node; when: hasChild"
        matTreeNodePadding
      >
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.item"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        {{ node.item + ' (' + node.optimizedOwners.length + ' companies)' }}
      </mat-tree-node>
    </mat-tree>
  </dd>
  <dt>Classes</dt>
  <dd class="content">1-45</dd>
  <dt>Markets</dt>
  <dd class="content">TRADEMARKS – Worldwide</dd>
  <dt class="delimiter"></dt>
  <dd class="delimiter"></dd>
</dl>
