<div class="account-statistics">
  <h1 class="title">Capped Subscription - {{ featureLevelDescription() }}</h1>
  <button
    mat-stroked-button
    color="primary"
    class="cdx-but-md back"
    routerLink="/"
  >
    Back to Initiate
  </button>
  <div class="subscription-panels">
    <mat-card class="chart-panel">
      <mat-card-header
        ><mat-card-title>
          Value:
          {{ stats.contract!.totalCap | currency: stats.contract!.currency }}
        </mat-card-title>
        <mat-card-subtitle>
          <p>
            Multi-country search:
            {{
              stats.contract!.multiCountrySearchPrice
                | currency: stats.contract!.currency
            }}
          </p>

          <p>
            Single-country search:
            {{
              stats.contract!.singleCountrySearchPrice
                | currency: stats.contract!.currency
            }}
          </p></mat-card-subtitle
        >
      </mat-card-header>
      <mat-card-content>
        <app-subscription-value [contract]="stats.contract">
        </app-subscription-value>
      </mat-card-content>
    </mat-card>
    <mat-card class="chart-panel">
      <mat-card-header
        ><mat-card-title> Remaining searches </mat-card-title>
      </mat-card-header>
      <div class="remaining-searches">
        <div class="chart">
          <app-remaining-searches [contract]="stats.contract" type="Multi">
          </app-remaining-searches>
        </div>
        <span class="span-between-charts">or</span>
        <div class="chart">
          <app-remaining-searches [contract]="stats.contract" type="Single">
          </app-remaining-searches>
        </div>
      </div>
    </mat-card>
    <mat-card class="chart-panel">
      <mat-card-content>
        <app-searches-run-capped [stats]="stats"> </app-searches-run-capped>
      </mat-card-content>
    </mat-card>
    <mat-card class="chart-panel">
      <app-subscription-expiry
        [contract]="stats.contract"
      ></app-subscription-expiry>
    </mat-card>
  </div>
</div>
<app-footer></app-footer>
