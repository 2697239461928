import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, OnInit } from '@angular/core';
import {
  OrderHistory,
  ResultsDeliveryService,
  ResultsDeliveryStatus,
} from '@compumark/bla-backend-client';

@Component({
  selector: 'app-rerun-renderer',
  templateUrl: './rerun-renderer.component.html',
  styleUrls: ['./rerun-renderer.component.scss'],
})
export class RerunRendererComponent implements OnInit {
  constructor(private service: ResultsDeliveryService) {}

  orderHistory!: OrderHistory;

  agInit(params: ICellRendererParams): void {
    this.orderHistory = params.data;
  }

  ngOnInit(): void {}

  isReRunInProgress(): boolean {
    return !this.isCompleted() && this.orderHistory.rerun!;
  }

  isCompleted(): boolean {
    return (
      this.orderHistory.status! === ResultsDeliveryStatus.COMPLETED ||
      this.isFailed()
    );
  }

  isFailed(): boolean {
    return (
      this.orderHistory.status! === ResultsDeliveryStatus.ERROR ||
      this.orderHistory.status! === ResultsDeliveryStatus.TOO_MANY_RESULTS ||
      this.orderHistory.status! === ResultsDeliveryStatus.SEARCH_FAILED ||
      this.orderHistory.status! === ResultsDeliveryStatus.RISK_ASSESSMENT_FAILED
    );
  }

  isNewSearchInProgress(): boolean {
    return !this.isCompleted() && !this.isReRunInProgress();
  }

  reRun(): void {
    if (
      confirm('Are you sure ? All customer data will be erased !!!') === true
    ) {
      this.orderHistory.status = ResultsDeliveryStatus.NEW;
      this.orderHistory.rerun = true;
      this.service.reRun(this.orderHistory.id).subscribe();
    }
  }
}
