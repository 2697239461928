<mat-dialog-content>
  <div class="head">
    <h2 class="title">Price Overview</h2>
    <button class="print-btn" mat-icon-button (click)="print()">
      <mat-icon class="print">print</mat-icon>
    </button>
  </div>
  <table class="price-overview">
    <tr>
      <th class="head candidate-levels">Candidate Levels</th>
      <th class="head regional-level">Regional Level</th>
      <th class="head standard">Standard</th>
      <th class="head expert">Expert</th>
    </tr>
    <ng-container *ngFor="let priceOverview of data.priceOverviewItems">
      <tr class="row" *ngIf="!priceOverview.customMessage">
        <td class="label">
          {{ priceOverview.candidateLevels }}
          <div>
            {{ priceOverview.candidateLevelsDescription }}
          </div>
        </td>
        <td colspan="3">
          <table class="secondary">
            <th class="regional-level"></th>
            <th class="standard"></th>
            <th class="expert"></th>
            <tr *ngFor="let standard of priceOverview.standard; let i = index">
              <td>
                {{ priceOverview.regionalLevel![i] }}
              </td>
              <td>{{ formatPrice(priceOverview.standard![i]) }}</td>
              <td>
                {{ formatPrice(priceOverview.expert![i]) }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr class="row" *ngIf="!!priceOverview.customMessage">
        <td>
          {{ priceOverview.candidateLevels }}
        </td>
        <td colspan="3" class="custom-cell">
          {{ priceOverview.customMessage }}
        </td>
      </tr>
    </ng-container>
  </table>
</mat-dialog-content>

<mat-dialog-actions align="end" class="actions">
  <button
    mat-stroked-button
    color="primary"
    class="cdx-but-lg"
    mat-dialog-close
  >
    Close
  </button>
</mat-dialog-actions>
