import { RowNode } from '@ag-grid-community/core';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ExportService,
  SelectForExportRequest,
  ThreatsService,
  UpdateThreatSelection,
  UpdateThreatSelectionAction,
} from '@compumark/bla-backend-client';
import {
  CommentData,
  NotificationService,
  ThreatCommentDialogComponent,
} from '@compumark/brand-context-components';
import {
  CommentService,
  Comment,
} from '@compumark/brand-context-components-backend-client';
import { Observable, Subscription, tap } from 'rxjs';
import { UserService } from 'src/app/security/services/user.service';
import { TopThreatsCounterService } from './top-threats-counter.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ActionRendererService {
  threatComments$: Subscription = new Subscription();
  threatComments: Comment[] = [];
  includedInTopThreats = false;
  selectedTopThreats: Set<string> = new Set<string>();
  updatedThreatsSelection: UpdateThreatSelection[] = [];

  constructor(
    private exportService: ExportService,
    public dialog: MatDialog,
    public commentService: CommentService,
    private ref: ChangeDetectorRef,
    public userService: UserService,
    public topThreatsService: TopThreatsCounterService,
    private threatsService: ThreatsService,
    public notification: NotificationService,
    private route: ActivatedRoute,
  ) {}

  get userDetails(): { userName: string; userId: string } {
    const currentUser = this.userService.getCurrentUser();
    return {
      userName: currentUser?.name!,
      userId: currentUser?.id!,
    };
  }

  toggleSelectForExport(
    id: string,
    resultsDeliveryId: string,
    selectedForExport: boolean,
    gridApi: any,
    isbulkAction = false,
    selectionType: any,
    isTrademarkExport?: boolean,
  ): Observable<void> {
    const previousState = selectedForExport;
    const request: SelectForExportRequest = {
      ids: [id],
      parentId: resultsDeliveryId,
      selectionType,
      selectedForExport: !selectedForExport,
    };
    return this.exportService.selectForExport(request, resultsDeliveryId).pipe(
      tap(() => {
        const row = gridApi.getRowNode(id);
        if (
          row &&
          ((isbulkAction && !row.data.selectedForExport) || !isbulkAction)
        ) {
          row.data.selectedForExport = !row.data.selectedForExport;
        }
        gridApi.redrawRows({ rowNodes: [row] });
        if (!isbulkAction && !isTrademarkExport) {
          const message = selectedForExport
            ? 'The citation has been removed from export'
            : 'The citation has been added to export';
          this.notification
            .showSnackbar(message, 'undo', 3000)
            .onAction()
            .subscribe(() => {
              const undoRequest: SelectForExportRequest = {
                ids: [id],
                parentId: resultsDeliveryId,
                selectionType,
                selectedForExport: previousState,
              };

              this.exportService
                .selectForExport(undoRequest, resultsDeliveryId)
                .subscribe(() => {
                  if (row) {
                    row.data.selectedForExport = previousState;
                    gridApi.redrawRows({ rowNodes: [row] });
                  }
                });
            });
        }
      }),
    );
  }

  addComment(node: RowNode<any>, gridApi: any): void {
    const commentData = {
      threatIds: [node.data.id],
      threatName: node.data.trademarkName || node.data.name,
      userName: this.userDetails.userName,
      userId: this.userDetails.userId,
    } as CommentData;

    this.threatComments = [];
    this.threatComments$ = this.commentService
      .getComments(node.data.id)
      .subscribe((tc) => {
        this.threatComments = tc;
        commentData.threatComments = this.threatComments;
        this.openCommentDialog([node], gridApi, commentData);
      });
  }

  addCommentToMultipleThreats(
    nodes: RowNode<any>[],
    gridApi: any,
    threatids: string[],
  ): void {
    const commentData = {
      threatIds: threatids,
      userName: this.userDetails.userName,
      userId: this.userDetails.userId,
      threatComments: [] as Comment[],
    } as CommentData;
    const dialogData = {
      ...commentData,
      enableUndoSnackbar: true,
    };
    this.openCommentDialog(nodes, gridApi, dialogData);
  }

  openCommentDialog(
    nodes: RowNode<any>[],
    gridApi: any,
    commentData: CommentData,
  ): void {
    this.dialog
      .open(ThreatCommentDialogComponent, {
        width: '54.375rem',
        data: commentData,
        panelClass: 'threat-comment-dialog-container',
      })
      .afterClosed()
      .subscribe((comments: Comment[]) => {
        this.ref.detectChanges();

        nodes.forEach((n) => {
          const comment = comments.find((c) => c.threatId === n.data.threatId);
          if (comment) {
            n.data.comments = [comment, ...(n.data.comments || [])];
          }
        });

        gridApi.redrawRows({ rowNodes: nodes });
        this.threatComments$.unsubscribe();
      });
  }

  addThreatToSelection(node: RowNode<any>, resultsDeliveryId: string): void {
    this.selectedTopThreats.add(node.data.id);
    this.topThreatsService.addThreat(resultsDeliveryId, node.data.id);
    this.updateThreatsSelection(
      Array.from(this.selectedTopThreats),
      UpdateThreatSelectionAction.ADD,
    );
    this.updateTopThreats(this.updatedThreatsSelection, resultsDeliveryId);
  }

  updateTopThreats(
    threats: UpdateThreatSelection[],
    resultsDeliveryId: string,
  ): void {
    threats.forEach((threat) => threat);
    this.threatsService
      .updateTopThreatsForResultsDelivery(
        resultsDeliveryId,
        this.updatedThreatsSelection,
      )
      .subscribe(() => {});
  }

  public updateThreatsSelection(
    threats: string[],
    action: UpdateThreatSelectionAction,
  ): void {
    threats.forEach((threatUpdate) => {
      const index = this.updatedThreatsSelection.findIndex(
        (threat) => threat.threatId === threatUpdate,
      );
      this.updatedThreatsSelection.push({
        threatId: threatUpdate,
        action,
      });

      if (index >= 0) {
        this.updatedThreatsSelection.splice(index, 1);
      } else {
        this.updatedThreatsSelection.push({
          threatId: threatUpdate,
          action,
        });
      }
    });

    this.updatedThreatsSelection = Array.from(
      new Set(
        this.updatedThreatsSelection.map((threat) => JSON.stringify(threat)),
      ),
    ).map((threat) => JSON.parse(threat));
  }

  removeThreatFromSelection(
    node: RowNode<any>,
    resultsDeliveryId: string,
  ): void {
    this.selectedTopThreats.add(node.data.id);

    this.updateThreatsSelection(
      Array.from(this.selectedTopThreats),
      UpdateThreatSelectionAction.REMOVE,
    );
    this.updateTopThreats(this.updatedThreatsSelection, resultsDeliveryId);
    this.selectedTopThreats.delete(node.data.id);
    this.topThreatsService.removeThreat(resultsDeliveryId, node.data.id);
  }

  exportSelection(
    node: RowNode<any>,
    resultsDeliveryId: string,
    gridApi: any,
  ): void {
    node.data.includedInTopThreats = !node.data.includedInTopThreats;
    const actionMessage = node.data.includedInTopThreats
      ? 'added to Top Citations and to export'
      : 'removed from Top Citations and from export';
    const selectionType =
      this.route.snapshot.data.selectionType ||
      this.route.snapshot.data.contentType;
    this.toggleSelectForExport(
      node.data.id,
      resultsDeliveryId,
      node.data.includedInTopThreats,
      gridApi,
      false,
      selectionType,
      true,
    ).subscribe();

    if (node.data.includedInTopThreats) {
      this.topThreatsService.addThreat(resultsDeliveryId, node.data.id);
      this.addThreatToSelection(node, resultsDeliveryId);
    } else {
      this.topThreatsService.removeThreat(resultsDeliveryId, node.data.id);
      this.removeThreatFromSelection(node, resultsDeliveryId);
    }

    this.notification
      .showSnackbar(`The citation has been ${actionMessage}`, 'undo', 3000)
      .onAction()
      .subscribe(() => {
        node.data.includedInTopThreats = !node.data.includedInTopThreats;

        if (!node.data.includedInTopThreats) {
          this.topThreatsService.removeThreat(resultsDeliveryId, node.data.id);
          this.removeThreatFromSelection(node, resultsDeliveryId);
        } else {
          this.topThreatsService.addThreat(resultsDeliveryId, node.data.id);
          this.addThreatToSelection(node, resultsDeliveryId);
        }
        this.toggleSelectForExport(
          node.data.id,
          resultsDeliveryId,
          node.data.includedInTopThreats,
          gridApi,
          false,
          selectionType,
          true,
        ).subscribe();
      });
  }
}
