import { Injectable } from '@angular/core';
import { ExportTemplate } from '@compumark/bla-backend-client';
import _ from 'lodash';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TemplateDataService {
  private saveTemplateSubject = new Subject<void>();

  clearData(exportTemplate: ExportTemplate): ExportTemplate {
    const template = _.cloneDeep(exportTemplate);
    template.sections?.forEach((section) =>
      section.subSections?.forEach((subSection) =>
        subSection.rows?.forEach((row: any) => {
          if (row.type === 'TEXT_INPUT' || row.type === 'TEXT_AREA') {
            row.value = undefined;
          }
          if (row.type === 'INCLUDE_FULLTEXT') {
            row.threatIds = [];
          }
          if (row.type === 'INPUT_DATEPICKER') {
            row.reportDate.value = undefined;
          }
        }),
      ),
    );
    return template;
  }

  get saveTriggered$(): Observable<void> {
    return this.saveTemplateSubject.asObservable();
  }

  set saveTriggered(value: void) {
    this.saveTemplateSubject.next(value);
  }

  saveReportTemplate(): void {
    this.saveTemplateSubject.next();
  }
}
