<div
  class="panel"
  [ngClass]="{ reportSetup: isReportSetupSection }"
  *ngIf="showHeader"
>
  <div
    class="header"
    [ngClass]="{
      selected: isSelected
    }"
    (click)="reverseState()"
  >
    <div class="header-item">
      <mat-icon
        class="drag-icon"
        matTooltip="Change sequence of section with drag and drop"
        >drag_handle</mat-icon
      >
      <div class="section-number">{{ sectionNumber }}</div>
      <div class="section-title">
        {{ section.label }}
      </div>
    </div>

    <div class="header-item">
      <button
        mat-icon-button
        tabindex="0"
        *ngIf="section.name === 'customSection'"
      >
        <mat-icon
          matTooltip="Remove report section"
          class="material-symbols-outlined"
          (click)="openConfirmationPopup()"
          >delete</mat-icon
        >
      </button>
      <button mat-icon-button tabindex="0">
        <mat-icon
          matTooltip="Add report subsection"
          class="add-icon"
          (click)="addSubSection()"
          >add</mat-icon
        >
      </button>
    </div>
  </div>
</div>

<div
  class="subsection-content"
  [ngClass]="{
    'no-vertical-line': isReportSetupSection || isLastSection,
    'has-subsections': hasSubsections,
    selected: isSelected
  }"
>
  <ng-content *ngIf="collapsed"></ng-content>
</div>
