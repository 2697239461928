import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { UtilModule } from '../util/util.module';

import { FullTextDialogComponent } from './components/full-text-dialog/full-text-dialog.component';
import { FullTextOpeningService } from './services/full-text-opening-service';

@NgModule({
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    RouterModule,
    UtilModule,
  ],
  declarations: [FullTextDialogComponent],
  providers: [FullTextOpeningService],
})
export class FullTextModule {}
