import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AllThreatsHeatMap,
  HeatMapItem,
  ResultsDelivery,
  SummaryService,
} from '@compumark/bla-backend-client';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/security/services/user.service';
import { WIDGETS } from 'src/app/widgets/widget/widget.component';

@Component({
  selector: 'app-all-threats-insights',
  templateUrl: './all-threats-insights.component.html',
  styleUrls: ['./all-threats-insights.component.scss'],
})
export class AllThreatsInsightsWidgetComponent {
  resultsDelivery: ResultsDelivery;
  @Output() removeEvent = new EventEmitter<string>();
  allThreatsHeatMap$: Observable<AllThreatsHeatMap>;

  constructor(
    route: ActivatedRoute,
    private router: Router,
    summaryService: SummaryService,
    private userService: UserService,
  ) {
    this.resultsDelivery = route.snapshot.data.resultsDelivery;
    this.allThreatsHeatMap$ = summaryService.getAllThreatsInsights(
      this.resultsDelivery.id,
    );
  }

  selectedHeatMapItemsEventHandler(selectedHeatMapItems: HeatMapItem[]): void {
    if (selectedHeatMapItems.length > 0) {
      const userRoles: any = this.userService.getCurrentUser().roles!;

      const url = [
        'summary',
        'isolate',
        'results-delivery',
        this.resultsDelivery.id,
        'all-threats',
      ];

      if (userRoles.includes('ALL_THREATS_BULK_ACTIONS')) {
        url.push('bulk');
      }

      this.router.navigate(url, {
        queryParams: {
          nameRiskLevel: selectedHeatMapItems[0].verbal,
          goodsAndServices: selectedHeatMapItems[0].goodsAndServices,
        },
        queryParamsHandling: '',
      });
    }
  }

  remove(): void {
    this.removeEvent.emit(WIDGETS.ALL_THREATS_INSIGHTS);
  }
}
