import {
  ColDef,
  ColGroupDef,
  ColumnResizedEvent,
  FilterChangedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  MenuItemDef,
  SetFilterValuesFuncParams,
  SideBarDef,
} from '@ag-grid-community/core';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FilterValues,
  FlagDefinition,
  FullTextService,
  ReorderType,
  ResultsDeliveryService,
  ThreatsService,
  UpdateThreatSelection,
  User,
} from '@compumark/bla-backend-client';
import {
  CustomTextFilterComponent,
  FilterChip,
  FilterChipStateService,
  GlasspaneService,
  LogoRendererComponent,
  NotificationService,
  StatusFilterComponent,
} from '@compumark/brand-context-components';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { debounceTime, first, map } from 'rxjs/operators';
import { MaterialTooltipComponent } from 'src/app/data-grid/components/material-tooltip/material-tooltip.component';
import { FullTextOpeningService } from 'src/app/full-text/services/full-text-opening-service';
import { BrandLandscapeRefreshService } from 'src/app/isolate/services/brand-landscape-refresh.service';
import { environment } from 'src/environments/environment';
import { TableColumnService } from 'src/app/util/services/table-column-service';
import { GracePeriodIndicatorComponent } from 'src/app/isolate/components/grace-period-indicator/grace-period-indicator.component';
import { CountryRendererComponent } from 'src/app/util/components/country-renderer/country-renderer.component';
import { TtabRendererComponent } from 'src/app/isolate/components/ttab-renderer/ttab-renderer.component';
import { ReputationIconRendererComponent } from 'src/app/isolate/components/reputation-icon-renderer/reputation-icon-renderer.component';
import { SnackbarService } from 'src/app/util/components/snackbar/snackbar.service';
import { includes } from 'lodash';
import { UserPreferencesService } from '@compumark/user-preferences-backend-specifications';
import { CommonPreferencesService } from 'src/app/util/services/common-preference.service';
import { SaveFreetextRendererComponent } from 'src/app/save-freetext-renderer/save-freetext-renderer.component';
import { FilterChipsCallbackHandlerService } from 'src/app/util/services/filter-chips-callback-handler.service';
import { PocaScoreIndicatorComponent } from 'src/app/isolate/components/poca-score-indicator/poca-score-indicator/poca-score-indicator.component';
import { FlagFilterComponent } from 'src/app/flag/components/flag-filter/flag-filter.component';
import { RiskNameRendererComponent } from 'src/app/threats-tables/components/risk-name-renderer/risk-name-renderer.component';
import { RiskLevelRendererComponent } from 'src/app/threats-tables/components/risk-level-renderer/risk-level-renderer.component';
import { OwnerFilterComponent } from 'src/app/threats-tables/components/owner-filter/owner-filter.component';
import { ExpandAllHeaderComponent } from 'src/app/threats-tables/components/expand-all-header/expand-all-header.component';
import { GoodsAndServicesRendererComponent } from 'src/app/threats-tables/components/goods-and-services-renderer/goods-and-services-renderer.component';
import { MaterialLoadingCellRendererComponent } from 'src/app/data-grid/components/material-loading-cell-renderer/material-loading-cell-renderer.component';
import { FilterParams } from 'src/app/deep-dive/services/filters.params';
import { AllThreatsFilterChangeService } from 'src/app/threats-tables/services/all-threats-filter-change-service';
import { AllThreatsDatasource } from 'src/app/threats-tables/services/all-threats.datasource';
import { ActionsRendererComponent } from '../actions-renderer/actions-renderer.component';
import { ActionRendererService } from 'src/app/threats-tables/services/action-renderer.service';
import { FlagService } from 'src/app/threats-tables/services/flag.service';
import { ClassesFilterComponent } from 'src/app/all-threats/components/classes-filter/classes-filter.component';
import { PreferenceStorageService } from 'src/app/util/services/preference-storage.service';
import { FlagsTableComponent } from 'src/app/flag/components/flags-table/flags-table.component';
import { OwnerCellRendererComponent } from 'src/app/threats-tables/components/owner-cell-renderer/owner-cell-renderer.component';
import { SelectAllHeaderComponent } from 'src/app/util/components/select-all-header/select-all-header.component';
import { AddToTopThreatsBulkActionService } from '../../services/add-to-top-threats-bulk-action.service';
import { TopThreatsCounterService } from 'src/app/threats-tables/services/top-threats-counter.service';
import { TableSetting } from 'src/app/threats-tables/components/table-settings/table-settings.component';
import { TableSettingsService } from 'src/app/threats-tables/services/table-settings.service';
import { GoodsServiceFilterComponent } from 'src/app/all-threats/components/goods-service-filter/goods-service-filter.component';
import {
  ACTION_FILTER_OPTIONS,
  ActionsFilterComponent,
} from 'src/app/threats-tables/components/actions-filter/actions-filter.component';
import { FullscreenService } from 'src/app/util/services/fullscreen.service';

export enum FilterTemplateOperation {
  NONE = 'NONE',
  ADD = 'ADD',
  CHANGE = 'CHANGE',
  REMOVE = 'REMOVE',
}

const riskNameToLongNameMapping = new Map([
  ['V', 'Verbal Risk'],
  ['G', 'Goods and Services Risk'],
]);

/* istanbul ignore next */
@Component({
  selector: 'app-all-threats-with-bulk-actions',
  templateUrl: './all-threats.component.html',
  styleUrls: ['./all-threats.component.scss'],
  providers: [ActionRendererService],
})
export class AllThreatsWithBulkActionsComponent implements OnInit, OnDestroy {
  static readonly insightHeatMapItemChipName = 'insight';
  static readonly riskPerMarketHeatMapItemChipName = 'riskPerMarket';
  readonly selectedThreatsKey =
    'bulkselectedThreats : ' + this.resultsDeliveryId;
  readonly selectedRowkey = 'selectedRowKey : ' + this.resultsDeliveryId;

  @Input() ownedBrandsOnly?: boolean;
  flagsEnabled = true;

  private removedChipsTriggerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  removedChipsTrigger$: Observable<boolean> = this.removedChipsTriggerSubject.asObservable();
  filterValues$: Observable<FilterValues>;
  currentUser$: Observable<User>;
  addingToThreatList = false;
  selectedThreats: Set<string> = new Set();
  maxSelectionLimit = 100;
  contentType = this.route.snapshot.data.contentType;
  updatedThreatsSelection: UpdateThreatSelection[] = [];
  areThreatsMovedtoOwnBrands = false;
  resultsDelivery: any;
  panelExpanded = true;
  filters$ = new BehaviorSubject<{ [key: string]: any }>({});
  marketFilter?: string;
  splitViewData:
    | { threat: any; hasPrevious: boolean; hasNext: boolean }
    | undefined;
  splitViewEnabled = false;
  selectedRowIndex: any;
  rowData: any;
  previousFocusedRowIndex: number | null = null;
  filterChips$ = this.filters$.pipe(
    map((filters) => this.getFilterChips(filters)),
  );
  fullscreen = this.fullScreenService.fullscreenStatus;
  screenIcon = 'open_in_full';
  fullscreenTooltip = 'Fullscreen';
  @HostBinding('style.height') hostHeight = 'calc(100vh - 5rem)';
  public userPreference: any = undefined;
  public rowHeight = 35;

  isDragging = false;
  firstPanelWidth = 50;
  private readonly MIN_PANEL_WIDTH = 600;

  private startX = 0;
  private startWidth = 0;
  private mouseMoveListener: (e: MouseEvent) => void;
  private mouseUpListener: () => void;

  verbalGoodsAndServicesTableFiltersSelected$: Observable<boolean> = this.filterChipStateService
    .getfilterChipChanges()
    .pipe(
      map(
        (fcs) =>
          !!fcs.find(
            (fc) =>
              fc.field === 'goodsAndServices' || fc.field === 'nameRiskLevel',
          ),
      ),
    );

  public sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [TableColumnService.columnsOpenPanel],
    position: 'right',
    hiddenByDefault: false,
  };

  gridApi?: any;

  public isFilteringActive = false;
  private saveFilterModel: any = undefined;
  isChangedFilterInDropdown = false;
  operationType = FilterTemplateOperation.NONE;

  readonly gridOptions: GridOptions = {
    getRowNodeId: (t) => t.id,
    defaultColDef: {
      resizable: true,
      suppressSizeToFit: true,
      menuTabs: ['filterMenuTab', 'generalMenuTab'],
    },
    onColumnMoved: (event) => {
      this.tableColumnService.onColumnChange(event, this.isExpert());
    },
    onColumnVisible: (event) => {
      this.tableColumnService.onColumnChange(event, this.isExpert());
      if (event.column?.getColId() === 'flags' && !event.column.isVisible()) {
        this.flagsEnabled = false;
      } else if (
        event.column?.getColId() === 'flags' &&
        event.column.isVisible()
      ) {
        this.flagsEnabled = true;
      }
    },
    onColumnResized: (event: ColumnResizedEvent): void => {
      if (event.column !== null) {
        this.tableColumnService.onColumnChange(event, this.isExpert());
      }
    },

    columnDefs: this.columnDefinition(),
    frameworkComponents: {
      riskColumnRenderer: RiskLevelRendererComponent,
      riskNameRenderer: RiskNameRendererComponent,
      materialTooltip: MaterialTooltipComponent,
      ownerFilter: OwnerFilterComponent,
      expandAllHeaderComponent: ExpandAllHeaderComponent,
      textColumnFilterWithoutOptions: CustomTextFilterComponent,
      classesFilter: ClassesFilterComponent,
      goodsAndServicesRendererComponent: GoodsAndServicesRendererComponent,
      checkboxTreeColumnFilter: StatusFilterComponent,
      logoRendererComponent: LogoRendererComponent,
      flagsRenderer: FlagsTableComponent,
      flagFilter: FlagFilterComponent,
      gracePeriodIndicator: GracePeriodIndicatorComponent,
      protectionClaimedRenderer: CountryRendererComponent,
      ttabColumnRenderer: TtabRendererComponent,
      reputationRenderer: ReputationIconRendererComponent,
      freetextRenderer: SaveFreetextRendererComponent,
      pocaScoreIndicator: PocaScoreIndicatorComponent,
      actionsRenderer: ActionsRendererComponent,
      ownerCellRenderer: OwnerCellRendererComponent,
      selectAllRenderer: SelectAllHeaderComponent,
      goodsAndServiceFilter: GoodsServiceFilterComponent,
      actionsFilter: ActionsFilterComponent,
    },
    rowModelType: 'serverSide',
    serverSideStoreType: 'partial',
    maxConcurrentDatasourceRequests: 2,
    cacheBlockSize: 100,
    rowBuffer: 0,
    isServerSideGroupOpenByDefault: () => {
      return true;
    },
    blockLoadDebounceMillis: 0,
    loadingCellRendererFramework: MaterialLoadingCellRendererComponent,
    overlayLoadingTemplate: '<span></span>',

    getRowHeight: () => {
      return this.rowHeight;
    },

    onGridReady: (event: GridReadyEvent) => {
      this.gridApi = event.api;
      event.api.setServerSideDatasource(
        this.allThreatsDatasource.createFor(
          this.resultsDeliveryId,
          !!this.ownedBrandsOnly,
        ),
      );

      this.currentUser$.subscribe((u) => {
        this.userPreference = u.preference!;
        this.tableColumnService.initColumns(
          event.columnApi,
          u.preference!,
          'threatTableWithBulkActionsColumns',
        );
        this.initTableSettings(this.userPreference.tableSettings);
      });

      this.userPreference.threatTableWithBulkActionsColumns.forEach(
        (column: any) => {
          if (column.colId === 'flags' && column.hide) {
            this.flagsEnabled = false;
          } else if (column.colId === 'flags' && !column.hide) {
            this.flagsEnabled = true;
          }
        },
      );

      this.applyRouteFilterIfExist();

      event.api.sizeColumnsToFit();

      fromEvent(window, 'resize')
        .pipe(untilDestroyed(this), debounceTime(250))
        .subscribe(() => {
          event.api.sizeColumnsToFit();
        });
    },
    onColumnPinned: (event) => {
      if (event && event.column) {
        this.tableColumnService.onColumnChange(event);
      }
    },
    onColumnEverythingChanged: (event) => {
      if (event.source === 'contextMenu') {
        this.tableColumnService.onColumnChange(event);
      }
    },
    processRowPostCreate: (params) => {
      const index =
        this.preferenceStorageService.getPreference(this.selectedRowkey) || 0;
      if (this.splitViewEnabled) {
        const firstRow = params.api?.getDisplayedRowAtIndex(index)!;
        if (!!firstRow && params.rowIndex === 0) {
          this.onRowClicked(firstRow! as any);
        }
      }
    },
    getContextMenuItems: ({ node }): Array<MenuItemDef> => {
      const items = [];
      if (!!node) {
        items.push({
          name: 'Open Full Text',
          action: () => this.openFulltext(node!.data),
        });

        items.push({
          name: 'Add a comment',
          action: () => {
            this.actionRendererService.addComment(node, this.gridApi);
          },
        });
        if (this.flagsEnabled) {
          items.push({
            name: 'Add flag',
            subMenu: this.resultsDelivery.flagDefinitions.flags.map(
              (flag: any) => {
                return {
                  name: `<mat-icon class="mat-icon material-icons circle" style="color: ${flag.color};">circle</mat-icon> ${flag.label}`,
                  action: () => {
                    this.flagService.toggleActive(
                      flag.id,
                      this.resultsDelivery,
                      node,
                      this.gridApi,
                    );
                  },
                };
              },
            ),
          });
        }

        items.push({
          name: node!.data.includedInTopThreats
            ? 'Unselect for export'
            : 'Select for export',
          action: () => {
            this.actionRendererService.exportSelection(
              node,
              this.resultsDeliveryId,
              this.gridApi,
            );
          },
        });

        items.push({
          name: node!.data.includedInTopThreats
            ? 'Remove from Top Citations'
            : 'Add to Top Citations',
          action: () => {
            this.actionRendererService.exportSelection(
              node,
              this.resultsDeliveryId,
              this.gridApi,
            );
          },
        });

        if (environment.features.threatOrderReset) {
          items.push({
            name: 'Send to top',
            action: () => {
              this.reoderThreats(
                node!.data.threatId,
                undefined,
                ReorderType.CLUSTER,
              );
            },
          });
        }

        if (this.ownedBrandsOnly) {
          items.push({
            name: 'Move to All Citations',
            action: () => this.setOwnedBrand(node!.data, false),
          });
        } else if (this.isExpert() && this.hasOwnCompany()) {
          items.push({
            name: 'Move to Owned Brands',
            action: () => this.setOwnedBrand(node!.data, true),
          });
        }

        if (!!node!.data.ptoLink) {
          const isPtoLinkAvailable =
            node!.data.ptoLink.indexOf('NotAvailable') === -1;

          items.push({
            name: 'Open PTO record',
            cssClasses: ['pto-record'],
            disabled: !isPtoLinkAvailable,
            tooltip: !isPtoLinkAvailable
              ? 'Your record cannot be retrieved from the PTO website'
              : undefined,
            action: () => {
              if (isPtoLinkAvailable) {
                window.open(node!.data.ptoLink);
              }
            },
          });
        }
      }
      return items;
    },
    onFirstDataRendered: (event) => {
      event.api.sizeColumnsToFit();
    },
    onPaginationChanged: (event) => {
      this.filterChipStateService.setHitsCount(event);
    },
    onRowSelected: (event) => {
      if (!event.node.isSelected()) {
        this.selectedThreats.delete(event.node.data.id);
      }

      if (this.selectedThreats.size >= this.maxSelectionLimit) {
        if (!this.selectedThreats.has(event.node.data.id)) {
          this.showMaxSelectionSnackbar();
          event.node.setSelected(false);
        }
        return;
      }

      this.triggerSelectionChanged();
    },

    onRowDoubleClicked: (event) => this.openFulltext(event.data),
    onRowDragEnter: (event) => {
      this.document.querySelector('.ag-dnd-ghost-label')!.textContent =
        event.node.data.trademarkName;
    },
    onRowDragEnd: (event) => {
      const putThreatId = event.node.data.threatId;
      const targetThreatId = event.overNode!.data.threatId;
      const putClusterRank = event.node.data.clusterRank;
      const targetClusterRank = event.overNode!.data.clusterRank;
      const reorderType =
        putClusterRank === targetClusterRank
          ? ReorderType.THREAT
          : ReorderType.CLUSTER;

      if (putThreatId !== targetThreatId) {
        this.reoderThreats(putThreatId, targetThreatId, reorderType);
      }
    },
    rowClassRules: {
      unreviewed: '!data.reviewed',
    },

    suppressHorizontalScroll: false,
    suppressMovableColumns: false,
    suppressDragLeaveHidesColumns: true,
    suppressCellSelection: true,
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    tooltipMouseTrack: true,

    onFilterChanged: (event: FilterChangedEvent) => {
      if (this.operationType !== FilterTemplateOperation.REMOVE) {
        this.operationType = FilterTemplateOperation.ADD;
      }
      if (this.isChangedFilterInDropdown) {
        this.operationType = FilterTemplateOperation.CHANGE;
      }
      let currentFilterModel = { ...event.api.getFilterModel() };

      if (
        'flags' in currentFilterModel &&
        currentFilterModel?.flags?.filter?.includes(undefined)
      ) {
        currentFilterModel = this.handleFlagFilterValues(currentFilterModel);
        event.api.setFilterModel(currentFilterModel);
        event.api.onFilterChanged();
      }

      this.filterChangeService.onFilterChange(event);

      this.isFilterActive();
    },

    debug: !environment.production,
  };

  columnDefinition(): (ColDef | ColGroupDef)[] {
    const definitions: (ColDef | ColGroupDef)[] = [
      {
        colId: 'checkboxAction',
        headerComponent: 'selectAllRenderer',
        checkboxSelection: true,
        lockPinned: true,
        lockPosition: 'left',
        suppressMenu: true,
        maxWidth: 100,
        minWidth: 100,
        rowDrag: true,
        suppressColumnsToolPanel: true,
        toolPanelClass: 'tool-panel-disable',
        cellStyle: { display: 'flex', alignItems: 'center' },
        cellRenderer: (params: any) => {
          params.node.setSelected(this.selectedThreats.has(params.node.id));
        },
        headerComponentParams: {
          onHeaderButtonClick: this.triggerSelectionChanged.bind(this),
        },
      },
      {
        headerName: 'Citation',
        field: 'trademarkName',
        cellRenderer: 'riskNameRenderer',
        filter: 'textColumnFilterWithoutOptions',
        cellClass: 'reviewed-indicator-container center-text-in-cell',
        minWidth: 240,
        autoHeight: true,
      },
      {
        headerName: 'Actions',
        cellRenderer: 'actionsRenderer',
        cellRendererParams: {
          resultsDelivery: this.route.snapshot.data.resultsDelivery,
          onClick: this.handleIconClick.bind(this),
        },
        field: 'actions',
        cellStyle: { display: 'flex', alignItems: 'center' },
        filter: 'actionsFilter',
        filterParams: {
          actionFilterOptions: [
            ACTION_FILTER_OPTIONS[0],
            ACTION_FILTER_OPTIONS[1],
            ACTION_FILTER_OPTIONS[2],
          ],
        },
        menuTabs: ['filterMenuTab'],
        width: 140,
        minWidth: 130,
        toolPanelClass: 'tool-panel-disabled',
      },
      {
        headerName: 'Flags',
        field: 'flags',
        cellRenderer: 'flagsRenderer',
        cellRendererParams: {
          resultsDelivery: this.route.snapshot.data.resultsDelivery,
          onClick: this.handleIconClick.bind(this),
        },
        filter: 'flagFilter',
        filterParams: {
          flagDefinitions: (): Observable<FlagDefinition[]> => {
            return this.filterValues$.pipe(map((fv) => fv.flagDefinitions!));
          },
        },
        minWidth: 100,
        cellStyle: { display: 'flex', alignItems: 'center' },
      },
      {
        headerName: 'Image',
        field: 'logo',
        cellRenderer: 'logoRendererComponent',
        cellRendererParams: { logoBackendUrl: environment.backendBaseUrl },
        width: 120,
        minWidth: 100,
        cellClass: 'left',
        cellStyle: { display: 'flex', alignItems: 'center' },
      },
      {
        headerName: 'Owner',
        field: 'trademarkApplicantName',
        filter: 'ownerFilter',
        cellStyle: { display: 'flex', alignItems: 'center' },
        minWidth: 200,
        cellRenderer: 'ownerCellRenderer',
        cellRendererParams: {
          isExpert: this.isExpert(),
        },
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params: any) => params.data.trademarkApplicantName,
      },
      {
        headerName: 'Market',
        field: 'trademarkJurisdictionCode',
        tooltipComponent: 'materialTooltip',
        valueGetter: (params: any) => {
          return params.data.trademarkJurisdictionName;
        },
        width: 130,
        minWidth: 100,
        filter: 'agSetColumnFilter',
        cellStyle: { display: 'flex', alignItems: 'center' },
        filterParams: {
          defaultToNothingSelected: true,
          ...FilterParams.setFilterParams,
          values: (params: SetFilterValuesFuncParams) => {
            this.filterValues$
              .pipe(first())
              .subscribe((filterValues) =>
                params.success(filterValues.jurisdiction),
              );
          },
        },
      },
      {
        headerName: 'Class(es)',
        field: 'classes',
        filter: 'classesFilter',
        cellStyle: { display: 'flex', alignItems: 'center' },
        minWidth: 120,
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params: any) => params.data.classes,
      },
      {
        flex: 1,
        minWidth: 400,
        headerName: 'Goods and Services',
        field: 'goodsAndServices',
        filter: 'goodsAndServiceFilter',
        cellRenderer: 'goodsAndServicesRendererComponent',
        cellClass: 'all-threats-goods-and-services',
        cellStyle: { display: 'flex', alignItems: 'center' },
        resizable: true,
      },
      {
        headerName: 'Status',
        field: 'trademarkStatus',
        width: 130,
        minWidth: 100,
        filter: 'checkboxTreeColumnFilter',
        cellStyle: { display: 'flex', alignItems: 'center' },
        filterParams: {
          trademarkStatus: (): Observable<string[]> => {
            return this.filterValues$.pipe(
              first(),
              map((fv) => fv.status),
            );
          },
        },
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params: any) => params.data.trademarkStatus,
      },
      {
        headerName: 'Verbal',
        headerClass: 'verbal-header-text',
        headerTooltip: 'Verbal',
        tooltipComponent: 'materialTooltip',
        children: [
          {
            headerName: 'V',
            headerClass: ['verbal-header-text', 'centered-header-text'],
            headerTooltip: 'Verbal Similarity',
            field: 'nameRiskLevel',
            cellRenderer: 'riskColumnRenderer',
            cellClass: 'centered-cell-content',
            cellStyle: { display: 'flex', alignItems: 'center' },
            width: 90,
            minWidth: 90,
            filter: 'agSetColumnFilter',
            filterParams: {
              defaultToNothingSelected: true,
              ...FilterParams.setFilterParams,
              values: (params: SetFilterValuesFuncParams) => {
                this.filterValues$
                  .pipe(first())
                  .subscribe((filterValues) =>
                    params.success(filterValues.nameRisk!),
                  );
              },
            },
            tooltipComponent: 'materialTooltip',
            tooltipValueGetter: (params: any) => {
              return (
                'Verbal Similarity: ' +
                params.data.nameRiskLevel[0] +
                params.data.nameRiskLevel.substr(1).toLowerCase()
              );
            },
          },
          {
            headerName: 'C',
            headerClass: ['verbal-header-text', 'centered-header-text'],
            headerTooltip: 'Verbal Case Law Prediction Score',
            field: 'verbalCaseLawRiskLevel',
            cellRenderer: 'riskColumnRenderer',
            cellClass: 'centered-cell-content',
            cellStyle: { display: 'flex', alignItems: 'center' },
            width: 90,
            minWidth: 90,
            filter: 'agSetColumnFilter',
            filterParams: {
              defaultToNothingSelected: true,
              ...FilterParams.setFilterParams,
              values: (params: SetFilterValuesFuncParams) => {
                this.filterValues$
                  .pipe(first())
                  .subscribe((filterValues) =>
                    params.success(filterValues.verbalCaseLawPrediction!),
                  );
              },
            },
            tooltipComponent: 'materialTooltip',
            tooltipValueGetter: (params: any) => {
              return (
                'Verbal Case Law Prediction Score: ' +
                params.data.verbalCaseLawRiskLevel[0] +
                params.data.verbalCaseLawRiskLevel.substr(1).toLowerCase()
              );
            },
          },
          {
            headerName: 'P',
            headerClass: ['verbal-header-text', 'centered-header-text'],
            headerTooltip: 'POCA Score',
            field: 'pocaScoreRiskLevel',
            cellRenderer: 'riskColumnRenderer',
            cellClass: 'centered-cell-content',
            cellStyle: { display: 'flex', alignItems: 'center' },
            width: 90,
            minWidth: 90,
            filter: 'agSetColumnFilter',
            filterParams: {
              defaultToNothingSelected: true,
              ...FilterParams.setFilterParams,
              values: (params: SetFilterValuesFuncParams) => {
                this.filterValues$
                  .pipe(first())
                  .subscribe((filterValues) =>
                    params.success(filterValues.pocaScore!),
                  );
              },
            },
            tooltipComponent: 'materialTooltip',
            tooltipValueGetter: (params: any) => {
              return (
                'Poca Score: ' +
                params.data.pocaScoreRiskLevel[0] +
                params.data.pocaScoreRiskLevel.substr(1).toLowerCase()
              );
            },
          },
        ],
      },
      {
        headerName: 'Goods',
        headerTooltip: 'Goods and Services',
        tooltipComponent: 'materialTooltip',
        children: [
          {
            headerName: 'G',
            headerClass: 'centered-header-text',
            headerTooltip: 'Goods and Services Similarity',
            field: 'goodsAndServicesRiskLevel',
            cellRenderer: 'riskColumnRenderer',
            cellClass: 'centered-cell-content',
            cellStyle: { display: 'flex', alignItems: 'center' },
            width: 90,
            minWidth: 90,
            filter: 'agSetColumnFilter',
            filterParams: {
              defaultToNothingSelected: true,
              ...FilterParams.setFilterParams,
              values: (params: SetFilterValuesFuncParams) => {
                this.filterValues$
                  .pipe(first())
                  .subscribe((filterValues) =>
                    params.success(filterValues.goodsAndServicesRisk),
                  );
              },
            },
            tooltipComponent: 'materialTooltip',
            tooltipValueGetter: (params: any) => {
              return (
                'Goods and Services Similarity: ' +
                params.data.goodsAndServicesRiskLevel[0] +
                params.data.goodsAndServicesRiskLevel.substr(1).toLowerCase()
              );
            },
          },
        ],
      },
      {
        headerName: 'Legal',
        headerClass: 'verbal-header-text',
        headerTooltip: 'Legal',
        tooltipComponent: 'materialTooltip',
        cellStyle: { display: 'flex', alignItems: 'center' },
        children: [
          {
            headerName: 'US 2(d)',
            headerClass: 'verbal-header-text',
            headerTooltip: 'US Section 2(d) of the Lanham Act',
            field: 'refusalInformation',
            tooltipComponent: 'materialTooltip',
            cellStyle: { display: 'flex', alignItems: 'center' },
            tooltipValueGetter: (params: any) => {
              return params.value
                ? 'This mark was cited in a US Section 2(d) case of the Lanham Act'
                : null;
            },
            cellRenderer: (params: any) => {
              return params.data.refusalInformation ? 'US 2(d)' : '';
            },
            cellClass: 'centered-cell-content',
            width: 150,
            minWidth: 150,
          },
          {
            headerName: 'TTAB',
            headerClass: ['verbal-header-text', 'centered-header-text'],
            headerTooltip: 'US Trademark Trial and Appeal Board',
            field: 'includesTtab',
            cellRenderer: 'ttabColumnRenderer',
            cellClass: 'centered-cell-content',
            width: 90,
            minWidth: 90,
            tooltipComponent: 'materialTooltip',
            tooltipValueGetter: (params: any) => {
              return params.data.includesTtab
                ? 'This mark was cited in a TTAB case'
                : '';
            },
          },
          {
            headerName: 'Reputation',
            headerClass: 'verbal-header-text',
            headerTooltip: 'Reputation',
            field: 'reputation',
            tooltipComponent: 'materialTooltip',
            cellStyle: { display: 'flex', alignItems: 'center' },
            tooltipValueGetter: (params: any) => {
              return params.value ? 'Trademark has reputation' : null;
            },
            cellRenderer: (params: any) => {
              return params.data.reputation
                ? '<div class="legal-reputation-icon">!</div>'
                : '';
            },
            cellClass: 'centered-cell-content',
            width: 120,
            minWidth: 120,
          },

          {
            headerName: 'Non-Grace Period',
            headerClass: ['verbal-header-text', 'centered-header-text'],
            headerTooltip: 'Non-Use Grace Period',
            cellClass: 'centered-cell-content',
            cellStyle: { display: 'flex', alignItems: 'center' },
            cellRenderer: 'gracePeriodIndicator',
            field: 'gracePeriod',
            minWidth: 100,
            suppressColumnsToolPanel: !this.isExpert(),
          },
          {
            headerName: 'POCA Scores',
            headerClass: ['verbal-header-text', 'centered-header-text'],
            headerTooltip: 'POCA Scores',
            cellClass: 'centered-cell-content',
            cellStyle: { display: 'flex', alignItems: 'center' },
            cellRenderer: 'pocaScoreIndicator',
            field: 'pocaScore',
            minWidth: 100,
          },
        ],
      },
      {
        headerName: 'Reg. No.',
        headerTooltip: 'Registration Number',
        cellStyle: { display: 'flex', alignItems: 'center' },
        tooltipComponent: 'materialTooltip',
        field: 'registrationNumber',
        minWidth: 130,
      },
      {
        headerName: 'Reg. Date',
        headerTooltip: 'Registration Date',
        tooltipComponent: 'materialTooltip',
        field: 'registrationDate',
        cellStyle: { display: 'flex', alignItems: 'center' },
        minWidth: 130,
      },
      {
        headerName: 'App. No.',
        headerTooltip: 'Application Number',
        tooltipComponent: 'materialTooltip',
        cellStyle: { display: 'flex', alignItems: 'center' },
        field: 'applicationNumber',
        minWidth: 130,
      },
      {
        headerName: 'App. Date',
        headerTooltip: 'Application Date',
        tooltipComponent: 'materialTooltip',
        cellStyle: { display: 'flex', alignItems: 'center' },
        field: 'applicationDate',
        minWidth: 130,
      },
      {
        headerName: 'Protection Claimed',
        headerTooltip: 'Protection Claimed (WIPO)',
        tooltipComponent: 'materialTooltip',
        field: 'protectedCountriesWIPO',
        cellRenderer: 'protectionClaimedRenderer',
        cellStyle: { display: 'flex', alignItems: 'center' },
        minWidth: 230,
      },
      {
        headerName: 'Free Text',
        headerTooltip: 'Free Text',
        tooltipComponent: 'materialTooltip',
        field: 'freeText',
        filter: 'textColumnFilterWithoutOptions',
        cellStyle: { display: 'flex', alignItems: 'center' },
        cellEditor: 'agTextCellEditor',
        minWidth: 230,
        cellRendererParams: { order: this.route.snapshot.data.order },
        cellRenderer: 'freetextRenderer',
      },
    ];

    return definitions;
  }

  removeFilter(filterChip: FilterChip): void {
    this.operationType = FilterTemplateOperation.REMOVE;
    this.filterChipsCallbackHandlerService.refreshTableAfterRemoveFilterChip(
      filterChip,
      this.gridApi,
    );
    this.handleRemovedChipsTriggerSubject();
  }

  removeAllFilters(): void {
    this.operationType = FilterTemplateOperation.REMOVE;
    this.gridApi?.onFilterChanged();
    this.gridApi!.setFilterModel(null);
    this.handleRemovedChipsTriggerSubject();
  }

  private handleRemovedChipsTriggerSubject(): void {
    const currentValue = this.removedChipsTriggerSubject.getValue();
    this.removedChipsTriggerSubject.next(!currentValue);
  }

  getFilterChips(filters: { [key: string]: any }): FilterChip[] {
    return Object.keys(filters).flatMap((field) => {
      let label = this.gridApi!.getColumnDefs()!.find(
        (colDef: ColDef) => colDef.field === field,
      )?.headerName;

      if (label && riskNameToLongNameMapping.get(label)) {
        label = riskNameToLongNameMapping.get(label);
      }

      const filter = filters[field];
      if (filter.filterType === 'custom') {
        if (!!filter.filter && filter.showCompetitors) {
          return this.getFilterChip(label!, field, filter).concat(
            this.getCustomFilterChip(label!, field, filter),
          );
        } else if (filter.showCompetitors) {
          return this.getCustomFilterChip(label!, field, filter);
        } else {
          return !!filter.filter
            ? this.getFilterChip(label!, field, filter)
            : [];
        }
      }
      return this.getFilterChip(label!, field, filter);
    });
  }

  triggerSelectionChanged(): void {
    const currentSelectedThreats: string[] = this.getSelectedNodes(
      this.gridApi,
    );
    this.selectedThreats = new Set([...currentSelectedThreats]);
    this.preferenceStorageService.setPreference(this.selectedThreatsKey, [
      ...this.selectedThreats.keys(),
    ]);
  }

  getFilterChip(
    label: string,
    field: string,
    filter: { [key: string]: any },
  ): FilterChip[] {
    if (filter.filterType === 'set') {
      return filter.values.map((value: string) => ({
        label,
        field,
        value,
      }));
    } else {
      return [
        {
          label,
          field,
          value: filter.filter,
        },
      ];
    }
  }

  getCustomFilterChip(
    label: string,
    field: string,
    filter: { [key: string]: any },
  ): FilterChip[] {
    return [
      {
        label,
        field,
        value: filter.value,
      },
    ];
  }

  constructor(
    private allThreatsDatasource: AllThreatsDatasource,
    private route: ActivatedRoute,
    private threatsService: ThreatsService,
    private brandLandscapeService: BrandLandscapeRefreshService,
    private glasspaneService: GlasspaneService,
    private fullTextOpeningService: FullTextOpeningService,
    private fullTextService: FullTextService,
    private filterChipStateService: FilterChipStateService,
    private filterChangeService: AllThreatsFilterChangeService,
    private router: Router,
    private actionRendererService: ActionRendererService,
    private flagService: FlagService,
    private tableColumnService: TableColumnService,
    private snackBarService: SnackbarService,
    private userPreferenceService: UserPreferencesService,
    private commonPreferencesService: CommonPreferencesService,
    private preferenceStorageService: PreferenceStorageService,
    private filterChipsCallbackHandlerService: FilterChipsCallbackHandlerService,
    private notificationService: NotificationService,
    private addToTopThreatsBulkActionService: AddToTopThreatsBulkActionService,
    private resultDeliveryService: ResultsDeliveryService,
    public topThreatsService: TopThreatsCounterService,
    @Inject(DOCUMENT) private document: Document,
    private tableSettingsService: TableSettingsService,
    private fullScreenService: FullscreenService,
    private elementRef: ElementRef,
  ) {
    this.filterValues$ = route.data.pipe(map((data) => data.filterValues));
    this.currentUser$ = route.data.pipe(map((data) => data.currentUser));
    this.resultsDelivery = route.snapshot.data.resultsDelivery;
    const marketFilter = route.snapshot.queryParamMap.get('marketFilter');
    if (!!marketFilter) {
      this.marketFilter = decodeURIComponent(marketFilter);
    }

    this.selectedThreats = new Set(
      this.preferenceStorageService.getPreference(this.selectedThreatsKey) ||
        [],
    );

    this.mouseMoveListener = (e: MouseEvent) => this.onMouseMove(e);
    this.mouseUpListener = () => this.onMouseUp();
  }

  @HostListener('window:resize')
  onResize(): void {
    if (!this.isDragging) {
      this.adjustInitialPanelWidth();
    }
  }

  ngOnInit(): void {
    document.addEventListener('mousemove', this.mouseMoveListener);
    document.addEventListener('mouseup', this.mouseUpListener);
  }

  initTableSettings(settings: TableSetting[]): void {
    if (this.userPreference?.tableSettings) {
      this.applyTableSetting(settings);
      this.setWidthOfPanels(settings);
    } else {
      this.setFullWidthOfFirstPanel();
    }
  }

  setWidthOfPanels(settings: TableSetting[]): void {
    if (settings[1].value === 'No split') {
      this.setFullWidthOfFirstPanel();
    }

    if (settings[1].value === 'Split view') {
      if (
        !this.userPreference.firstPanelWidth ||
        this.userPreference.firstPanelWidth === 100
      ) {
        this.setHalfWidthOfFirstPanel();
      } else {
        this.firstPanelWidth = this.userPreference.firstPanelWidth;
      }
    }
  }

  setHalfWidthOfFirstPanel(): void {
    this.firstPanelWidth = 50;
    this.userPreference.firstPanelWidth = 50;
  }

  setFullWidthOfFirstPanel(): void {
    this.firstPanelWidth = 100;
    this.userPreference.firstPanelWidth = 100;
  }

  toggleFullscreen(): void {
    this.fullScreenService.toggleFullscreen();

    if (!this.fullscreen.getValue()) {
      this.fullscreenTooltip = 'Fullscreen';
      this.screenIcon = 'open_in_full';
      this.hostHeight = 'calc(100vh - 5rem)';
    } else {
      this.fullscreenTooltip = 'Exit Fullscreen';
      this.screenIcon = 'close_fullscreen';
      this.hostHeight = '100vh';
    }
  }

  applyTableSetting(settings: TableSetting[]): void {
    this.tableSettingsService.applyTableSetting(settings);
    this.rowHeight = this.tableSettingsService.rowHeight;
    this.splitViewEnabled = this.tableSettingsService.splitViewEnabled;
    this.gridApi.resetRowHeights();
    this.gridApi.redrawRows();
  }

  private getSelectedNodes(gridapi: GridApi): string[] {
    const selectedNodes: string[] = [];
    gridapi.getSelectedNodes().map((selectedNode) => {
      if (selectedNode.id !== undefined) {
        selectedNodes.push(selectedNode.id);
      }
    });
    return selectedNodes;
  }

  get resultsDeliveryId(): string {
    return this.route.snapshot.params.resultsDeliveryId;
  }

  get filterChips(): FilterChip[] {
    return this.filterChipStateService.filterChips;
  }

  private openFulltext(data: any): void {
    if (data.id.clusterRoot) {
      return;
    }

    this.fullTextOpeningService.showFulltext(data.id, this.resultsDeliveryId);
    this.fullTextService
      .setThreatReviewedAssociatedWithResultsDelivery(
        this.resultsDeliveryId,
        data.id,
      )
      .subscribe(() => {
        const rowNode = this.gridApi?.getRowNode(data.id);
        rowNode?.setData({ ...data, reviewed: true });
      });
  }

  reoderThreats(
    putThreatId: string,
    targetThreatId?: string,
    reorderType?: ReorderType,
  ): void {
    this.glasspaneService.showGlasspane();
    this.threatsService
      .reorderThreatsAssociatedWithResultsDelivery(
        { put: putThreatId, target: targetThreatId, reorderType },
        this.resultsDeliveryId,
      )
      .pipe(first())
      .subscribe(() => this.gridApi?.refreshServerSideStore({ purge: false }));
  }

  private setOwnedBrand(data: any, isOwnedBrand: any): void {
    this.threatsService
      .updateThreatAssociatedWithResultsDelivery(
        data.id,
        this.resultsDeliveryId,
        isOwnedBrand,
      )
      .pipe(first())
      .subscribe(() => this.gridApi?.refreshServerSideStore({ purge: false }));
    this.areThreatsMovedtoOwnBrands = true;

    const index = this.updatedThreatsSelection.findIndex(
      (threat) => threat.threatId === data.id,
    );

    if (index >= 0) {
      this.updatedThreatsSelection.splice(index, 1);
    }
  }

  showLoadingOverlay(): void {
    this.glasspaneService.showGlasspane();
  }

  refreshTable(): void {
    this.gridApi?.refreshServerSideStore({ purge: false });
    this.brandLandscapeService.refresh(this.route, [], true);
    this.updatedThreatsSelection = [];
  }

  isExpert(): boolean {
    return (
      this.route.snapshot.data.resultsDelivery.strategy.featureLevel ===
      'EXPERT'
    );
  }

  hasOwnCompany(): boolean {
    return (
      this.route.snapshot.data.resultsDelivery.strategy.ownCompanies?.length > 0
    );
  }

  showNavBar(): boolean {
    return this.router.url.includes('threats');
  }

  private applyRouteFilterIfExist(): void {
    if (!!this.marketFilter) {
      const filterModel = this.gridApi!.getFilterModel();

      filterModel.trademarkJurisdictionCode = {
        filterType: 'set',
        values: [this.marketFilter],
      };

      this.gridApi!.setFilterModel(filterModel);
      this.gridApi!.onFilterChanged();
    }
  }

  isFilterActive(): void {
    const customFilters = this.filterChipStateService
      .getCurrentFilterChips()
      .filter(
        (fc) =>
          fc.field ===
            AllThreatsWithBulkActionsComponent.insightHeatMapItemChipName ||
          fc.field ===
            AllThreatsWithBulkActionsComponent.riskPerMarketHeatMapItemChipName,
      );
    if (this.operationType === FilterTemplateOperation.ADD) {
      this.isFilteringActive = true;
    } else if (
      (Object.keys(this.gridApi.getFilterModel()).length === 0 &&
        this.gridApi.sortController.getSortModel().length === 0 &&
        customFilters.length === 0) ||
      this.operationType === FilterTemplateOperation.CHANGE ||
      this.operationType === FilterTemplateOperation.NONE ||
      this.operationType === FilterTemplateOperation.REMOVE
    ) {
      this.isFilteringActive = false;
    } else {
      this.isFilteringActive = true;
    }

    setTimeout(() => {
      this.operationType = FilterTemplateOperation.NONE;
      this.isChangedFilterInDropdown = false;
    }, 3000);
  }

  public onSaveFilter(filterTemplateDataWithUserPreferences: any): void {
    const {
      templateName,
      userPreference,
    } = filterTemplateDataWithUserPreferences.data;

    let currentPreference: any = {};

    if (!this.userPreference) {
      this.currentUser$.subscribe((u) => {
        this.userPreference = u.preference!;
      });
    }

    if (userPreference.preference) {
      currentPreference = userPreference.preference;
    } else {
      currentPreference = userPreference;
    }

    if (!this.userPreference.filterTemplates) {
      this.userPreference.filterTemplates = [];
    }

    this.saveFilterModel = this.gridApi?.getFilterModel();
    this.textFilterDataUpdate();
    this.userPreference = {
      filterTemplates: [
        ...currentPreference?.filterTemplates,
        {
          templateName,
          filterModel: this.saveFilterModel,
          filterChips: this.filterChipStateService.filterChips,
        },
      ],
      threatTableColumns: [...currentPreference?.threatTableColumns],
      summaryWidgets: [...currentPreference?.summaryWidgets],
    };
    this.userPreferenceService
      .saveUserPreferences(this.userPreference)
      .subscribe(
        (pref) => {
          this.userPreference = pref;
        },
        () => {
          this.snackBarService.openSnackBar(
            'Saving filter preset was unsuccessful, please try again.',
          );
        },
        () => {
          this.snackBarService.openSnackBar(
            `Your filter template "${templateName}" has been saved.`,
          );
          this.commonPreferencesService.setUserPreference(true);
        },
      );
  }

  public onUpdateFilter(preference: any[]): void {
    const previousTemplateName = preference[0].templateName;

    const updatedFilterChips = preference[0].filterChips;

    let updatedFilterModel = {};

    updatedFilterModel = this.updateFilterModelWithTextFilterType(
      preference[0].filterModel,
      updatedFilterChips,
    );
    updatedFilterModel = this.updateFilterModelWithSetFilterType(
      updatedFilterModel,
      updatedFilterChips,
    );

    let currentPreference: any = {};

    if (!this.userPreference) {
      this.currentUser$.subscribe((u) => {
        this.userPreference = u.preference!;
      });
    }

    if (this.userPreference.preference) {
      currentPreference = this.userPreference.preference;
    } else {
      currentPreference = this.userPreference;
    }

    this.saveFilterModel = this.gridApi?.getFilterModel();
    this.textFilterDataUpdate();

    const updatedFilterTemplates = currentPreference?.filterTemplates.map(
      (f: { templateName: any }) =>
        f.templateName === previousTemplateName
          ? {
              ...f,
              templateName: preference[1].data.templateName,
              filterChips: updatedFilterChips,
              filterModel: updatedFilterModel,
            }
          : f,
    );

    this.userPreference = {
      filterTemplates: updatedFilterTemplates,
      threatTableColumns: [...currentPreference?.threatTableColumns],
      summaryWidgets: [...currentPreference?.summaryWidgets],
    };

    this.userPreferenceService
      .saveUserPreferences(this.userPreference)
      .subscribe(
        (pref) => {
          this.userPreference = pref;
        },
        () => {
          this.snackBarService.openSnackBar(
            `Updating filter template ${previousTemplateName} was unsuccessful, please try again.`,
          );
        },
        () => {
          this.snackBarService.openSnackBar(
            `Your filter template "${previousTemplateName}" has been updated.`,
          );
          this.commonPreferencesService.setUserPreference(true);
        },
      );
  }

  showMaxSelectionSnackbar(): void {
    this.notificationService
      .showSnackbar(
        'A maximum of 100 citations can be selected',
        'Dismiss',
        3000,
      )
      .onAction()
      .subscribe();
  }

  bulkAction(event: { type: string; value?: any }): void {
    if (event.type === 'flag') {
      this.selectedThreats.forEach((threatId) => {
        const flagId = event.value.id;
        const rowNode = this.gridApi?.getRowNode(threatId);
        this.flagService.toggleActive(
          flagId,
          this.resultsDelivery,
          rowNode,
          this.gridApi,
          event.value.checked,
        );
      });
    }
    if (event.type === 'top-threat') {
      this.resultDeliveryService
        .getResultsDeliveryTopThreatsIds(this.resultsDeliveryId)
        .subscribe((topThreatIds: string[]) => {
          this.addToTopThreatsBulkActionService.add(
            this.gridApi,
            this.resultsDeliveryId,
            this.selectedThreats,
            topThreatIds,
          );
        });
    } else if (event.type === 'comment') {
      this.actionRendererService.addCommentToMultipleThreats(
        this.gridApi.getSelectedNodes(),
        this.gridApi,
        Array.from(this.selectedThreats),
      );
    }
  }

  public updateFilterModelWithSetFilterType(
    filterModel: any,
    updatedFilterChips: any[],
  ): any {
    for (const key of Object.keys(filterModel)) {
      if (filterModel[key]?.filterType === 'set') {
        filterModel[key] = this.handleSetFilterType(
          filterModel[key],
          updatedFilterChips,
        );
      }
    }
    return filterModel;
  }

  public updateFilterModelWithTextFilterType(
    filterModel: any,
    updatedFilterChips: any[],
  ): any {
    for (const key of Object.keys(filterModel)) {
      if (
        !filterModel[key]?.filtertType ||
        filterModel[key]?.filtertType === 'custom' ||
        filterModel[key]?.filtertType === 'customText'
      ) {
        const fieldsFromUpdatedFilterChips = updatedFilterChips.map(
          (ufc) => ufc.field,
        );

        if (!fieldsFromUpdatedFilterChips.includes(key)) {
          delete filterModel[key];
        }
      }
    }
    return filterModel;
  }

  public handleSetFilterType(
    filterModelKey: any,
    updatedFilterChips: any[],
  ): any {
    const valuesFromUpdatedFilterChips = updatedFilterChips.map(
      (ufc) => ufc.value,
    );

    for (const val of filterModelKey.values) {
      if (!valuesFromUpdatedFilterChips.includes(val)) {
        const index = filterModelKey.values.indexOf(val);
        filterModelKey.values.splice(index, 1);
      }
    }
    return filterModelKey;
  }

  private textFilterDataUpdate(): void {
    const customTextFilterModelNames = [
      'clientReference',
      'subClientReference',
    ];
    Object.keys(this.saveFilterModel).forEach((property) => {
      if (includes(customTextFilterModelNames, property)) {
        if (this.saveFilterModel[property].textFilter?.filter) {
          const condition1 = this.saveFilterModel[property].textFilter;
          delete this.saveFilterModel[property].textFilter;
          this.saveFilterModel[property].textFilter = { condition1 };
        }
      }
    });
  }

  onRestoreFilter(preference: any): void {
    this.isChangedFilterInDropdown = true;
    this.operationType = FilterTemplateOperation.CHANGE;
    if (preference[0] === undefined) {
      this.removeAllFilters();
      return;
    }

    const allGridFilters = this.gridApi.filterManager.allColumnFilters.keys();
    for (const filter of allGridFilters) {
      this.gridApi.filterManager.allColumnFilters.delete(filter);
    }

    this.filterChipStateService.clear();
    this.clearFilterIconFromPreviousSelectedFilterTemplate();

    const filterChips = preference[0].filterChips.filter(
      (fc: FilterChip) =>
        fc.field ===
          AllThreatsWithBulkActionsComponent.insightHeatMapItemChipName ||
        fc.field ===
          AllThreatsWithBulkActionsComponent.riskPerMarketHeatMapItemChipName,
    );

    this.filterChipStateService.addAll(filterChips);

    this.gridApi?.setFilterModel(preference[0].filterModel);
    if (!preference[1]) {
      this.restScrollToTop();
    }
  }

  restScrollToTop(): void {
    this.gridApi?.ensureIndexVisible(0, 'top');
  }

  handleFlagFilterValues(currentFilterModel: any): any {
    currentFilterModel.flags = {
      ...currentFilterModel.flags,
      filter: currentFilterModel?.flags?.ids,
    };

    return currentFilterModel;
  }

  clearFilterIconFromPreviousSelectedFilterTemplate(): void {
    this.gridApi.getFilterInstance('trademarkName');
    this.gridApi.getFilterInstance('flags');
    this.gridApi.getFilterInstance('trademarkApplicantName');
    this.gridApi.getFilterInstance('trademarkJurisdictionCode');
    this.gridApi.getFilterInstance('classes');
    this.gridApi.getFilterInstance('trademarkStatus');
    this.gridApi.getFilterInstance('nameRiskLevel');
    this.gridApi.getFilterInstance('verbalCaseLawRiskLevel');
    this.gridApi.getFilterInstance('pocaScoreRiskLevel');
    this.gridApi.getFilterInstance('goodsAndServicesRiskLevel');
  }

  onRowClicked(event: any): void {
    setTimeout(() => {
      this.selectedRowIndex = event.rowIndex;
      this.setRowFocus(this.selectedRowIndex);
      this.splitViewData = {
        threat: event.data,
        hasPrevious: !!this.gridApi.getDisplayedRowAtIndex(
          this.selectedRowIndex - 1,
        )
          ? true
          : false,
        hasNext: !!this.gridApi.getDisplayedRowAtIndex(
          this.selectedRowIndex + 1,
        )
          ? true
          : false,
      };
    }, 100);
  }

  handleIconClick(params: any): void {
    this.onRowClicked(params);
  }

  onRowSelected(event: any): void {
    const selectedNodes = this.gridApi.getSelectedNodes();
    if (selectedNodes.length > -1) {
      const selectedNode = event.node;
      this.selectedRowIndex = selectedNode.rowIndex;
      this.splitViewData = {
        threat: selectedNode.data,
        hasPrevious: !!this.gridApi.getDisplayedRowAtIndex(
          this.selectedRowIndex - 1,
        ),
        hasNext: !!this.gridApi.getDisplayedRowAtIndex(
          this.selectedRowIndex + 1,
        ),
      };
      this.setRowFocus(this.selectedRowIndex);
    }
  }

  nextselection(direction: string): void {
    const newRowIndex =
      direction === 'forward'
        ? this.selectedRowIndex + 1
        : this.selectedRowIndex - 1;
    const newRowNode = this.gridApi.getDisplayedRowAtIndex(newRowIndex);

    if (newRowNode) {
      this.selectedRowIndex = newRowIndex;
      this.splitViewData = {
        threat: newRowNode.data,
        hasPrevious: !!this.gridApi.getDisplayedRowAtIndex(newRowIndex - 1)
          ? true
          : false,
        hasNext: !!this.gridApi.getDisplayedRowAtIndex(newRowIndex + 1)
          ? true
          : false,
      };
      this.setRowFocus(this.selectedRowIndex);
    }
  }

  setRowFocus(rowIndex: number): void {
    if (this.previousFocusedRowIndex !== rowIndex) {
      const gridApi = this.gridApi;
      gridApi.setFocusedCell(rowIndex, 'trademarkName');
      this.previousFocusedRowIndex = rowIndex;
      gridApi.ensureIndexVisible(rowIndex);
      this.preferenceStorageService.setPreference(
        this.selectedRowkey,
        rowIndex,
      );
    }
  }

  onMouseDown(event: MouseEvent): void {
    this.isDragging = true;
    this.startX = event.clientX;
    this.startWidth = this.firstPanelWidth;
    event.preventDefault();
  }

  private adjustInitialPanelWidth(): void {
    const containerWidth = this.elementRef.nativeElement.querySelector(
      '.splitviewtable',
    ).offsetWidth;

    const maxFirstPanelWidth =
      ((containerWidth - this.MIN_PANEL_WIDTH) / containerWidth) * 100;
    this.firstPanelWidth = Math.min(this.firstPanelWidth, maxFirstPanelWidth);
  }

  private onMouseMove(event: MouseEvent): void {
    if (!this.isDragging) {
      return;
    }

    const container = this.elementRef.nativeElement.querySelector(
      '.splitviewtable',
    );
    const containerWidth = container.offsetWidth;

    const minPercentage = (this.MIN_PANEL_WIDTH / containerWidth) * 100;
    const maxPercentage = 100 - minPercentage;

    const dx = event.clientX - this.startX;
    const deltaPercentage = (dx / containerWidth) * 100;

    this.firstPanelWidth = Math.max(
      minPercentage,
      Math.min(maxPercentage, this.startWidth + deltaPercentage),
    );
    this.userPreference.firstPanelWidth = this.firstPanelWidth;
  }

  private onMouseUp(): void {
    this.isDragging = false;
  }

  ngOnDestroy(): void {
    document.removeEventListener('mousemove', this.mouseMoveListener);
    document.removeEventListener('mouseup', this.mouseUpListener);
  }
}
