import { Component, Input, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ReportDate } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-input-datepicker',
  templateUrl: './input-datepicker.component.html',
  styleUrls: ['./input-datepicker.component.scss'],
})
export class InputDatePickerComponent implements OnInit {
  @Input() data!: ReportDate;
  @Input() customize = false;
  @Input() disableSubsection = false;

  reportDate: Date = new Date();

  ngOnInit(): void {
    if (this.data.reportDate!.value!) {
      this.reportDate = new Date(this.data!.reportDate!.value!);
    } else {
      this.data!.reportDate!.value = this.reportDate.getTime();
    }

    if (!this.data.reportDate!.timeZone) {
      this.data!.reportDate!.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  }

  addEvent(event: MatDatepickerInputEvent<Date>): void {
    this.data!.reportDate!.value = event?.value!.getTime();
    this.data!.reportDate!.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
