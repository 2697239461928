import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResultsDelivery } from '@compumark/bla-backend-client';

import { MatDialog } from '@angular/material/dialog';
import {
  CoexistencePerMarketDialogComponent,
  CoexistencePerMarketDialogData,
} from '../coexistence-per-market-dialog/coexistence-per-market-dialog.component';
import { WIDGETS } from 'src/app/widgets/widget/widget.component';

@Component({
  selector: 'app-coexistence-per-market-widget',
  templateUrl: './coexistence-per-market-widget.component.html',
  styleUrls: ['./coexistence-per-market-widget.component.scss'],
})
export class CoexistencePerMarketWidgetComponent {
  @Output() removeEvent = new EventEmitter<string>();
  resultsDelivery: ResultsDelivery;

  constructor(route: ActivatedRoute, public dialog: MatDialog) {
    this.resultsDelivery = route.snapshot.data.resultsDelivery;
  }

  get keywords(): string[] {
    return this.resultsDelivery.searchKeywords.length > 0
      ? this.resultsDelivery.searchKeywords.map((k) => k.toLowerCase())
      : this.resultsDelivery.strategy!.name!.split(' ');
  }

  get classes(): string[] {
    return this.resultsDelivery.strategy!.classes!.sort(
      (a, b) => parseInt(a, 10) - parseInt(b, 10),
    );
  }

  expand(): void {
    this.dialog
      .open(CoexistencePerMarketDialogComponent, {
        data: {
          resultsDeliveryId: this.resultsDelivery.id,
          keywords: this.keywords,
          classes: this.resultsDelivery.strategy!.classes,
        } as CoexistencePerMarketDialogData,
        autoFocus: false,
        width: '100%',
        hasBackdrop: true,
        panelClass: 'coexistence-per-market-dialog',
      })
      .afterClosed();
  }

  remove(): void {
    this.removeEvent.emit(WIDGETS.COEXISTENCE_PER_MARKET);
  }
}
