import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent {
  @Input() imgId?: string;
  @Input() fillAvailable?: boolean;

  get imageUrl(): string {
    return `${environment.backendBaseUrl}/brand-context/logo/${this.imgId}`;
  }
}
