import { GridApi } from '@ag-grid-community/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ThreatsService } from '@compumark/bla-backend-client';
import { FilterChip } from '@compumark/brand-context-components';
import _ from 'lodash';
import { Observable, map } from 'rxjs';
import { UserService } from 'src/app/security/services/user.service';

@Component({
  selector: 'app-custom-filters-panel',
  templateUrl: './custom-filters-panel.component.html',
  styleUrls: ['./custom-filters-panel.component.scss'],
})
export class CustomFiltersPanelComponent implements OnInit {
  @Input() showAddToTopThreats = true;
  @Input()
  resultsDeliveryId!: string;
  @Input()
  gridApi!: GridApi;
  @Input()
  selectedHeatMapFilterChips: FilterChip[] = [];
  @Input() ownedBrandsOnly?: boolean;
  showBulkActionMenu = false;
  @Input()
  flagsEnabled!: boolean;
  contentType?: string;

  totalCounts$!: Observable<number>;

  @Input()
  selectedThreads: Set<string> = new Set();

  @Output()
  actionSelected: EventEmitter<{
    type: string;
    value?: any;
  }> = new EventEmitter();
  constructor(
    private threatsService: ThreatsService,
    private userService: UserService,
    private route: ActivatedRoute,
  ) {}
  ngOnInit(): void {
    this.userService.getCurrentUserObservable().subscribe((u) => {
      const roles: string[] = u.roles || [];
      this.showBulkActionMenu = roles.includes('ALL_THREATS_BULK_ACTIONS');
      this.contentType = this.route.snapshot.data.contentType;
    });
    this.route.data.subscribe((data) => {
      this.contentType = data.contentType;
      this.loadTotalCounts();
    });
  }
  loadTotalCounts(): void {
    if (this.contentType === 'TRADEMARK') {
      this.totalCounts$ = this.threatsService
        .getThreatsAssociatedWithResultsDelivery(
          { contentType: 'TRADEMARK' },
          this.resultsDeliveryId,
          0,
          1,
        )
        .pipe(map((r) => r.totalCount));
    } else if (this.contentType === 'COMMON_LAW') {
      this.totalCounts$ = this.threatsService
        .getCommonLawThreatsAssociatedWithResultsDelivery(
          this.resultsDeliveryId,
          0,
          1,
        )
        .pipe(map((r) => r.totalCount));
    } else if (this.contentType === 'PHARMA') {
      this.totalCounts$ = this.threatsService
        .getPharmaThreatsAssociatedWithResultsDelivery(
          this.resultsDeliveryId,
          0,
          1,
        )
        .pipe(map((r) => r.totalCount));
    } else if (this.contentType === 'DOMAIN_NAMES') {
      this.totalCounts$ = this.threatsService
        .getDomainNameThreatsAssociatedWithResultsDelivery(
          this.resultsDeliveryId,
          0,
          1,
        )
        .pipe(map((r) => r.totalCount));
    } else if (this.contentType === 'COMPANY_NAMES') {
      this.totalCounts$ = this.threatsService
        .getCompanyNameThreatsAssociatedWithResultsDelivery(
          this.resultsDeliveryId,
          0,
          1,
        )
        .pipe(map((r) => r.totalCount));
    } else if (this.contentType === 'IMAGE') {
      this.totalCounts$ = this.threatsService
        .getImageThreatsAssociatedWithResultsDelivery(
          { contentType: 'IMAGE' },
          this.resultsDeliveryId,
          0,
          1,
        )
        .pipe(map((r) => r.totalCount));
    }
  }
  onActionSelected(selectedValue: { type: string; value?: any }): void {
    this.actionSelected.emit(selectedValue);
  }
}
