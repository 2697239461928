import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Strategy } from '@compumark/bla-backend-client';
import { SearchStrategyDialogComponent } from 'src/app/search-strategy/components/search-strategy-dialog/search-strategy-dialog.component';

export interface SearchStrategyModel {
  strategy: Strategy;
  packageName?: string;
  orderType?: string;
}

@Component({
  selector: 'app-search-strategy-button',
  templateUrl: './search-strategy-button.component.html',
  styleUrls: ['./search-strategy-button.component.scss'],
})
export class SearchStrategyButtonComponent {
  @Input() strategy?: Strategy;
  @Input() packageName?: string;
  @Input() orderType?: string;

  constructor(public dialog: MatDialog) {}

  openSearchStrategy(): void {
    this.dialog.open(SearchStrategyDialogComponent, {
      data: {
        strategy: this.strategy,
        packageName: this.packageName,
        orderType: this.orderType,
      } as SearchStrategyModel,
      autoFocus: false,
      panelClass: 'search-strategy-dialog-panel',
    });
  }
}
