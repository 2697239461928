<div class="content">
  <label class="label">Template Name</label>
  <mat-form-field appearance="outline" class="input">
    <mat-label>Template Name</mat-label>
    <input
      matInput
      title="template name"
      id="templateName"
      [(ngModel)]="templateName"
    />
  </mat-form-field>

  <mat-dialog-actions align="end">
    <button
      mat-stroked-button
      color="primary"
      class="cdx-but-lg"
      mat-dialog-close
    >
      Cancel
    </button>
    <button
      class="cdx-but-lg save"
      color="primary"
      mat-flat-button
      matBadgeColor="accent"
      (click)="saveTemplate()"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
