import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Section, SubSection } from '@compumark/bla-backend-client';
import _ from 'lodash';
import { filter, first } from 'rxjs/operators';
import { ExportConfirmationDialogComponent } from '../confirmation-dialog/confimation-dialog.component';

@Component({
  selector: 'app-report-section',
  templateUrl: './report-section.component.html',
  styleUrls: ['./report-section.component.scss'],
})
export class ReportSectionComponent implements OnInit, OnChanges {
  @Input() section!: Section;
  @Input() sectionNumber = 0;
  @Input() selectedSection!: Section;
  @Input() showHeader = true;
  @Input() collapsed = true;
  @Input() sections!: Array<Section>;
  @Output()
  sectionSelected: EventEmitter<Section> = new EventEmitter<Section>();
  @Output()
  subSectionSelected: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  customizeMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() disableSubsection = false;
  iconVisible = false;

  constructor(public dialog: MatDialog) {}

  get isSelected(): boolean {
    this.collapsed = this.isCollapsed;
    return this.collapsed;
  }

  reverseState(): void {
    this.collapsed = !this.collapsed;
  }

  get isReportSetupSection(): boolean {
    return !!this.section.name && this.section.name === 'reportSetup';
  }

  get hasSubsections(): boolean {
    return this.selectedSection?.subSections!.length > 1;
  }

  ngOnInit(): void {
    this.collapsed = this.isCollapsed;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedSection) {
      this.collapsed = this.isCollapsed;
    }
  }

  get isCollapsed(): boolean {
    return this.section === this.selectedSection;
  }

  get isLastSection(): boolean {
    let result = false;
    if (this.sections?.length) {
      const length = this.sections.length;
      if (this.sections[length - 2] === this.section) {
        result = true;
      }
    }
    return result;
  }

  removeSection(): void {
    if (this.sections?.length) {
      this.sections?.splice(this.sectionNumber - 1, 1);
      this.selectedSection = this.sections[this.sectionNumber - 2];
      this.sectionSelected.emit(this.selectedSection);
    }
  }

  openConfirmationPopup(): void {
    this.dialog
      .open(ExportConfirmationDialogComponent, {
        autoFocus: false,
        data: {
          header: 'Remove report section',
          title:
            'Are you sure you want to remove this section and its subsections?',
          confirmButtonLabel: 'Remove section',
          cancelButtonLabel: 'Cancel',
        },
      })
      .afterClosed()
      .pipe(
        first(),
        filter((result) => result === 'CONTINUE'),
      )
      .subscribe(() => this.removeSection());
  }

  addSubSection(): void {
    const subSection = {
      name: 'customSubSection',
      label: '',
      include: true,
      rows: [
        {
          name: 'comment',
          label: '',
          type: 'TEXT_AREA',
          include: true,
        },
      ],
    } as SubSection;
    const section = _.cloneDeep(subSection);
    this.section.subSections?.push(section);
    this.subSectionSelected.emit(this.section.subSections!.length - 1);
    this.customizeMode.emit(true);
  }
}
