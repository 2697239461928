import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  durationInSeconds = 5;
  errorBulkUpdateConfig: MatSnackBarConfig = {
    duration: this.durationInSeconds * 1000,
    panelClass: ['snack-bar'],
  };
  constructor(public snackBar: MatSnackBar) {}

  public openSnackBar(
    message: string,
    action: string = 'Dismiss',
    config: MatSnackBarConfig = this.errorBulkUpdateConfig,
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, action, config);
  }
}
