import { Component, Input, OnInit } from '@angular/core';
import {
  ExportService,
  ExportTemplate,
  Row,
} from '@compumark/bla-backend-client';

@Component({
  selector: 'app-custom-logo-upload',
  templateUrl: './custom-logo-upload.component.html',
  styleUrls: ['./custom-logo-upload.component.scss'],
})
export class CustomLogoUploadComponent implements OnInit {
  @Input() data!: Row;
  @Input() resultsDeliveryId!: string;
  @Input() disableSubsection = false;
  @Input() exportTemplate!: ExportTemplate;
  @Input() customize!: boolean;

  imageLoading = false;

  files: File[] = [];

  rejectedFiles: any[] = [];

  constructor(private exportService: ExportService) {}

  ngOnInit(): void {
    if (!!this.data.value) {
      this.files.push(new File([], 'logo', { type: 'image/png' }));
    }
  }

  onSelect(event: any): void {
    this.rejectedFiles.splice(0, this.rejectedFiles.length);
    if (event.rejectedFiles.length === 0 && event.addedFiles.length > 0) {
      this.data.value = undefined;
      this.files.splice(0, this.files.length);
      this.files.push(event.addedFiles[0]);
      this.imageLoading = true;

      const upload$ = this.exportTemplate?.name
        ? this.exportService.uploadLogoForTemplate(
            this.exportTemplate.name,
            this.files[0],
          )
        : this.exportService.uploadLogo(this.resultsDeliveryId, this.files[0]);

      upload$?.subscribe((result) => {
        this.data.value = result;
        this.imageLoading = false;
      });
    } else {
      this.rejectedFiles.push(...event.rejectedFiles);
    }
  }

  onRemove(): void {
    this.files.splice(0, this.files.length);
    this.data.value = undefined;
  }

  get hasErrors(): boolean {
    return this.rejectedFiles.length > 0;
  }
}
