import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, RowNode } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import _ from 'lodash';

@Component({
  selector: 'app-cluster-renderer',
  templateUrl: './cluster-renderer.component.html',
  styleUrls: ['./cluster-renderer.component.scss'],
})
export class ClusterRendererComponent implements ICellRendererAngularComp {
  public params!: ICellRendererParams;
  public groupNodes: RowNode[] = [];

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  /* istanbul ignore next */
  refresh(): boolean {
    return false;
  }

  get trademarkName(): string {
    return this.params.node.allLeafChildren[0].data.trademarkName;
  }

  get count(): string | undefined {
    return (
      '(' +
      this.params.node.allLeafChildren.filter((n) => n.isSelected()).length +
      '/' +
      this.params.node.allChildrenCount +
      ')'
    );
  }

  get tooltip(): string | undefined {
    const node = this.params.node;
    const threat = this.params.node.allLeafChildren[0].data;
    const clusterInfo = threat.clusterInfo!;
    const selected = node.allLeafChildren.filter((n) => n.isSelected()).length;
    const other =
      clusterInfo.includedInTopThreatsCount +
      clusterInfo.otherThreatsCount -
      selected;
    const markets = clusterInfo.markets;
    const owner = clusterInfo.owner;

    return (
      '<strong>Top citations:</strong> ' +
      selected +
      '<br><strong>Other citations:</strong> ' +
      other +
      '<br><strong>Markets:</strong> ' +
      '<span class="tooltip-markets">' +
      markets.join(', ') +
      '</span>' +
      '<br><strong>Owner:</strong> ' +
      owner +
      '</strong>'
    );
  }
}
