import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GridStackWidget } from 'gridstack';

@Component({
  selector: 'app-add-widget-dialog',
  templateUrl: './add-widget-dialog.component.html',
  styleUrls: ['./add-widget-dialog.component.scss'],
})
export class AddWidgetDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public widgets: GridStackWidget[],
    private dialogRef: MatDialogRef<AddWidgetDialogComponent>,
  ) {}

  addWidget(id: string): void {
    this.dialogRef.close(id);
  }
}
