import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '@compumark/brand-context-components';
import { StatusCodes } from 'http-status-codes';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/security/services/authentication.service';

@Injectable()
export class NotifyOnErrorInterceptor implements HttpInterceptor {
  constructor(
    private authentication: AuthenticationService,
    private alert: NotificationService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          !this.authentication.isErrorResponseUnauthorized(error) &&
          error.status !== 0 &&
          error.status !== StatusCodes.FORBIDDEN &&
          error.status !== StatusCodes.UNAUTHORIZED
        ) {
          if (error.error.message) {
            this.alert.showSnackbarWithTimeout(error.error.message);
          } else {
            this.alert.showSnackbarWithTimeout(
              'Could not complete request. Please try again.',
            );
          }
        }

        return throwError(error);
      }),
    );
  }
}
