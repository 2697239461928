import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-tooltip',
  templateUrl: './image-tooltip.component.html',
  styleUrls: ['./image-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageTooltipComponent {
  @Input() imageUrl = '';
}
