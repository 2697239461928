<app-widget-header [title]="'Coexistence per Market'" (removeEvent)="remove()">
  <span class="show-all">
    <div class="button" (click)="expand()">
      <mat-icon fontSet="material-symbols-outlined"> open_in_full </mat-icon>
    </div>
  </span>
</app-widget-header>

<ng-container *ngIf="resultsDelivery">
  <app-coexistence-per-market-filter
    [resultsDeliveryId]="resultsDelivery.id"
    [keywords]="keywords"
    [classes]="classes"
  ></app-coexistence-per-market-filter>
  <app-coexistence-per-market
    [resultsDeliveryId]="resultsDelivery.id"
    [keywords]="keywords"
    [classes]="classes"
    [limitNumberOfRows]="true"
  ></app-coexistence-per-market>
  <app-coexistence-per-market-description></app-coexistence-per-market-description>
</ng-container>
