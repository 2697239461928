import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/operators';

@Injectable()
export class AbsoluteGroundsDialogOpeningService {
  constructor(public dialog: MatDialog) {}

  showDialog(data: any, dialogComponent: any): void {
    this.dialog
      .open(dialogComponent, {
        data,
        autoFocus: false,
        width: '80vw',
        maxWidth: '100vw',
      })
      .afterClosed()
      .pipe(first())
      .subscribe();
  }
}
