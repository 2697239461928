import { Component, Input, OnInit } from '@angular/core';
import { HIGHCHARTS_CDX_THEME } from '@cdx/highcharts';
import { Contract } from '@compumark/bla-backend-client';
import Highcharts from 'highcharts';
import { Options } from 'highcharts';

@Component({
  selector: 'app-remaining-searches',
  templateUrl: './remaining-searches.component.html',
  styleUrls: ['./remaining-searches.component.scss'],
})
export class RemainingSearchesComponent implements OnInit {
  options?: Options;
  @Input() contract?: Contract;
  @Input() type?: any;
  readonly Highcharts = Highcharts;

  constructor() {}

  ngOnInit(): void {
    Highcharts.setOptions(HIGHCHARTS_CDX_THEME);
    this.options =
      this.type === 'Multi'
        ? this.getOptions('Multi&#x2013;country')
        : this.getOptions('Single&#x2013;country');
  }

  getOptions(title: string): any {
    return {
      chart: {
        type: 'pie',
        height: '190%',
        style: {
          fontFamily: 'Open Sans',
        },
      },
      title: {
        text: title,
        y: 60,
        style: {
          fontFamily: 'Open Sans',
          fontSize: '0.75rem',
        },
      },
      subtitle: {
        text: this.getSearches(),
        verticalAlign: 'middle',
        style: {
          fontWeight: '700',
          fontSize: '1.5rem',
          color: '#212121',
        },
        y: 40,
      },
      legend: { enabled: false },
      tooltip: { enabled: false },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
          innerSize: '60%',
          size: '100%',
        },
      },
      series: [
        {
          borderWidth: 0,
          showInLegend: false,
          type: 'pie',
          colors: ['#001750', '#E0E0E0'],
          data: [
            {
              y: this.getSearches(),
            },
            {
              y: this.getTotalSearches() - this.getSearches(),
            },
          ],
        },
      ],
    };
  }

  getSearches(): number {
    return Math.round(
      this.type === 'Multi'
        ? this.contract?.remainingCap! / this.contract?.multiCountrySearchPrice!
        : this.contract?.remainingCap! /
            this.contract?.singleCountrySearchPrice!,
    );
  }

  getTotalSearches(): number {
    return Math.round(
      this.type === 'Multi'
        ? this.contract?.totalCap! / this.contract?.multiCountrySearchPrice!
        : this.contract?.totalCap! / this.contract?.singleCountrySearchPrice!,
    );
  }
}
