<span
  *ngIf="showMarketsFilter"
  class="markets-filter filter"
  #marketsFilterTrigger="matMenuTrigger"
  [matMenuTriggerFor]="marketsFilter"
  (menuOpened)="menuOpened = true"
  (menuClosed)="menuOpened = false"
  [ngClass]="{ menuOpened }"
>
  <span class="chip-label">Markets</span>
  <mat-icon *ngIf="menuOpened">arrow_drop_up</mat-icon>
  <mat-icon *ngIf="!menuOpened">arrow_drop_down</mat-icon>
</span>

<mat-menu
  #marketsFilter="matMenu"
  class="markets-filter-menu coexistence-filter-menu"
  (click)="$event.stopPropagation()"
>
  <div class="filter-content" (click)="$event.stopPropagation()">
    <mat-form-field class="cdx-input-default" appearance="outline">
      <mat-label class="custom-label">Market</mat-label>
      <input
        class="input1"
        matInput
        ngModel
        [matAutocomplete]="autoMarkets"
        (focus)="currentInput = 0"
        #marketsInput1
        [formControl]="marketsControl1"
        (focus)="setActiveControl(marketsControl1)"
        (blur)="closeDropdownList()"
      />
    </mat-form-field>
    <mat-form-field class="cdx-input-default" appearance="outline">
      <mat-label class="custom-label">Market</mat-label>
      <input
        matInput
        ngModel
        [matAutocomplete]="autoMarkets"
        (focus)="currentInput = 1"
        #marketsInput2
        [formControl]="marketsControl2"
        (focus)="setActiveControl(marketsControl2)"
        (blur)="closeDropdownList()"
      />
    </mat-form-field>
    <mat-form-field class="cdx-input-default" appearance="outline">
      <mat-label class="custom-label">Market</mat-label>
      <input
        matInput
        ngModel
        [matAutocomplete]="autoMarkets"
        (focus)="currentInput = 2"
        #marketsInput3
        [formControl]="marketsControl3"
        (focus)="setActiveControl(marketsControl3)"
        (blur)="closeDropdownList()"
      />
    </mat-form-field>
    <mat-form-field class="cdx-input-default" appearance="outline">
      <mat-label class="custom-label">Market</mat-label>
      <input
        matInput
        ngModel
        [matAutocomplete]="autoMarkets"
        (focus)="currentInput = 3"
        #marketsInput4
        [formControl]="marketsControl4"
        (focus)="setActiveControl(marketsControl4)"
        (blur)="closeDropdownList()"
      />
    </mat-form-field>
    <mat-form-field class="cdx-input-default" appearance="outline">
      <mat-label class="custom-label">Market</mat-label>
      <input
        matInput
        ngModel
        [matAutocomplete]="autoMarkets"
        (focus)="currentInput = 4"
        #marketsInput5
        [formControl]="marketsControl5"
        (focus)="setActiveControl(marketsControl5)"
        (blur)="closeDropdownList()"
      />
    </mat-form-field>

    <div class="actions" (click)="$event.stopPropagation()">
      <button
        mat-raised-button
        color="primary"
        class="apply"
        (click)="onApply()"
      >
        Apply
      </button>
    </div>
  </div>
</mat-menu>

<mat-autocomplete #autoMarkets (optionSelected)="getSelectedMarket($event)">
  <div>
    <mat-option
      *ngFor="let market of this.filteredOptions | async"
      [value]="market"
    >
      {{ market }}
    </mat-option>
  </div>
</mat-autocomplete>
