<div class="search-strategy-dialog">
  <mat-dialog-content>
    <mat-tab-group>
      <mat-tab label="Search Strategy"
        ><app-bla-search-strategy
          *ngIf="data.orderType === 'BRAND_LANDSCAPE_ANALYZER'"
          [strategy]="data.strategy"
          [packageName]="data.packageName"
        ></app-bla-search-strategy>
        <app-owner-analyzer-search-strategy
          *ngIf="data.orderType === 'OWNER_ANALYZER'"
          [strategy]="data.strategy"
        ></app-owner-analyzer-search-strategy
      ></mat-tab>
      <mat-tab label="Filing Dates"
        ><app-filing-dates
          [filingDates]="data.strategy.filingDates"
        ></app-filing-dates
      ></mat-tab>
      <mat-tab disabled>
        <ng-template mat-tab-label>
          <mat-icon tabindex="0" mat-dialog-close>close</mat-icon>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      tabindex="0"
      mat-stroked-button
      color="primary"
      class="cdx-but-lg"
      mat-dialog-close
    >
      Close
    </button>
  </mat-dialog-actions>
</div>
