<div class="title">Include full text record for</div>
<div class="select-all">
  <mat-checkbox
    color="primary"
    [checked]="allChecked"
    (change)="selectAll($event.checked)"
  >
    Select All
  </mat-checkbox>
</div>
<div class="threat-container">
  <div class="threat" *ngFor="let threat of topThreats">
    <span class="checkbox">
      <mat-checkbox
        color="primary"
        [checked]="isChecked(threat.id)"
        (change)="onChange($event.checked, threat.id)"
      >
      </mat-checkbox
    ></span>
    <span class="name">{{ threat.trademark!.name }}</span>
    <span class="code"> - {{ threat.trademark!.jurisdiction!.code }}</span>
    <span class="owner" *ngIf="!!threat.trademark!.applicants">
      - {{ threat.trademark!.applicants![0].name }}
    </span>
  </div>
</div>
