import { Injectable } from '@angular/core';
import {
  ContractType,
  InitiateService,
  User,
} from '@compumark/bla-backend-client';
import { Observable, share } from 'rxjs';

/* istanbul ignore next */
@Injectable()
export class UserService {
  private user!: User;
  private userObservable!: Observable<User>;

  constructor(private initiate: InitiateService) {
    this.refreshCurrentUser();
  }

  getCurrentUserObservable(): Observable<User> {
    return this.userObservable;
  }

  getCurrentUser(): User {
    return this.user;
  }

  isUserTransactional(): boolean {
    return this.getCurrentUser().contract?.type === ContractType.TRANSACTIONAL;
  }

  refreshCurrentUser(): void {
    this.userObservable = this.initiate.getCurrentUser().pipe(share());
    this.userObservable.subscribe((u) => {
      this.user = u;
      return u;
    });
  }
}
