<button mat-icon-button (click)="toggleSideNav()" class="btn">
  <span class="material-symbols-outlined" [matTooltip]="settingsTooltip"
    >settings</span
  >
</button>
<div class="side-nav" [class.open]="isSideNavOpen">
  <div class="header">
    <h2>Table settings</h2>
    <button mat-icon-button class="btn" (click)="toggleSideNav()">
      <span class="material-symbols-outlined close-btn">close</span>
    </button>
  </div>
  <div class="reset-component">
    <app-reset-top-threats-link
      *appIfFeatureEnabled="'threatOrderReset'"
      (resetAllConfirmedEvent)="showLoadingOverlay()"
      (resetAllCompletedEvent)="refreshTable()"
    ></app-reset-top-threats-link>
  </div>
  <div class="side-nav-content">
    <ul>
      <div *ngFor="let section of favoriteViews" class="setting-subsection">
        <h3>{{ section.title }}</h3>
        <mat-radio-group class="setting-radio-group">
          <mat-radio-button
            color="primary"
            class="setting-radio-button"
            *ngFor="let sub of section.subsection"
            [value]="sub.value"
            [checked]="sub.selected"
            (change)="updateView(section, $event.value)"
          >
            <label>{{ sub.value }}</label>
            <img
              class="right non-clickable"
              [src]="sub.image"
              alt="{{ sub.value }}"
            />
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </ul>
  </div>
</div>
