import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import {
  ExportService,
  ExportTemplate,
  FeatureLevel,
  IncludeFulltext,
  Section,
  SubSection,
  Threat,
} from '@compumark/bla-backend-client';
import _ from 'lodash';
import { filter, first } from 'rxjs/operators';

import { FeatureLevelSubSectionFilterService } from '../../services/feature-level-subsection-filter.service';
import { ReportCreationService } from '../../services/report-creation.service';
import { TemplateMergeService } from '../../services/template-merge-service';
import { ExportConfirmationDialogComponent } from '../confirmation-dialog/confimation-dialog.component';

import {
  EXPORT_TEMPLATE_DEFAULT_SECTIONS_JSON,
  EXPORT_TEMPLATE_NEW_ADDED_SECTION_JSON,
} from './templates';
import { TemplateDataService } from '../../services/template-data.service';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-report-editor',
  templateUrl: './report-editor.component.html',
  styleUrls: ['./report-editor.component.scss'],
})
export class ReportEditorComponent implements OnInit, OnDestroy {
  saveReportTemplateSubscription = new Subscription();

  exportTemplate: ExportTemplate = {};
  selectedSection: Section | undefined;
  selectedSubSection: SubSection | undefined;
  selectedSubSectionIndex = 0;
  resultsDeliveryId: string;
  featureLevel: FeatureLevel;
  customizeMode = false;
  observer: IntersectionObserver;
  @ViewChild('content') content!: ElementRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private exportService: ExportService,
    public templateDataService: TemplateDataService,
    private templateMergeService: TemplateMergeService,
    private reportCreationService: ReportCreationService,
    public featureLevelSubSectionFilterService: FeatureLevelSubSectionFilterService,
    public dialog: MatDialog,
  ) {
    this.exportTemplate = EXPORT_TEMPLATE_DEFAULT_SECTIONS_JSON;
    this.resultsDeliveryId = data.resultsDeliveryId;
    this.featureLevel = data.featureLevel;
    this.selectedSection = this.exportTemplate.sections![0];

    const template: ExportTemplate = data.template;
    if (template) {
      this.exportTemplate = this.templateMergeService.merge(
        template,
        _.cloneDeep(EXPORT_TEMPLATE_DEFAULT_SECTIONS_JSON),
      );
      this.exportTemplate.name = template.name;
      this.selectedSection = this.exportTemplate.sections![0];
      this.selectedSubSection = this.selectedSection!.subSections![0];
    } else {
      this.exportService
        .getExportTemplate(data.resultsDeliveryId)
        .subscribe((exportTemplate) => {
          if (exportTemplate !== null) {
            this.exportTemplate = this.templateMergeService.merge(
              exportTemplate,
              _.cloneDeep(EXPORT_TEMPLATE_DEFAULT_SECTIONS_JSON),
            );
          } else {
            this.exportTemplate = _.cloneDeep(
              EXPORT_TEMPLATE_DEFAULT_SECTIONS_JSON,
            );
            this.initExportTemplate(data, this.exportTemplate);
          }
          this.selectedSection = this.exportTemplate.sections![0];
          this.selectedSubSection = this.selectedSection!.subSections![0];
        });
    }

    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionId = entry.target.getAttribute('data-section-id');
            if (sectionId) {
              this.setSelectedSection(sectionId);
            }
          }
        });
      },
      {
        root: null,
        threshold: 0.5,
      },
    );
    this.saveReportTemplateSubscription = new Subscription();
  }

  ngOnInit(): void {
    this.saveReportTemplateSubscription = this.templateDataService.saveTriggered$.subscribe(
      () => {
        this.saveReportTemplate();
      },
    );
  }

  scrollTo(): void {
    this.observer.disconnect();
    if (this.selectedSubSection) {
      this.scrollToSection(this.getId(this.selectedSubSection));
    } else {
      this.scrollToSection('sectionTop');
    }
  }

  getId(subSection: SubSection): string {
    let index = '';
    this.selectedSection?.subSections?.forEach((ss, i) => {
      if (ss === subSection) {
        index = i.toString();
      }
    });
    return subSection.name! + index;
  }

  scrollToSection(section: any): void {
    this.document.querySelector('#' + section)?.scrollIntoView();
  }

  selectSection(section: Section): void {
    if (!!section.subSections && section.subSections.length > 1) {
      this.selectedSubSection =
        this.selectedSection === section
          ? section.subSections[this.selectedSubSectionIndex]
          : section.subSections[0];
    }
    this.selectedSection = section;
    this.scrollTo();
  }

  selectSubSection(subSection: SubSection): void {
    this.selectedSubSection = subSection;
    this.scrollTo();
  }

  saveReportTemplateNDisableCustomize(): void {
    this.customizeMode = false;
    this.saveReportTemplate();
  }

  saveReportTemplate(): void {
    this.exportTemplate.resultsDeliveryId = this.exportTemplate.name
      ? undefined
      : this.resultsDeliveryId;
    this.exportService
      .saveExportTemplate(this.exportTemplate, this.resultsDeliveryId)
      .subscribe();
  }

  initExportTemplate(data: any, exportTemplate: ExportTemplate): void {
    const resultsIndex = exportTemplate.sections!.findIndex(
      (s) => s.name === 'appendix',
    );
    (exportTemplate.sections![resultsIndex].subSections![0]
      .rows![0] as IncludeFulltext).threatIds = data.topThreats.map(
      (tt: Threat) => tt.id,
    );
  }

  isFirst(): boolean {
    return this.selectedSectionIndex === 0;
  }

  isLast(): boolean {
    return (
      this.selectedSectionIndex + 1 === this.exportTemplate.sections?.length
    );
  }

  goBack(): void {
    const prevSectionIndex = this.selectedSectionIndex - 1;
    if (prevSectionIndex >= 0) {
      this.selectSection(this.exportTemplate.sections![prevSectionIndex]!);
    }
  }

  goNext(): void {
    const nextSectionIndex = this.selectedSectionIndex + 1;
    if (nextSectionIndex < this.exportTemplate.sections!.length) {
      this.selectSection(this.exportTemplate.sections![nextSectionIndex]!);
    }
  }

  createReport(): void {
    this.exportTemplate.resultsDeliveryId = this.resultsDeliveryId;
    this.reportCreationService.export(
      this.resultsDeliveryId,
      this.exportTemplate,
    );
  }

  get selectedSectionIndex(): number {
    return this.exportTemplate.sections!.findIndex(
      (s) => s.name === this.selectedSection!.name,
    );
  }

  addSection(sections: Array<Section>): void {
    const section = _.cloneDeep(EXPORT_TEMPLATE_NEW_ADDED_SECTION_JSON);
    if (sections?.length) {
      this.exportTemplate.sections?.splice(sections.length - 1, 0, section);
    }
    this.selectedSection = section;
    this.customizeMode = true;
  }

  onChangeSelectedSection(sectionSelectedValue: Section): void {
    this.selectedSection = sectionSelectedValue;
  }

  onChangeSelectedSubSection(sectionSubSelectedValue: number): void {
    this.selectedSubSectionIndex = sectionSubSelectedValue;
  }

  onChangeCustomizeMode(customizeMode: boolean): void {
    this.customizeMode = customizeMode;
  }

  reorder(event: CdkDragDrop<Section[]>, data: any[]): void {
    moveItemInArray(data, event.previousIndex, event.currentIndex);
  }

  toggleChange(toggleChange: MatSlideToggleChange): void {
    if (!toggleChange.checked) {
      this.saveReportTemplateNDisableCustomize();
    }
  }

  openConfirmationPopup(
    section: Section,
    subSection: SubSection,
    subSectionIndex: number,
  ): void {
    this.dialog
      .open(ExportConfirmationDialogComponent, {
        autoFocus: false,

        data: {
          header: 'Remove report section',
          title: 'Are you sure you want to remove this subsection?',
          confirmButtonLabel: 'Remove section',
          cancelButtonLabel: 'Cancel',
        },
      })
      .afterClosed()
      .pipe(
        first(),
        filter((result) => result === 'CONTINUE'),
      )
      .subscribe(() =>
        this.removeSubSection(section, subSection, subSectionIndex),
      );
  }

  removeSubSection(
    section: Section,
    subSection: SubSection,
    subSectionIndex: number,
  ): void {
    section.subSections!.splice(subSectionIndex, 1);
    if (this.selectedSubSection === subSection) {
      this.selectedSubSection = section.subSections![0];
    }
  }

  onMouseScroll(event: WheelEvent): void {
    if (event.isTrusted) {
      const sections = document.querySelectorAll('[data-section-id]');
      sections.forEach((section) => this.observer.observe(section));
    }
  }

  setSelectedSection(subSectionName: string): void {
    for (const section of this.exportTemplate.sections!) {
      const subSection = section.subSections!.find(
        (sub) => sub.name === subSectionName,
      );
      if (subSection) {
        this.selectedSection = section;
        this.selectedSubSection = subSection;
        this.selectedSubSectionIndex = section.subSections!.indexOf(subSection);
      }
    }
  }

  ngOnDestroy(): void {
    this.saveReportTemplateSubscription.unsubscribe();
  }
}
