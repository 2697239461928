import { ILoadingCellRendererAngularComp } from '@ag-grid-community/angular';
import { ILoadingCellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-material-loading-cell-renderer',
  templateUrl: './material-loading-cell-renderer.component.html',
  styleUrls: ['./material-loading-cell-renderer.component.scss'],
})
export class MaterialLoadingCellRendererComponent
  implements ILoadingCellRendererAngularComp {
  agInit(_: ILoadingCellRendererParams): void {
    // no op
  }
}
