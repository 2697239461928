<ng-container class="custom-header">
  <mat-checkbox
    color="primary"
    *ngIf="showCheckbox()"
    [ngModel]="isChecked"
    [indeterminate]="isIndeterminate"
    (change)="toggleSelect($event)"
    [checked]="isChecked"
  >
  </mat-checkbox>
</ng-container>
