import { Component, Input } from '@angular/core';
import { FilingDate } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-filing-dates',
  templateUrl: './filing-dates.component.html',
  styleUrls: ['./filing-dates.component.scss'],
})
export class FilingDatesComponent {
  @Input() filingDates?: FilingDate[];
}
