import { Component, Input, OnChanges, OnDestroy } from '@angular/core';

/* istanbul ignore next */
@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
})
export class ProgressSpinnerComponent implements OnChanges, OnDestroy {
  rotation = 0;
  timer: any;

  @Input()
  loading = false;

  ngOnChanges(): void {
    if (this.loading) {
      this.startRotation();
    } else {
      this.rotation = 0;
      clearInterval(this.timer);
    }
  }

  startRotation(): void {
    this.timer = setInterval(() => {
      this.rotation = (this.rotation + 1) % 360;
    }, 1000 / 60);
  }

  ngOnDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}
