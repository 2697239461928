import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-flag-definition',
  templateUrl: './flag-definition.component.html',
  styleUrls: ['./flag-definition.component.scss'],
})
export class FlagDefinitionComponent {
  @Input() flag: any;
  @Input() flagColors!: string[];

  @Output() deletedFlag = new EventEmitter<string>();
  @Output() flagColorChanged = new EventEmitter<any>();

  constructor() {}

  deleteFlag(id: any): void {
    this.deletedFlag.emit(id);
  }

  flagColorChange(color: any): void {
    const flagColor = { id: this.flag.id, new: color };
    this.flagColorChanged.emit(flagColor);
  }
}
