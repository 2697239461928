import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';

import { Applicant, OptimizedOwner } from '@compumark/bla-backend-client';
import {
  OwnerDataRequest,
  OwnerDataService,
  PortfolioSize,
} from '@compumark/brand-context-components-backend-client';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { OwnerFilterService } from '../services/owner-filter.service';
import { WIDGETS } from 'src/app/widgets/widget/widget.component';

@Component({
  selector: 'app-portfolio-size-widget',
  templateUrl: './portfolio-size-widget.component.html',
  styleUrls: ['./portfolio-size-widget.component.scss'],
})
export class PortfolioSizeWidgetComponent implements OnDestroy {
  @Output() removeEvent = new EventEmitter<string>();
  ownerData$: Subject<PortfolioSize> = new BehaviorSubject<PortfolioSize>({});
  showLoadingIndicator$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  selectedOwners: Observable<OptimizedOwner[] | undefined> | undefined;

  constructor(
    private ownerdataservice: OwnerDataService,
    ownerFilterService: OwnerFilterService,
  ) {
    this.selectedOwners = ownerFilterService.owners();
    this.selectedOwners.subscribe((data) => {
      this.filter(data!);
    });
  }

  ngOnDestroy(): void {
    this.selectedOwners = undefined;
  }

  filter(owners: OptimizedOwner[]): void {
    if (this.selectedOwners) {
      this.showLoadingIndicator$.next(true);
      const applicants: Applicant[] = owners?.map(
        (owner) =>
          ({
            optimizedApplicant: {
              normName: owner.optimizedName,
              countryCode: owner.countryCode,
            },
          } as Applicant),
      );

      this.ownerdataservice
        .calculateOwnerPortfolioSize({
          brandContext: {
            parentId: '',
            trademarkId: '',
            trademarkName: '',
            register: '',
            applicants,
          },
          knownCompetitors: [],
        } as OwnerDataRequest)
        .subscribe((portfolioSize) => {
          this.ownerData$.next(portfolioSize);
          this.showLoadingIndicator$.next(false);
        });
    }
  }

  remove(): void {
    this.removeEvent.emit(WIDGETS.OWNER_PORTFOLIO_SIZE);
  }
}
