import { Injectable } from '@angular/core';
import { GridApi, MenuItemDef, RowNode } from '@ag-grid-community/core';
import { ActionRendererService } from 'src/app/threats-tables/services/action-renderer.service';
import { FlagService } from 'src/app/threats-tables/services/flag.service';
import { ActivatedRoute } from '@angular/router';
import {
  ExportService,
  FullTextService,
  SelectForExportRequest,
} from '@compumark/bla-backend-client';
import { FullTextOpeningService } from 'src/app/full-text/services/full-text-opening-service';
import { NotificationService } from '@compumark/brand-context-components';

@Injectable({
  providedIn: 'root',
})
export class ContextMenuService {
  constructor(
    private actionRendererService: ActionRendererService,
    private flagService: FlagService,
    private fullTextService: FullTextService,
    private exportService: ExportService,
    private fullTextOpeningService: FullTextOpeningService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
  ) {}

  public openFulltext(
    data: any,
    resultsDeliveryId: string,
    gridApi: GridApi,
  ): void {
    if (data.id.clusterRoot) {
      return;
    }

    this.fullTextOpeningService.showFulltext(data.id, resultsDeliveryId);
    this.fullTextService
      .setThreatReviewedAssociatedWithResultsDelivery(
        resultsDeliveryId,
        data.id,
      )
      .subscribe(() => {
        const rowNode = gridApi?.getRowNode(data.id);
        rowNode?.setData({ ...data, reviewed: true });
      });
  }

  getContextMenuItems(
    node: RowNode<any>,
    gridApi: any,
    flagsEnabled: boolean,
    selectionType: string,
    resultsDeliveryId: string,
    contentType: string,
  ): Array<MenuItemDef> {
    const items = [];
    if (!!node) {
      items.push({
        name: 'Open Full Text',
        action: () => {
          if (contentType === 'DOMAIN_NAMES') {
            this.fullTextOpeningService.showFulltextForDomainName(
              node.data,
              resultsDeliveryId,
            );
          } else {
            this.openFulltext(node.data, resultsDeliveryId, gridApi);
          }
        },
      });
      items.push({
        name: 'Add a comment',
        action: () => {
          this.actionRendererService.addComment(node, gridApi);
        },
      });
      if (flagsEnabled) {
        items.push({
          name: 'Add flag',
          subMenu: this.route.snapshot.data.resultsDelivery.flagDefinitions.flags.map(
            (flag: any) => {
              return {
                name: `<mat-icon class="mat-icon material-icons circle" style="color: ${flag.color};">circle</mat-icon> ${flag.label}`,
                action: () => {
                  this.flagService.toggleActive(
                    flag.id,
                    this.route.snapshot.data.resultsDelivery,
                    node,
                    gridApi,
                  );
                },
              };
            },
          ),
        });
      }
      if (node?.data) {
        items.push({
          name: node.data.selectedForExport
            ? 'Unselect for export'
            : 'Select for export',
          action: () => {
            const previousState = !node.data.selectedForExport;
            const actionMessage = previousState
              ? 'added to export'
              : 'removed from export';
            this.selectForExport(node, selectionType, resultsDeliveryId);
            this.notificationService
              .showSnackbar(
                `The citation has been ${actionMessage}`,
                'undo',
                3000,
              )
              .onAction()
              .subscribe(() => {
                node.data.selectedForExport = previousState;
                this.selectForExport(node, selectionType, resultsDeliveryId);
              });
          },
        });
      }
    }
    return items;
  }

  selectForExport(
    row: RowNode,
    selectionType: string,
    resultsDeliveryId: string,
  ): void {
    this.exportService
      .selectForExport(
        {
          ids: [row.data.id],
          parentId: resultsDeliveryId,
          selectionType,
          selectedForExport: !row.data.selectedForExport,
        } as SelectForExportRequest,
        resultsDeliveryId,
      )
      .subscribe({
        complete: () => {
          const updatedData = {
            ...row.data,
            selectedForExport: !row.data.selectedForExport,
          };
          row.setData(updatedData);
        },
      });
  }
}
