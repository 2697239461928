import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface CoexistencePerMarketDialogData {
  resultsDeliveryId: string;
  classes: string[];
  keywords: string[];
}

@Component({
  selector: 'app-coexistence-per-market-dialog',
  templateUrl: './coexistence-per-market-dialog.component.html',
  styleUrls: ['./coexistence-per-market-dialog.component.scss'],
})
export class CoexistencePerMarketDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CoexistencePerMarketDialogData,
  ) {}
}
