import { Injectable } from '@angular/core';

import _ from 'lodash';

import { FilterChip } from '@compumark/brand-context-components';
import { RiskPerMarketFilter } from '@compumark/bla-backend-client';
import {
  RiskPerMarketItem,
  RiskType,
} from '../risk-per-market/risk-per-market.component';
import { AllThreatsWithBulkActionsComponent } from 'src/app/threats-tables/bulk-actions/components/all-threats/all-threats.component';
import { FormatClassesPipe } from 'src/app/util/pipes/format-classes.pipe';

@Injectable({
  providedIn: 'root',
})
export class RiskPerMarketChipConverterService {
  constructor(private formatClasses: FormatClassesPipe) {}

  convertToChips(riskPerMarketItems: RiskPerMarketItem[]): FilterChip[] {
    const filterChips = [];
    for (const item of riskPerMarketItems) {
      const fc = {
        field:
          AllThreatsWithBulkActionsComponent.riskPerMarketHeatMapItemChipName,
        label: 'Market',
        value: item.jurisdiction,
        graph:
          AllThreatsWithBulkActionsComponent.riskPerMarketHeatMapItemChipName,
        additionalFilter: {
          field: item.riskType.toString() + '',
          label: this.getRiskName(item.riskType),
          value: item.riskLevel,
          additionalFilter: {
            field: 'classes',
            label: 'Classes',
            value: item.classes,
            valueLabel: this.formatClasses.transform(
              item.classes.map((c) => parseInt(c, 10)),
              ',',
            ),
          },
        },
      } as FilterChip;
      filterChips.push(fc);
    }
    return filterChips;
  }

  convertFromChips(
    riskPerMarketChips: FilterChip[],
  ): RiskPerMarketFilter[] | undefined {
    const riskPerItems: RiskPerMarketFilter[] = [];
    for (const chip of riskPerMarketChips) {
      riskPerItems.push({
        jurisdiction: chip.value,
        classes: chip.additionalFilter!.additionalFilter!.value,
        riskLevel: chip.additionalFilter!.value,
        riskType: chip.additionalFilter!.field as RiskType,
      });
    }
    return riskPerItems.length > 0 ? riskPerItems : undefined;
  }

  getRiskName(riskType: RiskType): string {
    let level = 'Verbal Similarity';
    switch (riskType) {
      case 'nameRisk':
        level = 'Verbal Similarity';
        break;
      case 'goodsAndServicesRisk':
        level = 'Goods Similarity';
        break;
      case 'verbalCaseLawRisk':
        level = 'Verbal Case Law Score';
        break;
    }

    return level;
  }
}
