<div class="body">
  <div class="icon"><mat-icon>drag_handle</mat-icon></div>
  <div
    class="content"
    (mousedown)="$event.stopPropagation()"
    (mousemove)="$event.stopPropagation()"
  >
    <ng-content></ng-content>
  </div>
  <div
    class="icon"
    (mousedown)="$event.stopPropagation()"
    (mousemove)="$event.stopPropagation()"
  >
    <mat-icon>delete</mat-icon>
  </div>
</div>
