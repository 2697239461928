<div class="flag-filter">
  <section class="section">
    <span class="all-flag">
      <mat-checkbox
        color="primary"
        (change)="onSelectAll($event.checked)"
        [indeterminate]="checkSomeSelected()"
        [checked]="checkAllselected()"
      >
        Select all flags
      </mat-checkbox> </span
    ><br />
    <span class="flag-list-section">
      <ng-container *ngFor="let flagDefinition of flagDefinitions">
        <mat-checkbox
          color="primary"
          [checked]="selectedFlags.has(flagDefinition)"
          (change)="onFlagChange(flagDefinition, $event.checked)"
        >
          <span
            class="round-button"
            [matTooltip]="flagDefinition.label"
            [ngStyle]="{
              'background-color': flagDefinition.color
            }"
          ></span>
          {{ flagDefinition.label }}</mat-checkbox
        ><br />
      </ng-container>

      <mat-checkbox
        color="primary"
        [checked]="selectedFlags.has(noFlag)"
        (change)="onFlagChange(noFlag, $event.checked)"
        class="no-flag-section"
      >
        <span
          class="round-button-no-flag"
          [matTooltip]="noFlag.label"
          [ngStyle]="{
            'background-color': noFlag.color
          }"
        ></span>
        {{ noFlag.label }}</mat-checkbox
      ><br />
    </span>
  </section>

  <div class="ag-filter-apply-panel">
    <button
      type="button"
      class="ag-standard-button ag-filter-clear-panel-button"
      (click)="onClear()"
    >
      Clear</button
    ><button
      type="button"
      class="ag-standard-button ag-filter-apply-panel-button"
      (click)="onApply()"
    >
      Apply
    </button>
  </div>
</div>
