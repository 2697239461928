<header>
  <app-clarivate-header></app-clarivate-header>

  <div *ngIf="loading$ | async" class="cdx-progress-bar-wrapper">
    <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
  </div>
  <app-pricing-account-error></app-pricing-account-error>

  <app-application-header></app-application-header>
</header>
<lib-glasspane></lib-glasspane>

<router-outlet></router-outlet>
