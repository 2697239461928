import { DomainNameDatasourceService } from './../../../domain-names/services/domain-name-datasource.service';
import { Injectable } from '@angular/core';
import { PharmaThreatsDatasource } from '../pharma-threats.datasource';
import { CommonLawThreatsDatasource } from '../common-law-threats.datasource';
import { ThreatDatasource } from '../interfaces/threat-datasource.interface';
import { CompanyNameDatasourceService } from 'src/app/company-names/services/company-name-datasource.service';
import { ImageResultsDatasourceService } from 'src/app/image-results/services/image-results-datasource.service';

@Injectable({
  providedIn: 'root',
})
export class ThreatDatasourceFactory {
  constructor(
    private pharmaThreatsDatasource: PharmaThreatsDatasource,
    private commonLawThreatsDatasource: CommonLawThreatsDatasource,
    private domainNameDatasourceService: DomainNameDatasourceService,
    private companyNameDatasourceService: CompanyNameDatasourceService,
    private imageResultsDatasourceService: ImageResultsDatasourceService,
  ) {}

  public create(contentType: string): ThreatDatasource {
    switch (contentType) {
      case 'COMMON_LAW':
        return this.commonLawThreatsDatasource;

      case 'PHARMA':
        return this.pharmaThreatsDatasource;

      case 'DOMAIN_NAMES':
        return this.domainNameDatasourceService;

      case 'COMPANY_NAMES':
        return this.companyNameDatasourceService;

      case 'IMAGE':
        return this.imageResultsDatasourceService;

      default:
        throw new Error(`Unsupported contentType: ${contentType}`);
    }
  }
}
