<div class="input">
  <div class="label">
    <mat-checkbox
      *ngIf="!customize"
      color="primary"
      [(ngModel)]="data.searchStrategy!.value"
      [disabled]="disableSubsection"
      >{{ data.searchStrategy!.label }}</mat-checkbox
    >
    <mat-checkbox
      *ngIf="customize"
      color="primary"
      [(ngModel)]="data.searchStrategy!.value"
      [disabled]="disableSubsection"
    ></mat-checkbox>
    <mat-form-field
      *ngIf="customize"
      appearance="outline"
      class="cdx-input-default"
    >
      <input
        matInput
        #input
        [(ngModel)]="data.searchStrategy!.label"
        [disabled]="disableSubsection"
      />
    </mat-form-field>
  </div>

  <div class="search-strategy">
    <div class="content">
      <mat-icon [ngClass]="{ 'report-editor-disabled-div': disableSubsection }"
        >done</mat-icon
      ><span *ngIf="!customize">{{ data.candidateName!.label }}</span>
      <mat-form-field
        *ngIf="customize"
        appearance="outline"
        class="cdx-input-default"
      >
        <input
          matInput
          #input
          [(ngModel)]="data.candidateName!.label"
          [disabled]="disableSubsection"
        />
      </mat-form-field>
    </div>
    <div class="content">
      <mat-icon [ngClass]="{ 'report-editor-disabled-div': disableSubsection }"
        >done</mat-icon
      ><span *ngIf="!customize">{{ data.markets!.label }}</span>
      <mat-form-field
        *ngIf="customize"
        appearance="outline"
        class="cdx-input-default"
      >
        <input
          matInput
          #input
          [(ngModel)]="data.markets!.label"
          [disabled]="disableSubsection"
        />
      </mat-form-field>
    </div>
    <div class="content">
      <mat-icon [ngClass]="{ 'report-editor-disabled-div': disableSubsection }"
        >done</mat-icon
      ><span *ngIf="!customize">{{ data.goodsAndServices!.label }}</span>
      <mat-form-field
        *ngIf="customize"
        appearance="outline"
        class="cdx-input-default"
      >
        <input
          matInput
          #input
          [(ngModel)]="data.goodsAndServices!.label"
          [disabled]="disableSubsection"
        />
      </mat-form-field>
    </div>
    <div class="content">
      <mat-icon [ngClass]="{ 'report-editor-disabled-div': disableSubsection }"
        >done</mat-icon
      ><span *ngIf="!customize">{{ data.classes!.label }}</span>
      <mat-form-field
        *ngIf="customize"
        appearance="outline"
        class="cdx-input-default"
      >
        <input
          matInput
          #input
          [(ngModel)]="data.classes!.label"
          [disabled]="disableSubsection"
        />
      </mat-form-field>
    </div>
    <div class="content">
      <mat-icon [ngClass]="{ 'report-editor-disabled-div': disableSubsection }"
        >done</mat-icon
      ><span *ngIf="!customize">{{ data.searchDate!.label }}</span>
      <mat-form-field
        *ngIf="customize"
        appearance="outline"
        class="cdx-input-default"
      >
        <input
          matInput
          #input
          [(ngModel)]="data.searchDate!.label"
          [disabled]="disableSubsection"
        />
      </mat-form-field>
    </div>
  </div>
</div>
