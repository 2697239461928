<div class="pricing">
  <div class="left">
    <div class="packages">
      <app-packages-list [packages]="packages"></app-packages-list>
    </div>
  </div>
  <div class="right">
    <ng-content></ng-content>
  </div>
</div>
<app-footer></app-footer>
