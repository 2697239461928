import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchStrategyButtonComponent } from './components/search-strategy-button/search-strategy-button.component';
import { MatButtonModule } from '@angular/material/button';
import { SearchStrategyDialogComponent } from './components/search-strategy-dialog/search-strategy-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { UtilModule } from '../util/util.module';
import { FormatMarketsPipe } from './pipes/format-markets.pipe';
import { ConcatPipe } from './pipes/concat.pipe';
import { MatIconModule } from '@angular/material/icon';
import { BlaSearchStrategyComponent } from './components/bla-search-strategy/bla-search-strategy.component';
import { OwnerAnalyzerSearchStrategyComponent } from './components/owner-analyzer-search-strategy/owner-analyzer-search-strategy.component';
import { MatTreeModule } from '@angular/material/tree';

@NgModule({
  declarations: [
    SearchStrategyButtonComponent,
    SearchStrategyDialogComponent,
    FormatMarketsPipe,
    ConcatPipe,
    BlaSearchStrategyComponent,
    OwnerAnalyzerSearchStrategyComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatTabsModule,
    MatIconModule,
    MatTreeModule,
    UtilModule,
  ],
  exports: [SearchStrategyButtonComponent],
})
export class SearchStrategyModule {}
