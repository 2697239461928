import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { SaveFilterDialogComponent } from 'src/app/util/components/save-filter-dialog/save-filter-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserPreference } from '@compumark/bla-backend-client';
import { Observable, Subject, takeUntil } from 'rxjs';
import { cloneDeep } from 'lodash';
import { UserService } from 'src/app/security/services/user.service';
import { UserPreferencesService } from '@compumark/user-preferences-backend-specifications';
import { SnackbarService } from 'src/app/util/components/snackbar/snackbar.service';
import { CommonPreferencesService } from 'src/app/util/services/common-preference.service';
import { EditFilterDialogComponent } from 'src/app/util/components/edit-filter-dialog/edit-filter-dialog.component';
import { FilterChip } from '@compumark/brand-context-components';

@Component({
  selector: 'app-filter-templates',
  templateUrl: './filter-templates.component.html',
  styleUrls: ['./filter-templates.component.scss'],
})
export class FilterTemplatesComponent implements OnInit, OnDestroy {
  @Input() isFilteringActive = false;
  @Input() removedChipsTrigger!: Observable<boolean>;
  currentChipsTrigger!: boolean;
  selectedValue!: any;
  savedFilterTemplatesNames!: any;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  @Output() saveFilterClicked = new EventEmitter<any>();
  @Output() updateFilterClicked = new EventEmitter<
    [string, string, UserPreference]
  >();
  selectedFilter: any;
  placeHolderMessage = 'Filter templates';
  unSelectedSaveFilterDeleted = false;
  @Output() applyFilterClicked = new EventEmitter<[any, boolean]>();
  @Input() gridApi: any;
  @Input() filterChips: FilterChip[] = [];
  public userPreference: any = undefined;
  currentPreference: any = {};
  isLoadAfterUpdate = false;

  ngOnInit(): void {
    this.commonPreferencesService
      .getClearFilterChipsChange()
      ?.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value && this.savedFilterTemplatesNames) {
          this.selectedValue = this.placeHolderMessage;
          this.sortTheTemplateNames(this.savedFilterTemplatesNames);
        }
      });

    this.onLoadTemplateData();
    this.selectedValue = this.placeHolderMessage;
    this.removedChipsTrigger?.subscribe((value: boolean) => {
      this.currentChipsTrigger = value;
      this.selectedValue = 'Filter templates';
    });
  }

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private snackBarService: SnackbarService,
    private userPreferenceService: UserPreferencesService,
    private commonPreferencesService: CommonPreferencesService,
  ) {}

  onLoadTemplateData(isLoadAfterSave: boolean = false): void {
    this.userService?.refreshCurrentUser();
    this.userService?.getCurrentUserObservable()?.subscribe((user) => {
      this.userPreference = cloneDeep(user.preference!);

      this.sortTheTemplateNames(this.userPreference!.filterTemplates);
      this.savedFilterTemplatesNames = this.userPreference!.filterTemplates?.map(
        (ft: { templateName: any }) => ft.templateName,
      );
      this.savedFilterTemplatesNames?.unshift('None');

      this.getSelectedValue(this.selectedFilter);

      if (isLoadAfterSave) {
        const savedTemplate = this.savedFilterTemplatesNames?.find(
          (f: any) => f === this.selectedValue,
        );
        this.changeValue(savedTemplate, isLoadAfterSave);
        this.commonPreferencesService.setUserPreference(false);
      }
    });
  }

  changeValue(value: any, isLoadAfterSave: boolean = false): void {
    this.isLoadAfterUpdate = false;
    this.selectedValue = value;
    this.getSelectedValue(value);
    let selectedFilter = this.userPreference?.filterTemplates?.find(
      (ft: { templateName: any }) => ft.templateName === value,
    );

    if (selectedFilter && 'flags' in selectedFilter?.filterModel) {
      selectedFilter = this.setSelectedFlagsInFlagsFilter(selectedFilter);
    }
    this.applyFilterClicked.emit([selectedFilter, isLoadAfterSave]);
    this.sortTheTemplateNames(this.savedFilterTemplatesNames);
  }

  removeSavedFilter(templateName: any): any {
    const dropdownItems: any = this.savedFilterTemplatesNames;
    dropdownItems?.forEach((element: any, index: number) => {
      if (element === templateName) {
        dropdownItems.splice(index, 1);
        this.selectedValue = this.placeHolderMessage;
      }
    });

    let currentPreference: any = {};

    if (this.userPreference.preference) {
      currentPreference = this.userPreference.preference;
    } else {
      currentPreference = this.userPreference;
    }
    let deletedFilterTemplate: any = {};
    let deletedFilterTemplatePosition = 0;

    currentPreference?.filterTemplates?.forEach(
      (filterTemplate: any, index: number) => {
        if (filterTemplate?.templateName === templateName) {
          deletedFilterTemplate = filterTemplate;
          deletedFilterTemplatePosition = index;
          currentPreference?.filterTemplates?.splice(index, 1);
        }
      },
    );

    this.userPreference = {
      filterTemplates: [...currentPreference?.filterTemplates],
      threatTableColumns: [...currentPreference?.threatTableColumns],
      summaryWidgets: [...currentPreference?.summaryWidgets],
    };

    this.userPreferenceService
      .saveUserPreferences(this.userPreference)
      .subscribe(
        (pref) => {
          this.userPreference = pref;
        },
        () => {
          this.snackBarService.openSnackBar(
            `Your filter template "${templateName}" has been deleted.`,
            'Undo',
          );
        },
        () => {
          const snackBarRef = this.snackBarService.openSnackBar(
            `Your filter template "${templateName}" has been deleted.`,
            'Undo',
          );
          snackBarRef.onAction().subscribe(() => {
            this.onUndoRemoveAction(
              deletedFilterTemplate,
              deletedFilterTemplatePosition,
            );
          });
        },
      );
    this.isFilteringActive = false;
  }

  onUndoRemoveAction(
    deletedTemplate: any,
    deletedFilterTemplatePosition: number,
  ): void {
    this.savedFilterTemplatesNames.splice(
      deletedFilterTemplatePosition,
      0,
      deletedTemplate?.templateName,
    );

    const currentPreference = this.userPreference?.preference;
    this.userPreference = {
      filterTemplates: [...currentPreference?.filterTemplates, deletedTemplate],
      threatTableColumns: [...currentPreference?.threatTableColumns],
      summaryWidgets: [...currentPreference?.summaryWidgets],
    };

    this.userPreferenceService
      .saveUserPreferences(this.userPreference)
      .subscribe((pref) => {
        this.userPreference = pref;
      });

    this.sortTheTemplateNames(this.savedFilterTemplatesNames);
    this.isFilteringActive = false;
  }

  getSelectedValue(value: any): void {
    if (!!value) {
      this.selectedFilter = value;
      const selectedValue = this.savedFilterTemplatesNames;
      selectedValue?.forEach((element: any, index: number) => {
        if (element === value) {
          selectedValue.splice(index, 1);
        }
      });

      if (!this.isLoadAfterUpdate) {
        selectedValue?.unshift(value);
      }
      this.savedFilterTemplatesNames = selectedValue;
      this.sortTheTemplateNames(selectedValue);
      this.isFilteringActive = false;
    }
  }

  sortTheTemplateNames(templates: any): void {
    templates?.sort(
      (current: { templateName: string }, next: { templateName: any }) =>
        current.templateName?.localeCompare(next.templateName),
    );
    const indexOfNoneElement = templates?.indexOf('None');
    if (indexOfNoneElement !== -1) {
      templates?.splice(indexOfNoneElement, 1);
      templates?.unshift('None');
    }
  }

  convertFilterModelToFilterChip(filterModel: any): void {
    this.filterChips = filterModel?.filterChips;
  }

  openSaveFilterDialog(): void {
    if (this.isFilteringActive) {
      this.dialog
        .open(SaveFilterDialogComponent, {
          data: {
            gridApi: this.gridApi,
            filterChips: this.filterChips,
            userPreference: this.userPreference,
          },
          autoFocus: false,
          width: '27.75rem',
          minWidth: '22rem',
          panelClass: 'filter-templates-dialog-container',
          position: { top: '4rem' },
        })
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe((filterTemplateDataWithUserPreferences) => {
          if (filterTemplateDataWithUserPreferences?.data?.templateName!) {
            this.saveFilterClicked.emit(filterTemplateDataWithUserPreferences);
            this.commonPreferencesService
              .getUserPreference()
              .subscribe((data) => {
                if (data) {
                  this.selectedValue =
                    filterTemplateDataWithUserPreferences?.data?.templateName;
                  this.onLoadTemplateData();
                  this.sortTheTemplateNames(this.savedFilterTemplatesNames);
                  this.isFilteringActive = false;
                }
              });
          }
        });
    }
  }

  openEditFilterDialog(templateName: string): void {
    let selectedFilter = this.userPreference?.filterTemplates?.find(
      (ft: { templateName: any }) => ft.templateName === templateName,
    );
    this.convertFilterModelToFilterChip(selectedFilter);

    if (selectedFilter && 'flags' in selectedFilter?.filterModel) {
      selectedFilter = this.setSelectedFlagsInFlagsFilter(selectedFilter);
    }

    this.dialog
      .open(EditFilterDialogComponent, {
        data: {
          filterTemplateForEditing: selectedFilter,
          gridApi: this.gridApi,
          filterChips: this.filterChips,
        },
        autoFocus: false,
        width: '27.75rem',
        minWidth: '22rem',
        panelClass: 'filter-templates-dialog-container',
        position: { top: '4rem' },
      })
      ?.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((filterData) => {
        if (filterData?.data?.templateName!) {
          this.selectedValue = filterData?.data?.templateName;
          this.updateFilterClicked.emit([
            selectedFilter,
            filterData,
            this.userPreference,
          ]);
          this.commonPreferencesService
            .getUserPreference()
            .subscribe((data) => {
              if (data) {
                this.selectedValue = filterData?.data?.templateName;
                this.isLoadAfterUpdate = true;
                this.onLoadTemplateData();
                this.sortTheTemplateNames(this.savedFilterTemplatesNames);
                this.isFilteringActive = false;
              }
            });
        }
      });
  }
  setSelectedFlagsInFlagsFilter(selectedFilter: any): any {
    selectedFilter.filterModel.flags.selectedFlags = new Set(
      selectedFilter.filterModel.flags.filter.map((flagNo: any) => ({
        id: flagNo,
        label: flagNo,
      })),
    );
    return selectedFilter;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
