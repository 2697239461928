<app-all-threats-tab-nav-bar
  *ngIf="showNavBar() && !fullscreen.getValue()"
></app-all-threats-tab-nav-bar>

<lib-glasspane></lib-glasspane>
<div class="actions" *ngIf="!ownedBrandsOnly">
  <div class="filters">
    <app-custom-filters-panel
      [resultsDeliveryId]="resultsDeliveryId"
      [gridApi]="gridApi!"
      [flagsEnabled]="flagsEnabled"
      [ownedBrandsOnly]="ownedBrandsOnly"
      [selectedThreads]="selectedThreats"
      (actionSelected)="bulkAction($event)"
    >
    </app-custom-filters-panel>
  </div>
  <div class="actionbar-right-container">
    <app-filter-templates
      *appIfFeatureEnabled="'allThreatsFilterTemplate'"
      [isFilteringActive]="isFilteringActive"
      [gridApi]="gridApi"
      [filterChips]="filterChips"
      (saveFilterClicked)="onSaveFilter($event)"
      (updateFilterClicked)="onUpdateFilter($event)"
      (applyFilterClicked)="onRestoreFilter($event)"
      [removedChipsTrigger]="removedChipsTrigger$"
      class="filter-templates"
    ></app-filter-templates>
    <div class="separator-line"></div>
    <button mat-icon-button (click)="toggleFullscreen()" class="btn">
      <span
        class="material-symbols-outlined"
        [matTooltip]="fullscreenTooltip"
        >{{ screenIcon }}</span
      >
    </button>
    <app-table-settings
      *appIfFeatureEnabled="'allThreatsTableSettings'"
      (tableSetting)="applyTableSetting($event)"
      (triggerRefreshTable)="refreshTable()"
      [contentType]="contentType"
      [userPreference]="userPreference"
    ></app-table-settings>
    <app-reset-top-threats-link
      *appIfFeatureEnabled="'threatOlderReset'"
      (resetAllConfirmedEvent)="showLoadingOverlay()"
      (resetAllCompletedEvent)="refreshTable()"
    ></app-reset-top-threats-link>
  </div>
</div>

<lib-filter-chips
  (allFiltersRemoved)="removeAllFilters()"
  (filterRemoved)="removeFilter($event)"
  [filterChips]="(filterChips$ | async)!"
></lib-filter-chips>

<div
  class="splitviewtable"
  [ngClass]="{ 'split-view-enabled': splitViewEnabled }"
>
  <div class="first-panel" [style.width.%]="firstPanelWidth">
    <ag-grid-angular
      [sideBar]="sideBar"
      [gridOptions]="gridOptions"
      class="ag-theme-cdx content-view"
      (rowClicked)="onRowClicked($event)"
      (rowSelected)="onRowSelected($event)"
    ></ag-grid-angular>
  </div>

  <div
    *ngIf="splitViewEnabled"
    class="divider"
    (mousedown)="onMouseDown($event)"
  >
    <mat-icon>drag_handle</mat-icon>
  </div>

  <div
    class="second-panel"
    [style.width.%]="splitViewEnabled ? 100 - firstPanelWidth : 0"
  >
    <app-full-text
      *ngIf="splitViewEnabled"
      class="content-view"
      [splitViewData]="splitViewData"
      (navigate)="nextselection($event)"
      [contentType]="contentType"
    ></app-full-text>
  </div>
</div>

<app-footer *ngIf="!fullscreen.getValue()"></app-footer>

<router-outlet></router-outlet>
