import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ResultsDelivery,
  SummaryService,
  Threats,
} from '@compumark/bla-backend-client';
import { Observable } from 'rxjs';
import { WIDGETS } from 'src/app/widgets/widget/widget.component';

@Component({
  selector: 'app-top-threats-widget',
  templateUrl: './top-threats-widget.component.html',
  styleUrls: ['./top-threats-widget.component.scss'],
})
export class TopThreatsWidgetComponent {
  @ViewChild('widgetContent') widgetContent?: ElementRef;
  @Output() contentSizeChangedEvent = new EventEmitter<number>();
  @Output() removeEvent = new EventEmitter<string>();
  resultsDelivery: ResultsDelivery;
  topThreats$: Observable<Threats>;

  constructor(route: ActivatedRoute, summaryService: SummaryService) {
    this.resultsDelivery = route.snapshot.data.resultsDelivery;
    this.topThreats$ = summaryService.getTopThreats(this.resultsDelivery.id);
  }

  topThreatsInitEvent(): void {
    const contentHeight = this.widgetContent?.nativeElement.offsetHeight;
    this.contentSizeChangedEvent.emit(contentHeight + 130);
  }

  remove(): void {
    this.removeEvent.emit(WIDGETS.TOP_THREATS_SUMMARY);
  }
}
