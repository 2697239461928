import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from '@ag-grid-community/core';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  OrderHistoryList,
  ResultsDeliveryService,
} from '@compumark/bla-backend-client';
import { GlasspaneService } from '@compumark/brand-context-components';
import { first } from 'rxjs/operators';

@Injectable()
export class HistoryDatasource {
  constructor(
    private resultsDeliveryService: ResultsDeliveryService,
    private glasspaneService: GlasspaneService,
    private datePipe: DatePipe,
  ) {}

  private parseDate(dateToParse: string): string | undefined {
    if (!!dateToParse) {
      return this.datePipe.transform(dateToParse, 'dd-MM-yyyy')!;
    }
    return undefined;
  }

  createFor(projetcId: string | undefined): IServerSideDatasource {
    const r = this.resultsDeliveryService;
    const o = this.glasspaneService;
    const self = this;
    return {
      getRows(params: IServerSideGetRowsParams): void {
        let dateFrom;
        let dateTo;
        if (!!params.request.filterModel?.date) {
          dateFrom = self.parseDate(params.request.filterModel?.date.dateFrom);
          dateTo = self.parseDate(params.request.filterModel?.date.dateTo);

          switch (params.request.filterModel?.date?.type) {
            case 'equals': {
              dateTo = dateFrom;
              break;
            }
            case 'lessThan': {
              dateTo = dateFrom;
              dateFrom = undefined;
              break;
            }
            case 'greaterThan': {
              dateTo = undefined;
              break;
            }
          }
        }

        r.getOrderHistory(
          params.request.startRow!,
          params.request.endRow!,
          dateFrom,
          dateTo,
          params.request.filterModel?.date?.type,
          params.request.filterModel?.candidate?.filter,
          projetcId,
          undefined,
          undefined,
        )
          .pipe(first())
          .subscribe((result: OrderHistoryList) => {
            params.success({
              rowData: result.orders,
              rowCount: result.totalCount,
            }),
              o.hideGlasspane();
          });
      },
    };
  }
}
