import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CaseLawDataPointsMap,
  ResultsDelivery,
} from '@compumark/bla-backend-client';
import { AbsoluteGroundsDialogOpeningService } from '../../services/absolute-grounds-dialog-opening.service';
import { CaselawDialogComponent } from '../case-law-dialog/case-law-dialog.component';
import { WIDGETS } from 'src/app/widgets/widget/widget.component';

@Component({
  selector: 'app-absolute-ground-caselaw-widget',
  templateUrl: './absolute-ground-caselaw-widget.component.html',
  styleUrls: ['./absolute-ground-caselaw-widget.component.scss'],
})
export class AbsoluteGroundCaselawWidgetComponent {
  resultsDelivery: ResultsDelivery;
  @Output() removeEvent = new EventEmitter<string>();

  constructor(
    route: ActivatedRoute,
    private openingService: AbsoluteGroundsDialogOpeningService,
  ) {
    this.resultsDelivery = route.snapshot.data.resultsDelivery;
  }

  getCaseLawBubbles(
    caseLawDataPoints: CaseLawDataPointsMap[],
  ): any[] | undefined {
    return caseLawDataPoints?.map((c) => c.chip);
  }

  getCaseLawResultsLength(
    caseLawDataPoints: CaseLawDataPointsMap[],
    chipName: string,
  ): any {
    return this.getCaseLawDataPoints(this.getCards(caseLawDataPoints, chipName))
      .length;
  }

  getCaseLawDataPoints(caseLawDataPoints: any[]): any[] {
    let allCaseLawDataPoints: any[] = [];
    allCaseLawDataPoints = caseLawDataPoints
      .flatMap((cl) => cl.pointOfLaws)
      .map((dp) => dp)
      .filter((dp) => !!dp);

    return allCaseLawDataPoints;
  }

  getCards(caseLawDataPoints: CaseLawDataPointsMap[], chipName: string): any[] {
    return caseLawDataPoints?.filter((c) => c.chip === chipName)[0]
      .caseLawDataPointList!;
  }

  openCaseLawDialog(
    resultsDeliveryId: string,
    caseLawDataPoints: CaseLawDataPointsMap[],
    chipName: string,
  ): void {
    const cards = this.getCaseLawDataPoints(
      this.getCards(caseLawDataPoints, chipName),
    );
    if (!!cards) {
      this.openingService.showDialog(
        {
          resultsDeliveryId,
          label: chipName,
          cards,
        } as any,
        CaselawDialogComponent,
      );
    }
  }

  getCaseLawTooltip(
    caseLawDataPoints: CaseLawDataPointsMap[],
    chipName: string,
  ): string {
    let tooltip = '';
    this.getCards(caseLawDataPoints, chipName).forEach((c) => {
      tooltip += this.createTooltip(c.searchTerm, c.pointOfLaws.length, 'case');
    });
    return tooltip;
  }

  createTooltip(searchTerm: any, results: any, endTerm: string): string {
    const suffix = results !== 0 ? (results > 1 ? endTerm + 's' : endTerm) : '';
    return `<strong> ${searchTerm} </strong>: ${results} ${suffix} <br> `;
  }

  remove(): void {
    this.removeEvent.emit(WIDGETS.ABSOLUTE_GROUNDS_CASE_LAW);
  }
}
