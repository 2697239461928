import { Component, Input } from '@angular/core';
import { SearchStrategy } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-input-search-strategy',
  templateUrl: './input-search-strategy.component.html',
  styleUrls: ['./input-search-strategy.component.scss'],
})
export class InputSearchStrategyComponent {
  @Input() data!: SearchStrategy;
  @Input() customize = false;
  @Input() disableSubsection = false;
}
