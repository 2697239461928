<div
  [ngClass]="{
    'report-editor-disabled-div': disableSubsection,
    'padding-on-customize': !!data.require && customize
  }"
>
  <mat-checkbox
    class="report-editor-checkbox checkbox-padding"
    color="primary"
    [checked]="allSelected()"
    [indeterminate]="!allSelected() && !noneSelected()"
    [disabled]="disableSubsection"
    (change)="toggle($event)"
    *ngIf="customize"
    >Select all</mat-checkbox
  >
  <div class="summary-graphic-select">
    <app-graphic-select
      [(value)]="data.totalHits!"
      label="All Citations - Insights"
      graphImageName="all-threats-insights"
      [disableSubsection]="disableSubsection"
    >
    </app-graphic-select>
    <app-graphic-select
      [(value)]="data.topThreats!"
      label="Top Citations"
      graphImageName="top-threats"
      [disableSubsection]="disableSubsection"
    >
    </app-graphic-select>
    <app-graphic-select
      [(value)]="data.riskPerMarket!"
      label="Risk per Market"
      graphImageName="risk-per-market"
      [disableSubsection]="disableSubsection"
    >
    </app-graphic-select>
    <app-graphic-select
      [(value)]="data.coexistencePerMarket!"
      label="Coexistence per Market"
      graphImageName="coexistence-per-market"
      [disableSubsection]="disableSubsection"
    >
    </app-graphic-select>
  </div>
</div>
