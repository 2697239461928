import { DatePipe, formatDate } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { HIGHCHARTS_CDX_THEME } from '@cdx/highcharts';
import { AccountStatistics } from '@compumark/bla-backend-client';
import Highcharts, { Options } from 'highcharts';

@Component({
  selector: 'app-searches-run-uncapped',
  templateUrl: './searches-run-uncapped.component.html',
  styleUrls: ['./searches-run-uncapped.component.scss'],
})
export class SearchesRunUncappedComponent implements OnInit {
  options?: Options;
  @Input() stats?: AccountStatistics;
  readonly Highcharts = Highcharts;

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  ngOnInit(): void {
    const months = this.getMonths('MM/YYYY');
    const monthNames = this.getMonths('MMM');

    const values = months.map((m) =>
      !!this.stats!.monthlyCounts![m] ? this.stats!.monthlyCounts![m] : 0,
    );

    const searchesRun = values.reduce((sum, value) => sum + value);

    Highcharts.setOptions(HIGHCHARTS_CDX_THEME);
    this.options = {
      chart: {
        type: 'column',
        height: '50%',

        style: {
          fontFamily: 'Open Sans',
        },
      },
      title: {
        text: 'Searches Run: ' + searchesRun,
        style: {
          fontSize: '1.25rem',
          fontWeight: '700',
        },
      },

      xAxis: {
        categories: monthNames,
      },
      yAxis: {
        title: {
          text: null,
        },
      },
      tooltip: {
        pointFormatter(): string {
          return 'Searches Run: ' + '<b>' + this.y + '</b>';
        },
      },
      legend: { enabled: false },
      series: [
        {
          name: 'Searches Run',
          type: 'column',
          color: '#5E33BF',
          data: values!,
        },
      ],
    };
  }

  getDate(date: any): string {
    return formatDate(
      new Date(parseInt(date, 10) * 1000),
      'dd/MM/yyyy',
      this.locale,
    );
  }

  getMonths(dateFormat: string): string[] {
    const contractDate = new Date(
      parseInt(this.stats!.contract!.startDate!, 10) * 1000,
    );
    const datepipe = new DatePipe('en-US');
    return Array.from({ length: 12 }, () => {
      const monthYear = datepipe.transform(contractDate, dateFormat)!;
      contractDate.setMonth(contractDate.getMonth() + 1);
      return monthYear;
    });
  }
}
