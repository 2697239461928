<ng-container *ngIf="resultsDelivery$ | async as resultsDelivery">
  <nav mat-tab-nav-bar>
    <a mat-tab-link [active]="showTrademark()" routerLink="../all-threats"
      >Trademarks</a
    >
    <a
      *ngIf="showPharma(resultsDelivery)"
      mat-tab-link
      [active]="activeLink.includes('pharma-threats')"
      [routerLink]="pharmaPath"
      >{{ showCommonLaw(resultsDelivery) ? 'Pharma In-Use' : 'Pharma' }}</a
    >
    <a
      *ngIf="showCommonLaw(resultsDelivery)"
      mat-tab-link
      [active]="activeLink.includes('common-law')"
      [routerLink]="commonLawPath"
      >Common Law</a
    >
    <a
      *ngIf="
        loaded &&
        resultsDelivery!.strategy!.commonLawCountries!.length > 0 &&
        resultsDelivery!.type === 'BRAND_LANDSCAPE_ANALYZER'
      "
      mat-tab-link
      [active]="activeLink == 'wcl'"
      [routerLink]="wclPath"
      class="wcl-tab"
      >Web Common Law</a
    >

    <a
      *ngIf="
        !loaded && resultsDelivery!.strategy!.commonLawCountries!.length > 0
      "
      mat-tab-link
      disabled
      [active]="activeLink == 'wcl'"
      ><span title="Results loading">Web Common Law</span>
      <mat-progress-spinner
        class="spinner"
        [diameter]="20"
        mode="indeterminate"
        color="primary"
      ></mat-progress-spinner
    ></a>

    <a
      *ngIf="showDomainNames(resultsDelivery)"
      mat-tab-link
      [active]="activeLink == 'domain-names'"
      [routerLink]="domainPath"
      >Domain Names</a
    >
    <a
      *ngIf="showCompanyNames(resultsDelivery)"
      mat-tab-link
      [active]="activeLink.includes('company-names')"
      [routerLink]="companyPath"
      >Company Names</a
    >
  </nav>
</ng-container>
