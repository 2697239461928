<div class="input">
  <div *ngIf="!customize">
    <div
      class="label"
      [ngClass]="{ 'report-editor-disabled-div': disableSubsection }"
    >
      {{ data.label }}
    </div>
    <div class="value">
      <mat-checkbox color="primary" [(ngModel)]="data.filingDates!.value">{{
        data.filingDates!.label
      }}</mat-checkbox>
    </div>
  </div>
  <div *ngIf="customize">
    <div class="padding-on-customize">
      <mat-form-field appearance="outline" class="cdx-input-default">
        <input
          matInput
          #input
          [(ngModel)]="data.label"
          [disabled]="disableSubsection"
        />
      </mat-form-field>
    </div>
    <div class="custom-div">
      <mat-checkbox
        class="custom-checkbox"
        color="primary"
        [(ngModel)]="data.filingDates!.value"
        [disabled]="disableSubsection"
      ></mat-checkbox>
      <mat-form-field
        appearance="outline"
        class="filing-dates cdx-input-default padding-on-customize"
      >
        <input
          matInput
          #input
          [(ngModel)]="data.filingDates!.label"
          [disabled]="disableSubsection"
        />
      </mat-form-field>
    </div>
  </div>
</div>
