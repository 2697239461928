import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

import { ImageTooltipComponent } from '../components/image-tooltip/image-tooltip.component';

@Directive({
  selector: '[appImageTooltip]',
})
export class ImageTooltipDirective implements OnInit {
  @Input('appImageTooltip') imageUrl = '';
  private overlayRef?: OverlayRef;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          panelClass: 'arrow-up',
          originX: 'center',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
          offsetY: 0,
        },
        {
          panelClass: 'arrow-down',
          originX: 'center',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
          offsetY: 5,
        },
      ]);
    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener('mousemove')
  show(): void {
    if (!!this.overlayRef) {
      this.overlayRef!.detach();
    }

    const tooltipRef: ComponentRef<ImageTooltipComponent> = this.overlayRef!.attach(
      new ComponentPortal(ImageTooltipComponent),
    );
    tooltipRef.instance.imageUrl = this.imageUrl;
  }

  @HostListener('mouseout')
  hide(): void {
    this.overlayRef!.detach();
  }
}
