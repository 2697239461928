import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnInit } from '@angular/core';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { OptimizedOwner, Strategy } from '@compumark/bla-backend-client';
import {
  ItemFlatNode,
  OwnerNode,
} from 'src/app/owner-results/owner-filter/owner-filter.component';
import { OwnerComparatorService } from 'src/app/util/services/owner-comparator.service';

@Component({
  selector: 'app-owner-analyzer-search-strategy',
  templateUrl: './owner-analyzer-search-strategy.component.html',
  styleUrls: ['./owner-analyzer-search-strategy.component.scss'],
})
export class OwnerAnalyzerSearchStrategyComponent implements OnInit {
  @Input() strategy?: Strategy;

  treeControl!: FlatTreeControl<ItemFlatNode>;
  treeFlattener!: MatTreeFlattener<OwnerNode, ItemFlatNode>;
  treeOwners: OwnerNode[] = [];
  nestedNodeMap: Map<OwnerNode, ItemFlatNode> = new Map<
    OwnerNode,
    ItemFlatNode
  >();
  flatNodeMap: Map<ItemFlatNode, OwnerNode> = new Map<
    ItemFlatNode,
    OwnerNode
  >();
  dataSource!: MatTreeFlatDataSource<OwnerNode, ItemFlatNode>;
  ownerName = '';

  constructor(private ownerComparatorService: OwnerComparatorService) {}

  transformer = (node: any, level: number) => {
    let ownerNode = '';
    if (node.optimizedOwners) {
      if (node.children) {
        ownerNode = node.optimizedOwners[0].parentUXName;
      } else {
        ownerNode = node.optimizedOwners[0].optimizedUXName;
      }
    } else {
      ownerNode = node.optimizedUXName;
    }
    const flatNode =
      this.nestedNodeMap.has(node) &&
      this.nestedNodeMap.get(node)!.item === node.optimizedOwner.optimizedUXName
        ? this.nestedNodeMap.get(node)!
        : ({} as ItemFlatNode);
    flatNode.item = ownerNode;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    flatNode.optimizedOwners = node.optimizedOwners
      ? node.optimizedOwners
      : node;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  ngOnInit(): void {
    this.ownerName = this.strategy?.name.toUpperCase()!;
    const res = this.groupById(this.strategy!.owners!).map(
      (optimizedOwners: OptimizedOwner[]) => {
        return {
          optimizedOwners,
          expandable: true,
          level:
            optimizedOwners.length > 1 ||
            optimizedOwners[0].guid !== optimizedOwners[0].parentGuid
              ? 2
              : 1,
          children:
            optimizedOwners.length > 1 ||
            optimizedOwners[0].guid !== optimizedOwners[0].parentGuid
              ? optimizedOwners
              : undefined,
          count:
            optimizedOwners.length > 1
              ? optimizedOwners
                  .filter((ow) => !!ow.trademarksCount)
                  .map((ow) => ow.trademarksCount)
                  .reduce((a, b) => a! + b!, 0)
              : optimizedOwners[0].trademarksCount,
        } as OwnerNode;
      },
    );

    this.treeOwners = res.sort(this.ownerComparatorService.compareOwnerNodes);

    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      (node) => node.level,
      (node) => node.expandable,
      (node) => node.children,
    );
    this.treeControl = new FlatTreeControl<ItemFlatNode>(
      this.getLevel,
      this.isExpandable,
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener,
    );

    this.dataSource.data = this.treeOwners;
  }

  groupById(owners: OptimizedOwner[]): OptimizedOwner[][] {
    const groups: OptimizedOwner[][] = [];
    owners.forEach((owner) => {
      const filter = groups.filter(
        (os) => os[0].parentGuid === owner.parentGuid,
      );
      if (filter && filter.length > 0) {
        filter[0].push(owner);
      } else {
        groups.push([owner]);
      }
    });
    groups.forEach((group) =>
      group.sort((first, second) =>
        first.optimizedUXName.localeCompare(second.optimizedUXName),
      ),
    );
    return groups;
  }

  hasChild = (_: number, node: ItemFlatNode) => node.expandable;

  getLevel = (node: ItemFlatNode) => {
    return node.level;
  };

  isExpandable = (node: ItemFlatNode) => {
    return node.expandable;
  };
}
