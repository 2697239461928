import { AgFilterComponent } from '@ag-grid-community/angular';
import {
  IDoesFilterPassParams,
  AgPromise,
  IAfterGuiAttachedParams,
} from '@ag-grid-community/core';
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterValues } from '@compumark/bla-backend-client';
import { map, Observable } from 'rxjs';

export const ACTION_FILTER_OPTIONS = [
  {
    label: 'Top Citations',
    checked: false,
  },
  {
    label: 'Citations with comments',
    checked: false,
  },
  {
    label: 'Citations selected for export',
    checked: false,
  },
];

@Component({
  selector: 'app-actions-filter',
  templateUrl: './actions-filter.component.html',
  styleUrls: ['./actions-filter.component.scss'],
})
export class ActionsFilterComponent implements AgFilterComponent {
  @Input() actionFilterOptions: { label: string; checked: boolean }[] = [];

  params!: any;
  filter: any;
  hidePopup: any;
  valueGetter!: (rowNode: any) => any;
  filterValues$: Observable<FilterValues>;
  hasTopThreats = false;
  hasComments = false;
  private filterType = 'customCheckboxArray';

  constructor(private route: ActivatedRoute) {
    this.filterValues$ = this.route.data.pipe(map((data) => data.filterValues));
  }

  agInit(params: any): void {
    this.params = params;
    this.valueGetter = params.valueGetter;
    this.actionFilterOptions = params.actionFilterOptions;
  }

  isFilterActive(): boolean {
    return this.actionFilterOptions?.some((option) => option.checked);
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    this.valueGetter(params.node);
    return true;
  }

  getModel(): any {
    return {
      filter: this.actionFilterOptions
        .filter((option) => option.checked)
        .map((option) => option.label),
      filterType: this.filterType,
    };
  }

  setModel(model: any): void | AgPromise<void> {
    if (!!model) {
      this.filter = model.filter;
      this.actionFilterOptions.forEach((option) => {
        if (this.filter.includes(option.label)) {
          option.checked = true;
        } else {
          option.checked = false;
        }
      });
    } else {
      this.onClear();
    }
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    this.hidePopup = params?.hidePopup;
  }

  onClear(): void {
    this.actionFilterOptions.forEach((option) => (option.checked = false));
  }

  onApply(): void {
    this.params?.filterChangedCallback();
    this.hidePopup();
  }

  toggleChecked(option: any): void {
    option.checked = !option.checked;
  }
}
