import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { UtilModule } from '../util/util.module';

import { FullTextDialogComponent } from './components/full-text-dialog/full-text-dialog.component';
import { FullTextOpeningService } from './services/full-text-opening-service';
import { ThreatsTableModule } from '../threats-tables/threats-table.module';
import { CommonModule } from '@angular/common';

import { DomainNamesDailogComponent } from './components/full-text-dialog/domain-names-dailog/domain-names-dailog/domain-names-dailog.component';
@NgModule({
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    RouterModule,
    UtilModule,
    ThreatsTableModule,
    CommonModule,
  ],
  declarations: [FullTextDialogComponent, DomainNamesDailogComponent],
  providers: [FullTextOpeningService],
})
export class FullTextModule {}
