<div id="details-container">
  <dl>
    <ng-container *ngIf="!!orderMetrics?.totalTime">
      <dt>Total Time:</dt>
      <dd>{{ orderMetrics?.totalTime! }}ms</dd>
    </ng-container>
    <ng-container *ngIf="!!orderMetrics?.search">
      <dt>Search:</dt>
      <dd>{{ orderMetrics?.search! }}ms</dd>
    </ng-container>
    <ng-container *ngIf="!!orderMetrics?.riskAssessment">
      <dt>Risk Assessment:</dt>
      <dd>{{ orderMetrics?.riskAssessment! }}ms</dd>
    </ng-container>
    <ng-container *ngIf="!!orderMetrics?.wcl">
      <dt>WCL:</dt>
      <dd>{{ orderMetrics?.wcl! }}ms</dd>
    </ng-container>
    <ng-container *ngIf="!!orderMetrics?.domain">
      <dt>Domain:</dt>
      <dd>{{ orderMetrics?.domain! }}ms</dd>
    </ng-container>
  </dl>
  <ng-container *ngIf="!!kibanaUrl">
    <a [href]="kibanaUrl" target="_blank">Open Logs</a>
  </ng-container>
</div>
