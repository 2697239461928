import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AbsoluteGrounds,
  CaseLawDataPointsMap,
  DictionaryMeaning,
  GeographicLocationDataPoints,
  ResultsDelivery,
  Term,
} from '@compumark/bla-backend-client';
import { AbsoluteGroundsDialogOpeningService } from '../../services/absolute-grounds-dialog-opening.service';
import { GeographicLocationDialogComponent } from '../geographic-location-dialog/geographic-location-dialog.component';
import { IdenticalMatchesDialogComponent } from '../identical-matches-dialog/identical-matches-dialog.component';
import { DictionaryMeaningDialogComponent } from '../dictionary-meaning-dialog/dictionary-meaning-dialog.component';
import _ from 'lodash';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UserService } from 'src/app/security/services/user.service';
import { WIDGETS } from 'src/app/widgets/widget/widget.component';

@Component({
  selector: 'app-absolute-ground-data-points-widget',
  templateUrl: './absolute-ground-data-points-widget.component.html',
  styleUrls: ['./absolute-ground-data-points-widget.component.scss'],
})
export class AbsoluteGroundDataPointsWidgetComponent {
  resultsDelivery: ResultsDelivery;
  showAllToggleState = false;
  hasPharmaRole = false;
  @Output() removeEvent = new EventEmitter<string>();

  constructor(
    private openingService: AbsoluteGroundsDialogOpeningService,
    route: ActivatedRoute,
    user: UserService,
  ) {
    this.resultsDelivery = route.snapshot.data.resultsDelivery;
    this.hasPharmaRole = (user.getCurrentUser().roles as any).includes(
      'PHARMA',
    );
  }

  showAllToggle(showAllToggleEvent: MatSlideToggleChange): void {
    this.showAllToggleState = showAllToggleEvent.checked;
  }

  getGeographicLocations(
    geographicLocationDataPoints: Array<GeographicLocationDataPoints>,
  ): any[] {
    const allGeographicLocations: any[] = [];
    geographicLocationDataPoints?.forEach((geographicDataPoint) => {
      if (!!geographicDataPoint?.country) {
        allGeographicLocations?.push(geographicDataPoint.country);
      }
      if (!!geographicDataPoint?.cities) {
        geographicDataPoint.cities?.forEach((city) => {
          allGeographicLocations.push(city);
        });
      }
    });

    return allGeographicLocations;
  }
  getCards(caseLawDataPoints: CaseLawDataPointsMap[], chipName: string): any[] {
    return caseLawDataPoints?.filter((c) => c.chip === chipName)[0]
      .caseLawDataPointList!;
  }

  openGeographicLocationDialog(
    absoluteGrounds: AbsoluteGrounds,
    resultsDeliveryId: string,
  ): void {
    const geographicLocations = this.getGeographicLocations(
      absoluteGrounds?.geographicLocationDataPoints!,
    );
    if (geographicLocations.length > 0) {
      this.openingService.showDialog(
        {
          resultsDeliveryId,
          geographicLocations,
        } as any,
        GeographicLocationDialogComponent,
      );
    }
  }

  openDictionaryMeaningDialog(
    absoluteGrounds: AbsoluteGrounds,
    resultsDeliveryId: string,
  ): void {
    const dictionaryMeanings = this.getAllDictionaryLanguages(
      absoluteGrounds?.dictionaryMeaning!,
    );
    if (dictionaryMeanings.length > 0) {
      this.openingService.showDialog(
        {
          resultsDeliveryId,
          dictionaryMeanings,
        } as any,
        DictionaryMeaningDialogComponent,
      );
    }
  }

  openIdenticalMatchesDialog(
    terms: Term[],
    title: string,
    resultsDeliveryId: string,
  ): void {
    if (terms.length > 0) {
      this.openingService.showDialog(
        {
          resultsDeliveryId,
          terms,
          label: title,
        } as any,
        IdenticalMatchesDialogComponent,
      );
    }
  }

  getAllDictionaryLanguages(
    dictionaryMeaning: Array<DictionaryMeaning>,
  ): Array<DictionaryMeaning> {
    return dictionaryMeaning?.filter((item) =>
      item.languages?.some((t) => t.lexicalCategories?.length! > 0),
    );
  }

  getGeographicLocationTooltip(
    geographicLocationDataPoints: Array<GeographicLocationDataPoints>,
  ): string {
    let tooltip = '';

    if (this.getGeographicLocations(geographicLocationDataPoints).length > 0) {
      geographicLocationDataPoints.forEach((g) => {
        const countryResults = g.country == null ? 0 : 1;
        tooltip += this.createTooltip(
          g.searchTerm,
          g.cities?.length! + countryResults,
          'location',
        );
      });
    } else {
      tooltip += 'No Results found';
    }
    return tooltip;
  }

  geIdenticalMatchTooltip(terms: Term[]): string {
    let tooltip = '';

    const groupedByTerm = _.groupBy(terms, (t) => t.term);

    Object.keys(groupedByTerm).forEach((term) => {
      tooltip += this.createTooltip(term, groupedByTerm[term].length, 'case');
    });

    if (tooltip.length === 0) {
      tooltip += 'No Results found';
    }

    return tooltip;
  }

  getDictionaryLanguages(dictionaryMeaning: Array<DictionaryMeaning>): number {
    let count = 0;
    this.getAllDictionaryLanguages(dictionaryMeaning)?.forEach((language) => {
      count = count + language?.languages!.length;
    });
    return count;
  }

  getDictionaryMeaningTooltip(
    dictionaryMeaning: Array<DictionaryMeaning>,
  ): string {
    let tooltip = '';

    if (this.getDictionaryLanguages(dictionaryMeaning!) > 0) {
      dictionaryMeaning?.forEach((language) => {
        const searchTerm = language?.languages![0].word!;
        const results = !!language?.languages![0].lexicalCategories
          ? language.languages.length
          : 0;
        tooltip += this.createTooltip(searchTerm, results, 'language');
      });
    } else {
      tooltip += 'No Results found';
    }
    return tooltip;
  }

  createTooltip(searchTerm: any, results: any, endTerm: string): string {
    const suffix = results !== 0 ? (results > 1 ? endTerm + 's' : endTerm) : '';
    return `<strong> ${searchTerm} </strong>: ${results} ${suffix} <br> `;
  }

  /* istanbul ignore next */
  checkAnyDataPointExist(absoluteGrounds?: AbsoluteGrounds): boolean {
    return (
      this.getDictionaryLanguages(absoluteGrounds?.dictionaryMeaning!) > 0 ||
      this.getGeographicLocations(
        absoluteGrounds?.geographicLocationDataPoints!,
      ).length > 0 ||
      (!!absoluteGrounds?.stems && absoluteGrounds.stems.length > 0) ||
      (!!absoluteGrounds?.ban && absoluteGrounds?.ban.length > 0) ||
      (!!absoluteGrounds?.inn && absoluteGrounds?.inn.length > 0) ||
      (!!absoluteGrounds?.abbreviations &&
        absoluteGrounds?.abbreviations?.length > 0) ||
      (!!absoluteGrounds?.romanNumerals &&
        absoluteGrounds?.romanNumerals?.length > 0) ||
      this.showAllToggleState
    );
  }

  remove(): void {
    this.removeEvent.emit(WIDGETS.ABSOLUTE_GROUNDS_DATA_POINTS);
  }
}
