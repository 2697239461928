import { Component, Input } from '@angular/core';
import { Row } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-toggle-format',
  templateUrl: './toggle-format.component.html',
  styleUrls: ['./toggle-format.component.scss'],
})
export class ToggleFormatComponent {
  @Input() data!: Row;

  onFormatChange(value: string): void {
    this.data.value = value;
  }

  isChecked(value: string): boolean {
    return this.data.value === value;
  }
}
