import { DOCUMENT } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class TermsAndConditionsInterceptor implements HttpInterceptor {
  private readonly acceptedHeaderName: string = 'terms-and-conditions-accepted';
  private readonly userIdHeaderName: string = 'terms-and-conditions-userid';

  constructor(@Inject(DOCUMENT) private document: Document) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((httpEvent: HttpEvent<any>) => {
        if (httpEvent instanceof HttpResponse) {
          if (
            !!environment.termsAndCondition.enabled &&
            httpEvent.headers.get(this.acceptedHeaderName) === 'false'
          ) {
            this.document.location.href =
              environment.termsAndCondition.url +
              `?userId=${httpEvent.headers.get(
                this.userIdHeaderName,
              )}&returnUrl=${window.location.href}`;
          }
        }
      }),
    );
  }
}
