import { EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ResultsDeliveryService } from '@compumark/bla-backend-client';
import { filter, first, switchMap, tap } from 'rxjs/operators';

import { ConfirmationDialogComponent } from '../confirmation-dialog/confimation-dialog.component';
import { GlasspaneService } from '@compumark/brand-context-components';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-top-threats-link',
  templateUrl: './reset-top-threats-link.component.html',
  styleUrls: ['./reset-top-threats-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetTopThreatsLinkComponent {
  @Input() displayAsButton = true;
  @Output() resetAllConfirmedEvent = new EventEmitter<void>();
  @Output() resetAllCompletedEvent = new EventEmitter<void>();
  @ViewChild('matAnchor') anchor: MatAnchor | undefined;
  resetLabel = environment.features?.threatOlderReset
    ? 'Reset'
    : 'Reset table search results';

  constructor(
    private route: ActivatedRoute,
    private resultsDeliveryService: ResultsDeliveryService,
    private glasspaneService: GlasspaneService,
    public dialog: MatDialog,
  ) {}

  openConfirmationPopup(): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        autoFocus: false,
      })
      .afterClosed()
      .pipe(
        first(),
        tap(() => {
          this.anchor?._elementRef?.nativeElement?.blur();
        }),
        filter((result) => result === 'CONTINUE'),
        tap(() => {
          this.glasspaneService.showGlasspane();
          this.resetAllConfirmedEvent.emit();
        }),
        switchMap(() =>
          this.resultsDeliveryService
            .resetThreatsAssociatedWithResultsDelivery(
              this.route.snapshot.params.resultsDeliveryId,
            )
            .pipe(first()),
        ),
      )
      .subscribe(() => {
        this.resetAllCompletedEvent.emit();
        this.glasspaneService.hideGlasspane();
      });
  }
}
