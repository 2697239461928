import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { UserService } from 'src/app/security/services/user.service';

@Component({
  selector: 'app-comments-renderer',
  templateUrl: './comments-renderer.component.html',
})
export class CommentsRendererComponent {
  name?: string;
  threatId!: string;

  constructor(public userService: UserService) {}

  agInit(params: ICellRendererParams): void {
    this.name = params.data.trademarkName;
    this.threatId = params.data.threatId;
  }

  get userName(): string {
    return this.userService.getCurrentUser()?.name!;
  }

  get userId(): string {
    return this.userService.getCurrentUser()?.id!;
  }
}
