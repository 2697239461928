<div class="label">My Search Packages</div>
<ol>
  <li
    class="item"
    *ngFor="let pack of packages"
    queryParamsHandling="preserve"
    routerLink="../{{ pack.id }}"
    [routerLinkActive]="'selected-item'"
  >
    {{ pack.name }}
  </li>
</ol>
<span class="purchase-package" routerLink="../create"
  >Purchase another search package</span
>
