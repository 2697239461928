import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { WhoisService } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-domain-names-fulltext-view',
  templateUrl: './domain-names-fulltext-view.component.html',
  styleUrls: ['./domain-names-fulltext-view.component.scss'],
})
export class DomainNamesFulltextViewComponent implements OnInit, OnChanges {
  @Input()
  threat: any;
  @Input()
  resultsDeliveryId: any;
  screenshotButtonLabel = 'Load screenshot';
  whoisRetrieved = false;

  constructor(private whois: WhoisService) {}
  ngOnInit(): void {}

  getWhoisData(): void {
    this.whois
      .getWhoisForThreatAssociatedWithResultsDelivery(
        this.threat.id,
        this.resultsDeliveryId,
        true,
      )
      .subscribe((res) => {
        this.threat.whois = res;
        this.whoisRetrieved = true;
      });
  }
  ngOnChanges(): void {
    this.whoisRetrieved = false;
  }
  getUrl(): string {
    return 'http://www.' + this.threat?.name;
  }
  public showWebPageInfo(): boolean {
    return (
      this.threat.title ||
      this.threat.description ||
      this.threat.status ||
      this.threat.keywords
    );
  }
}
