import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ResultsDelivery } from '@compumark/bla-backend-client';
import { Observable } from 'rxjs';
import { AbsoluteGroundCaselawWidgetComponent } from 'src/app/summary/components/absolute-ground-caselaw-widget/absolute-ground-caselaw-widget.component';
import { AbsoluteGroundDataPointsWidgetComponent } from 'src/app/summary/components/absolute-ground-data-points-widget/absolute-ground-data-points-widget.component';
import { AllThreatsInsightsWidgetComponent } from 'src/app/summary/components/all-threats-insights/all-threats-insights.component';
import { TopThreatsWidgetComponent } from 'src/app/summary/components/top-threats-widget/top-threats-widget.component';
import { RiskPerMarketWidgetComponent } from '../risk-per-market-widget/risk-per-market-widget.component';
import { CoexistencePerMarketWidgetComponent } from 'src/app/summary/components/coexistence-per-market-widget/coexistence-per-market-widget.component';
import { GeographicPresenceOfTopThreatsWidgetComponent } from 'src/app/summary/components/geographic-presece-of-top-threats/geographic-presece-of-top-threats.component';
import { OwnerGlobalityComponent } from 'src/app/owner-results/owner-globality/owner-globality.component';
import { PortfolioSizeWidgetComponent } from 'src/app/owner-results/portfolio-size-widget/portfolio-size-widget.component';
import { LitigationsWidgetComponent } from 'src/app/owner-results/litigations-widget/litigations-widget.component';

export enum WIDGETS {
  ABSOLUTE_GROUNDS_CASE_LAW = 'absolute-ground-case-law',
  ABSOLUTE_GROUNDS_DATA_POINTS = 'absolute-ground-data-points',
  GEOGRAPHIC_PRESENCE_OF_TOP_THREATS = 'geographic-presence-top-threats',
  ALL_THREATS_INSIGHTS = 'all-threats-insights',
  RISK_PER_MARKET = 'risk-per-market',
  TOP_THREATS_SUMMARY = 'top-threats-summary',
  COEXISTENCE_PER_MARKET = 'coexistence-per-market',
  OWNER_GLOBALITY = 'owner-globality',
  OWNER_PORTFOLIO_SIZE = 'owner-portfolio-size',
  OWNER_LITIGATIONS = 'owner-litigations',
}

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
})
export class WidgetComponent implements AfterViewInit {
  @ViewChild('widgetComponent', { read: ViewContainerRef })
  widgetComponent?: ViewContainerRef;
  @Output() contentSizeChangedEvent = new EventEmitter<number>();
  @Output() removeEvent = new EventEmitter<string>();

  @Input()
  widgetName?: string | WIDGETS;
  @Input()
  locked = false;
  @Input()
  isHovered = false;

  resultsDelivery?: ResultsDelivery;

  widgetComponentRef?: ComponentRef<unknown>;

  constructor(private changeDetector: ChangeDetectorRef) {}

  /* istanbul ignore next */
  ngAfterViewInit(): void {
    if (!!this.widget) {
      this.widgetComponentRef = this.widgetComponent!.createComponent(
        this.widget,
      );

      const instance = this.widgetComponentRef.instance as any;

      if (!!instance?.contentSizeChangedEvent) {
        (instance?.contentSizeChangedEvent as Observable<number>).subscribe(
          (n) => this.contentSizeChangedEvent.emit(n),
        );
      }

      if (!!instance?.removeEvent) {
        (instance?.removeEvent as Observable<string>).subscribe((id) =>
          this.removeEvent.emit(id),
        );
      }
    }

    this.changeDetector.detectChanges();
  }

  get widget(): Type<unknown> | undefined {
    let result;

    if (!!this.widgetName) {
      switch (this.widgetName) {
        case WIDGETS.TOP_THREATS_SUMMARY:
          result = TopThreatsWidgetComponent;
          break;
        case WIDGETS.ABSOLUTE_GROUNDS_CASE_LAW:
          result = AbsoluteGroundCaselawWidgetComponent;
          break;
        case WIDGETS.ABSOLUTE_GROUNDS_DATA_POINTS:
          result = AbsoluteGroundDataPointsWidgetComponent;
          break;
        case WIDGETS.ALL_THREATS_INSIGHTS:
          result = AllThreatsInsightsWidgetComponent;
          break;
        case WIDGETS.RISK_PER_MARKET:
          result = RiskPerMarketWidgetComponent;
          break;
        case WIDGETS.COEXISTENCE_PER_MARKET:
          result = CoexistencePerMarketWidgetComponent;
          break;
        case WIDGETS.GEOGRAPHIC_PRESENCE_OF_TOP_THREATS:
          result = GeographicPresenceOfTopThreatsWidgetComponent;
          break;
        case WIDGETS.OWNER_GLOBALITY:
          result = OwnerGlobalityComponent;
          break;
        case WIDGETS.OWNER_PORTFOLIO_SIZE:
          result = PortfolioSizeWidgetComponent;
          break;
        case WIDGETS.OWNER_LITIGATIONS:
          result = LitigationsWidgetComponent;
          break;
        default:
          break;
      }
    }

    return result;
  }
}
