import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import {
  PricePackage,
  PricePackageService,
} from '@compumark/bla-backend-client';
import { Observable } from 'rxjs';

@Injectable()
export class PackageFromBackendResolver implements Resolve<PricePackage[]> {
  constructor(private packageService: PricePackageService) {}

  resolve(): Observable<PricePackage[]> {
    return this.packageService.getPricePackages();
  }
}
