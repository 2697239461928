<ng-container
  *ngIf="{
    ownerData: ownerData$ | async,
    showLoadingIndicator: showLoadingIndicator$ | async
  } as data"
>
  <app-widget-header
    [title]="'Owner | Globality'"
    tooltip="Classification of the owner based on geographical presence of its trademark portfolio."
    (removeEvent)="remove()"
  ></app-widget-header>

  <div class="body" *ngIf="!data.showLoadingIndicator">
    <lib-risk-measure
      noDataLabel="No cases found"
      *ngIf="data.ownerData!.globalityScale"
      [exploreResultsLink]="undefined"
      [scaleAndValue]="data.ownerData!.globalityScale!"
      class="globality"
    ></lib-risk-measure>
    <div class="map-container">
      <lib-globality-graphic
        [selectable]="false"
        [height]="'320px'"
        [globality]="data.ownerData!"
      ></lib-globality-graphic>
    </div>
    <a
      class="explore-results-link"
      [routerLink]="'../deep-dive/owner/globality'"
      *ngIf="data.ownerData?.numberOfCountries !== 0"
      queryParamsHandling="preserve"
    >
      Explore results
    </a>
  </div>
  <lib-loading-indicator
    *ngIf="data.showLoadingIndicator"
    [showLoadingIndicator]="true"
    [type]="'GRAPH'"
  ></lib-loading-indicator>
</ng-container>
