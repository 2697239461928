<footer cdx-footer appCustomFooter>
  <a cdx-footer-link tabindex="0" href="https://clarivate.com/compumark/"
    >CompuMark Home</a
  >
  <a
    cdx-footer-link
    href="https://clarivate.com/compumark/terms-and-conditions/"
    >Terms of Use</a
  >
</footer>
