import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { BrandLandscapeAccessDeniedComponent } from './components/brandlandscape-access-denied/brandlandscape-access-denied.component';
import { AuthenticationService } from './services/authentication.service';
import { AuthorizationService } from './services/authorization.service';
import { OwnerAnalyzerAccessDeniedComponent } from './components/owner-analyzer-access-denied/owner-analyzer-access-denied.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    AccessDeniedComponent,
    BrandLandscapeAccessDeniedComponent,
    OwnerAnalyzerAccessDeniedComponent,
  ],
  providers: [AuthenticationService, AuthorizationService],
})
export class SecurityModule {}
