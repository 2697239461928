import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrandContextComponentsModule } from '@compumark/brand-context-components';

import { NotificationDropdownComponent } from './components/notification-dropdown/notification-dropdown.component';

@NgModule({
  declarations: [NotificationDropdownComponent],
  imports: [
    CommonModule,
    MatBadgeModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatSnackBarModule,
    BrandContextComponentsModule,
  ],
  providers: [],
  exports: [NotificationDropdownComponent],
})
export class NotificationModule {}
