<ng-container *ngIf="loaded">
  <button mat-menu-item [routerLink]="path">
    {{ item.label }}
  </button>
</ng-container>
<ng-container *ngIf="!loaded">
  <button mat-menu-item>
    <span title="Results loading">{{ item.label }}</span>
    <mat-progress-spinner
      [diameter]="20"
      mode="indeterminate"
      color="primary"
    ></mat-progress-spinner>
  </button>
</ng-container>
