import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { GoodsAndServicesDialogOpeningService } from '../../products-dialog/services/goods-and-services-dialog-opening-service';
import { TableSettingsService } from '../../services/table-settings.service';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-goods-and-services-renderer',
  templateUrl: './goods-and-services-renderer.component.html',
  styleUrls: ['./goods-and-services-renderer.component.scss'],
})
export class GoodsAndServicesRendererComponent
  implements ICellRendererAngularComp {
  @ViewChild('link') link?: ElementRef;
  readonly symbolSizeinPixels = 10;
  readonly sizeOfViewAllPorducts = 100;
  readonly lineGap = 1.5;
  readonly expandIconInPixels = 20;

  goodsAndServicesText?: string;
  goodsAndServicesPlainText?: string;
  cellWidth?: number;
  id?: string;
  trademarkName?: string;
  showViewAll = false;
  cellHeight = 40;
  maxTextSizeAllowed = 5;
  settingsSubscription!: Subscription;
  gridApi: any;
  goodsAndServicesRiskLevel? = '';
  goodsAndServicesHighlightOn = false;
  highlightedText?: string[];
  goodsWithRisk: string[] = [];
  safeHtmlText?: SafeHtml;

  constructor(
    private goodsAndServicesOpeningService: GoodsAndServicesDialogOpeningService,
    private route: ActivatedRoute,
    public tableSettingsService: TableSettingsService,
    public sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  ) {}

  agInit(params: ICellRendererParams): void {
    this.gridApi = params.api;
    this.id = params.data.id;
    this.trademarkName = params.data.trademarkName;
    this.goodsAndServicesText = params.value;
    this.cellWidth = params.eGridCell.clientWidth;
    this.cellHeight = this.tableSettingsService.rowHeight;
    this.goodsAndServicesPlainText = params.data.goodsAndServices;
    this.goodsAndServicesHighlightOn = this.tableSettingsService.highlightOn;
    this.maxTextSizeAllowed = this.calculateTextSize();
    this.goodsAndServicesRiskLevel = params.data.goodsAndServicesRiskLevel;
    if (params.data?.goodsAndServices) {
      if (
        this.goodsAndServicesText!.length > 0 &&
        this.goodsAndServicesHighlightOn &&
        !!params.data.goodsAndServicesMatchedSegments
      ) {
        this.processGoodsAndServicesSegments(
          params.data.goodsAndServicesMatchedSegments,
        );
      } else {
        this.goodsWithRisk = [this.goodsAndServicesText!];
      }
      this.getGoodsAndServicesText((params as any).disableViewAll);
      this.cdr.detectChanges();
    }
  }
  refresh(): boolean {
    return true;
  }
  openProudctsDialog(event: any): void {
    event.preventDefault();
    this.goodsAndServicesOpeningService
      .showGoodsAndServicesText(
        this.trademarkName!,
        this.id!,
        this.resultsDeliveryId,
      )
      .subscribe(() => {
        this.link!.nativeElement.focus();
      });
  }

  calculateTextSize(): number {
    const availableWidth = this.cellWidth! - this.expandIconInPixels;

    const availableLines = Math.floor(
      this.cellHeight / (this.symbolSizeinPixels * this.lineGap) - 1,
    );
    const charsPerLine = Math.floor(availableWidth / this.symbolSizeinPixels);
    const totalChars = charsPerLine * availableLines;

    return totalChars;
  }

  textClassSize(): string {
    return (
      'goods-text-base goods-text-' +
      this.tableSettingsService.textLines.toString()
    );
  }

  private get resultsDeliveryId(): string {
    return this.route.snapshot.params.resultsDeliveryId;
  }

  getGoodsAndServicesText(disableViewAll = false): void {
    if (!disableViewAll) {
      if (this.goodsAndServicesPlainText!.length < this.maxTextSizeAllowed) {
        this.showViewAll = false;
      } else {
        this.showViewAll = true;
      }
    }
    this.highlightedText = [this.goodsAndServicesText!];
    if (this.goodsAndServicesHighlightOn) {
      this.safeHtmlText = this.sanitizer.bypassSecurityTrustHtml(
        this.reApplyRiskLevelForCss(
          this.goodsAndServicesRiskLevel!,
          this.goodsWithRisk.join('... '),
        ),
      );
    } else {
      this.safeHtmlText = this.sanitizer.bypassSecurityTrustHtml(
        this.goodsAndServicesText!,
      );
    }
  }

  get text(): SafeHtml {
    return this.safeHtmlText!;
  }

  processGoodsAndServicesSegments(segments: any): void {
    const goodsWithRisk = segments
      .filter((item: string) => item.includes(this.goodsAndServicesRiskLevel!))
      .map((item: string) => {
        const splitBy =
          "<strong><em class='" + this.goodsAndServicesRiskLevel + "'>";
        if (item.indexOf(splitBy) > 40) {
          item = item.substring(item.indexOf(splitBy), item.length);
        }

        return item.replace(/\.\.\./g, '');
      });

    this.goodsWithRisk = goodsWithRisk;

    if (this.goodsWithRisk.length === 0) {
      this.goodsWithRisk = segments.map((item: string) =>
        item.replace(/\.\.\./g, ''),
      );
    }

    if (this.goodsWithRisk.length === 0) {
      this.goodsWithRisk = ['No Goods and Services similarity matches'];
      this.goodsAndServicesText = this.goodsWithRisk[0];
    }
  }

  reApplyRiskLevelForCss(risk: string, inputHtml: string): string {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = inputHtml;
    const highlightedText: string[] = [];

    const cleanNodes = (element: HTMLElement | ChildNode): string => {
      let result = '';

      element.childNodes.forEach((node: ChildNode) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          const el = node as HTMLElement;

          if (el.classList.contains(risk)) {
            highlightedText.push(el.textContent || '');
            result += `<${el.tagName.toLowerCase()} class="${risk}">${cleanNodes(
              el,
            )}</${el.tagName.toLowerCase()}>`;
          } else {
            result += cleanNodes(el);
          }
        } else if (node.nodeType === Node.TEXT_NODE) {
          result += node.textContent;
        }
      });

      return result;
    };

    this.highlightedText = highlightedText;
    return cleanNodes(tempElement);
  }
}
