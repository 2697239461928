import { GridApi } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import {
  ThreatsService,
  UpdateThreatSelection,
  UpdateThreatSelectionAction,
} from '@compumark/bla-backend-client';
import { TopThreatsCounterService } from '../../services/top-threats-counter.service';
import { NotificationService } from '@compumark/brand-context-components';

@Injectable({
  providedIn: 'root',
})
export class AddToTopThreatsBulkActionService {
  constructor(
    private notification: NotificationService,
    private countService: TopThreatsCounterService,
    private threatsService: ThreatsService,
  ) {}

  add(
    gridApi: GridApi,
    resultsDeliveryId: string,
    selectedThreats: Set<string>,
    currentTopThreatsIds: string[],
  ): void {
    const threatsToAdd = [...selectedThreats].filter(
      (id) => !currentTopThreatsIds.includes(id),
    );

    this.updateThreatsSelection(
      gridApi,
      resultsDeliveryId,
      threatsToAdd,
      UpdateThreatSelectionAction.ADD,
    );
    this.notification
      .showSnackbar(
        'The selected citations have been added to Top Citations and selected for export',
        'undo',
        3000,
      )
      .onAction()
      .subscribe(() =>
        this.updateThreatsSelection(
          gridApi,
          resultsDeliveryId,
          threatsToAdd,
          UpdateThreatSelectionAction.REMOVE,
        ),
      );
  }

  updateThreatsSelection(
    gridApi: GridApi,
    resultsDeliveryId: string,
    ids: string[],
    action: UpdateThreatSelectionAction,
  ): void {
    ids.forEach((id) => {
      action === UpdateThreatSelectionAction.ADD
        ? this.countService.addThreat(resultsDeliveryId, id)
        : this.countService.removeThreat(resultsDeliveryId, id);
    });

    gridApi.forEachNode((node: any) => {
      if (ids.includes(node.data.id)) {
        node.data.includedInTopThreats =
          action === UpdateThreatSelectionAction.ADD;
        node.setData(node.data);
        gridApi.refreshCells({ rowNodes: [node], force: false });
      }
    });

    const updatedThreatsSelection: UpdateThreatSelection[] = ids.map((id) => ({
      threatId: id,
      action,
    }));

    this.threatsService
      .updateTopThreatsForResultsDelivery(
        resultsDeliveryId,
        updatedThreatsSelection,
      )
      .subscribe();
  }
}
