<ng-container
  *ngIf="
    userService.hasUserAccessToProduct('IMAGE_SEARCH') &&
      userService.hasUserAccessToProduct('OWNER_ANALYZER');
    else singleLink
  "
>
  <mat-icon
    aria-label="Home page"
    aria-hidden="false"
    [matMenuTriggerFor]="menuTrigger"
    >home</mat-icon
  >
  <mat-menu [class]="customOverlayClass" #menuTrigger="matMenu">
    <a routerLink="/initiate" class="menu-item"> Word Search </a>

    <a
      *ngIf="userService.hasUserAccessToProduct('IMAGE_SEARCH')"
      routerLink="/initiate/image-search"
      class="menu-item"
    >
      Image Search
    </a>

    <a
      *ngIf="userService.hasUserAccessToProduct('OWNER_ANALYZER')"
      routerLink="/initiate/owner-analyzer"
      class="menu-item"
    >
      Owner Search
    </a>
  </mat-menu>
</ng-container>

<ng-template #singleLink>
  <mat-icon aria-label="Home page" aria-hidden="false" [routerLink]="['/']"
    >home</mat-icon
  >
</ng-template>
