import { Injectable } from '@angular/core';
import { Currency } from '@compumark/bla-backend-client';
import { UserService } from 'src/app/security/services/user.service';

@Injectable()
export class PriceFormattingService {
  constructor(private userService: UserService) {}

  formatPrice(price: number, currency?: Currency): string {
    const marketCurrency = this.userService.getCurrentUser().contract!
      .currency!;

    return (
      this.currencyPrefix(
        price === 0 || !currency ? marketCurrency : currency,
      ) + price.toLocaleString('en-US')
    );
  }

  private currencyPrefix(currency: Currency): string {
    let prefix;

    switch (currency) {
      case 'EUR': {
        prefix = '€';
        break;
      }
      case 'USD': {
        prefix = '$';
        break;
      }
      default: {
        prefix = '$';
        break;
      }
    }

    return prefix;
  }
}
