import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FlagsService, ResultsDelivery } from '@compumark/bla-backend-client';
import _ from 'lodash';

@Component({
  selector: 'app-flag-definitions-dialog',
  templateUrl: './flag-definitions-dialog.component.html',
  styleUrls: ['./flag-definitions-dialog.component.scss'],
})
export class FlagDefinitionDialogComponent {
  flagColors = [
    '#DE2839',
    '#FFD90B',
    '#16AB03',
    '#5E33BF',
    '#5693F5',
    '#F89324',
    '#029DB7',
    '#7D2E68',
    '#297C43',
    '#A65000',
    '#046B99',
    '#BDBDBD',
    '#001750',
    '#0054D0',
    '#AE13BB',
    '#E4F279',
  ];
  flagDefinitions = [{}] as any[];

  userDefault = false;
  seq = 3;
  resultsDelivery: ResultsDelivery;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private flagService: FlagsService,
    public dialog: MatDialog,
  ) {
    this.resultsDelivery = data.resultsDelivery;
    this.loadFlagDefinitions();
  }

  loadFlagDefinitions(): void {
    const flagDefinitions = _.cloneDeep(this.resultsDelivery!.flagDefinitions);
    if (!!flagDefinitions) {
      const flags = flagDefinitions.flags;
      this.flagDefinitions = flags as any;
      if (flagDefinitions.flags.length > 0) {
        this.seq = +flags[flags.length - 1].id;
      } else {
        this.seq = 0;
      }
    } else {
      this.flagService
        .getFlagDefinitions(this.resultsDelivery.id)
        .subscribe((f) => {
          if (f.flags.length > 0) {
            this.flagDefinitions = f.flags as any;
            this.seq = +f.flags[f.flags.length - 1].id as any;
          }
        });
    }
  }

  addNewFlag(): void {
    const id = this.getSequence();
    this.flagDefinitions.push({
      id,
      label: id,
      color: this.getFlagColor(),
    });
  }

  removeFlag(id: any): void {
    this.flagDefinitions = this.flagDefinitions.filter((f) => f.id !== id);
  }

  getFlagColor(): string {
    return this.getAvailableFlagColors()[0];
  }

  getAvailableFlagColors(): any {
    const selectedFlagColors = this.flagDefinitions.map((fd) =>
      fd.color?.toUpperCase(),
    );

    return this.flagColors.filter((fc) => !selectedFlagColors.includes(fc));
  }

  getSequence(): any {
    return (this.seq = this.seq + 1);
  }

  apply(): void {
    const fd = {
      flags: this.flagDefinitions,
      userDefault: this.userDefault,
      resultsDeliveryId: this.resultsDelivery?.id,
    } as any;
    this.flagService
      .createFlagDefinitions(fd, this.resultsDelivery.id)
      .subscribe(() => {
        window.location.reload();
        this.dialog.closeAll();
      });
  }

  flagColorChange(color: any): void {
    const index = this.flagDefinitions.findIndex((f) => f.id === color.id);
    if (index !== -1) {
      this.flagDefinitions[index].color = color.new;
    }
  }
}
