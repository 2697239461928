import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'app-owner-cell-renderer',
  template: `
    <span [style.vertical-align]="'center'">
      {{ params.data.trademarkApplicantName }}
      <mat-icon
        *ngIf="params.data.knownCompetitor && isExpert"
        class="mat-icon material-icons"
        style="vertical-align: top; padding-left: 0.5rem; line-height: 1.5; font-size: 1.5rem"
      >
        person
      </mat-icon>
    </span>
  `,
})
export class OwnerCellRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public isExpert = false;

  agInit(params: any): void {
    this.params = params;
    this.isExpert = params.isExpert;
  }

  refresh(params: any): boolean {
    this.params = params;
    this.isExpert = params.isExpert;
    return true;
  }
}
