import { Injectable } from '@angular/core';
import {
  RiskPerMarketTable,
  SummaryService,
} from '@compumark/bla-backend-client';
import _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { PreferenceStorageService } from '../../util/services/preference-storage.service';

export interface RiskPerMarketResult {
  table: RiskPerMarketTable;
  filteredTable: RiskPerMarketTable;
}

export interface RiskPerMarketFilters {
  classes?: Array<string>;
  markets?: Array<string>;
}

export interface Market {
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class RiskPerMarketColumnStateService {
  static readonly preferenceKey: string = 'riskPerMarketFilters';
  private resultsDeliveryId?: string;
  private result$: BehaviorSubject<RiskPerMarketResult> = new BehaviorSubject({
    table: { rows: [] },
    filteredTable: { rows: [] },
  } as RiskPerMarketResult);

  private filters$: BehaviorSubject<RiskPerMarketFilters> = new BehaviorSubject(
    {},
  );

  private markets$: BehaviorSubject<string[]> = new BehaviorSubject(
    [] as string[],
  );

  constructor(
    private summaryService: SummaryService,
    private preferenceService: PreferenceStorageService,
  ) {}

  loadInitialData(
    resultsDeliveryId: string,
    appliedFilters: RiskPerMarketFilters,
  ): void {
    const currentFilters = this.filters$.getValue();

    const filterFromStorage = this.preferenceService.getPreference(
      RiskPerMarketColumnStateService.preferenceKey + ':' + resultsDeliveryId,
    );

    if (_.isEmpty(currentFilters) && !_.isEmpty(filterFromStorage)) {
      appliedFilters = filterFromStorage;
    }

    if (
      !_.isEqual(currentFilters, appliedFilters) ||
      this.resultsDeliveryId !== resultsDeliveryId
    ) {
      this.preferenceService.setPreference(
        RiskPerMarketColumnStateService.preferenceKey + ':' + resultsDeliveryId,
        appliedFilters,
      );
      this.summaryService
        .riskPerMarket(resultsDeliveryId, appliedFilters.classes!)
        .subscribe((table) => {
          if (this.resultsDeliveryId !== resultsDeliveryId) {
            this.loadMarkets(table);
          }

          const filteredTable = _.clone(table);
          if (!!appliedFilters && !!appliedFilters.markets) {
            const regs = appliedFilters.markets;
            filteredTable.rows = filteredTable.rows?.filter((r) =>
              regs.includes(r.jurisdiction!),
            );
          } else {
            filteredTable.rows = filteredTable.rows!.slice(0, 5);
          }

          this.result$.next({
            table,
            filteredTable,
          } as RiskPerMarketResult);
          this.resultsDeliveryId = resultsDeliveryId;
        });

      this.filters$.next(appliedFilters);
    }
  }

  /* istanbul ignore next */
  get result(): Observable<RiskPerMarketResult> {
    return this.result$.asObservable();
  }

  /* istanbul ignore next */
  get filter(): Observable<RiskPerMarketFilters> {
    return this.filters$.asObservable();
  }

  /* istanbul ignore next */
  get curentFilter(): RiskPerMarketFilters {
    return this.filters$.getValue();
  }

  /* istanbul ignore next */
  get markets(): Observable<string[]> {
    return this.markets$;
  }

  /* istanbul ignore next */
  get curentMarkets(): string[] {
    return this.markets$.getValue();
  }

  private loadMarkets(table: RiskPerMarketTable): void {
    const marekts =
      table.rows!.map((r) => {
        return r.jurisdiction!;
      }) || [];

    this.markets$.next(marekts);
  }
}
