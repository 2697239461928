<ng-container class="cluster-info" *ngIf="!!clusterInfo">
  <mat-icon
    fontSet="material-symbols-outlined"
    [appCustomTooltip]="tooltip!"
    class="cluster-icon icon cdx-mat-icon-20"
  >
    insert_link
  </mat-icon>
</ng-container>

<span
  class="name"
  [ngClass]="{
    selected: selectedForExport
  }"
  ><span [matTooltip]="name!">{{ name }}</span></span
>
