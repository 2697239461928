<dl>
  <ng-container *ngFor="let filingDate of filingDates">
    <dt>{{ filingDate.jurisdictionName }}</dt>
    <dd>
      <ng-container *ngFor="let text of filingDate.filingDateTexts">
        {{ text }} <br />
      </ng-container>
    </dd>
  </ng-container>
</dl>
