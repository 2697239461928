<div class="goods-container">
  <div class="left highlights">
    <span
      [ngClass]="textClassSize()"
      [innerHTML]="text"
      [matTooltip]="
        (!!this.highlightedText && this.highlightedText.length > 0)
      ? this.highlightedText.join(', ')
      : this.goodsAndServicesText ?? ''
      "
    ></span>
  </div>
  <span
    class="material-symbols-outlined open-in-new"
    (click)="openProudctsDialog($event)"
    *ngIf="showViewAll"
  >
    open_in_new
  </span>
</div>
