import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Market } from '@compumark/bla-backend-client';
import _ from 'lodash';

export const contentTypeMapping = new Map([
  ['TRADEMARK', 'TRADEMARKS'],
  ['PHARMA', 'PHARMA CONTENT'],
  ['COMPANY_NAMES', 'COMPANY NAMES'],
  ['DOMAIN_NAMES', 'DOMAIN NAMES'],
]);

@Pipe({
  name: 'formatMarkets',
})
export class FormatMarketsPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  markets?: Array<Market>;
  internationalMarkets = ['ISKP', 'WO'];
  pharmaRelatedDbMarkets = ['POCA', 'PCL'];

  transform(value: Array<Market>): string | null {
    this.markets = value;
    let isRegion = false;
    let marketString = '';
    let isFirstMarketType = true;

    const groupedMarkets = _.groupBy(value, (market) => market.type);

    const { COMMON_LAW, ...filteredMarkets } = groupedMarkets;

    for (const [type] of Object.entries(filteredMarkets)) {
      marketString +=
        this.shouldBreakLine(!isFirstMarketType) +
        '<b>' +
        contentTypeMapping.get(type) +
        '</b>';
      groupedMarkets[type].forEach((market) => {
        if (this.isRegion(market)) {
          marketString += marketString === '' ? '' : '<br/>';
          marketString +=
            '<b>' + market.name + ' ' + this.regionCounts(market) + ': </b>';
          isRegion = true;
        } else if (this.isInternationalRegister(market)) {
          marketString += '<br/>' + market.name;
        } else if (this.isPharmaRelatedRegister(market)) {
          marketString +=
            '<br/>' +
            '<span class="market-name">' +
            market.name +
            '</span> ' +
            this.concatMarketSources(market);
        } else {
          marketString += isRegion ? market.name : ', ' + market.name;
          isRegion = false;
        }
        isFirstMarketType = false;
      });
    }

    return this.sanitizer.sanitize(SecurityContext.HTML, marketString!);
  }

  shouldBreakLine(breakLine: boolean): string {
    return breakLine ? '<br/><br/>' : '';
  }

  isRegion(market: Market): boolean {
    return !!market.children && market.children!.length > 0;
  }

  isInternationalRegister(market: Market): boolean {
    return this.internationalMarkets.includes(market.id!);
  }

  isPharmaRelatedRegister(market: Market): boolean {
    return (
      market!.id!.indexOf('POCA-') >= 0 ||
      market!.id!.indexOf('PCL-') >= 0 ||
      market.id!.startsWith('PI')
    );
  }

  regionCounts(market: Market): string {
    const totalCount = this.totalCount(market);
    const count = this.regionCount(market);
    return '(' + count + '/' + totalCount + ')';
  }

  totalCount(market: Market): number {
    let count = 0;
    count = market.children!.length!;
    market.children!.forEach((child) => {
      count! += child.children?.length === 0 ? 0 : this.totalCount(child) - 1;
    });
    return count!;
  }

  regionCount(market: Market): number {
    const marketIds = this.markets!.map((data) => data.id!);
    let count = 0;
    count = market.children!.filter(
      (child) =>
        (child as Market).children?.length === 0 &&
        marketIds?.includes((child as Market).id!),
    ).length!;
    market.children!.forEach((child) => {
      count! += child.children?.length === 0 ? 0 : this.regionCount(child);
    });
    return count!;
  }

  private concatMarketSources(market: Market): string {
    if (market.sources) {
      return '(' + market.sources + ')';
    }
    return '';
  }
}
