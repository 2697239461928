import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { StatusCodes } from 'http-status-codes';
import { environment } from 'src/environments/environment';

/* istanbul ignore next */
@Injectable()
export class AuthenticationService {
  constructor(@Inject('nativeWindow') private nativeWindow: Window) {}

  isErrorResponseUnauthorized(response: HttpErrorResponse): boolean {
    return response.status === StatusCodes.UNAUTHORIZED;
  }

  login(path: string): void {
    const login = new URL(environment.backendBaseUrl + '/loginRedirect');
    login.searchParams.append('refUrl', path);

    this.nativeWindow.location.href = login.href;
  }

  logout(): void {
    const logout = new URL(environment.backendBaseUrl + '/logout');
    this.nativeWindow.location.href = logout.href;
  }
}
