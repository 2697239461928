import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ExportService,
  ExportTemplate,
  ResultsDelivery,
  QuickTemplateType,
  Threat,
  TopThreatsCluster,
} from '@compumark/bla-backend-client';
import { filter, first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { ReportCreationService } from '../../services/report-creation.service';

import { ReportEditorComponent } from './../report-editor/report-editor.component';

import { ExportConfirmationDialogComponent } from '../confirmation-dialog/confimation-dialog.component';
import { NotificationService } from '@compumark/brand-context-components';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
})
export class ExportComponent {
  @Input()
  resultsDelivery!: ResultsDelivery;

  templates: ExportTemplate[] | undefined;

  showDialog(exportTemplate: ExportTemplate | undefined): void {
    this.dialog
      .open(ReportEditorComponent, {
        data: {
          resultsDeliveryId: this.resultsDelivery.id,
          topThreats: this.getThreathFromClusters(
            this.resultsDelivery.clusters!,
          ),
          featureLevel: this.resultsDelivery.strategy?.featureLevel,
          template: exportTemplate,
        },
        autoFocus: false,
        width: '90%',
        scrollStrategy: new NoopScrollStrategy(),
        panelClass: 'report-editor-dialog-conatiner',
      })
      .afterClosed()
      .pipe(first())
      .subscribe();
  }

  private getThreathFromClusters(
    topThreatsClusters: TopThreatsCluster[],
  ): Threat[] {
    return topThreatsClusters.flatMap((tcs) => tcs.threats!);
  }

  constructor(
    public dialog: MatDialog,
    private reportCreationService: ReportCreationService,
    private exportService: ExportService,
    private notificationService: NotificationService,
  ) {
    this.updateTemplates();
  }

  export(quickTemplateType: QuickTemplateType): void {
    this.reportCreationService.exportQuick(
      this.resultsDelivery.id,
      quickTemplateType,
    );
  }

  exportTemplate(exportTemplate: ExportTemplate): void {
    this.reportCreationService.export(this.resultsDelivery.id, exportTemplate);
  }

  canExport(): boolean {
    return this.getTopThreatsCount() <= environment.maxExportTopThreatsLimit;
  }

  getTopThreatsCount(): number {
    return this.getThreathFromClusters(this.resultsDelivery.clusters!).length;
  }

  getExportTopThreatsLimit(): number {
    return environment.maxExportTopThreatsLimit;
  }

  openExportConfirmationPopup($event: Event, template: ExportTemplate): void {
    $event.stopPropagation();
    this.dialog
      .open(ExportConfirmationDialogComponent, {
        autoFocus: false,

        data: {
          header: 'Delete template?',
          title:
            'Are you sure you want to delete the report template “' +
            template.name +
            '”?',
          confirmButtonLabel: 'CONTINUE',
          cancelButtonLabel: 'CANCEL',
        },
      })
      .afterClosed()
      .pipe(
        first(),
        filter((result) => result === 'CONTINUE'),
      )
      .subscribe(() => {
        this.exportService
          .deleteExportTemplate(template.id!)
          .subscribe(() => this.onComplete(template));
      });
  }

  onComplete(exportTemplate: ExportTemplate): void {
    this.updateTemplates();
    this.notificationService.showSnackbarWithTimeout(
      'Your report template ' + exportTemplate.name + ' has been deleted',
      5000,
    );
  }

  updateTemplates(): void {
    this.exportService
      .getExportTemplates()
      .subscribe((data: any) => (this.templates = data));
  }
}
