/* istanbul ignore next */
import {
  ColDef,
  ColumnResizedEvent,
  FilterChangedEvent,
  GridApi,
  GridOptions,
  MenuItemDef,
  RowDoubleClickedEvent,
  RowNode,
  SideBarDef,
} from '@ag-grid-community/core';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FullTextService,
  NameFilterType,
  ResultsDelivery,
  User,
} from '@compumark/bla-backend-client';
import {
  FilterChip,
  FilterChipStateService,
  LogoRendererComponent,
  NotificationService,
} from '@compumark/brand-context-components';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { MaterialTooltipComponent } from 'src/app/data-grid/components/material-tooltip/material-tooltip.component';
import { environment } from 'src/environments/environment';

import { CustomTextFilterComponent } from '../../../custom-text-filter/customTextFilter.component';
import { MaterialLoadingCellRendererComponent } from '../../../data-grid/components/material-loading-cell-renderer/material-loading-cell-renderer.component';
import { ClearableFilterChangeService } from '../../services/clearable-filter-change-service';
import { RiskLevelRendererComponent } from '../risk-level-renderer/risk-level-renderer.component';
import { FlagsTableComponent } from 'src/app/flag/components/flags-table/flags-table.component';
import { CommentsRendererComponent } from 'src/app/util/components/comments-renderer/comments-renderer.component';
import { TableColumnService } from 'src/app/util/services/table-column-service';
import { UserService } from 'src/app/security/services/user.service';
import { FilterChipsCallbackHandlerService } from 'src/app/util/services/filter-chips-callback-handler.service';
import { PocaScoreIndicatorComponent } from 'src/app/isolate/components/poca-score-indicator/poca-score-indicator/poca-score-indicator.component';
import { RiskNameRendererComponent } from '../risk-name-renderer/risk-name-renderer.component';
import { FlagFilterComponent } from 'src/app/flag/components/flag-filter/flag-filter.component';
import { SelectAllHeaderComponent } from 'src/app/util/components/select-all-header/select-all-header.component';
import { ActionsComponent } from '../../bulk-actions/components/actions/actions.component';
import { ActionRendererService } from '../../services/action-renderer.service';
import { TableSettingsService } from '../../services/table-settings.service';
import { PreferenceStorageService } from 'src/app/util/services/preference-storage.service';
import { TableSetting } from '../table-settings/table-settings.component';
import { BulkActionService } from 'src/app/util/services/bulk-action.service';
import { GoodsAndServicesRendererComponent } from '../goods-and-services-renderer/goods-and-services-renderer.component';
import { ContextMenuService } from '../../services/context-menu.service';
import { FullscreenService } from 'src/app/util/services/fullscreen.service';
import { ThreatDatasourceFactory } from '../../services/factories/threat-datasource-factory.service';
import { ActionsFilterComponent } from '../actions-filter/actions-filter.component';
import { ThreatSelectionService } from '../../services/threatSelection.service';
import { FullTextOpeningService } from 'src/app/full-text/services/full-text-opening-service';
import { CompanyNameGoodsRendererComponent } from '../goods-and-services-renderer/company-name-goods-renderer/company-name-goods-renderer/company-name-goods-renderer.component';
import { OwnerCellRendererComponent } from '../owner-cell-renderer/owner-cell-renderer.component';
import { SaveFreetextRendererComponent } from 'src/app/save-freetext-renderer/save-freetext-renderer.component';
import { TtabRendererComponent } from 'src/app/isolate/components/ttab-renderer/ttab-renderer.component';
import { ReputationIconRendererComponent } from 'src/app/isolate/components/reputation-icon-renderer/reputation-icon-renderer.component';
import { GracePeriodIndicatorComponent } from 'src/app/isolate/components/grace-period-indicator/grace-period-indicator.component';
import { CountryRendererComponent } from 'src/app/util/components/country-renderer/country-renderer.component';

const riskNameToLongNameMapping = new Map([['V', 'Verbal Risk']]);

export const pharmaTypeMapping = new Map([
  ['pharma_in_use', 'Pharma In Use'],
  ['poca', 'POCA'],
  ['pcl', 'PCL'],
]);

@Component({
  selector: 'app-content-threats-table',
  templateUrl: './content-threats-table.component.html',
  styleUrls: ['./content-threats-table.component.scss'],
  providers: [
    UserService,
    ActionRendererService,
    BulkActionService,
    ContextMenuService,
  ],
})
export class ContentThreatsTableComponent
  implements OnDestroy, OnChanges, OnInit {
  static readonly heatMapIteChipName = 'verbalRisk';
  readonly selectedRowkey = 'selectedRowKey : ' + this.resultsDeliveryId;
  @Input() noResultsMessage = '';
  @Input() columnDefinitions: any[] = [];
  @Input() showNavBar = true;
  @Input() showViewSelection = false;
  @Input() selectedView: 'TILE' | 'TABLE' = 'TABLE';
  nameFilter?: string;
  currentUser$: Observable<User>;
  contentType = this.route.snapshot.data.contentType;
  pharmaIdenticalFilter?: string;
  totalThreatCount$!: Observable<number | null>;
  @Input() selectedThreats: Set<string> = new Set();
  @Output() rowDoubleClicked = new EventEmitter<RowDoubleClickedEvent>();
  public rowHeight = 35;
  splitViewData:
    | { threat: any; hasPrevious: boolean; hasNext: boolean }
    | undefined;
  splitViewEnabled = false;
  selectedRowIndex: any;
  rowData: any;
  fullscreen = this.fullScreenService.fullscreenStatus;
  screenIcon = 'open_in_full';
  fullscreenTooltip = 'Fullscreen';
  @HostBinding('style.height') hostHeight = 'calc(100vh - 5rem)';
  previousFocusedRowIndex: number | null = null;
  public userPreference: any = undefined;
  maxSelectionLimit = 100;
  flagsEnabled = true;
  filters$ = new BehaviorSubject<{ [key: string]: any }>({});
  filterChips$ = this.filters$.pipe(
    map((filters) => this.getFilterChips(filters)),
  );
  resultsDelivery$: Observable<ResultsDelivery>;

  public sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [TableColumnService.columnsOpenPanel],
    position: 'right',
    hiddenByDefault: false,
  };

  public gridApi?: GridApi;
  public isFilteringActive = false;
  private removedChipsTriggerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  removedChipsTrigger$: Observable<boolean> = this.removedChipsTriggerSubject.asObservable();

  readonly gridOptions: GridOptions = {
    defaultColDef: {
      resizable: true,
      suppressSizeToFit: true,
      menuTabs: ['filterMenuTab', 'generalMenuTab'],
    },
    getRowNodeId: (t) => t.id,
    onColumnMoved: (event) => {
      this.tableColumnService.onColumnChange(event, this.isExpert());
    },
    onColumnVisible: (event) => {
      this.tableColumnService.onColumnChange(event, this.isExpert());
    },
    onColumnResized: (event: ColumnResizedEvent): void => {
      if (event.column !== null) {
        this.tableColumnService.onColumnChange(event, this.isExpert());
      }
    },
    columnDefs: [],
    frameworkComponents: {
      riskColumnRenderer: RiskLevelRendererComponent,
      riskNameRenderer: RiskNameRendererComponent,
      materialTooltip: MaterialTooltipComponent,
      textColumnFilterWithoutOptions: CustomTextFilterComponent,
      flagsRenderer: FlagsTableComponent,
      flagFilter: FlagFilterComponent,
      commentsRenderer: CommentsRendererComponent,
      pocaScoreIndicator: PocaScoreIndicatorComponent,
      actions: ActionsComponent,
      selectAllRenderer: SelectAllHeaderComponent,
      logoRendererComponent: LogoRendererComponent,
      protectionClaimedRenderer: CountryRendererComponent,
      goodsAndServicesRendererComponent: GoodsAndServicesRendererComponent,
      actionsFilter: ActionsFilterComponent,
      ownerCellRenderer: OwnerCellRendererComponent,
      freetextRenderer: SaveFreetextRendererComponent,
      ttabColumnRenderer: TtabRendererComponent,
      reputationRenderer: ReputationIconRendererComponent,
      gracePeriodIndicator: GracePeriodIndicatorComponent,
      companyNameGoodsRenderer: CompanyNameGoodsRendererComponent,
    },
    rowModelType: 'serverSide',
    serverSideStoreType: 'partial',
    maxConcurrentDatasourceRequests: 2,
    cacheBlockSize: 100,
    rowBuffer: 0,
    groupRemoveSingleChildren: true,
    isServerSideGroupOpenByDefault: () => {
      return true;
    },
    rowHeight: 35,
    blockLoadDebounceMillis: 0,
    loadingCellRendererFramework: MaterialLoadingCellRendererComponent,
    overlayLoadingTemplate: '<span></span>',

    getRowHeight: () => {
      return this.rowHeight;
    },

    onGridReady: (event) => {
      this.gridApi = event.api;
      const datasource = this.datasourceFactory
        .create(this.contentType)
        .createDatasource(this.resultsDeliveryId);
      event.api.setServerSideDatasource(datasource);
      this.currentUser$.subscribe((u) => {
        this.userPreference = u.preference!;
        this.tableColumnService.initColumns(
          event.columnApi,
          this.userPreference!,
          this.datasourceFactory
            .create(this.contentType)
            .getThreatTableColumnsName(),
        );
        if (this.userPreference?.tableSettings) {
          this.applyTableSetting(this.userPreference.tableSettings);
        }
      });

      this.applyNameFilterIfExist();

      event.api.sizeColumnsToFit();

      fromEvent(window, 'resize')
        .pipe(untilDestroyed(this), debounceTime(250))
        .subscribe(() => {
          event.api.sizeColumnsToFit();
        });
    },

    rowClassRules: {
      unreviewed: '!data.reviewed',
    },

    onPaginationChanged: (event) => {
      this.filterChipStateService.setHitsCount(event);
    },

    onRowSelected: (event) => {
      const selectedThreats = this.threatSelection.selectedThreats;
      if (!event.node.isSelected()) {
        selectedThreats.delete(event.node.data.id);
      }
      if (selectedThreats.size >= this.maxSelectionLimit) {
        if (!selectedThreats.has(event.node.data.id)) {
          this.showMaxSelectionSnackbar();
          event.node.setSelected(false);
        }
        return;
      }

      this.threatSelection.triggerSelectionChanged(
        this.selectedRowkey,
        this.gridApi,
      );
    },

    onColumnPinned: (event) => {
      this.tableColumnService.onColumnChange(event);
    },

    onColumnEverythingChanged: (event) => {
      if (event.source === 'contextMenu') {
        this.tableColumnService.onColumnChange(event);
      }
    },

    onFirstDataRendered: (event) => {
      event.api.sizeColumnsToFit();
    },

    onRowDoubleClicked: (event) => {
      this.rowDoubleClicked.emit(event);
    },
    getContextMenuItems: ({ node }) =>
      node ? this.getContextMenuItems({ node }) : [],

    suppressHorizontalScroll: false,
    suppressDragLeaveHidesColumns: true,
    suppressMovableColumns: false,
    suppressCellSelection: true,
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    tooltipMouseTrack: true,

    onFilterChanged: (event: FilterChangedEvent) => {
      this.filterChangeService.onFilterChange(event);
    },

    processRowPostCreate: (params) => {
      const index =
        this.preferenceStorageService.getPreference(this.selectedRowkey) || 0;
      if (this.splitViewEnabled) {
        const firstRow = params.api?.getDisplayedRowAtIndex(index)!;
        if (!!firstRow && params.rowIndex === index) {
          this.onRowClicked(firstRow! as any);
        }
      }
    },
    debug: !environment.production,
  };

  getFilterChips(filters: { [key: string]: any }): FilterChip[] {
    return Object.keys(filters).flatMap((field) => {
      let label = this.gridApi!.getColumnDefs()!.find(
        (colDef: ColDef) => colDef.field === field,
      )?.headerName;

      if (label && riskNameToLongNameMapping.get(label)) {
        label = riskNameToLongNameMapping.get(label);
      }

      const filter = filters[field];
      if (filter.filterType === 'custom') {
        if (!!filter.filter && filter.showCompetitors) {
          return this.getFilterChip(label!, field, filter).concat(
            this.getCustomFilterChip(label!, field, filter),
          );
        } else if (filter.showCompetitors) {
          return this.getCustomFilterChip(label!, field, filter);
        } else {
          return !!filter.filter
            ? this.getFilterChip(label!, field, filter)
            : [];
        }
      }
      return this.getFilterChip(label!, field, filter);
    });
  }

  private applyNameFilterIfExist(): void {
    if (!!this.nameFilter) {
      this.filterChangeService.clearAppliedFilters();
      const filterComponent = this.gridApi!.getFilterInstance('name');

      if (filterComponent != null) {
        filterComponent.setModel({
          type: NameFilterType.EXACT,
          filterType: 'customText',
          filter: this.nameFilter,
          subFilter: {
            label: 'Pharma Identical type',
            key: this.pharmaIdenticalFilter!,
            value: pharmaTypeMapping.get(this.pharmaIdenticalFilter!),
          },
        });

        this.gridApi?.onFilterChanged();
      }
    }
  }

  onRowClicked(event: any): void {
    setTimeout(() => {
      this.selectedRowIndex = event.rowIndex;
      this.setRowFocus(this.selectedRowIndex);
      this.splitViewData = {
        threat: event.data,
        hasPrevious: !!this.gridApi?.getDisplayedRowAtIndex(
          this.selectedRowIndex - 1,
        )
          ? true
          : false,
        hasNext: !!this.gridApi?.getDisplayedRowAtIndex(
          this.selectedRowIndex + 1,
        )
          ? true
          : false,
      };
    }, 100);
  }

  isExpert(): boolean {
    return (
      this.route.snapshot.data.resultsDelivery.strategy.featureLevel ===
      'EXPERT'
    );
  }
  handleIconClick(params: any): void {
    this.onRowClicked(params);
  }

  onRowSelected(event: any): void {
    const selectedNodes = this.gridApi?.getSelectedNodes() || [];
    if (selectedNodes.length > -1) {
      const selectedNode = event.node;
      this.selectedRowIndex = selectedNode.rowIndex;
      this.splitViewData = {
        threat: selectedNode.data,
        hasPrevious: !!this.gridApi?.getDisplayedRowAtIndex(
          this.selectedRowIndex - 1,
        ),
        hasNext: !!this.gridApi?.getDisplayedRowAtIndex(
          this.selectedRowIndex + 1,
        ),
      };
      this.setRowFocus(this.selectedRowIndex);
    }
  }

  applyTableSetting(settings: TableSetting[]): void {
    this.tableSettingsService.applyTableSetting(settings);
    this.rowHeight = this.tableSettingsService.rowHeight;
    this.splitViewEnabled = this.tableSettingsService.splitViewEnabled;
    if (this.gridApi) {
      this.gridApi.resetRowHeights();
      this.gridApi.redrawRows();
    }
  }

  nextselection(direction: string): void {
    const newRowIndex =
      direction === 'forward'
        ? this.selectedRowIndex + 1
        : this.selectedRowIndex - 1;
    const newRowNode = this.gridApi?.getDisplayedRowAtIndex(newRowIndex);
    if (newRowNode) {
      this.selectedRowIndex = newRowIndex;
      this.splitViewData = {
        threat: newRowNode.data,
        hasPrevious: !!this.gridApi?.getDisplayedRowAtIndex(newRowIndex - 1)
          ? true
          : false,
        hasNext: !!this.gridApi?.getDisplayedRowAtIndex(newRowIndex + 1)
          ? true
          : false,
      };
      this.setRowFocus(this.selectedRowIndex);
    }
  }

  setRowFocus(rowIndex: number): void {
    if (this.previousFocusedRowIndex !== rowIndex) {
      const gridApi = this.gridApi;
      gridApi?.setFocusedCell(rowIndex, 'name');
      this.previousFocusedRowIndex = rowIndex;
      this.preferenceStorageService.setPreference(
        this.selectedRowkey,
        rowIndex,
      );
      gridApi?.ensureIndexVisible(rowIndex);
    }
  }

  refreshTable(): void {}

  public openFulltext(data: any): void {
    if (data.id.clusterRoot) {
      return;
    }
    this.fullTextOpeningService.showFulltext(data.id, this.resultsDeliveryId);
    this.fullTextService
      .setThreatReviewedAssociatedWithResultsDelivery(
        this.resultsDeliveryId,
        data.id,
      )
      .subscribe(() => {
        const rowNode = this.gridApi?.getRowNode(data.id);
        rowNode?.setData({ ...data, reviewed: true });
      });
  }
  getFilterChip(
    label: string,
    field: string,
    filter: { [key: string]: any },
  ): FilterChip[] {
    if (filter.filterType === 'set') {
      return filter.values.map((value: string) => ({
        label,
        field,
        value,
      }));
    } else {
      return [
        {
          label,
          field,
          value: filter.filter,
        },
      ];
    }
  }

  getCustomFilterChip(
    label: string,
    field: string,
    filter: { [key: string]: any },
  ): FilterChip[] {
    return [
      {
        label,
        field,
        value: filter.value,
      },
    ];
  }

  constructor(
    private route: ActivatedRoute,
    private datasourceFactory: ThreatDatasourceFactory,
    private filterChipStateService: FilterChipStateService,
    private filterChangeService: ClearableFilterChangeService,
    private contextMenuService: ContextMenuService,
    private tableColumnService: TableColumnService,
    private filterChipsCallbackHandlerService: FilterChipsCallbackHandlerService,
    private tableSettingsService: TableSettingsService,
    private notificationService: NotificationService,
    private preferenceStorageService: PreferenceStorageService,
    private bulkActionService: BulkActionService,
    private fullScreenService: FullscreenService,
    public threatSelection: ThreatSelectionService,
    private fullTextOpeningService: FullTextOpeningService,
    private fullTextService: FullTextService,
  ) {
    this.currentUser$ = route.data.pipe(map((data) => data.currentUser));
    const nameFilter = route.snapshot.queryParamMap.get('nameFilter');
    this.selectedRowkey += datasourceFactory
      .create(this.contentType)
      .getThreatTableColumnsName();
    const pharmaIdenticalFilter = route.snapshot.queryParamMap.get(
      'pharmaIdenticalFilter',
    );

    if (!!nameFilter) {
      this.nameFilter = decodeURIComponent(nameFilter);
    }

    if (!!pharmaIdenticalFilter) {
      this.pharmaIdenticalFilter = decodeURIComponent(pharmaIdenticalFilter);
    }

    this.resultsDelivery$ = route.data.pipe(
      map((data) => data.resultsDelivery),
    );

    this.filterChipStateService
      .getfilterChipChanges()
      .subscribe((filterChips) => {
        if (this.gridApi?.getFilterModel()) {
          const filterFields = filterChips.map((fc) => fc.field);

          const filterFieldsToRemove = Object.keys(
            this.gridApi?.getFilterModel()!,
          ).filter((fm) => !filterFields.includes(fm));

          filterFieldsToRemove.forEach((f) => {
            this.gridApi?.destroyFilter(f);
          });
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columnDefinitions) {
      this.gridOptions.columnDefs = this.columnDefinitions;
    }
  }

  ngOnInit(): void {
    const contentType = this.route.snapshot.data.contentType;
    const threatCountService = this.datasourceFactory.create(contentType);
    this.totalThreatCount$ = threatCountService
      .getTotalThreatCount(this.resultsDeliveryId)
      .pipe(startWith(-1));
  }

  get resultsDeliveryId(): string {
    return this.route.snapshot.params.resultsDeliveryId;
  }

  removeFilter(filterChip: FilterChip): void {
    this.filterChipsCallbackHandlerService.refreshTableAfterRemoveFilterChip(
      filterChip,
      this.gridApi!,
    );
  }

  removeAllFilters(): void {
    this.gridApi?.onFilterChanged();
    this.gridApi!.setFilterModel(null);
  }
  getContextMenuItems = ({ node }: { node: RowNode }): Array<MenuItemDef> => {
    const selectionType =
      this.route.snapshot.data.selectionType ||
      this.route.snapshot.data.contentType;
    return this.contextMenuService.getContextMenuItems(
      node,
      this.gridApi,
      this.flagsEnabled,
      selectionType,
      this.resultsDeliveryId,
      this.contentType,
    );
  };

  toggleFullscreen(): void {
    this.fullScreenService.toggleFullscreen();

    if (!this.fullscreen.getValue()) {
      this.fullscreenTooltip = 'Fullscreen';
      this.screenIcon = 'open_in_full';
      this.hostHeight = 'calc(100vh - 5rem)';
    } else {
      this.fullscreenTooltip = 'Exit Fullscreen';
      this.screenIcon = 'close_fullscreen';
      this.hostHeight = '100vh';
    }
  }

  showMaxSelectionSnackbar(): void {
    this.notificationService
      .showSnackbar(
        'A maximum of 100 citations can be selected',
        'Dismiss',
        3000,
      )
      .onAction()
      .subscribe();
  }

  bulkAction(event: { type: string; value?: any }): void {
    const selectionType =
      this.route.snapshot.data.selectionType ||
      this.route.snapshot.data.contentType;
    if (event.type === 'flag') {
      if (this.gridApi) {
        this.bulkActionService.handleFlagAction(
          this.selectedThreats,
          this.gridApi,
          this.route.snapshot.data.resultsDelivery,
          event,
        );
      }
    } else if (event.type === 'top-threat') {
      if (this.gridApi) {
        this.bulkActionService.handleTopThreatAction(
          this.selectedThreats,
          this.gridApi,
          this.resultsDeliveryId,
          event,
          selectionType,
        );
      }
    } else if (event.type === 'comment') {
      if (this.gridApi) {
        this.bulkActionService.handleCommentAction(
          this.selectedThreats,
          this.gridApi,
        );
      }
    }
  }

  toggleView(view: 'TILE' | 'TABLE'): void {
    this.selectedView = view;
  }

  ngOnDestroy(): void {}
}
