import {
  ColumnApi,
  ColumnMovedEvent,
  ColumnState,
  ColumnVisibleEvent,
} from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { UserPreference } from '@compumark/bla-backend-client';
import { UserPreferencesService } from '@compumark/user-preferences-backend-specifications';
import _ from 'lodash';
import { PreferenceStorageService } from './preference-storage.service';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class TableColumnService {
  public static readonly columnsOpenPanel = {
    id: 'columns',
    labelDefault: 'Columns',
    labelKey: 'columns',
    iconKey: 'columns',
    toolPanel: 'agColumnsToolPanel',
    minWidth: 225,
    maxWidth: 225,
    width: 225,
    toolPanelParams: {
      suppressRowGroups: true,
      suppressValues: true,
      suppressPivots: true,
      suppressPivotMode: true,
    },
  };

  private readonly gracePeriod = 'gracePeriod';
  private userPreference?: UserPreference;
  private preferenceName?: string;
  readonly COLUMN_STATE_KEY: string;

  constructor(
    private userPreferenceService: UserPreferencesService,
    private preferenceStorageService: PreferenceStorageService,
    private route: ActivatedRoute,
  ) {
    this.COLUMN_STATE_KEY = `columnState:${this.route.snapshot.data.contentType}`;
  }

  initColumns(
    columnApi: ColumnApi,
    userPreference: UserPreference,
    preferenceName: string,
  ): void {
    this.userPreference = userPreference;
    this.preferenceName = preferenceName;
    const userPreferenceColumns = _.get(userPreference, preferenceName, {});
    const columnState = columnApi.getColumnState();
    const sortedColumnState = this.getSortedColumnStateArray(
      userPreferenceColumns,
      columnState,
      columnApi,
    );

    columnApi.applyColumnState({
      state: sortedColumnState,
      applyOrder: true,
    });
  }

  onColumnChange(
    event: ColumnMovedEvent | ColumnVisibleEvent,
    isExpert: boolean = false,
  ): void {
    _.assign(this.userPreference, {
      [this.preferenceName!]: event.columnApi.getColumnState().map((cs) => {
        return this.updateColumnsSettings(event.columnApi, cs, isExpert);
      }),
    });

    this.userPreferenceService
      .saveUserPreferences(this.userPreference as any)
      .subscribe();
  }

  getSortedColumnStateArray(
    userPreferenceColumns: any[],
    columnState: ColumnState[],
    columnApi: ColumnApi,
  ): ColumnState[] {
    const sortedArray: ColumnState[] = [];
    for (const column of columnState) {
      const userPrefCol = userPreferenceColumns.find(
        (c) => column.colId === c.colId,
      );

      if (!userPrefCol) {
        columnApi?.setColumnVisible(column.colId, false);
      }
    }

    for (const up of userPreferenceColumns) {
      const col = columnState.find((c) => c.colId === up.colId);

      if (!!col) {
        col.hide = up.hide;
        sortedArray.push(col!);
      }
    }

    return sortedArray;
  }

  updateColumnsSettings(
    columnApi: ColumnApi,
    cs: ColumnState,
    isExpert: boolean,
  ): any {
    if (!isExpert && cs.colId === this.gracePeriod) {
      columnApi?.setColumnVisible(cs.colId, false);
      return _.get(this.userPreference, this.preferenceName!, {}).find(
        (c: ColumnState) => c.colId === this.gracePeriod,
      );
    } else {
      return { colId: cs.colId, hide: cs.hide, width: cs.width } as any;
    }
  }

  saveColumnState(columnApi: ColumnApi): void {
    const columnState = columnApi.getColumnState();
    this.preferenceStorageService.setPreference(
      this.COLUMN_STATE_KEY,
      JSON.stringify(columnState),
    );
  }

  applyColumnState(columnApi: ColumnApi): void {
    const savedColumnState = this.preferenceStorageService.getPreference(
      this.COLUMN_STATE_KEY,
    );
    if (savedColumnState) {
      columnApi.applyColumnState({
        state: JSON.parse(savedColumnState),
        applyOrder: true,
      });
    }
  }
}
