import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GoodsAndServicesText } from '@compumark/bla-backend-client';
import { TableSettingsService } from 'src/app/threats-tables/services/table-settings.service';

export interface GoodsAndServicesData {
  name: string;
  goodsAndServicesText: GoodsAndServicesText[];
}

@Component({
  selector: 'app-goods-and-services-dialog',
  templateUrl: './goods-and-services-dialog.component.html',
  styleUrls: ['./goods-and-services-dialog.component.scss'],
})
export class GoodsAndServicesDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public goodsAndServicesData: GoodsAndServicesData,
    public tableSettingsService: TableSettingsService,
  ) {}
}
