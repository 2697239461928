import { Injectable } from '@angular/core';
import {
  ExportTemplate,
  Section,
  SubSection,
} from '@compumark/bla-backend-client';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class TemplateMergeService {
  merge(definition: ExportTemplate, values: ExportTemplate): ExportTemplate {
    const result: ExportTemplate = {};
    const sections: Array<Section> = [];
    result.id = definition.id;
    result.resultsDeliveryId = definition.resultsDeliveryId;
    result.sections = sections;

    definition.sections?.forEach((s) => {
      const section: Section = {};
      const subSections: Array<SubSection> = [];
      section.label = s.label;
      section.name = s.name;
      section.subSections = subSections;

      s.subSections?.forEach((ss) => {
        const subSection: SubSection = {};
        const subSectionFromTemplate = this.getSubsections(ss.name!, values);
        subSection.label = ss.label;
        subSection.name = ss.name;
        subSection.include = ss.include ?? !!subSectionFromTemplate.include;
        subSection.require = !!subSectionFromTemplate.require;
        const subSectionRows = subSectionFromTemplate.rows
          ? subSectionFromTemplate.rows
          : [];

        if (subSectionRows.length > 0) {
          const customValue = (v1: any, v2: any, key: string) => {
            if (key === 'require') {
              return v1 || v2;
            }
          };
          subSection.rows = _.values(
            _.mergeWith(
              _.keyBy(subSectionRows, 'name'),
              _.keyBy(ss.rows, 'name'),
              customValue,
            ),
          );
        } else {
          ss.rows?.forEach((r) => {
            if (!r.type || r.type === '') {
              r.type = 'TEXT_AREA';
            }
          });
          subSection.rows = ss.rows;
        }

        subSections.push(subSection);
      });
      sections.push(section);
    });
    return result;
  }

  private getSubsections(
    subSectionName: string,
    exportTemplate: ExportTemplate,
  ): SubSection {
    let sub: SubSection = {};
    exportTemplate.sections?.forEach((s) =>
      s.subSections?.forEach((ss) => {
        if (ss.name === subSectionName) {
          sub = ss;
        }
      }),
    );
    return sub;
  }
}
