<span
  class="all-threats-custom-filter filter"
  #insights
  FilterTrigger="matMenuTrigger"
  [matMenuTriggerFor]="insightsFilter"
  (menuOpened)="menuOpened = true"
  (menuClosed)="menuOpened = false"
  [ngClass]="{ menuOpened: menuOpened || selectedHeatMapItems.length > 0 }"
>
  <span class="chip-label">All Citations - Insights</span>

  <mat-icon *ngIf="menuOpened">arrow_drop_up</mat-icon>
  <mat-icon *ngIf="!menuOpened">arrow_drop_down</mat-icon>
</span>

<mat-menu
  #insightsFilter="matMenu"
  class="all-threats-custom-filter-menu"
  (click)="$event.stopPropagation()"
>
  <lib-heat-map
    [showExpandPanel]="false"
    [heatMap]="(heatmap$ | async)!"
    [clickable]="true"
    [showCount]="false"
    [showLegend]="false"
    [selectedHeatMapItems]="selectedHeatMapItems"
    (selectedHeatMapItemsEvent)="selectedHeatMapItemsEventHandler($event)"
  >
  </lib-heat-map>
</mat-menu>
