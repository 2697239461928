import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountStatistics, FeatureLevel } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-capped-account',
  templateUrl: './capped-account.component.html',
  styleUrls: ['./capped-account.component.scss'],
})
export class CappedAccountComponent {
  stats: AccountStatistics;

  constructor(route: ActivatedRoute) {
    this.stats = route.snapshot.data.stats;
  }

  featureLevelDescription(): string {
    return this.stats.contract!.featureLevel === FeatureLevel.STANDARD
      ? 'Standard'
      : 'Expert';
  }
}
