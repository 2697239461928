<ng-container *ngIf="result$ | async as result">
  <table *ngIf="getTable(result).rows!.length > 0">
    <tr *ngIf="!limitNumberOfRows" class="sticky">
      <td class="label-y"></td>
      <td
        class="label-x"
        *ngFor="let column of getColumns(getTable(result).rows!)"
      >
        {{ getColumnHeader(column.searchPattern!) }}
      </td>
    </tr>
    <tr *ngFor="let row of getTable(result).rows">
      <td class="label-y">
        {{ row.countryName }}
      </td>
      <td
        class="count"
        *ngFor="let column of row.columns"
        [ngClass]="getBackgroundClass(column.count!)"
      >
        {{ column.count! }}
      </td>
    </tr>
    <tr *ngIf="limitNumberOfRows">
      <td class="label-y"></td>
      <td
        class="label-x"
        *ngFor="let column of getColumns(getTable(result).rows!)"
      >
        {{ getColumnHeader(column.searchPattern!) }}
      </td>
    </tr>
  </table>
</ng-container>
