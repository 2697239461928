import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  DocumentStatus,
  ExportService,
  ExportTemplate,
  QuickTemplateType,
  ReportType,
} from '@compumark/bla-backend-client';
import { NotificationService } from '@compumark/brand-context-components';
import { Observable, timer } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { OwnerFilterService } from 'src/app/owner-results/services/owner-filter.service';

@Injectable({
  providedIn: 'root',
})
export class ReportCreationService {
  constructor(
    private router: Router,
    private exportService: ExportService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private ownerFilterService: OwnerFilterService,
  ) {}

  export(resultsDeliveryId: string, exportTemplate: ExportTemplate): void {
    this.exportService
      .startExportForResultsDelivery(exportTemplate, resultsDeliveryId)
      .subscribe({
        complete: () => this.onComplete(resultsDeliveryId, 'TOP_THREATS'),
      });
  }

  exportQuick(
    resultsDeliveryId: string,
    quickTemplateType: QuickTemplateType,
  ): void {
    this.exportService
      .startQuickExportForResultsDelivery(
        { owners: this.ownerFilterService.value },
        resultsDeliveryId,
        quickTemplateType,
      )
      .subscribe({
        complete: () => this.onComplete(resultsDeliveryId, 'QUICK'),
      });
  }

  private onComplete(resultsDeliveryId: string, reportType: ReportType): void {
    this.notificationService.showSnackbarWithActionAndTimeout(
      'Your export file is being generated. You will be notified when it is ready' +
        this.getNotificationTextExtenstion(reportType),
      'Dismiss',
      5000,
    );
    this.followStatusOfExport(resultsDeliveryId, reportType)
      .pipe(
        switchMap((status) => {
          if (DocumentStatus.ERROR.includes(status)) {
            this.notificationService.showSnackbarWithTimeout(
              'Export request has failed',
            );
          }

          if (DocumentStatus.COMPLETED.includes(status)) {
            this.notificationService
              .showSnackbar(
                'Your export document is available for download from the history page',
                'View',
                7000,
              )
              .onAction()
              .subscribe(() => {
                this.router.navigate(['/history']);
              });
          }
          return 'export document ready';
        }),
      )
      .subscribe();
  }

  private followStatusOfExport(
    resultsDeliveryId: string,
    reportType: ReportType,
    pollingInterval = 10000,
  ): Observable<DocumentStatus> {
    return timer(0, pollingInterval).pipe(
      switchMap(() => {
        return this.exportService.getExportStatus(
          resultsDeliveryId,
          reportType,
        );
      }),
      takeWhile((status) => [DocumentStatus.ANALYZING].includes(status), true),
    );
  }

  private getNotificationTextExtenstion(reportType: ReportType): string {
    return reportType === ReportType.QUICK
      ? '. Note max exportable characters for the Goods and Services section is 32.000'
      : '';
  }
}
