import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Template } from '@compumark/bla-backend-client';
import { NotificationService } from '@compumark/brand-context-components';

@Component({
  selector: 'app-name-input-dialog',
  templateUrl: './name-input-dialog.component.html',
  styleUrls: ['./name-input-dialog.component.scss'],
})
export class NameInputDialogComponent implements OnInit {
  templateName!: string;
  templates!: Array<Template>;
  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<NameInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.templates = this.data;
  }

  saveTemplate(): void {
    if (this.validateTemplate() === true) {
      this.dialogRef.close({ data: this.templateName });
    } else {
      this.notificationService.showSnackbarWithTimeout(
        this.validateTemplate() as string,
        3000,
      );
    }
  }

  validateTemplate(): string | boolean {
    if (!this.templateName) {
      return 'Enter a template name.';
    }

    if (
      this.templates &&
      this.templates.some((template) => template.name === this.templateName)
    ) {
      return 'Template name already exists.';
    }
    if (this.templateName.trim() === '') {
      return 'Enter a valid template name.';
    }

    return true;
  }
}
