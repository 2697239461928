import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PreferenceStorageService {
  localStorage: Storage | null;

  constructor() {
    this.localStorage = window.localStorage;
  }

  getPreference(key: string): any | undefined {
    if (!!this.localStorage) {
      const item = this.localStorage.getItem(key);
      if (!!item) {
        return JSON.parse(item);
      }
    }
    return undefined;
  }

  setPreference(key: string, value: any): boolean {
    if (!!this.localStorage) {
      this.localStorage.setItem(key, JSON.stringify(value));
      return true;
    }
    return false;
  }
}
