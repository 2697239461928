<span
  class="classes-filter filter"
  #classesFilterTrigger="matMenuTrigger"
  [matMenuTriggerFor]="classesFilter"
  (menuOpened)="menuOpened = true"
  (menuClosed)="menuOpened = false"
  [ngClass]="{ menuOpened }"
>
  <span class="chip-label" *ngIf="isInDefaultState">All Searched Classes</span>
  <span class="chip-label" *ngIf="!isInDefaultState">Class(es):</span>
  <span class="chip-value" *ngIf="!isInDefaultState">{{
    selectedClasses | formatClasses: ','
  }}</span>
  <mat-icon *ngIf="menuOpened">arrow_drop_up</mat-icon>
  <mat-icon *ngIf="!menuOpened">arrow_drop_down</mat-icon>
</span>

<mat-menu
  #classesFilter="matMenu"
  class="classes-filter-menu coexistence-filter-menu"
  (click)="$event.stopPropagation()"
>
  <div class="filter-content">
    <div class="select-all" (click)="$event.stopPropagation()">
      <mat-checkbox
        color="primary"
        [checked]="selectAllClasses"
        [indeterminate]="indeterminate"
        (change)="onSelectAllFilterChange($event.checked)"
      >
        Select All</mat-checkbox
      >
    </div>
    <div class="keywords"></div>
    <div
      class="classes-container"
      [ngClass]="{ scroll: !!classes && classes.length > 10 }"
      (click)="$event.stopPropagation()"
    >
      <div *ngFor="let class of classesFilters">
        <div class="checkbox" (click)="$event.stopPropagation()">
          <mat-checkbox
            color="primary"
            [checked]="class.value"
            (change)="onFilterChange($event.checked, class)"
          >
            {{ class.key }}</mat-checkbox
          >
        </div>
      </div>
    </div>

    <div class="actions" (click)="$event.stopPropagation()">
      <button
        [disabled]="getSelectedClasses().length === 0"
        mat-raised-button
        color="primary"
        class="apply"
        (click)="onApply()"
      >
        Apply
      </button>
    </div>
  </div>
</mat-menu>
