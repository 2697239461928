<app-widget-header
  [title]="'Geographic Presence of Top Citations'"
  (removeEvent)="remove()"
></app-widget-header>

<ng-container *ngIf="topThreatsPerCountry$ | async as topThreatsPerCountry">
  <div class="map-container">
    <app-geographic-map
      [topThreatsPerCountry]="topThreatsPerCountry"
      [showTitle]="false"
      [customSize]="true"
      [width]="800"
      [height]="330"
    >
    </app-geographic-map>
  </div>
</ng-container>
