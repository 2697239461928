<div class="container">
  <ng-container *ngIf="totalCounts$ | async as totalCounts">
    <span>{{ totalCounts }} Total Hits</span>
  </ng-container>
  <ng-container *ngIf="selectedThreads.size > 0">
    <label class="selected-left">{{ selectedThreads.size }} selected </label>
    <mat-icon
      class="material-symbols-outlined"
      *ngIf="selectedThreads.size > 99"
      >warning</mat-icon
    >
    <label class="selected-right"></label>
  </ng-container>
  <app-bulk-action-menu
    (actionSelected)="onActionSelected($event)"
    *ngIf="showBulkActionMenu"
    [gridApi]="gridApi!"
    [flagsEnabled]="flagsEnabled"
    [selectedThreats]="selectedThreads"
    [showAddToTopThreats]="showAddToTopThreats"
    [disabled]="selectedThreads.size === 0"
  ></app-bulk-action-menu>
  <label class="selected-right"></label>
  <section *ngIf="contentType === 'TRADEMARK'">
    <app-insights-filter
      [gridApi]="gridApi!"
      [selectedHeatMapFilterChips]="selectedHeatMapFilterChips"
      [ownedBrandsOnly]="ownedBrandsOnly"
    ></app-insights-filter>
    <app-risk-per-market-table-filter
      [gridApi]="gridApi!"
    ></app-risk-per-market-table-filter>
  </section>
</div>
