import { Component, Input, OnInit } from '@angular/core';
import { SummaryGraphicSelect } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-summary-graphic-select',
  templateUrl: './summary-graphic-select.component.html',
  styleUrls: ['./summary-graphic-select.component.scss'],
})
export class SummaryGraphicSelectComponent implements OnInit {
  @Input() data!: SummaryGraphicSelect;
  @Input() disableSubsection!: boolean;
  @Input() customize = false;

  constructor() {}

  ngOnInit(): void {}

  allSelected(): boolean {
    return !!(
      this.data.coexistencePerMarket &&
      this.data.riskPerMarket &&
      this.data.topThreats &&
      this.data.totalHits
    );
  }

  noneSelected(): boolean {
    return (
      !this.data.coexistencePerMarket &&
      !this.data.riskPerMarket &&
      !this.data.topThreats &&
      !this.data.totalHits
    );
  }

  toggle(event: any): void {
    this.data.coexistencePerMarket = event.checked;
    this.data.riskPerMarket = event.checked;
    this.data.topThreats = event.checked;
    this.data.totalHits = event.checked;
  }
}
