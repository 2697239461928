import { Injectable } from '@angular/core';

/* istanbul ignore next */
@Injectable()
export class FilterParams {
  static readonly textFilterParams = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true,
    buttons: ['clear', 'apply'],
    closeOnApply: true,
  };

  static readonly setFilterParams = {
    buttons: ['clear', 'apply'],
    suppressMiniFilter: true,
    closeOnApply: true,
  };

  static readonly dateFilterParams = {
    buttons: ['clear', 'apply'],
    suppressMiniFilter: true,
    closeOnApply: true,
    suppressAndOrCondition: true,
    filterOptions: ['equals', 'inRange', 'lessThan', 'greaterThan'],
  };
}
