import { Component, Input } from '@angular/core';
import { UserService } from 'src/app/security/services/user.service';

@Component({
  selector: 'app-choose-product',
  templateUrl: './choose-product.component.html',
  styleUrls: ['./choose-product.component.scss'],
})
export class ChooseProductComponent {
  @Input() customOverlayClass = 'default-overlay-class';

  constructor(public userService: UserService) {}
}
