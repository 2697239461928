<div class="title" mat-dialog-title>Leave page?</div>

<mat-dialog-content>
  <div class="content">
    Are you sure you want to leave this page?<br />
    Your package will not be saved.
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-stroked-button
    color="primary"
    class="cdx-but-md"
    mat-dialog-close
  >
    Stay on page
  </button>
  <button
    mat-flat-button
    color="primary"
    class="cdx-but-md"
    mat-dialog-close="LEAVE_PAGE"
  >
    Leave page
  </button>
</mat-dialog-actions>
