import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FeatureLevel } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-graphic-select',
  templateUrl: './graphic-select.component.html',
  styleUrls: ['./graphic-select.component.scss'],
})
export class GraphicSelectComponent {
  @Input() value = false;
  @Input() label = '';
  @Input() risk?: string;
  @Input() graphImageName = '';
  @Input() featureLevel!: FeatureLevel;
  @Input() disableSubsection!: boolean;
  @Output() valueChange = new EventEmitter<boolean>();

  toggle(value: boolean): void {
    this.valueChange.emit(!value);
  }

  get isStandard(): boolean {
    return !!this.featureLevel && this.featureLevel !== 'EXPERT';
  }
}
