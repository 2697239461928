import { Component, ViewEncapsulation } from '@angular/core';
import { ThreatsService } from '@compumark/bla-backend-client';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-save-freetext-renderer',
  templateUrl: './save-freetext-renderer.component.html',
  styleUrls: ['./save-freetext-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SaveFreetextRendererComponent {
  freeText?: string;
  threatId?: string;
  modifiedString?: string;
  updateMessage?: string;
  freeTextData?: string;

  agInit(params: ICellRendererParams): void {
    this.threatId = params.data.id;
    this.freeTextData = params.data.freeText;
  }

  constructor(
    private threatService: ThreatsService,
    private route: ActivatedRoute,
  ) {}

  saveFreeText(event: any): void {
    this.freeText = event.target.value;
    this.saveData(this.threatId, this.resultsDeliveryId, event.target.value);
  }
  saveData(
    threatId: string | undefined,
    resultsDeliveryId: string,
    freeText: any,
  ): void {
    this.modifiedString = String(freeText);
    if (
      threatId != null &&
      this.modifiedString != null &&
      this.modifiedString !== ''
    ) {
      this.threatService
        .updateThreatFreeText(threatId, resultsDeliveryId, freeText)
        .subscribe({
          next: () => this.handleSuccess(),
          error: () => this.handleError(),
        });
    } else {
      this.handleError();
    }
  }
  public handleSuccess(): void {
    this.updateMessage = 'Update successful';
  }
  public handleError(): void {
    this.updateMessage = 'Update failed';
  }
  get resultsDeliveryId(): string {
    return this.route.snapshot.params.resultsDeliveryId;
  }
}
