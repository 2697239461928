<div class="split-view">
  <mat-tab-group animationDuration="1500ms" class="split-view-tab-group">
    <mat-tab label="Full Text">
      <div class="trademark-name">
        <span>{{
          splitViewData?.threat?.trademarkName || splitViewData?.threat?.name
        }}</span>
      </div>
      <div class="content-view">
        <ng-container
          *ngIf="contentType === 'DOMAIN_NAMES' && threat; else normal_fulltext"
        >
          <app-domain-names-fulltext-view
            [threat]="threat"
            [resultsDeliveryId]="resultsDeliveryId"
          ></app-domain-names-fulltext-view>
        </ng-container>

        <ng-template #normal_fulltext>
          <ng-container *ngIf="data$ | async as data; else load_spinner">
            <div [innerHTML]="data | safeHtml" class="full-text-content"></div>
          </ng-container>
        </ng-template>
      </div>
    </mat-tab>

    <mat-tab label="Goods and Services" *ngIf="contentType === 'TRADEMARK'">
      <div class="trademark-name">
        <span>{{ splitViewData?.threat?.trademarkName }}</span>
      </div>
      <div class="content-view" *ngIf="!loadingGoods; else load_spinner">
        <div *ngFor="let pt of goodsAndServicesData" class="full-text-content">
          <span
            ><label
              >Class
              {{ pt.intClass }}
              :
            </label>
          </span>

          <span
            *ngIf="tableSettingsService.highlightOn; else plainText"
            class="highlights"
            [innerHTML]="pt.descriptionHighlighted"
          >
          </span>
          <ng-template #plainText>
            <span>
              {{ pt.description }}
            </span>
          </ng-template>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <ng-template #load_spinner>
    <mat-spinner class="spinner-view"></mat-spinner>
  </ng-template>

  <div class="arrow-buttons">
    <button
      mat-icon-button
      (click)="navigate.emit('backward')"
      aria-label="Go backward"
      [disabled]="!splitViewData?.hasPrevious"
    >
      <mat-icon>arrow_left</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="navigate.emit('forward')"
      aria-label="Go forward"
      [disabled]="!splitViewData?.hasNext"
    >
      <mat-icon>arrow_right</mat-icon>
    </button>
  </div>
</div>
