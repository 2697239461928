import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  Currency,
  PriceOverview,
  PricingService,
} from '@compumark/bla-backend-client';
import { GlasspaneService } from '@compumark/brand-context-components';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { PriceOverviewDialogComponent } from '../components/price-overview/price-overview-dialog.component';

/* istanbul ignore next */
@Injectable()
export class PriceOverviewOpeningService {
  constructor(
    public dialog: MatDialog,
    private glasspaneService: GlasspaneService,
    public pricing: PricingService,
  ) {}

  showPriceOverview(currency: Currency): void {
    this.handleResult(this.pricing.getPriceOverview(currency));
  }

  private handleResult(priceOverview: Observable<PriceOverview>): void {
    this.glasspaneService.showGlasspane();

    priceOverview
      .pipe(
        first(),
        map((price) => price),
        filter((price) => !!price),
      )
      .subscribe((price) => {
        this.glasspaneService.hideGlasspane();
        this.showDialog(price);
      });
  }

  showDialog(priceOverview: PriceOverview): void {
    this.dialog
      .open(PriceOverviewDialogComponent, {
        data: priceOverview,
        autoFocus: false,
        width: '800px',
        height: '660px',
      })
      .afterClosed()
      .pipe(first())
      .subscribe();
  }
}
