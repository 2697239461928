<div class="input">
  <div
    class="label"
    *ngIf="!!data.require || (!customize && data.include) || !customize"
    [ngClass]="{ 'report-editor-disabled-div': disableSubsection }"
  >
    {{ data.label }}
  </div>

  <div>
    <div
      [ngClass]="{
        'report-editor-row-content': !!data.require ? false : customize,
        'report-editor-highlight-div':
          customize && !disableSubsection && data.include
      }"
    >
      <div
        [ngClass]="{
          left: customize,
          'report-editor-disabled-div':
            !(data.require || data.include) && !disableSubsection
        }"
        *ngIf="!!data.require || (!customize && data.include) || customize"
      >
        <mat-form-field
          *ngIf="customize"
          appearance="outline"
          class="custom-label cdx-input-default"
        >
          <input
            matInput
            #input
            [(ngModel)]="data.label"
            [disabled]="disableSubsection"
          />
        </mat-form-field>

        <mat-form-field
          class="input-field"
          appearance="outline"
          class="cdx-input-default"
        >
          <input
            matInput
            #input
            [(ngModel)]="data.value"
            [disabled]="disableSubsection"
          />
        </mat-form-field>
      </div>
      <div
        [ngClass]="{
          right: customize,
          'report-editor-disabled-div': disableSubsection
        }"
        class="customize-toggle"
        *ngIf="!data.require && customize"
      >
        <mat-slide-toggle
          role="button"
          color="primary"
          checked="data.include"
          [(ngModel)]="data.include"
        ></mat-slide-toggle>
      </div>
    </div>
  </div>
</div>
