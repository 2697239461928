import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Applicant, OptimizedOwner } from '@compumark/bla-backend-client';
import {
  LitigationData,
  LitigationDataService,
} from '@compumark/brand-context-components-backend-client';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { OwnerFilterService } from '../services/owner-filter.service';
import { WIDGETS } from 'src/app/widgets/widget/widget.component';

@Component({
  selector: 'app-litigations-widget',
  templateUrl: './litigations-widget.component.html',
  styleUrls: ['./litigations-widget.component.scss'],
})
export class LitigationsWidgetComponent implements OnDestroy {
  @Output() removeEvent = new EventEmitter<string>();
  litigation$: Subject<LitigationData> = new BehaviorSubject<LitigationData>(
    {},
  );
  showLoadingIndicator$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  selectedOwners: Observable<OptimizedOwner[] | undefined> | undefined;

  constructor(
    private litigationDataService: LitigationDataService,
    ownerFilterService: OwnerFilterService,
  ) {
    this.selectedOwners = ownerFilterService.owners();
    this.selectedOwners.subscribe((data) => {
      this.filter(data!);
    });
  }
  ngOnDestroy(): void {
    this.selectedOwners = undefined;
  }

  filter(owners: OptimizedOwner[]): void {
    if (this.selectedOwners) {
      this.showLoadingIndicator$.next(true);
      const applicants: Applicant[] = owners?.map(
        (owner) =>
          ({
            optimizedApplicant: {
              normName: owner.optimizedName,
              countryCode: owner.countryCode,
            },
          } as Applicant),
      );

      this.litigationDataService
        .getLitigationData({
          parentId: '',
          trademarkName: '',
          applicants,
        })
        .subscribe((litigation) => {
          this.litigation$.next(litigation);
          this.showLoadingIndicator$.next(false);
        });
    }
  }

  remove(): void {
    this.removeEvent.emit(WIDGETS.OWNER_LITIGATIONS);
  }
}
