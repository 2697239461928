import { Injectable } from '@angular/core';
import {
  allTableSettings,
  TableSetting,
} from '../components/table-settings/table-settings.component';

@Injectable({
  providedIn: 'root',
})
export class TableSettingsService {
  rowHeight = 40;
  textLines = 1;
  hasModified = false;
  highlightOn = false;
  splitViewEnabled = false;

  applyTableSetting(event: TableSetting[]): void {
    if (event[0]?.name === allTableSettings[0].title) {
      switch (event[0].value) {
        case allTableSettings[0].subsection[1].value:
          this.rowHeight = 51;
          this.textLines = 2;
          break;

        case allTableSettings[0].subsection[2].value:
          this.rowHeight = 66;
          this.textLines = 3;
          break;

        default:
          this.rowHeight = 35;
          this.textLines = 1;
          break;
      }
    }
    if (event[1]?.name === allTableSettings[1].title) {
      this.splitViewEnabled = event[1].value === 'Split view';
    }
    if (event[2]?.name === allTableSettings[2].title) {
      switch (event[2].value) {
        case allTableSettings[2].subsection[1].value:
          this.highlightOn = true;
          break;

        default:
          this.highlightOn = false;
          break;
      }
    }
  }
}
