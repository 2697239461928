<div class="row unselectable">
  <div class="info">
    <mat-icon>check</mat-icon>
    <span class="label">Citation name</span>
  </div>
  <div class="info">
    <mat-icon>check</mat-icon>
    <span class="label">Application number</span>
  </div>
  <div class="info">
    <mat-icon>check</mat-icon>
    <span class="label">Registration numbers</span>
  </div>
  <div class="info">
    <mat-icon>check</mat-icon>
    <span class="label">Application date</span>
  </div>
  <div class="info">
    <mat-icon>check</mat-icon>
    <span class="label">Registration date</span>
  </div>
</div>
<div class="row unselectable">
  <div class="info">
    <mat-icon>check</mat-icon>
    <span class="label">Class(es)</span>
  </div>
  <div class="info">
    <mat-icon>check</mat-icon>
    <span class="label">Goods and Services</span>
  </div>
  <div class="info">
    <mat-icon>check</mat-icon>
    <span class="label">Market </span>
  </div>
  <div class="info">
    <mat-icon>check</mat-icon>
    <span class="label">Status </span>
  </div>
  <div class="info">
    <mat-icon>check</mat-icon>
    <span class="label">Owner Information </span>
  </div>
</div>
<div class="row">
  <div class="info selectable">
    <mat-checkbox color="primary" [(ngModel)]="data.riskAssesment">
      Risk assesment
    </mat-checkbox>
  </div>
  <div class="info selectable nonUseGracePeriod">
    <ng-container *ngIf="isExpert">
      <mat-checkbox color="primary" [(ngModel)]="data.nonUseGracePeriod">
        Non-use grace period information</mat-checkbox
      >
    </ng-container>
  </div>
  <div class="info selectable">
    <ng-container *ngIf="isExpert">
      <mat-checkbox color="primary" [(ngModel)]="data.reputation">
        Reputation</mat-checkbox
      >
    </ng-container>
  </div>
  <div class="info selectable">
    <ng-container *ngIf="isExpert">
      <mat-checkbox color="primary" [(ngModel)]="data.includesTtab">
        TTAB</mat-checkbox
      >
    </ng-container>
  </div>
  <div class="info selectable">
    <ng-container *ngIf="isExpert">
      <mat-checkbox color="primary" [(ngModel)]="data.refusalInformation">
        US Section 2(d)
      </mat-checkbox>
    </ng-container>
  </div>
</div>
<div class="row">
  <div class="info selectable">
    <ng-container *ngIf="isExpert">
      <mat-checkbox color="primary" [(ngModel)]="data.inUseCheck">
        In-Use Check</mat-checkbox
      >
    </ng-container>
  </div>
  <div class="info selectable">
    <ng-container *ngIf="isExpert">
      <mat-checkbox color="primary" [(ngModel)]="data.fullGoodsCaseLawData">
        Full goods case law data</mat-checkbox
      >
    </ng-container>
    <div class="info selectable">
      <mat-checkbox color="primary" [(ngModel)]="data.flags">
        Flags</mat-checkbox
      >
    </div>
    <div class="info selectable">
      <mat-checkbox color="primary" [(ngModel)]="data.comments">
        Comments</mat-checkbox
      >
    </div>
    <div class="info selectable">
      <mat-checkbox color="primary" [(ngModel)]="data.verbalSimilarity">
        Verbal Similarity</mat-checkbox
      >
    </div>
    <div class="info selectable">
      <mat-checkbox color="primary" [(ngModel)]="data.goodsSimilarity">
        Goods Similarity</mat-checkbox
      >
    </div>
  </div>
</div>
