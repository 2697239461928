import {
  ColumnApi,
  GridApi,
  GridOptions,
  RowHeightParams,
} from '@ag-grid-community/core';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import {
  DocumentStatus,
  ReportStatus,
  Role,
  User,
} from '@compumark/bla-backend-client';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, interval } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MaterialLoadingCellRendererComponent } from 'src/app/data-grid/components/material-loading-cell-renderer/material-loading-cell-renderer.component';
import { MaterialTooltipComponent } from 'src/app/data-grid/components/material-tooltip/material-tooltip.component';
import { environment } from 'src/environments/environment';

import { CustomTextFilterComponent } from '../../../custom-text-filter/customTextFilter.component';
import { HistoryDatasource } from '../../services/history.datasource';
import { DownloadReportComponent } from '../download-report/download-report.component';
import { GoToSummaryRendererComponent } from '../go-to-summary-renderer/go-to-summary-renderer.component';
import { UserService } from 'src/app/security/services/user.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnDestroy, OnInit, OnChanges {
  gridApi!: GridApi;
  columnApi!: ColumnApi;
  reportsStatuses: DocumentStatus[] = [];
  @Input()
  packageColumn: 'PACKAGE_NAME' | 'YOUR_PRICE' = 'PACKAGE_NAME';
  @Input()
  displayFooter = true;

  @Input() packageId!: string;

  readonly dateFilterParams = {
    buttons: ['clear', 'apply'],
    suppressMiniFilter: true,
    closeOnApply: true,
    suppressAndOrCondition: true,
    filterOptions: ['equals', 'inRange', 'lessThan', 'greaterThan'],
  };

  readonly gridOptions: GridOptions = {
    getRowNodeId: (t) => t.id,
    getRowHeight: this.getRowHeight,
    rowModelType: 'serverSide',
    serverSideStoreType: 'partial',
    blockLoadDebounceMillis: 1000,
    loadingCellRendererFramework: MaterialLoadingCellRendererComponent,
    suppressHorizontalScroll: true,
    suppressMovableColumns: true,
    suppressCellSelection: true,
    suppressRowClickSelection: true,
    overlayLoadingTemplate: '<span></span>',
    columnDefs: [
      {
        headerName: 'Date',
        field: 'date',
        filter: 'agDateColumnFilter',
        filterParams: this.dateFilterParams,
        headerTooltip: 'Date',
        tooltipComponent: 'materialTooltip',
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Candidate',
        field: 'candidate',
        filter: 'textColumnFilterWithoutOptions',
        headerTooltip: 'Candidate',
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => params.data.candidate,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Product',
        field: 'type',
        headerTooltip: 'Product',
        cellRenderer: (params: any) => this.getProductName(params),
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => this.getProductName(params),
        menuTabs: [],
      },
      {
        headerName: 'Goods and Services',
        field: 'goodsAndServices',
        headerTooltip: 'Goods and Services',
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => params.data.goodsAndServices,
        menuTabs: [],
      },
      {
        headerName: 'Markets',
        field: 'markets',
        headerTooltip: 'Markets',
        cellRenderer: (params: any) => this.getMarkets(params),
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => this.getMarkets(params),
        menuTabs: [],
      },
      {
        headerName: 'Competitors',
        field: 'competitors',
        headerTooltip: 'Competitors',
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => params.data.competitors,
        menuTabs: [],
      },
      {
        colId: 'yourCost',
        headerName: 'Your Cost',
        field: 'pricePackageCost',
        headerTooltip: 'Your Cost',
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => params.data.pricePackageCost,
        hide: true,
        menuTabs: [],
        width: 120,
      },
      {
        colId: 'packageName',
        headerName: 'Search Package',
        field: 'pricePackageName',
        headerTooltip: 'Search Package',
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => params.data.pricePackageName,
        hide: true,
        menuTabs: [],
        width: 150,
      },
      {
        headerName: 'Delivery expires',
        field: 'expiryDate',
        headerTooltip: 'Delivery expires',
        tooltipComponent: 'materialTooltip',
        cellRenderer: (params: any) => {
          return params.data.status === 'COMPLETED'
            ? params.data.expiryDate
            : '';
        },
        menuTabs: [],
      },
      {
        headerName: 'User',
        field: 'createdByFullName',
        headerTooltip: 'User',
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => params.data.createdByFullName,
        menuTabs: [],
      },
      {
        headerName: 'Download Report',
        field: 'report',
        cellRenderer: 'downloadReport',
        headerTooltip: 'Download Report',
        tooltipComponent: 'materialTooltip',
        width: 330,
        menuTabs: [],
        hide: !environment.features.export,
      },
      {
        headerName: '',
        field: 'id',
        cellRenderer: 'goToSummaryRenderer',
        menuTabs: [],
      },
    ],

    frameworkComponents: {
      goToSummaryRenderer: GoToSummaryRendererComponent,
      materialTooltip: MaterialTooltipComponent,
      downloadReport: DownloadReportComponent,
      textColumnFilterWithoutOptions: CustomTextFilterComponent,
    },

    onGridReady: (event) => {
      event.api.setServerSideDatasource(
        this.historyDatasource.createFor(this.packageId),
      );

      this.gridApi = event.api;
      this.columnApi = event.columnApi;

      this.userService.getCurrentUserObservable().subscribe((u: User) => {
        const hasInboxRole =
          u.roles?.find((r) => r === Role.MEMBER_INBOX) !== undefined;
        this.columnApi.setColumnVisible('createdByFullName', hasInboxRole);
      });
      this.columnApi.setColumnVisible(
        'yourCost',
        this.packageColumn === 'YOUR_PRICE',
      );
      this.columnApi.setColumnVisible(
        'packageName',
        this.packageColumn === 'PACKAGE_NAME',
      );

      event.api.sizeColumnsToFit();

      fromEvent(window, 'resize')
        .pipe(untilDestroyed(this), debounceTime(250))
        .subscribe(() => {
          event.api.sizeColumnsToFit();
        });
    },

    onModelUpdated: (event) => {
      this.reportsStatuses = [];
      event.api.forEachNode((node) => {
        node.data?.reportStatuses.forEach((reportStatus: ReportStatus) => {
          this.reportsStatuses.push(reportStatus.documentStatus!);
        });
      });
    },
  };

  constructor(
    private historyDatasource: HistoryDatasource,
    private userService: UserService,
  ) {}
  ngOnChanges(): void {
    if (this.gridApi) {
      this.gridApi.setServerSideDatasource(
        this.historyDatasource.createFor(this.packageId),
      );
      this.gridApi.refreshServerSideStore({ purge: true });
    }
  }

  ngOnInit(): void {
    interval(10000).subscribe(() => {
      if (this.reportsStatuses.includes(DocumentStatus.ANALYZING)) {
        this.gridApi.refreshServerSideStore({ purge: false });
      }
    });
  }

  getRowHeight(params: RowHeightParams): number | undefined | null {
    return params.data?.reportStatuses.length === 2 ? 70 : 35;
  }

  getProductName(params: any): string {
    switch (params.data.type) {
      case 'BRAND_LANDSCAPE_ANALYZER': {
        return 'Brand Landscape Analyzer';
      }
      case 'OWNER_ANALYZER': {
        return 'Owner Analyzer';
      }
      case 'IMAGE': {
        return 'Image Search';
      }
    }
    return '';
  }

  getMarkets(params: any): string {
    switch (params.data.type) {
      case 'BRAND_LANDSCAPE_ANALYZER': {
        return params.data.markets;
      }
      case 'OWNER_ANALYZER': {
        return 'Worldwide';
      }
      case 'IMAGE': {
        return params.data.markets;
      }
    }
    return '';
  }

  ngOnDestroy(): void {}
}
