import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { TableSettingsService } from 'src/app/threats-tables/services/table-settings.service';

@Component({
  selector: 'app-company-name-goods-renderer',
  templateUrl: './company-name-goods-renderer.component.html',
  styleUrls: ['./company-name-goods-renderer.component.scss'],
})
export class CompanyNameGoodsRendererComponent
  implements ICellRendererAngularComp {
  primarySicCode?: string;
  primarySicDescription?: string;

  constructor(private tableSettingsService: TableSettingsService) {}

  agInit(params: ICellRendererParams<any, any>): void {
    this.primarySicCode = params.data.primarySicCode;
    this.primarySicDescription = params.data.primarySicDescription;
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    this.primarySicCode = params.data.primarySicCode;
    this.primarySicDescription = params.data.primarySicDescription;
    return true;
  }

  textClassSize(): string {
    return (
      'goods-text-base goods-text-' +
      this.tableSettingsService.textLines.toString()
    );
  }

  renderCompanyNamesText(): string {
    return !!this.primarySicCode && !!this.primarySicDescription
      ? '<strong>Primary SIC Code:' +
          this.primarySicCode +
          '</strong> (' +
          this.primarySicDescription +
          ')'
      : '';
  }
}
