<ng-container
  *ngIf="{
    litigation: litigation$ | async,
    showLoadingIndicator: showLoadingIndicator$ | async
  } as data"
>
  <app-widget-header
    [title]="'Litigation | Litigiousness of Owner'"
    tooltip="
      Risk associated with the owner's activity as a plaintiff.
    "
    (removeEvent)="remove()"
  ></app-widget-header>

  <div class="body" *ngIf="!data.showLoadingIndicator">
    <lib-risk-measure
      [scaleAndValue]="data.litigation!.litigiousness!.litigiousnessScale!"
      noDataLabel="No cases found"
      class="litigiousness-of-owner"
    >
      <lib-case-action-graph
        *ngIf="data.litigation!.litigiousness!.numberOfCases! > 0"
        [litigiousness]="data.litigation!.litigiousness"
        [selectable]="false"
        [height]="'328px'"
      ></lib-case-action-graph>
    </lib-risk-measure>
    <a
      class="explore-results-link"
      [routerLink]="'../deep-dive/litigation/litigiousness-of-owner'"
      *ngIf="data.litigation!.litigiousness!.numberOfCases! > 0"
      queryParamsHandling="preserve"
    >
      Explore results
    </a>
  </div>
  <lib-loading-indicator
    *ngIf="data.showLoadingIndicator"
    [showLoadingIndicator]="true"
    [type]="'GRAPH'"
  ></lib-loading-indicator>
</ng-container>
