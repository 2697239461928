import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PricePackage } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-package-select',
  templateUrl: './package-select.component.html',
  styleUrls: ['./package-select.component.scss'],
})
export class PackageSelectComponent implements OnInit {
  @Input()
  packages?: PricePackage[];
  packageControl = new UntypedFormControl('');
  placeholderMessage = 'Search Package (optional)';

  filteredPackages?: PricePackage[];

  @Output() selectPackage = new EventEmitter<PricePackage | null>();

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.filteredPackages = this.packages?.filter(
      (p) => !!p && p.numberOfCandidatesRemaining! > 0!,
    );

    this.packageControl.valueChanges.subscribe((sp) => {
      if (sp === 'createPackage') {
        this.router.navigate(['/account/packages/create']);
      } else if (sp === 'packageOptional') {
        this.selectPackage.emit(null);
      } else if (sp === 'None') {
        this.packageControl.setValue(null);
      } else {
        this.selectPackage.emit(this.packages?.find((p) => p.id === sp));
      }
    });

    if (this.route.snapshot.queryParams.packageId) {
      this.packageControl.setValue(this.route.snapshot.queryParams.packageId);
    }
  }
}
