import { Component, Input, OnInit } from '@angular/core';
import {
  FeatureLevel,
  ResultsGraphicSelect,
} from '@compumark/bla-backend-client';

@Component({
  selector: 'app-result-graphic-select',
  templateUrl: './result-graphic-select.component.html',
  styleUrls: ['./result-graphic-select.component.scss'],
})
export class ResultGraphicSelectComponent implements OnInit {
  @Input() data!: ResultsGraphicSelect;
  @Input() featureLevel!: FeatureLevel;
  @Input() customize!: boolean;
  allChecked: boolean | undefined = false;

  constructor() {}

  selectAll(checked: boolean): void {
    this.allChecked = checked;
    this.data.verbalSimilarity = checked;
    this.data.verbalCaseLaw = checked;
    this.data.pocaScore = checked;
    this.data.riskIndication = checked;
    this.data.goodsCaseLaw = checked;
    this.data.image = checked;
    this.data.geographicPresence = checked;
    this.data.competitor = checked;
    this.data.sizeOfTotalPortfolio = checked;
    this.data.globality = checked;
    this.data.directCaseHistory = checked;
    this.data.reputationFame = checked;
    this.data.litigiousnessOfOwner = checked;
    this.data.internetPresence = checked;
  }

  isAllChecked(): void {
    this.allChecked =
      !!this.data &&
      this.data!.verbalSimilarity &&
      this.data!.verbalCaseLaw &&
      this.data!.pocaScore &&
      this.data!.riskIndication &&
      this.data!.image &&
      this.data!.geographicPresence &&
      this.data!.competitor &&
      this.data!.sizeOfTotalPortfolio &&
      this.data!.globality &&
      this.data!.directCaseHistory &&
      this.data!.reputationFame &&
      this.data!.litigiousnessOfOwner &&
      this.data!.internetPresence &&
      this.isGoodsCaseLawChecked;
  }

  get isGoodsCaseLawChecked(): boolean {
    if (!this.isExpert) {
      this.data.goodsCaseLaw = false;
      return true;
    }
    return this.data!.goodsCaseLaw!;
  }

  ngOnInit(): void {
    this.isAllChecked();
  }

  get isExpert(): boolean {
    return this.featureLevel === 'EXPERT';
  }
}
