import { GridApi } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PreferenceStorageService } from 'src/app/util/services/preference-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ThreatSelectionService {
  public selectedThreats: Set<string> = new Set();
  contentType = this.route.snapshot.data.contentType;

  constructor(
    private preferenceStorageService: PreferenceStorageService,
    private route: ActivatedRoute,
  ) {}
  public loadPreferences(selectedRowkey: string): void {
    const preference = this.preferenceStorageService.getPreference(
      selectedRowkey,
    );
    this.selectedThreats = new Set(Array.isArray(preference) ? preference : []);
  }

  get resultsDeliveryId(): string {
    return this.route.snapshot.params.resultsDeliveryId;
  }
  private getSelectedNodes(gridapi: GridApi): string[] {
    const selectedNodes: string[] = [];
    gridapi.getSelectedNodes().map((selectedNode) => {
      if (selectedNode.id !== undefined) {
        selectedNodes.push(selectedNode.id);
      }
    });
    return selectedNodes;
  }

  public triggerSelectionChanged(selectedRowkey: string, gridApi: any): void {
    if (!gridApi) {
      return;
    }
    const currentSelectedThreats: string[] = this.getSelectedNodes(gridApi);
    this.selectedThreats = new Set([
      ...this.selectedThreats,
      ...currentSelectedThreats,
    ]);
    this.preferenceStorageService.setPreference(selectedRowkey, [
      ...this.selectedThreats.keys(),
    ]);
  }
}
