<button
  mat-button
  [matMenuTriggerFor]="
    resultsDelivery.type! === 'BRAND_LANDSCAPE_ANALYZER'
      ? blaMenu
      : ownerAnalyzerMenu
  "
  (mouseenter)="updateTemplates()"
  class="cdx-but-menu export-button"
  role="button"
>
  <mat-icon fontSet="material-symbols-outlined"> file_download </mat-icon>
  Export
</button>

<mat-menu #blaMenu="matMenu" role="menu">
  <ng-container *ngIf="canExport(); else message">
    <button (click)="showDialog(undefined)" mat-menu-item>Report Editor</button>
    <span class="dilimeter"></span>
    <ng-container *ngIf="templates && templates?.length !== 0">
      <span class="section-label">EXPORT TEMPLATES</span>
      <div class="template" *ngFor="let template of templates" mat-menu-item>
        <div class="item-label" (click)="showDialog(template)">
          {{ template.name }}
        </div>

        <mat-icon
          (click)="openExportConfirmationPopup($event, template)"
          [appCustomTooltip]="'Delete template'"
          class="material-symbols-outlined"
          >delete</mat-icon
        >
      </div>

      <span class="dilimeter"></span>
    </ng-container>

    <span class="section-label">QUICK EXPORT</span>
    <button (click)="export('TOP_THREATS')" mat-menu-item>
      Top Citations (.xlsx)
    </button>
    <button (click)="export('ALL_THREATS')" mat-menu-item>
      All Citations (.xlsx)
    </button>
  </ng-container>
</mat-menu>

<mat-menu #ownerAnalyzerMenu="matMenu" role="menu">
  <ng-container *ngIf="canExport(); else message">
    <span class="section-label">QUICK EXPORT</span>
    <button (click)="export('ALL_THREATS')" mat-menu-item>
      Quick Export (.xlsx)
    </button>
  </ng-container>
</mat-menu>

<ng-template #message>
  <div class="export-limit">
    Export is limited to {{ getExportTopThreatsLimit() }} Top Citations. Please
    reduce your current list of {{ getTopThreatsCount() }}
    Top Citations to proceed with the export.
  </div>
</ng-template>
