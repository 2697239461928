import { Component, Input, OnInit } from '@angular/core';
import { Row } from '@compumark/bla-backend-client';
import Quill from 'quill';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
})
export class TextAreaComponent implements OnInit {
  @Input() data!: Row;
  @Input() customize = false;
  @Input() disableSubsection = false;

  quillConfiguration = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    },
  };

  onSelectionChanged = (event: any) => {
    if (event.oldRange == null) {
      this.onFocus(event);
    }
    if (event.range == null) {
      this.onBlur(event);
    }
  };

  onFocus(event: any): void {
    event.editor.theme.modules.toolbar.container.style.display = 'block';
    event.editor.theme.options.container.style.borderRadius =
      '0rem 0rem 0.3rem 0.3rem';
  }

  onBlur(event: any): void {
    event.editor.theme.modules.toolbar.container.style.display = 'none';
    event.editor.theme.options.container.style.borderRadius = '0.3rem';
  }

  ngOnInit(): void {
    const bold = Quill.import('formats/bold');
    bold.tagName = 'B';
    Quill.register(bold, true);

    const italic = Quill.import('formats/italic');
    italic.tagName = 'i';
    Quill.register(italic, true);
  }
}
