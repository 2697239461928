import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-widget-header',
  templateUrl: './widget-header.component.html',
  styleUrls: ['./widget-header.component.scss'],
})
export class WidgetHeaderComponent {
  @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;
  @Input() title?: string;
  @Input() tooltip?: string;
  @Output() removeEvent = new EventEmitter<void>();
  isOpened = false;
  timedOutCloser?: any;
  targetMenuTrigger?: any;

  mouseEnter(trigger: any): void {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    trigger.openMenu();
    this.isOpened = true;
  }

  mouseLeave(trigger: any): void {
    this.timedOutCloser = setTimeout(() => {
      trigger.closeMenu();
      this.isOpened = false;
    }, 200);
  }

  remove(): void {
    this.removeEvent.emit();
  }
}
