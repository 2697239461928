<div
  class="label"
  *ngIf="!!data.label"
  [ngClass]="{
    'report-editor-disabled-div': disableSubsection,
    'padding-on-customize': customize
  }"
>
  {{ data.label }}
</div>
<ngx-dropzone
  [multiple]="false"
  (change)="onSelect($event)"
  accept="image/png,image/jpeg,image/gif"
  [maxFileSize]="500000"
  [ngClass]="{ errors: hasErrors }"
  [disabled]="disableSubsection"
>
  <ngx-dropzone-label>
    <div class="image"></div>
    <div class="drop-message">Drop your logo here, or <span>browse</span></div>
    <div class="drop-supports">Supports JPG,PNG,GIF</div>
  </ngx-dropzone-label>

  <app-custom-dropzone-preview
    *ngIf="files.length > 0 && !this.imageLoading"
    [file]="files[0]"
    [value]="data.value"
    [removable]="true"
    (removed)="onRemove()"
    ngProjectAs="ngx-dropzone-preview"
  >
    <ngx-dropzone-label>
      <div class="image"></div>
      <div class="drop-message">
        Drop your logo here, or <span>browse</span>
      </div>
      <div class="drop-supports">Supports JPG,PNG,GIF</div>
    </ngx-dropzone-label>
  </app-custom-dropzone-preview>

  <div class="upload-errors" *ngIf="hasErrors">
    <div class="file-error" *ngFor="let file of rejectedFiles">
      <container-element [ngSwitch]="file.reason">
        <span *ngSwitchCase="'type'"
          >The file {{ file.name }} could not be uploaded. Upload only files
          with the following extensions: png, jpg, gif.</span
        >
        <span *ngSwitchCase="'size'"
          >The file {{ file.name }} is too big. Please upload a file that is
          smaller than 0.5mb.</span
        >
        <span *ngSwitchDefault>Oops some error</span>
      </container-element>
    </div>
  </div>
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="this.imageLoading"
  ></mat-progress-bar>
</ngx-dropzone>
