import { Component, Input, OnChanges } from '@angular/core';
import { HIGHCHARTS_CDX_THEME } from '@cdx/highcharts';
import { TopThreatsPerCountry } from '@compumark/bla-backend-client';
import world from '@highcharts/map-collection/custom/world.geo.json';
import Highcharts, { Options } from 'highcharts/highmaps';

@Component({
  selector: 'app-geographic-map',
  templateUrl: './geographic-map.component.html',
  styleUrls: ['./geographic-map.component.scss'],
})
export class GeographicMapComponent implements OnChanges {
  readonly Highcharts: typeof Highcharts = Highcharts;
  @Input()
  topThreatsPerCountry: Array<TopThreatsPerCountry> = [];
  @Input()
  showTitle = true;
  @Input()
  customSize = false;
  @Input()
  width: number | undefined = 550;
  @Input()
  height: number | undefined = 300;

  options?: Options;

  ngOnChanges(): void {
    Highcharts.setOptions(HIGHCHARTS_CDX_THEME);

    this.options = {
      title: {
        text: undefined,
      },

      legend: {
        enabled: false,
      },
      colorAxis: {
        dataClasses: [
          {
            from: 1,
            to: 10,
            color: '#DFD6F2',
          },
          {
            from: 10,
            to: 50,
            color: '#B5A0E2',
          },
          {
            from: 50,
            to: 100,
            color: '#8A6AD0',
          },
          {
            from: 100,
            color: '#5E33BF',
          },
        ],
      },

      chart: {
        map: world,
        width: this.width,
        height: this.height,
        marginTop: -5,
        marginLeft: -5,
      },

      tooltip: {
        enabled: true,
      },

      plotOptions: {
        map: {
          allAreas: true,
          joinBy: 'iso-a2',
          keys: ['iso-a2', 'value', 'color'],

          states: {
            hover: {
              color: '#001750',
            },
          },
          tooltip: {
            headerFormat: '',
            pointFormat: '{point.name}: <strong>{point.value}</strong>',
          },
        },
      },

      series: [
        {
          type: 'map',
          data: this.topThreatsPerCountry.map((b) => [
            b.countryCode!,
            b.topThreats!,
          ]),
        },
      ] as Highcharts.SeriesOptionsType[],
    };
  }
}
