import { Component } from '@angular/core';
import { Role } from '@compumark/bla-backend-client';
import { UserService } from 'src/app/security/services/user.service';

@Component({
  selector: 'app-admin-link',
  templateUrl: './admin-link.component.html',
  styleUrls: ['./admin-link.component.scss'],
})
export class AdminLinkComponent {
  constructor(private service: UserService) {}

  isAdmin(): boolean {
    return (
      !!this.service.getCurrentUser() &&
      !!this.service.getCurrentUser().roles?.find((r) => r === Role.ADMIN)
    );
  }
}
