import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ExportTemplate,
  FeatureLevel,
  Row,
  Threat,
} from '@compumark/bla-backend-client';

import { PropertyProviderService } from '../../services/property-provider.service';

@Component({
  selector: 'app-dynamic-property',
  templateUrl: './dynamic-property.component.html',
})
export class DynamicPropertyComponent implements OnInit, OnChanges {
  portal!: ComponentPortal<any>;
  @Input() row!: Row;
  @Input() exportTemplate!: ExportTemplate;
  @Input() topThreats!: Threat[];
  @Input() resultsDeliveryId!: string;
  @Input() featureLevel!: FeatureLevel;
  @Input() customize = false;
  @Input() disableSubsection = false;
  ref: any;

  constructor(public propertyProviderService: PropertyProviderService) {}

  setInput(
    ref: any,
    data: any,
    exportTemplate: ExportTemplate,
    topThreats: Threat[],
    resultsDeliveryId: string,
    featureLevel: FeatureLevel,
    customize: boolean,
    disableSubsection: boolean,
  ): void {
    this.ref = ref;
    ref.instance.data = data;
    ref.instance.exportTemplate = exportTemplate;
    ref.instance.topThreats = topThreats;
    ref.instance.resultsDeliveryId = resultsDeliveryId;
    ref.instance.featureLevel = featureLevel;
    ref.instance.customize = customize;
    ref.instance.disableSubsection = disableSubsection;
  }

  ngOnInit(): void {
    this.portal = new ComponentPortal(
      this.propertyProviderService.getProperty(this.row),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.ref) {
      if (changes.customize) {
        this.ref.instance.customize = changes.customize?.currentValue;
      }
      if (changes.disableSubsection) {
        this.ref.instance.disableSubsection =
          changes.disableSubsection?.currentValue;
      }
    }
  }
}
