import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HIGHCHARTS_CDX_THEME } from '@cdx/highcharts';
import { AccountStatistics, FeatureLevel } from '@compumark/bla-backend-client';
import Highcharts from 'highcharts/highmaps';

@Component({
  selector: 'app-uncapped-account',
  templateUrl: './uncapped-account.component.html',
  styleUrls: ['./uncapped-account.component.scss'],
})
export class UncappedAccountComponent {
  readonly Highcharts = Highcharts;
  stats: AccountStatistics;

  constructor(route: ActivatedRoute) {
    Highcharts.setOptions(HIGHCHARTS_CDX_THEME);
    this.stats = route.snapshot.data.stats;
  }

  featureLevelDescription(): string {
    return this.stats.contract!.featureLevel === FeatureLevel.STANDARD
      ? 'Standard'
      : 'Expert';
  }
}
