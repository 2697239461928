import { AgGridModule } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import {
  BrandContextComponentsModule,
  FilterChipsModule,
  GlasspaneModule,
} from '@compumark/brand-context-components';

import { CustomTooltipModule } from '../custom-tooltip/custom-tooltip.module';
import { DataGridModule } from '../data-grid/data-grid.module';
import { FeatureFlagModule } from '../feature-flag/feature-flag.module';
import { AppFooterModule } from '../footer/footer.module';
import { ImageTooltipDirective } from '../image-tooltip/directives/image-tooltip.directive';
import { ResetTopThreatsModule } from '../reset-top-threats/reset-top-threats.module';
import { UtilModule } from '../util/util.module';

import { AllThreatsTabNavBarComponent } from './components/all-threats-tab-nav-bar/all-threats-tab-nav-bar.component';
import { ClusterRendererComponent } from './components/cluster-renderer/cluster-renderer.component';
import { ExpandAllHeaderComponent } from './components/expand-all-header/expand-all-header.component';
import { OwnerFilterComponent } from './components/owner-filter/owner-filter.component';
import { ContentThreatsTableComponent } from './components/content-threats-table/content-threats-table.component';
import { GoodsAndServicesRendererComponent } from './components/goods-and-services-renderer/goods-and-services-renderer.component';
import { RiskLevelRendererComponent } from './components/risk-level-renderer/risk-level-renderer.component';
import { ThreatNameRendererComponent } from './components/threat-name-renderer/threat-name-renderer.component';
import { TopThreatsComponent } from './components/top-threats/top-threats.component';
import { GoodsAndServicesDialogComponent } from './products-dialog/components/goods-and-services-dialog/goods-and-services-dialog.component';
import { GoodsAndServicesDialogOpeningService } from './products-dialog/services/goods-and-services-dialog-opening-service';
import { AllThreatsFilterChangeService } from './services/all-threats-filter-change-service';
import { AllThreatsDatasource } from './services/all-threats.datasource';
import { ClearableFilterChangeService } from './services/clearable-filter-change-service';
import { PharmaThreatsDatasource } from './services/pharma-threats.datasource';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { WidgetsModule } from '../widgets/widgets.module';
import { CustomFiltersPanelComponent } from './components/custom-filters-panel/custom-filters-panel.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FilterTemplatesComponent } from './components/filter-templates/filter-templates.component';
import { RiskNameRendererComponent } from './components/risk-name-renderer/risk-name-renderer.component';
import { InsightsFilterComponent } from './components/insights-filter/insights-filter.component';
import { RiskPerMarketTableFilterComponent } from './components/risk-per-market-table-filter/risk-per-market-table-filter.component';
import { AllThreatsWithBulkActionsComponent } from './bulk-actions/components/all-threats/all-threats.component';
import { ActionsRendererComponent } from './bulk-actions/components/actions-renderer/actions-renderer.component';
import { BulkActionMenuComponent } from './bulk-actions/components/bulk-action-menu/bulk-action-menu.component';
import { OwnerCellRendererComponent } from './components/owner-cell-renderer/owner-cell-renderer.component';
import { AddToTopThreatsBulkActionService } from './bulk-actions/services/add-to-top-threats-bulk-action.service';
import { TableSettingsComponent } from './components/table-settings/table-settings.component';
import { MatRadioModule } from '@angular/material/radio';
import { FullTextComponent } from './components/splitview/full-text/full-text.component';
import { CommonLawThreatsDatasource } from './services/common-law-threats.datasource';
import { ActionsComponent } from './bulk-actions/components/actions/actions.component';
import { ActionsFilterComponent } from './components/actions-filter/actions-filter.component';
import { DomainNamesFulltextViewComponent } from './components/splitview/domain-names-fulltext-view/domain-names-fulltext-view.component';

import { CompanyNameGoodsRendererComponent } from './components/goods-and-services-renderer/company-name-goods-renderer/company-name-goods-renderer/company-name-goods-renderer.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TileViewComponent } from './components/tile-view/tile-view.component';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { TileComponent } from './components/tile/tile.component';
import { ImageComponent } from './components/image/image.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MatDividerModule } from '@angular/material/divider';
import { FlagModule } from '../flag/flag.module';

ModuleRegistry.registerModules([
  MenuModule,
  SetFilterModule,
  RowGroupingModule,
  ServerSideRowModelModule,
  ClientSideRowModelModule,
  SideBarModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
]);

@NgModule({
  imports: [
    AgGridModule,
    AppFooterModule,
    BrandContextComponentsModule,
    FilterChipsModule,
    FormsModule,
    CommonModule,
    DataGridModule,
    MatBadgeModule,
    MatButtonModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    RouterModule,
    ResetTopThreatsModule,
    GlasspaneModule,
    UtilModule,
    FeatureFlagModule,
    CustomTooltipModule,
    WidgetsModule,
    MatRadioModule,
    MatButtonToggleModule,
    VirtualScrollerModule,
    MatCardModule,
    MatSliderModule,
    LazyLoadImageModule,
    MatDividerModule,
    FlagModule,
  ],
  declarations: [
    TopThreatsComponent,
    ContentThreatsTableComponent,
    AllThreatsWithBulkActionsComponent,
    RiskLevelRendererComponent,
    GoodsAndServicesRendererComponent,
    ActionsRendererComponent,
    OwnerFilterComponent,
    ClusterRendererComponent,
    RiskNameRendererComponent,
    ExpandAllHeaderComponent,
    ThreatNameRendererComponent,
    AllThreatsTabNavBarComponent,
    GoodsAndServicesDialogComponent,
    ImageTooltipDirective,
    CustomFiltersPanelComponent,
    InsightsFilterComponent,
    RiskPerMarketTableFilterComponent,
    FilterTemplatesComponent,
    BulkActionMenuComponent,
    OwnerCellRendererComponent,
    TableSettingsComponent,
    FullTextComponent,
    ActionsComponent,
    ActionsFilterComponent,
    DomainNamesFulltextViewComponent,
    CompanyNameGoodsRendererComponent,
    TileViewComponent,
    TileComponent,
    ImageComponent,
  ],
  exports: [
    TopThreatsComponent,
    AllThreatsWithBulkActionsComponent,
    AllThreatsTabNavBarComponent,
    ContentThreatsTableComponent,
    ImageTooltipDirective,
    DomainNamesFulltextViewComponent,
  ],
  providers: [
    AllThreatsDatasource,
    AllThreatsFilterChangeService,
    ClearableFilterChangeService,
    PharmaThreatsDatasource,
    CommonLawThreatsDatasource,
    GoodsAndServicesDialogOpeningService,
    AddToTopThreatsBulkActionService,
  ],
})
export class ThreatsTableModule {}
