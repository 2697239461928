<div class="goods-and-services-dialog">
  <div class="dialog-header" mat-dialog-title>
    <span>Goods and Services for {{ goodsAndServicesData.name }}</span>
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>

  <mat-dialog-content>
    <div
      *ngFor="let pt of goodsAndServicesData.goodsAndServicesText"
      class="class"
    >
      <span class="label">Class {{ pt.intClass }}: </span>

      <span
        *ngIf="tableSettingsService.highlightOn; else plainText"
        class="highlights"
        [innerHTML]="pt.descriptionHighlighted"
      >
      </span>
      <ng-template #plainText>
        <span>
          {{ pt.description }}
        </span>
      </ng-template>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      mat-stroked-button
      color="primary"
      class="cdx-but-lg"
      mat-dialog-close
    >
      Back
    </button>
  </mat-dialog-actions>
</div>
