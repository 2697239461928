import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CustomTooltipComponent } from './components/custom-tooltip.component';
import { CustomTooltipDirective } from './directives/custom-tooltip.directive';

@NgModule({
  declarations: [CustomTooltipDirective, CustomTooltipComponent],
  imports: [CommonModule, OverlayModule],
  exports: [CustomTooltipDirective],
})
export class CustomTooltipModule {}
