import { Component } from '@angular/core';

@Component({
  selector: 'app-owner-analyzer-access-denied',
  templateUrl: './owner-analyzer-access-denied.component.html',
  styleUrls: ['./owner-analyzer-access-denied.component.scss'],
})
export class OwnerAnalyzerAccessDeniedComponent {
  date = new Date();
}
