import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';

import { CustomTooltipComponent } from '../components/custom-tooltip.component';

@Directive({ selector: '[appCustomTooltip]' })
export class CustomTooltipDirective implements OnInit, OnDestroy {
  @Input('appCustomTooltip') value: string | TemplateRef<any> | undefined;
  @Input('appCustomTooltipType') type = 'COMMON';
  private overlayRef?: OverlayRef;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
  ) {}

  ngOnDestroy(): void {
    this.overlayRef!.detach();
  }

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          panelClass: 'arrow-up',
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetY: 0,
        },
        {
          panelClass: 'arrow-down',
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: 5,
        },
      ]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  /* istanbul ignore next */
  @HostListener('mouseenter')
  show(): void {
    if (!!this.value && !this.overlayRef?.hasAttached()) {
      const tooltipRef = this.overlayRef!.attach(
        new ComponentPortal(CustomTooltipComponent),
      );
      tooltipRef.instance.value = this.value;
      tooltipRef.instance.type = this.type;
    }
  }

  /* istanbul ignore next */
  @HostListener('mouseleave')
  hide(): void {
    this.overlayRef!.detach();
  }

  /* istanbul ignore next */
  @HostListener('click')
  toggleTooltip(): void {
    if (this.overlayRef?.hasAttached()) {
      this.hide();
    } else {
      this.show();
    }
  }
}
