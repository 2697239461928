import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { HIGHCHARTS_CDX_THEME } from '@cdx/highcharts';
import { Contract, Currency } from '@compumark/bla-backend-client';
import Highcharts, { Chart } from 'highcharts';
import { Options } from 'highcharts';

@Component({
  selector: 'app-subscription-value',
  templateUrl: './subscription-value.component.html',
  styleUrls: ['./subscription-value.component.scss'],
})
export class SubscriptionValueComponent implements OnInit {
  options?: Options;
  @Input() contract?: Contract;
  readonly Highcharts = Highcharts;

  constructor(private currency: CurrencyPipe) {}

  ngOnInit(): void {
    Highcharts.setOptions(HIGHCHARTS_CDX_THEME);
    this.options = {
      chart: {
        type: 'pie',
        height: '80%',
        style: {
          fontFamily: 'Open Sans',
        },
      },
      title: {
        verticalAlign: 'middle',
        text: this.currency.transform(
          this.contract?.remainingCap!,
          this.contract?.currency,
        ) as any,
        style: {
          fontWeight: '700',
          fontSize: '1rem',
          padding: '0.5rem',
        },
        y: 15,
      },
      subtitle: {
        text: 'Remaining',
        verticalAlign: 'middle',
        style: {
          fontSize: '0.75rem',
        },
        y: 25,
      },
      legend: { enabled: false },
      tooltip: { enabled: false },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
          innerSize: '60%',
          size: '100%',
        },
      },
      series: [
        {
          borderWidth: 0,
          showInLegend: false,
          type: 'pie',
          colors: ['#16AB03', '#E0E0E0'],
          data: [
            {
              y: this.contract?.remainingCap,
            },
            {
              y: this.contract?.totalCap! - this.contract?.remainingCap!,
            },
          ],
        },
      ],
    };
  }

  /* istanbul ignore next */
  forceReflow = (chart: Chart) => {
    setTimeout(() => chart.reflow());
  };

  getCurrencyFormat(price: string, currencyType: Currency): any {
    return this.currency.transform(price, currencyType);
  }
}
