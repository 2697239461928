import { AgGridModule } from '@ag-grid-community/angular';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { DataGridModule } from '../data-grid/data-grid.module';

import { AdminRoutingModule as AdminRoutingModule } from './admin-routing.module';
import { AdminLinkComponent as AdminLinkComponent } from './components/admin-link/admin-link.component';
import { AdminConsoleComponent as BrandLandscapeAdminComponent } from './components/brandlandscape-admin/brandlandscape-admin.component';
import { DeleteSearchComponent } from './components/delete-search/delete-search.component';
import { ExportAdminComponent as ExportAdminComponent } from './components/export-admin/export-admin.component';
import { GoToSummaryRendererComponent } from './components/go-to-summary-renderer/go-to-summary-renderer.component';
import { RerunRendererComponent } from './components/rerun-renderer/rerun-renderer.component';
import { BrandLandscapeAdminDatasource as AdminBrandLandscapeDatasource } from './services/brandlandscape-admin.datasource';
import { UserRolesAdminComponent } from './components/user-roles-admin/user-roles-admin.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';

ModuleRegistry.registerModules([ServerSideRowModelModule]);

@NgModule({
  imports: [
    AgGridModule,
    CommonModule,
    FormsModule,
    DataGridModule,
    AdminRoutingModule,
    MatMenuModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatSelectModule,
  ],
  declarations: [
    GoToSummaryRendererComponent,
    BrandLandscapeAdminComponent,
    AdminLinkComponent,
    RerunRendererComponent,
    ExportAdminComponent,
    DeleteSearchComponent,
    UserRolesAdminComponent,
  ],
  providers: [AdminBrandLandscapeDatasource],
  exports: [AdminLinkComponent],
})
export class AdminModule {}
