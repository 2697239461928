import { Component, ElementRef, ViewChild } from '@angular/core';
import { AgFilterComponent } from '@ag-grid-community/angular';
import {
  IAfterGuiAttachedParams,
  IDoesFilterPassParams,
  IFilterParams,
} from '@ag-grid-community/core';
import { GlasspaneService } from '@compumark/brand-context-components';

@Component({
  selector: 'app-goods-service-filter',
  templateUrl: './goods-service-filter.component.html',
  styleUrls: ['./goods-service-filter.component.scss'],
})
export class GoodsServiceFilterComponent implements AgFilterComponent {
  params!: IFilterParams;
  filterTextOne: string | null = '';
  filterTextTwo: string | null = '';
  chipText: string | null = '';
  conditionOne: string | null = 'CONTAINS';
  conditionTwo: string | null = 'CONTAINS';
  operator: 'AND' | 'OR' = 'AND';
  filterChipText: string | null = '';
  hidePopup?: () => void;

  @ViewChild('input1') inputElement1!: ElementRef;
  @ViewChild('input2') inputElement2!: ElementRef;

  constructor(private glasspaneService: GlasspaneService) {}

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  isFilterActive(): boolean {
    return this.filterTextOne !== '' || this.filterTextTwo !== '';
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const value = params.data[this.params.colDef.field!].toLowerCase();
    const match1 = this.applyCondition(
      value,
      this.filterTextOne,
      this.conditionOne,
    );

    const match2 = this.applyCondition(
      value,
      this.filterTextTwo,
      this.conditionTwo,
    );

    return this.operator === 'AND' ? match1 && match2 : match1 || match2;
  }

  applyCondition(
    value: string,
    filterText: string | null,
    condition: string | null,
  ): boolean {
    if (!filterText) {
      return false;
    }
    const text = filterText.toLowerCase();

    switch (condition) {
      case 'NOT_CONTAINS':
        return value.startsWith(text);
      case 'CONTAINS':
        return value.includes(text);
      default:
        return false;
    }
  }

  getModel(): any {
    return {
      filter: this.filterChipText || this.filterTextOne,
      filterTextOne: this.filterTextOne,
      filterTextTwo: this.filterTextTwo,
      conditionOne: this.conditionOne,
      conditionTwo: this.conditionTwo,
      operator: this.operator,
    };
  }

  setModel(model: any): void {
    if (model) {
      this.filterTextOne = model.filterTextOne || '';
      this.filterTextTwo = model.filterTextTwo || '';
      this.conditionOne = model.conditionOne || 'CONTAINS';
      this.conditionTwo = model.conditionTwo || 'CONTAINS';
      this.operator = model.operator || 'OR';
    } else {
      this.clearFilter();
    }
    this.updateFilterChipText();
  }

  afterGuiAttached(params?: IAfterGuiAttachedParams): void {
    this.hidePopup = params?.hidePopup;
    this.inputElement1?.nativeElement.focus();
  }

  onApply(): void {
    this.params!.filterChangedCallback();
    this.updateFilterChipText();
    this.hidePopup?.();
    this.glasspaneService.showGlasspane();
  }

  onClear(): void {
    this.clearFilter();
    this.chipText = null;
    this.params.filterChangedCallback();
    this.hidePopup?.();
  }

  private clearFilter(): void {
    this.filterTextOne = '';
    this.filterTextTwo = '';
    this.filterChipText = '';
  }

  updateFilterChipText(): void {
    const chipParts = [];

    if (this.filterTextOne) {
      chipParts.push(
        `${this.getReadableCondition(this.conditionOne || 'CONTAINS')} "${
          this.filterTextOne
        }"`,
      );
    }

    if (this.filterTextTwo) {
      chipParts.push(
        `${this.getReadableCondition(this.conditionTwo || 'CONTAINS')} "${
          this.filterTextTwo
        }"`,
      );
    }

    this.filterChipText = chipParts.join(` ${this.operator} `);
  }

  private getReadableCondition(condition: string): string {
    const conditionMap: { [key: string]: string } = {
      CONTAINS: 'contains',
      NOT_CONTAINS: 'does not contain',
    };
    return conditionMap[condition] || condition.toLowerCase();
  }

  onEnter(): void {
    this.onApply();
  }
}
