<div *ngFor="let reportStatus of reportStatuses" class="container">
  <ng-container *ngIf="reportStatus.documentStatus === 'ANALYZING'">
    <mat-spinner
      fontSet="material-symbols-outlined"
      color="primary"
      diameter="15"
    ></mat-spinner>
  </ng-container>
  <ng-container *ngIf="reportStatus.documentStatus === 'COMPLETED'">
    <mat-icon fontSet="material-symbols-outlined"> file_download </mat-icon>
  </ng-container>
  <ng-container *ngIf="reportStatus.documentStatus === 'ERROR'">
    <mat-icon fontSet="material-symbols-outlined" class="error">error</mat-icon>
  </ng-container>
  <a
    class="link"
    [class]="reportStatus"
    [attr.href]="url(reportStatus.reportType!)"
    >{{ getTitle(reportStatus.reportType!) }}
  </a>
</div>
