import { Injectable } from '@angular/core';
import {
  CoexistencePerMarketTable,
  SummaryService,
} from '@compumark/bla-backend-client';
import _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

export interface CoexistencePerMarketResult {
  table: CoexistencePerMarketTable;
  filteredTable: CoexistencePerMarketTable;
}

export interface CoexistencePerMarketFilters {
  keywords?: Array<string>;
  classes?: Array<string>;
  markets?: Array<Market>;
}

export interface Market {
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class CoexistencePerMarketColumnStateService {
  private resultsDeliveryId?: string;
  private result$: BehaviorSubject<CoexistencePerMarketResult> = new BehaviorSubject(
    {
      table: { rows: [] },
      filteredTable: { rows: [] },
    } as CoexistencePerMarketResult,
  );

  private filters$: BehaviorSubject<CoexistencePerMarketFilters> = new BehaviorSubject(
    {},
  );

  private markets$: BehaviorSubject<Market[]> = new BehaviorSubject(
    [] as Market[],
  );

  constructor(private summaryService: SummaryService) {}

  loadInitialData(
    resultsDeliveryId: string,
    appliedFilters: CoexistencePerMarketFilters,
  ): void {
    const currentFilters = this.filters$.getValue();

    if (
      !_.isEqual(currentFilters, appliedFilters) ||
      this.resultsDeliveryId !== resultsDeliveryId
    ) {
      this.summaryService
        .coexistencePerMarket(
          {
            classes: appliedFilters.classes?.map((num) => num.padStart(2, '0')),
            keywords: appliedFilters.keywords,
          },
          resultsDeliveryId,
        )
        .subscribe((table) => {
          this.loadMarkets(table);

          const filteredTable = _.clone(table);
          if (!!appliedFilters && !!appliedFilters.markets) {
            const regs = appliedFilters.markets.map((m) => m.code);
            filteredTable.rows = filteredTable.rows?.filter((r) =>
              regs.includes(r.register!),
            );
          } else {
            filteredTable.rows = filteredTable.rows!.slice(0, 5);
          }

          this.result$.next({
            table,
            filteredTable,
          } as CoexistencePerMarketResult);
          this.resultsDeliveryId = resultsDeliveryId;
        });

      this.filters$.next(appliedFilters);
    }
  }

  /* istanbul ignore next */
  get result(): Observable<CoexistencePerMarketResult> {
    return this.result$.asObservable();
  }

  /* istanbul ignore next */
  get filter(): Observable<CoexistencePerMarketFilters> {
    return this.filters$.asObservable();
  }

  /* istanbul ignore next */
  get curentFilter(): CoexistencePerMarketFilters {
    return this.filters$.getValue();
  }

  /* istanbul ignore next */
  get markets(): Observable<Market[]> {
    return this.markets$;
  }

  /* istanbul ignore next */
  get curentMarkets(): Market[] {
    return this.markets$.getValue();
  }

  private loadMarkets(table: CoexistencePerMarketTable): void {
    const marekts = table.rows!.map((r) => {
      return { code: r.register, name: r.countryName } as Market;
    });

    if (this.markets$.getValue().length === 0) {
      this.markets$.next(marekts);
    }
  }
}
