import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  Notification,
  NotificationService,
} from '@compumark/bla-backend-client';
import { interval } from 'rxjs';

@Component({
  selector: 'app-notification-dropdown',
  templateUrl: './notification-dropdown.component.html',
  styleUrls: ['./notification-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationDropdownComponent implements OnInit {
  notifications: Notification[] = [];
  notificationsAriaLabelTitle = 'Notifications';

  constructor(private notificationService: NotificationService) {
    this.updateNotifications();
  }

  setReviewed($event: Event, id: string): void {
    $event.stopPropagation();
    const notification: any = this.notifications.find((n) => n.id === id);
    if (!notification.reviewed) {
      this.notificationService.setNotificationReviewed(id).subscribe({
        complete: () => {
          notification.reviewed = true;
        },
      });
    }
  }

  getNotReviewedCount(notifications: Notification[] | null): number {
    const count = notifications?.filter((n) => !n.reviewed).length || 0;
    if (count > 0) {
      this.notificationsAriaLabelTitle = 'Notifications - new notification';
    }
    return count;
  }

  sort(notifications: Notification[] | null): Notification[] {
    return notifications
      ? notifications?.sort(
          (first, second) =>
            Number(first.reviewed!) - Number(second.reviewed!) ||
            second.creationDate! - first.creationDate!,
        )
      : [];
  }

  updateNotifications(): void {
    this.notificationService.getNotifications().subscribe((notifications) => {
      if (
        JSON.stringify(this.notifications.map(({ id }) => id).sort()) !==
        JSON.stringify(notifications.map(({ id }) => id).sort())
      ) {
        this.notifications = this.sort(notifications);
      }
    });
  }

  ngOnInit(): void {
    this.updateNotifications();

    interval(10000).subscribe(() => {
      this.updateNotifications();
    });
  }
}
