import { Component } from '@angular/core';
import { User } from '@compumark/bla-backend-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '../../security/services/user.service';

@Component({
  selector: 'app-pricing-account-error',
  templateUrl: './pricing-account-error.component.html',
  styleUrls: ['./pricing-account-error.component.scss'],
})
export class PricingAccountErrorComponent {
  isContractInError: Observable<boolean>;

  constructor(private userService: UserService) {
    this.isContractInError = this.userService
      .getCurrentUserObservable()
      ?.pipe(map((user: User) => !!user.contract?.error));
  }
}
