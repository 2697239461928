<ng-container *ngIf="resultsDelivery">
  <app-widget-header
    [title]="'Absolute Grounds - Data Points'"
    (removeEvent)="remove()"
  >
    <span class="show-all">
      <span class="label">Show all inspection points</span>
      <mat-slide-toggle
        role="button"
        color="primary"
        (change)="showAllToggle($event)"
      ></mat-slide-toggle>
    </span>
  </app-widget-header>

  <div class="container">
    <div class="bubbles">
      <ng-container
        *ngIf="
          checkAnyDataPointExist(resultsDelivery.absoluteGrounds);
          else noData
        "
      >
        <app-bubble
          *ngIf="
            getGeographicLocations(
              resultsDelivery.absoluteGrounds?.geographicLocationDataPoints!
            ).length > 0 || showAllToggleState
          "
          label="Geographic Location"
          [numberOfCases]="
            getGeographicLocations(
              resultsDelivery.absoluteGrounds?.geographicLocationDataPoints!
            ).length
          "
          [showZeroValue]="true"
          (click)="
            openGeographicLocationDialog(
              resultsDelivery.absoluteGrounds!,
              resultsDelivery.id
            )
          "
          [appCustomTooltip]="
            getGeographicLocationTooltip(
              resultsDelivery.absoluteGrounds?.geographicLocationDataPoints!
            )
          "
        ></app-bubble>
        <app-bubble
          *ngIf="
            getDictionaryLanguages(
              resultsDelivery.absoluteGrounds?.dictionaryMeaning!
            ) > 0 || showAllToggleState
          "
          label="Dictionary Meaning"
          [numberOfCases]="
            getDictionaryLanguages(
              resultsDelivery.absoluteGrounds?.dictionaryMeaning!
            )
          "
          [showZeroValue]="true"
          (click)="
            openDictionaryMeaningDialog(
              resultsDelivery.absoluteGrounds!,
              resultsDelivery.id
            )
          "
          [appCustomTooltip]="
            getDictionaryMeaningTooltip(
              resultsDelivery.absoluteGrounds?.dictionaryMeaning!
            )
          "
        ></app-bubble>
        <ng-container
          *ngIf="!!resultsDelivery.absoluteGrounds && hasPharmaRole"
        >
          <ng-container *ngIf="!!resultsDelivery.absoluteGrounds.stems">
            <app-bubble
              *ngIf="
                resultsDelivery.absoluteGrounds.stems.length > 0 ||
                showAllToggleState
              "
              label="Identical match(es) - WHO Stems"
              [showZeroValue]="true"
              [numberOfCases]="resultsDelivery.absoluteGrounds.stems.length"
              (click)="
                openIdenticalMatchesDialog(
                  resultsDelivery.absoluteGrounds.stems,
                  'WHO Stems',
                  resultsDelivery.id
                )
              "
              [appCustomTooltip]="
                geIdenticalMatchTooltip(resultsDelivery.absoluteGrounds.stems)
              "
            ></app-bubble>
          </ng-container>
          <ng-container *ngIf="!!resultsDelivery.absoluteGrounds.ban">
            <app-bubble
              *ngIf="
                resultsDelivery.absoluteGrounds.ban.length > 0 ||
                showAllToggleState
              "
              label="Identical match(es) - BAN"
              [numberOfCases]="resultsDelivery.absoluteGrounds.ban.length"
              [showZeroValue]="true"
              (click)="
                openIdenticalMatchesDialog(
                  resultsDelivery.absoluteGrounds.ban,
                  'BAN',
                  resultsDelivery.id
                )
              "
              [appCustomTooltip]="
                geIdenticalMatchTooltip(resultsDelivery.absoluteGrounds.ban)
              "
            ></app-bubble>
          </ng-container>
          <ng-container *ngIf="!!resultsDelivery.absoluteGrounds.inn">
            <app-bubble
              *ngIf="
                resultsDelivery.absoluteGrounds.inn.length > 0 ||
                showAllToggleState
              "
              label="Identical match(es) - WHO INNs"
              [numberOfCases]="resultsDelivery.absoluteGrounds.inn.length"
              [showZeroValue]="true"
              (click)="
                openIdenticalMatchesDialog(
                  resultsDelivery.absoluteGrounds.inn,
                  'WHO INNs',
                  resultsDelivery.id
                )
              "
              [appCustomTooltip]="
                geIdenticalMatchTooltip(resultsDelivery.absoluteGrounds.inn)
              "
            ></app-bubble>
          </ng-container>
          <ng-container *ngIf="!!resultsDelivery.absoluteGrounds.abbreviations">
            <app-bubble
              *ngIf="
                resultsDelivery.absoluteGrounds.abbreviations.length > 0 ||
                showAllToggleState
              "
              label="Identical match(es) - Stedman's Abbreviations"
              [numberOfCases]="
                resultsDelivery.absoluteGrounds.abbreviations.length
              "
              [showZeroValue]="true"
              (click)="
                openIdenticalMatchesDialog(
                  resultsDelivery.absoluteGrounds.abbreviations,
                  'Stedman\'s Abbreviations',
                  resultsDelivery.id
                )
              "
              [appCustomTooltip]="
                geIdenticalMatchTooltip(
                  resultsDelivery.absoluteGrounds.abbreviations
                )
              "
            ></app-bubble>
          </ng-container>
          <ng-container *ngIf="!!resultsDelivery.absoluteGrounds.romanNumerals">
            <app-bubble
              *ngIf="
                resultsDelivery.absoluteGrounds.romanNumerals.length > 0 ||
                showAllToggleState
              "
              label="Identical match(es) - Roman Numerals"
              [numberOfCases]="
                resultsDelivery.absoluteGrounds.romanNumerals.length
              "
              [showZeroValue]="true"
              (click)="
                openIdenticalMatchesDialog(
                  resultsDelivery.absoluteGrounds.romanNumerals,
                  'Roman Numerals',
                  resultsDelivery.id
                )
              "
              [appCustomTooltip]="
                geIdenticalMatchTooltip(
                  resultsDelivery.absoluteGrounds.romanNumerals
                )
              "
            ></app-bubble>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #noData>
  <span class="not-found"><h3>No data found</h3></span>
</ng-template>
