import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminConsoleComponent } from './components/brandlandscape-admin/brandlandscape-admin.component';
import { ExportAdminComponent } from './components/export-admin/export-admin.component';
import { UserRolesAdminComponent } from './components/user-roles-admin/user-roles-admin.component';

const routes: Routes = [
  {
    path: 'results-deliveries',
    component: AdminConsoleComponent,
    data: {
      breadcrumb: {
        label: 'Results Deliveries',
      },
    },
  },
  {
    path: 'export',
    component: ExportAdminComponent,
    data: {
      breadcrumb: {
        label: 'Export',
      },
    },
  },
  {
    path: 'roles',
    component: UserRolesAdminComponent,
    data: {
      breadcrumb: {
        label: 'Roles',
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
