import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-caselaw-dialog',
  templateUrl: './case-law-dialog.component.html',
  styleUrls: ['./case-law-dialog.component.scss'],
})
export class CaselawDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  getMatchType(pointOfLawtype: string): string | undefined {
    if (pointOfLawtype === 'SEMALT') {
      return 'Semantic';
    } else if (pointOfLawtype === 'ORIGINAL') {
      return 'Identical Match';
    }
    return '';
  }

  getTrademarkType(trademarkType: string): string | undefined {
    if (trademarkType === 'FG') {
      return 'Figurative';
    } else if (trademarkType === 'VB') {
      return 'Verbal';
    }
    return '';
  }
}
