import { Component, Input } from '@angular/core';
import { PricePackage } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-package-panel',
  templateUrl: './package-panel.component.html',
  styleUrls: ['./package-panel.component.scss'],
})
export class PackagePanelComponent {
  @Input() packages: PricePackage[] | null = null;
}
