import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { RiskLevel, RiskPerMarketTable } from '@compumark/bla-backend-client';
import { Observable } from 'rxjs';
import {
  RiskPerMarketColumnStateService,
  RiskPerMarketFilters,
  RiskPerMarketResult,
} from '../services/risk-per-market-state-service';
import _ from 'lodash';

export type RiskType =
  | 'nameRisk'
  | 'goodsAndServicesRisk'
  | 'verbalCaseLawRisk';

export interface RiskPerMarketItem {
  jurisdiction: string;
  riskType: RiskType;
  riskLevel: RiskLevel;
  classes: string[];
}

@Component({
  selector: 'app-risk-per-market',
  templateUrl: './risk-per-market.component.html',
  styleUrls: ['./risk-per-market.component.scss'],
})
export class RiskPerMarketComponent implements OnInit, OnChanges {
  @Input()
  resultsDeliveryId?: string;
  @Input()
  limitNumberOfRows = false;
  @Input()
  loadInitialData = false;
  @Input()
  keywords?: string[];
  @Input()
  classes?: string[];
  @Input()
  selectable = false;
  @Input()
  allFiltersEnabled = true;
  @Input()
  selectedItems: RiskPerMarketItem[] = [];
  @Output()
  selectedItemsEvent = new EventEmitter<RiskPerMarketItem[]>();
  @Output()
  hasDataEvent = new EventEmitter<boolean>();

  result$?: Observable<RiskPerMarketResult>;

  constructor(private stateService: RiskPerMarketColumnStateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedItems) {
      const newValues = changes.selectedItems.currentValue;
      if (!_.isEqual(this.selectedItems, newValues)) {
        this.selectedItems.splice(0, this.selectedItems.length);
        this.selectedItems.push(...changes.selectedItems.currentValue);
      }
    }
  }

  ngOnInit(): void {
    const filter = {
      classes: this.classes,
    } as RiskPerMarketFilters;
    this.result$ = this.stateService.result;
    if (this.loadInitialData) {
      this.stateService.loadInitialData(this.resultsDeliveryId!, filter);
    }
  }

  getTable(result: RiskPerMarketResult): RiskPerMarketTable {
    this.hasDataEvent.emit(result.table.rows!.length > 0 || false);
    return this.limitNumberOfRows ||
      (this.allFiltersEnabled && !!this.stateService.curentFilter.markets)
      ? result.filteredTable
      : result.table;
  }

  getBackgroundClass(level?: RiskLevel): string {
    if (level === 'EXACT') {
      return 'color-5';
    } else if (level === 'HIGH') {
      return 'color-4';
    } else if (level === 'MEDIUM') {
      return 'color-3';
    } else if (level === 'LOW') {
      return 'color-2';
    } else if (level === 'NONE') {
      return 'color-1';
    }

    return 'color-1';
  }

  getSelectionClass(riskType: RiskType, jurisdiction: string): string {
    if (!this.selectable) {
      return '';
    }

    const isSelected =
      this.selectedItems.find(
        (si) => si.jurisdiction === jurisdiction && si.riskType === riskType,
      ) !== undefined;
    return isSelected ? 'selected' : '';
  }

  toggleSelection(
    riskType: RiskType,
    riskLevel: RiskLevel,
    jurisdiction: string,
  ): void {
    if (!this.selectable) {
      return;
    }

    this.selectedItems = _.xorWith(
      this.selectedItems,
      [
        {
          jurisdiction,
          riskType,
          riskLevel,
          classes: this.stateService.curentFilter.classes || [],
        },
      ],
      _.isEqual,
    );

    this.selectedItemsEvent.emit(this.selectedItems);
  }
}
