import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class BreadcrumbService {
  constructor(private route: ActivatedRoute) {}

  resolvePath(path: string): string {
    return this.fillParams(this.route, path);
  }

  private fillParams(activatedRoute: ActivatedRoute, path: string): string {
    if (path.includes(':')) {
      if (activatedRoute.routeConfig && activatedRoute.routeConfig.path) {
        path = this.replaceParam(activatedRoute, path);
        if (activatedRoute.firstChild) {
          path = this.fillParams(activatedRoute.firstChild, path);
        }
      } else if (activatedRoute.firstChild) {
        path = this.fillParams(activatedRoute.firstChild, path);
      }
    }
    return path;
  }

  private replaceParam(activatedRoute: ActivatedRoute, path: string): string {
    Object.entries(activatedRoute.snapshot.params).forEach(([key, value]) => {
      path = path.replace(`:${key}`, `${value}`);
    });
    return path;
  }
}
