import { GridApi, GridOptions, MenuItemDef } from '@ag-grid-community/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReportStatus } from '@compumark/bla-backend-client';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MaterialLoadingCellRendererComponent } from 'src/app/data-grid/components/material-loading-cell-renderer/material-loading-cell-renderer.component';
import { MaterialTooltipComponent } from 'src/app/data-grid/components/material-tooltip/material-tooltip.component';
import { FilterParams } from 'src/app/deep-dive/services/filters.params';
import { environment } from 'src/environments/environment';

import { CustomTextFilterComponent } from '../../../custom-text-filter/customTextFilter.component';
import { BrandLandscapeAdminDatasource } from '../../services/brandlandscape-admin.datasource';
import { DeleteSearchComponent } from '../delete-search/delete-search.component';
import { GoToSummaryRendererComponent } from '../go-to-summary-renderer/go-to-summary-renderer.component';
import { RerunRendererComponent } from '../rerun-renderer/rerun-renderer.component';
import { OrderHistoryDetailsComponent } from '../order-history-details/order-history-details.component';

@Component({
  selector: 'app-brandlandscape-admin',
  templateUrl: './brandlandscape-admin.component.html',
  styleUrls: ['./brandlandscape-admin.component.scss'],
})
export class AdminConsoleComponent implements OnDestroy, OnInit {
  gridApi!: GridApi;
  reportsStatus: ReportStatus[] = [];
  resultsDeliveryStatuses = [
    'NEW',
    'ANALYZING',
    'SEARCH_IN_PROGRESS',
    'SEARCH_COMPLETED',
    'RISK_ASSESSMENT_IN_PROGRESS',
    'RISK_ASSESSMENT_COMPLETED',
    'COMPLETED',
    'SEARCH_FAILED',
    'TOO_MANY_RESULTS',
    'RISK_ASSESSMENT_FAILED',
    'ERROR',
  ];

  readonly textFilterParams = {
    filterOptions: ['equals'],
    suppressAndOrCondition: true,
    buttons: ['clear', 'apply'],
    closeOnApply: true,
  };

  readonly dateFilterParams = {
    buttons: ['clear', 'apply'],
    suppressMiniFilter: true,
    closeOnApply: true,
    suppressAndOrCondition: true,
    filterOptions: ['equals', 'inRange', 'lessThan', 'greaterThan'],
  };

  readonly gridOptions: GridOptions = {
    getRowNodeId: (t) => t.id,
    getRowHeight: () => 35,
    rowModelType: 'serverSide',
    serverSideStoreType: 'partial',
    blockLoadDebounceMillis: 1000,
    loadingCellRendererFramework: MaterialLoadingCellRendererComponent,
    enableCellTextSelection: true,
    suppressHorizontalScroll: false,
    suppressMovableColumns: true,
    suppressCellSelection: true,
    suppressRowClickSelection: true,
    detailCellRenderer: OrderHistoryDetailsComponent,
    masterDetail: true,
    detailRowAutoHeight: true,
    overlayLoadingTemplate: '<span></span>',
    columnDefs: [
      {
        field: '',
        cellRenderer: 'agGroupCellRenderer',
        valueGetter: () => '',
        resizable: true,
        minWidth: 60,
        menuTabs: [],
      },
      {
        headerName: 'Result Delivery Id',
        headerTooltip: 'Result Delivery Id',
        field: 'id',
        filter: 'textColumnFilterWithoutOptions',
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => params.data.id,
        menuTabs: ['filterMenuTab'],
        resizable: true,
        minWidth: 210,
      },
      {
        headerName: 'Created By',
        headerTooltip: 'Created By',
        field: 'createdBy',
        filter: 'textColumnFilterWithoutOptions',
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => params.data.createdBy,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'createdBy',
        resizable: true,
        minWidth: 140,
      },
      {
        headerName: 'Member Id',
        headerTooltip: 'Member Id',
        field: 'memberId',
        filter: 'textColumnFilterWithoutOptions',
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => params.data.memberId,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'memberId',
        resizable: true,
        minWidth: 120,
      },
      {
        headerName: 'Member Name',
        headerTooltip: 'Member Name',
        field: 'memberName',
        filter: 'textColumnFilterWithoutOptions',
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => params.data.memberName,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'memberName',
        resizable: true,
        minWidth: 160,
      },
      {
        headerName: 'Last Run Date',
        headerTooltip: 'Last Run Date',
        field: 'date',
        filter: 'agDateColumnFilter',
        filterParams: this.dateFilterParams,
        tooltipComponent: 'materialTooltip',
        menuTabs: ['filterMenuTab'],
        resizable: true,
        minWidth: 180,
      },
      {
        headerName: 'Status',
        headerTooltip: 'Status',
        field: 'status',
        width: 130,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => params.data.status,
        menuTabs: ['filterMenuTab'],
        filterParams: {
          defaultToNothingSelected: true,
          ...FilterParams.setFilterParams,
          values: this.resultsDeliveryStatuses,
        },
        resizable: true,
        minWidth: 150,
      },
      {
        headerName: 'Candidate',
        headerTooltip: 'Candidate',
        field: 'candidate',
        filter: 'textColumnFilterWithoutOptions',
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => params.data.candidate,
        menuTabs: ['filterMenuTab'],
        resizable: true,
        minWidth: 200,
      },
      {
        headerName: 'Product',
        field: 'type',
        headerTooltip: 'Product',
        cellRenderer: (params: any) => this.getProductName(params),
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => this.getProductName(params),
        menuTabs: [],
        minWidth: 200,
      },
      {
        headerName: 'Goods and Services',
        headerTooltip: 'Goods and Services',
        field: 'goodsAndServices',
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => params.data.goodsAndServices,
        menuTabs: [],
        resizable: true,
        minWidth: 250,
      },
      {
        headerName: 'Markets',
        headerTooltip: 'Markets',
        field: 'markets',
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => params.data.markets,
        menuTabs: [],
        resizable: true,
        minWidth: 150,
      },
      {
        headerName: 'Competitors',
        headerTooltip: 'Competitors',
        field: 'competitors',
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => params.data.competitors,
        menuTabs: [],
        resizable: true,
        minWidth: 120,
      },
      {
        headerName: 'Owned Brands',
        headerTooltip: 'Owned Brands',
        field: 'ownCompanies',
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => params.data.ownCompanies,
        menuTabs: [],
        resizable: true,
        minWidth: 120,
      },
      {
        headerName: 'Total Results',
        headerTooltip: 'Total Results',
        field: 'totalResults',
        tooltipComponent: 'materialTooltip',
        tooltipValueGetter: (params) => params.data.totalResults,
        menuTabs: [],
        resizable: true,
        minWidth: 120,
      },
      {
        headerName: 'Go To Summary',
        headerTooltip: 'Go To Summary',
        field: 'id',
        tooltipComponent: 'materialTooltip',
        cellRenderer: 'goToSummaryRenderer',
        menuTabs: [],
        pinned: 'right',
        minWidth: 150,
      },
      {
        headerName: 'Re-run',
        headerTooltip: 'Re-run',
        field: 'id',
        tooltipComponent: 'materialTooltip',
        cellRenderer: 'reRunRenderer',
        menuTabs: [],
        pinned: 'right',
        minWidth: 120,
      },
      {
        tooltipComponent: 'materialTooltip',
        cellRenderer: 'deleteSearch',
        menuTabs: [],
        pinned: 'right',
        minWidth: 60,
      },
    ],

    frameworkComponents: {
      goToSummaryRenderer: GoToSummaryRendererComponent,
      materialTooltip: MaterialTooltipComponent,
      reRunRenderer: RerunRendererComponent,
      deleteSearch: DeleteSearchComponent,
      textColumnFilterWithoutOptions: CustomTextFilterComponent,
    },

    onGridReady: (event) => {
      event.api.setServerSideDatasource(
        this.adminConsoleDatasource.createFor(),
      );

      event.api.sizeColumnsToFit();

      fromEvent(window, 'resize')
        .pipe(untilDestroyed(this), debounceTime(250))
        .subscribe(() => {
          event.api.sizeColumnsToFit();
        });

      this.gridApi = event.api;
    },

    onModelUpdated: (event) => {
      this.reportsStatus = [];
      event.api.forEachNode((node) =>
        this.reportsStatus.push(node.data?.reportStatus),
      );
    },
    getContextMenuItems: (): Array<MenuItemDef> => {
      const items = [];

      items.push({
        name: 'Export to CSV',
        action: () => {
          const filters = this.adminConsoleDatasource.getCurrentFilterState();
          const searchParams = new URLSearchParams();
          Object.keys(filters).forEach((key) => {
            if (filters[key]) {
              searchParams.append(key, filters[key]);
            }
          });
          window.open(
            `${environment.backendBaseUrl}/results-delivery/brandLandscapes-to-csv?${searchParams}`,
          );
        },
      });
      return items;
    },
  };

  getProductName(params: any): string {
    switch (params.data.type) {
      case 'BRAND_LANDSCAPE_ANALYZER': {
        return 'Brand Landscape Analyzer';
      }
      case 'OWNER_ANALYZER': {
        return 'Owner Analyzer';
      }
    }
    return '';
  }

  constructor(private adminConsoleDatasource: BrandLandscapeAdminDatasource) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
