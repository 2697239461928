import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ReportStatus, ReportType } from '@compumark/bla-backend-client';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-download-report',
  templateUrl: './download-report.component.html',
  styleUrls: ['./download-report.component.scss'],
})
export class DownloadReportComponent {
  resultsDeliveryId!: string;
  reportStatuses!: ReportStatus[];

  agInit(params: ICellRendererParams): void {
    this.resultsDeliveryId = params.data.id;
    this.reportStatuses = params.data.reportStatuses;
  }

  public url(reportType: ReportType): string {
    return `${environment.backendBaseUrl}/export/results-delivery/${this.resultsDeliveryId}/report-type/${reportType}/download`;
  }

  getTitle(reportType: ReportType): string {
    return reportType === 'QUICK'
      ? 'Quick Export – Download Report'
      : 'Download Report';
  }
}
