import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { StatusCodes } from 'http-status-codes';

/* istanbul ignore next */
@Injectable()
export class AuthorizationService {
  constructor(@Inject('nativeWindow') private nativeWindow: Window) {}

  isErrorResponseForbidden(response: HttpErrorResponse): boolean {
    return response.status === StatusCodes.FORBIDDEN;
  }

  navigateToAccessDenied(): void {
    this.nativeWindow.location.href = '/brandlandscape-access-denied';
  }
}
