import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from '@ag-grid-community/core';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  OrderHistoryList,
  ResultsDeliveryService,
} from '@compumark/bla-backend-client';
import { GlasspaneService } from '@compumark/brand-context-components';
import { first } from 'rxjs/operators';

/* istanbul ignore next */
@Injectable()
export class BrandLandscapeAdminDatasource {
  currentFilterState: any;

  constructor(
    private resultsDeliveryService: ResultsDeliveryService,
    private glasspaneService: GlasspaneService,
    private datePipe: DatePipe,
  ) {}

  private parseDate(dateToParse: string): string | undefined {
    if (!!dateToParse) {
      return this.datePipe.transform(dateToParse, 'dd-MM-yyyy')!;
    }
    return undefined;
  }

  private cleanFilterModel(params: IServerSideGetRowsParams): any {
    const { filterModel } = params.request;
    for (const propName in filterModel) {
      if (propName === 'status' || propName === 'date') {
        continue;
      }
      if (!filterModel[propName].filter) {
        delete filterModel[propName];
      }
    }
    return filterModel;
  }

  createFor(): IServerSideDatasource {
    const r = this.resultsDeliveryService;
    const o = this.glasspaneService;
    const self = this;
    return {
      getRows(params: IServerSideGetRowsParams): void {
        params.request.filterModel = self.cleanFilterModel(params);
        let dateFrom;
        let dateTo;
        if (!!params.request.filterModel?.date) {
          dateFrom = self.parseDate(params.request.filterModel?.date.dateFrom);
          dateTo = self.parseDate(params.request.filterModel?.date.dateTo);

          switch (params.request.filterModel?.date?.type) {
            case 'equals': {
              dateTo = dateFrom;
              break;
            }
            case 'lessThan': {
              dateTo = dateFrom;
              dateFrom = undefined;
              break;
            }
            case 'greaterThan': {
              dateTo = undefined;
              break;
            }
          }
        }

        self.currentFilterState = {
          creationDateStart: dateFrom,
          creationDateEnd: dateTo,
          creationDateFilterType: params.request.filterModel?.date?.type,
          candidateName: params.request.filterModel?.candidate?.filter,
          pricePackageId: undefined,
          id: params.request.filterModel?.id?.filter,
          createdBy: params.request.filterModel?.createdBy?.filter,
          admin: true,
          memberId: params.request.filterModel?.memberId?.filter,
          trademarkStatus: params.request.filterModel?.status?.values,
          memberName: params.request.filterModel?.memberName?.filter,
        };

        r.getOrderHistory(
          params.request.startRow!,
          params.request.endRow!,
          dateFrom,
          dateTo,
          params.request.filterModel?.date?.type,
          params.request.filterModel?.candidate?.filter,
          undefined,
          params.request.filterModel?.id?.filter,
          params.request.filterModel?.createdBy?.filter,
          true,
          params.request.filterModel?.memberId?.filter,
          params.request.filterModel?.status?.values,
          params.request.filterModel?.memberName?.filter,
        )
          .pipe(first())
          .subscribe((result: OrderHistoryList) => {
            params.success({
              rowData: result.orders,
              rowCount: result.totalCount,
            }),
              o.hideGlasspane();
          });
      },
    };
  }

  public getCurrentFilterState(): any {
    return this.currentFilterState;
  }
}
