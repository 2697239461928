import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { NotificationModule as CdxNotificationModule } from '@cdx/ngx-notification';

import { AdminModule } from '../admin/admin.module';
import { CustomBreadcrumbModule } from '../breadcrumb/breadcrumb.module';
import { FeatureFlagModule } from '../feature-flag/feature-flag.module';
import { HistoryModule } from '../history/history.module';
import { NotificationModule } from '../notification/notification.module';
import { AuthenticationService } from '../security/services/authentication.service';
import { UserService } from '../security/services/user.service';

import { PricingModule } from './../pricing/pricing.module';
import { ApplicationHeaderComponent } from './application-header/application-header.component';
import { ClarivateHeaderComponent } from './clarivate-header/clarivate-header.component';
import { PricingAccountErrorComponent } from './pricing-account-error/pricing-account-error.component';

@NgModule({
  declarations: [
    ApplicationHeaderComponent,
    ClarivateHeaderComponent,
    PricingAccountErrorComponent,
  ],
  exports: [
    ApplicationHeaderComponent,
    ClarivateHeaderComponent,
    PricingAccountErrorComponent,
  ],
  imports: [
    AdminModule,
    CdxNotificationModule,
    CustomBreadcrumbModule,
    CommonModule,
    FeatureFlagModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    NotificationModule,
    HistoryModule,
    PricingModule,
  ],
  providers: [UserService, AuthenticationService],
})
export class HeaderModule {}
