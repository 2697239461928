<div class="brandlandscape-navigation">
  <nav mat-tab-nav-bar class="navigation-tabs">
    <a mat-icon-button mat-tab-link class="home">
      <app-choose-product></app-choose-product>
    </a>
    <a mat-tab-link [disabled]="true">
      <div class="candidate-tab">
        <div class="candidate-label">Candidate name</div>
        <div class="candidate-name">
          {{ (resultsDelivery?.strategy)!.name }}
        </div>
      </div>
    </a>
    <ng-container *ngIf="isExpert()">
      <div class="summary-tab">
        <a
          mat-tab-link
          routerLinkActive
          #rla="routerLinkActive"
          [active]="rla.isActive"
          routerLink="/summary/results-delivery/{{ resultsDeliveryId }}"
          queryParamsHandling="preserve"
        >
          Summary
        </a>
      </div>
    </ng-container>

    <a
      mat-tab-link
      routerLinkActive
      #rla="routerLinkActive"
      [active]="'top-threats' == isActiveTab()"
      [routerLinkActiveOptions]="{ exact: false }"
      queryParamsHandling="preserve"
      (click)="openIsolatePage()"
      [matBadge]="'+' + (count$ | async)"
      matBadgeColor="accent"
      [matBadgeHidden]="(count$ | async) === 0"
    >
      Top Citations
    </a>

    <a
      mat-tab-link
      routerLinkActive
      #rla="routerLinkActive"
      [active]="'all-threats' == isActiveTab()"
      (click)="openAllThreatsPage()"
      queryParamsHandling="preserve"
    >
      All Citations
    </a>

    <ng-container *ngIf="resultsDelivery?.strategy?.ownCompanies?.length! > 0">
      <a
        mat-tab-link
        routerLinkActive
        #rla="routerLinkActive"
        [active]="'owned-brands' == isActiveTab()"
        routerLink="owner"
        (click)="openOwnBrandsPage()"
        queryParamsHandling="preserve"
      >
        Own Brands
      </a>
    </ng-container>
  </nav>
</div>
