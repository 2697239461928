<app-coexistence-per-market-classes-filter
  [resultsDeliveryId]="resultsDeliveryId"
  [classes]="classes"
></app-coexistence-per-market-classes-filter>
<app-coexistence-per-market-markets-filter
  [resultsDeliveryId]="resultsDeliveryId"
  [showMarketsFilter]="showMarketsFilter"
>
</app-coexistence-per-market-markets-filter>
<app-coexistence-per-market-keywords-filter
  [resultsDeliveryId]="resultsDeliveryId"
  [keywords]="keywords"
>
</app-coexistence-per-market-keywords-filter>

<ng-container *ngIf="currentFilter$ | async as currentFilter">
  <span
    class="clear-all"
    (click)="clearAll()"
    *ngIf="!isFilterSame(currentFilter)"
    >Clear All filters</span
  >
</ng-container>
