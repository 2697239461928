import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Role } from '@compumark/bla-backend-client';
import { get } from 'lodash';
import { UserService } from 'src/app/security/services/user.service';
import { environment } from 'src/environments/environment';

export interface Feature {
  featureKey: string;
  role: Role;
}

@Directive({
  selector: '[appIfFeatureEnabled]',
})
export class IfFeatureEnabledDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private service: UserService,
  ) {}

  @Input() set appIfFeatureEnabled(feature: any) {
    if (this.shouldEnable(feature)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private shouldEnable(feature: any): boolean {
    let shouldBeEnabled = false;

    if (typeof feature === 'string') {
      shouldBeEnabled =
        !!feature && this.isFeaturePresentInEnvironmentAndEnabled(feature);
    } else {
      const hasFeature =
        !!feature &&
        this.isFeaturePresentInEnvironmentAndEnabled(feature.featureKey);
      const hasRole =
        !!feature.role &&
        !!this.service.getCurrentUser() &&
        !!this.service.getCurrentUser().roles?.includes(feature.role);

      shouldBeEnabled = hasFeature && hasRole;
    }

    return shouldBeEnabled;
  }

  private isFeaturePresentInEnvironmentAndEnabled(feature: string): boolean {
    const path = feature.replace(/\//g, '.');
    return !!get(environment.features as any, path);
  }
}
