import { Injectable } from '@angular/core';

import _ from 'lodash';
import { RiskPerMarketItem } from '../risk-per-market/risk-per-market.component';
import { Router } from '@angular/router';
import { UserService } from 'src/app/security/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectSelectedRiskPerMarketService {
  constructor(private router: Router, private userService: UserService) {}

  redirectToAllThreats(
    resultsDeliveryId: string,
    selectedItems: RiskPerMarketItem[],
  ): void {
    if (selectedItems.length > 0) {
      const userRoles: any = this.userService.getCurrentUser().roles!;

      const url = [
        'summary',
        'isolate',
        'results-delivery',
        resultsDeliveryId,
        'all-threats',
      ];

      if (userRoles.includes('ALL_THREATS_BULK_ACTIONS')) {
        url.push('bulk');
      }

      this.router.navigate(url, {
        queryParams: {
          jurisdiction: selectedItems[0].jurisdiction,
          riskType: selectedItems[0].riskType,
          riskLevel: selectedItems[0].riskLevel,
          classes: selectedItems[0].classes,
        },
        queryParamsHandling: '',
      });
    }
  }
}
