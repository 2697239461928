import {
  AfterContentInit,
  Component,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import _ from 'lodash';
import { Observable, map, startWith, timer } from 'rxjs';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  Market,
  RiskPerMarketColumnStateService,
} from '../services/risk-per-market-state-service';

export interface CheckBoxFilter {
  key: string;
  value: boolean;
}

@Component({
  selector: 'app-risk-per-market-markets-filter',
  templateUrl: './risk-per-market-markets-filter.component.html',
  styleUrls: ['./risk-per-market-markets-filter.component.scss'],
})
export class RiskPerMarketMarketsFilterComponent implements AfterContentInit {
  @ViewChild('marketsFilterTrigger') marketsFilterTrigger!: MatMenuTrigger;
  @ViewChildren(MatAutocompleteTrigger)
  autoCompleteTriggers!: QueryList<MatAutocompleteTrigger>;

  currentInput = 0;

  @Input()
  resultsDeliveryId?: string;
  @Input()
  showMarketsFilter = false;
  markets$?: Observable<Market[]>;

  filteredOptions!: Observable<string[]>;

  menuOpened = false;

  marketsControlGroup: FormControl[] = [];
  marketsControl1 = new FormControl();
  marketsControl2 = new FormControl();
  marketsControl3 = new FormControl();
  marketsControl4 = new FormControl();
  marketsControl5 = new FormControl();

  activeControl = new FormControl();

  selectedMarkets: string[] = [];

  constructor(private stateService: RiskPerMarketColumnStateService) {
    this.marketsControlGroup.push(this.marketsControl1);
    this.marketsControlGroup.push(this.marketsControl2);
    this.marketsControlGroup.push(this.marketsControl3);
    this.marketsControlGroup.push(this.marketsControl4);
    this.marketsControlGroup.push(this.marketsControl5);

    this.activeControl = this.marketsControlGroup[0];
  }

  setActiveControl(control: FormControl): void {
    this.activeControl = control;

    this.filteredOptions = this.activeControl.valueChanges.pipe(
      startWith(''),
      map((value) => (!!value.toLowerCase ? value.toLowerCase() : value)),
      map((value) => this._filter(value)),
    );
  }

  private _filter(value: string): string[] {
    return this.stateService.curentMarkets
      .filter((m) => !this.selectedMarkets.includes(m))
      .filter((m) => m.toLowerCase().startsWith(value));
  }

  /* istanbul ignore next */
  ngAfterContentInit(): void {
    this.stateService.filter.subscribe((filter) => {
      if (!!filter.markets) {
        this.selectedMarkets = _.clone(filter.markets);
        this.marketsControlGroup.forEach((mc, i) =>
          mc.setValue(this.selectedMarkets[i]),
        );
      } else {
        this.selectedMarkets = [];
        this.marketsControlGroup.forEach((mc) => mc.setValue(''));
      }
    });
  }

  getSelectedMarket(event: MatAutocompleteSelectedEvent): void {
    this.selectedMarkets[this.currentInput] = event.option.value;
  }

  onApply(): void {
    const filter = _.clone(this.stateService.curentFilter);
    filter.markets = this.selectedMarkets;
    this.stateService.loadInitialData(this.resultsDeliveryId!, filter);
    this.marketsFilterTrigger.closeMenu();
  }

  closeDropdownList(): void {
    timer(200).subscribe(() =>
      this.autoCompleteTriggers.forEach((trigger) => trigger.closePanel()),
    );
  }
}
