<div class="geographic-location-dialog">
  <div class="title" mat-dialog-title>
    Absolute Grounds - Geographic Location
  </div>

  <mat-dialog-content>
    <app-absolute-grounds-item
      *ngFor="let item of data.geographicLocations"
      [item]="item"
      [resultsDeliveryId]="data.resultsDeliveryId"
    >
      <mat-card-header>
        <mat-card-title> {{ item.name }} </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <div class="row" *ngIf="item.countryName">
          <span class="label">Country</span><br />
          <span class="value">{{ item.countryName }}</span>
        </div>
        <div class="row" *ngIf="item.description">
          <span class="label">Description</span><br />
          <span class="value">{{ item.description }}</span>
        </div>
        <div class="row" *ngIf="item.countryCode">
          <span class="label">Country Code</span><br />
          <span class="value">{{ item.countryCode }}</span>
        </div>
        <div class="row" *ngIf="item.latitude">
          <span class="label">Latitude</span><br />
          <span class="value">{{ item.latitude }}</span>
        </div>
        <div class="row" *ngIf="item.longitude">
          <span class="label">Longitude</span><br />
          <span class="value">{{ item.longitude }}</span>
        </div>
        <div class="row" *ngIf="item.capital">
          <span class="label">Capital</span><br />
          <span class="value">{{ item.capital }}</span>
        </div>
        <div class="row" *ngIf="item.continent">
          <span class="label">Continent</span><br />
          <span class="value">{{ item.continent }}</span>
        </div>
        <div class="row" *ngIf="item.size">
          <span class="label">Size</span><br />
          <span class="value">{{ item.size }}</span>
        </div>
        <div class="row" *ngIf="item.population">
          <span class="label">Population</span><br />
          <span class="value">{{ item.population }}</span>
        </div>
        <div class="row link" *ngIf="item.googleMapsLink">
          <span class="value"
            ><a href="{{ item.googleMapsLink }}" target="_blank">Google Maps</a>
          </span>
        </div>
      </mat-card-content>
    </app-absolute-grounds-item>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      mat-stroked-button
      color="primary"
      class="cdx-but-lg"
      mat-dialog-close
    >
      Close
    </button>
  </mat-dialog-actions>
</div>
