<div class="flag-definitions-container">
  <div>
    <div class="title" mat-dialog-title>
      <div>
        <h5>Flag Definitions</h5>
      </div>
      <button mat-icon-button>
        <mat-icon mat-dialog-close>close</mat-icon>
      </button>
    </div>
    <mat-divider class="mat-divider"></mat-divider>
  </div>

  <div class="flag-definitions">
    <div class="flag-list">
      <ng-container *ngFor="let item of flagDefinitions">
        <app-flag-definition
          [flag]="item"
          [flagColors]="getAvailableFlagColors()"
          (deletedFlag)="removeFlag($event)"
          (flagColorChanged)="flagColorChange($event)"
        ></app-flag-definition>
      </ng-container>
    </div>
    <button
      class="cdx-but-lg new-flag"
      color="primary"
      mat-stroked-button
      matBadgeColor="accent"
      (click)="addNewFlag()"
      [disabled]="flagDefinitions.length! >= 16"
    >
      + Add a new Flag
    </button>
  </div>
  <div>
    <mat-checkbox class="save-button" color="primary" [(ngModel)]="userDefault">
      Save as default settings
    </mat-checkbox>
  </div>
</div>
<mat-divider class="mat-divider"></mat-divider>
<mat-dialog-actions class="custom-align-actions">
  <button
    class="cdx-but-lg save"
    color="primary"
    mat-flat-button
    matBadgeColor="accent"
    (click)="apply()"
  >
    Apply
  </button>
</mat-dialog-actions>
