import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PreferenceStorageService } from 'src/app/util/services/preference-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TopThreatsCounterService {
  readonly unviewedTopThreatCountKey = 'unviewed-top-threat-count:';
  private countSubject: BehaviorSubject<number>;
  selectedTopThreats: Set<string>;

  public increment(): void {
    const currentCount = this.countSubject.value;
    this.countSubject.next(currentCount + 1);
  }

  public decrement(): void {
    const currentCount = this.countSubject.value;
    if (currentCount > 0) {
      this.countSubject.next(currentCount - 1);
    }
  }

  public getCount(): Observable<number> {
    return this.countSubject.asObservable();
  }

  public restoreCountFromPreference(resultsDeliveryId: string): void {
    const selectedTopThreats = this.preferenceStorageService.getPreference(
      this.unviewedTopThreatCountKey + resultsDeliveryId,
    );

    if (!!selectedTopThreats && selectedTopThreats.length > 0) {
      this.selectedTopThreats = new Set(selectedTopThreats);
      this.updateCount(resultsDeliveryId);
    } else {
      this.clearCount();
    }
  }

  constructor(private preferenceStorageService: PreferenceStorageService) {
    this.selectedTopThreats = new Set<string>();
    this.countSubject = new BehaviorSubject<number>(
      this.selectedTopThreats.size,
    );
  }

  addThreat(resultDeliveryId: string, threatId: string): void {
    if (!this.selectedTopThreats.has(threatId)) {
      this.selectedTopThreats.add(threatId);
      this.updateCount(resultDeliveryId);
    }
  }

  removeThreat(resultDeliveryId: string, threatId: string): void {
    this.selectedTopThreats.delete(threatId);
    this.updateCount(resultDeliveryId);
  }

  updateCount(resultDeliveryId: string): void {
    this.preferenceStorageService.setPreference(
      this.unviewedTopThreatCountKey + resultDeliveryId,
      Array.from(this.selectedTopThreats),
    );
    this.countSubject.next(this.selectedTopThreats.size);
  }

  getCurrentCount(): number {
    return this.selectedTopThreats.size;
  }

  clearCount(): void {
    this.selectedTopThreats.clear();
    this.countSubject.next(0);
  }
}
