<div class="export-panel">
  <mat-card class="upload-templates">
    <h2 class="export-panel-title">Export Admin</h2>
    <mat-card-header>
      <mat-card-title>Upload New Templates</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <button
        mat-flat-button
        (click)="uploadTemplates()"
        color="primary"
        [disabled]="disableUpload"
      >
        Upload Templates
      </button>
      <ng-container *ngFor="let updatedTemplateInfo of updatedTemplatesInfo">
        <div class="template-info" *ngIf="!!updatedTemplateInfo">
          <div><strong>Templates update successful</strong></div>
          <br />
          <span><strong> id </strong> - {{ updatedTemplateInfo.id }} </span>
          <span><strong>Name</strong> - {{ updatedTemplateInfo.name }} </span>
          <span
            ><strong>Version</strong> - {{ updatedTemplateInfo.version }} </span
          ><br />
          <span
            ><strong> Last Updated on </strong> -
            {{ getDate(updatedTemplateInfo.updatedOn) }}</span
          >
        </div>
      </ng-container>
    </mat-card-content>
  </mat-card>
  <div class="upload-templates">
    <ng-container *ngIf="exportTemplates$ | async as exportTemplates">
      <ng-container *ngFor="let exportTemplate of exportTemplates">
        <mat-card class="upload-templates-card">
          <mat-card-header>
            <mat-card-title>Current Template Info</mat-card-title>
          </mat-card-header>
          <div *ngIf="!!exportTemplate" class="template-info">
            <span><strong> id </strong> - {{ exportTemplate.id }} </span>
            <span><strong>Name</strong> - {{ exportTemplate.name }} </span>
            <span
              ><strong>Version</strong> - {{ exportTemplate.version }}
            </span>
            <span
              ><strong> Last Updated on </strong> -
              {{ getDate(exportTemplate.updatedOn) }}</span
            >
          </div>
        </mat-card>
        <mat-card-title class="title">Subtemplate List</mat-card-title>
        <ag-grid-angular
          class="ag-theme-cdx"
          [rowData]="exportTemplate?.subReports!"
          [columnDefs]="columnDefs"
          [modules]="modules"
        >
        </ag-grid-angular>
      </ng-container>
    </ng-container>
  </div>
</div>
