import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, timer } from 'rxjs';
import { mergeMap, retryWhen, tap } from 'rxjs/operators';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  retryDelay = 2000;
  retryMaxAttempts = 3;
  retryStatusCodes = [0, 502, 503, 504];
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(this.retryAfterDelay());
  }

  retryAfterDelay(): any {
    return retryWhen((errors) => {
      return errors.pipe(
        mergeMap((err, count) => {
          if (
            this.retryStatusCodes.indexOf(err.status) === -1 ||
            count >= this.retryMaxAttempts
          ) {
            return throwError(err);
          }
          return of(err).pipe(
            tap((error) =>
              // tslint:disable-next-line
              console.log(`Retrying ${error.url}. Retry count ${count + 1}`),
            ),
            mergeMap(() => timer(this.retryDelay)),
          );
        }),
      );
    });
  }
}
