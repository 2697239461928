import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Role } from '@compumark/bla-backend-client';
import { Observable, map } from 'rxjs';
import { UserService } from 'src/app/security/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class OwnerAnalyzerAccessService implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.userService.getCurrentUserObservable().pipe(
      map(() => {
        if (
          !!this.userService
            .getCurrentUser()
            .roles?.find((r) => r === Role.OWNER_ANALYZER || r === Role.ADMIN)
        ) {
          return true;
        } else {
          return this.router.createUrlTree(['owner-analyzer-access-denied']);
        }
      }),
    );
  }
}
