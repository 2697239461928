<mat-button-toggle-group
  multiple
  [disabled]="true"
  *ngIf="!customize; else allDataPoints"
  class="responsive-toggle-group"
>
  <mat-button-toggle> Citation </mat-button-toggle>
  <mat-button-toggle> Owner </mat-button-toggle>
  <mat-button-toggle> Market </mat-button-toggle>
  <mat-button-toggle> Application number </mat-button-toggle>
  <mat-button-toggle> Registration number </mat-button-toggle>
  <mat-button-toggle> Status </mat-button-toggle>
  <mat-button-toggle *ngIf="data.verbalSimilarityColumn">
    Verbal Similarity
  </mat-button-toggle>
  <mat-button-toggle *ngIf="data.goodsSimilarityColumn">
    Goods Similarity
  </mat-button-toggle>
  <mat-button-toggle *ngIf="data.flagsColumn"> Flags </mat-button-toggle>
</mat-button-toggle-group>

<ng-template #allDataPoints>
  <div
    class="allDataPoints"
    [ngClass]="{ 'report-editor-disabled-div': disableSubsection }"
  >
    <div class="row">
      <div class="info app-report-editor mat-checkbox-layout">
        <mat-icon class="tick">check</mat-icon>
        <span class="label">Citation</span>
      </div>
      <div class="info app-report-editor mat-checkbox-layout">
        <mat-icon class="tick">check</mat-icon>
        <span class="label">Owner</span>
      </div>
      <div class="info app-report-editor mat-checkbox-layout">
        <mat-icon class="check">check</mat-icon>
        <span class="label">Market</span>
      </div>
    </div>

    <div class="row">
      <div class="info app-report-editor mat-checkbox-layout">
        <mat-icon class="tick">check</mat-icon>
        <span class="label">Application number</span>
      </div>
      <div class="info app-report-editor mat-checkbox-layout">
        <mat-icon class="tick">check</mat-icon>
        <span class="label">Registration number</span>
      </div>
    </div>

    <div class="row">
      <div class="info app-report-editor mat-checkbox-layout">
        <mat-icon class="tick">check</mat-icon>
        <span class="label">Status</span>
      </div>
      <div>
        <mat-checkbox
          color="primary"
          class="checkbox"
          [(ngModel)]="data.verbalSimilarityColumn"
        >
          Verbal Similarity
        </mat-checkbox>
      </div>
    </div>

    <div class="row">
      <div>
        <mat-checkbox color="primary" [(ngModel)]="data.goodsSimilarityColumn">
          Goods Similarity
        </mat-checkbox>
      </div>
      <div>
        <mat-checkbox color="primary" [(ngModel)]="data.flagsColumn">
          Flags
        </mat-checkbox>
      </div>
    </div>
  </div>
</ng-template>
