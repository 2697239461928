import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bubble',
  templateUrl: './bubble.component.html',
  styleUrls: ['./bubble.component.scss'],
})
export class BubbleComponent {
  @Input()
  numberOfCases?: number;
  @Input()
  showZeroValue = false;
  @Input()
  label?: string;
  @Input()
  type?: string;

  getBubbleType(): string {
    if (this.numberOfCases! > 0 && this.type === 'HIGHLIGHTED') {
      return 'HIGHLIGHTED';
    }
    return this.numberOfCases! > 0 ? 'REGULAR' : 'TEXT_ONLY';
  }

  get identicalMatch(): boolean {
    return !!this.label && this.label.startsWith('Identical match(es)');
  }
}
