import { Component, Input } from '@angular/core';
import { TableSummary } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-table-summary',
  templateUrl: './table-summary.component.html',
  styleUrls: ['./table-summary.component.scss'],
})
export class TableSummaryComponent {
  @Input() data!: TableSummary;
  @Input() customize!: boolean;
  @Input() disableSubsection!: boolean;
}
