<div (click)="notifications = sort(notifications)">
  <button
    mat-icon-button
    [matMenuTriggerFor]="menu"
    class="cdx-but-menu notification-button"
    role="button"
  >
    <mat-icon
      class="cdx-mat-icon-24"
      [matBadge]="getNotReviewedCount(notifications) > 0 ? 1 : null"
      matBadgeSize="small"
      matBadgeColor="warn"
      >notifications_none</mat-icon
    >
  </button>
</div>

<mat-menu #menu="matMenu" role="menu" class="notification-menu">
  <div
    class="label"
    (click)="$event.stopPropagation()"
    [matBadge]="getNotReviewedCount(notifications)"
  >
    Notifications
  </div>
  <mat-divider></mat-divider>
  <ng-container *ngFor="let notification of notifications">
    <div
      class="notification"
      (click)="setReviewed($event, notification.id!)"
      mat-menu-item
    >
      <div *ngIf="!notification.reviewed" class="left reviewed">
        <span class="content">⬤</span>
      </div>
      <div class="right">
        <div [ngClass]="notification.reviewed ? 'message' : 'message bold'">
          {{ notification.message }}
        </div>
        <div class="date">
          {{ notification.creationDate! * 1000 | date: 'MMMM d, y h:mm a' }}
        </div>
        <a
          *ngIf="notification.url"
          class="link"
          [attr.href]="notification.url"
          >{{ notification.urlLabel }}</a
        >
      </div>
    </div>
    <mat-divider id="item-divider"></mat-divider>
  </ng-container>
  <div *ngIf="notifications.length === 0" class="no-notifications">
    <strong>You have not received any notifications yet.</strong>
  </div>
  <mat-divider id="item-divider"></mat-divider>
</mat-menu>
