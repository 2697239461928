import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { NavigateDialogComponent } from './../components/navigate-dialog/navigate-dialog.component';

@Injectable()
export class NavigateDialogService {
  constructor(public dialog: MatDialog) {}

  openNavigationPopup(packageId: string): Observable<any> {
    return this.dialog
      .open(NavigateDialogComponent, {
        autoFocus: false,
        data: packageId,
        height: '180',
        width: '320px',
      })
      .afterClosed();
  }
}
