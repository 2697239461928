<div class="title" mat-dialog-title>
  <span>{{ header }}</span>
</div>

<mat-dialog-content>{{ title }} </mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    class="cdx-but-md cdx-but-link"
    color="primary"
    mat-button
    mat-dialog-close
  >
    {{ cancelButtonLabel }}
  </button>
  <button
    class="cdx-but-md cdx-but-link"
    color="primary"
    mat-button
    mat-dialog-close="CONTINUE"
  >
    {{ confirmButtonLabel }}
  </button>
</mat-dialog-actions>
