<div
  class="widget"
  [ngClass]="{ hovered: isHovered }"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onLeave()"
>
  <div class="overlay">
    <div class="image-container">
      <div class="image" [ngClass]="widgetId"></div>
    </div>

    <div class="add-button" *ngIf="isHovered">
      <div class="button" tabindex="0" (click)="add(widgetId!)">+ Add</div>
    </div>
  </div>
  <div class="content">
    <div class="title">{{ widgetDescription(widgetId!).title }}</div>
    <div class="description">
      {{ widgetDescription(widgetId!).description }}
    </div>
  </div>
</div>
