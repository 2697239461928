import { AgFilterComponent } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { FlagDefinition } from '@compumark/bla-backend-client';
import _ from 'lodash';

@Component({
  selector: 'app-flag-filter',
  templateUrl: './flag-filter.component.html',
  styleUrls: ['./flag-filter.component.scss'],
})
export class FlagFilterComponent implements AgFilterComponent {
  flagDefinitions: FlagDefinition[] = [];
  selectedFlags: Set<FlagDefinition> = new Set();
  noFlag: FlagDefinition = {
    color: 'white',
    label: 'No Flag',
    id: 'no_flag',
  };
  private params!: any;
  valueGetter!: (rowNode: any) => any;
  private filterType = 'customFlagArray';
  filter: any;
  hidePopup: any;

  constructor() {}

  agInit(params: any): void {
    this.params = params;
    this.params.flagDefinitions().subscribe((fd: FlagDefinition[]) => {
      this.flagDefinitions = fd;
    });
    this.valueGetter = params.valueGetter;
  }

  isFilterActive(): boolean {
    return this.selectedFlags.size > 0;
  }
  doesFilterPass(params: any): boolean {
    const value = this.valueGetter(params.node);
    return this.selectedFlags.has(value);
  }

  getModel(): any {
    return {
      filter: Array.from(this.selectedFlags!).map((f) => f.label),
      filterType: this.filterType,
      ids: Array.from(this.selectedFlags!).map((f) => f.id),
      selectedFlags: this.selectedFlags,
    };
  }

  setModel(model: any): void {
    this.filter = model?.filter!;
    this.selectedFlags = model?.selectedFlags! || new Set();
  }

  onFlagChange(flagDefinition: any, selected: any): void {
    if (selected) {
      this.selectedFlags.add(flagDefinition);
    } else {
      this.selectedFlags.delete(flagDefinition);
    }
  }

  onSelectAll(selected: boolean): void {
    if (selected) {
      this.flagDefinitions.map((fd) => this.selectedFlags.add(fd));
    } else {
      this.selectedFlags.clear();
    }
  }

  onClear(): void {
    this.selectedFlags.clear();
  }

  onApply(): void {
    this.params?.filterChangedCallback();
    this.hidePopup();
  }

  afterGuiAttached(params: any): void {
    this.hidePopup = params?.hidePopup;
  }

  checkAllselected(): boolean {
    return (
      (this.selectedFlags.has(this.noFlag) &&
        this.flagDefinitions?.length === this.selectedFlags.size - 1) ||
      (!this.selectedFlags.has(this.noFlag) &&
        this.flagDefinitions?.length === this.selectedFlags.size)
    );
  }

  checkSomeSelected(): boolean {
    return (
      (this.selectedFlags.size !== 0 &&
        !this.selectedFlags.has(this.noFlag) &&
        this.flagDefinitions.length !== this.selectedFlags.size) ||
      (this.selectedFlags.has(this.noFlag) &&
        this.flagDefinitions.length !== this.selectedFlags.size - 1)
    );
  }
}
