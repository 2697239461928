<div class="filterPopup">
  <div class="filterboxContainer">
    <input
      #input
      class="filterbox"
      type="text"
      (keyup.enter)="onEnter()"
      [(ngModel)]="filterText"
      placeholder="Filter..."
    />
  </div>
  <div class="ag-filter-apply-panel">
    <button
      type="button"
      class="ag-standard-button ag-filter-apply-panel-button"
      (click)="onClear()"
    >
      Clear</button
    ><button
      type="button"
      class="ag-standard-button ag-filter-apply-panel-button"
      (click)="onApply()"
    >
      Apply
    </button>
  </div>
</div>
