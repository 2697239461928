import { Component, Input } from '@angular/core';
import { PricePackage } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-packages-list',
  templateUrl: './packages-list.component.html',
  styleUrls: ['./packages-list.component.scss'],
})
export class PackagesListComponent {
  @Input() packages: PricePackage[] | null = [];
}
