<button
  *ngIf="!!filteredPackages && filteredPackages.length == 0"
  mat-stroked-button
  routerLink="/account/packages/create"
  color="primary"
  aria-label="cdx lg button"
  class="cdx-but-lg"
>
  Purchase Package
</button>
<section
  class="select-package"
  *ngIf="!!filteredPackages && filteredPackages.length > 0"
>
  <mat-form-field appearance="outline">
    <mat-select
      [formControl]="packageControl"
      [placeholder]="placeholderMessage"
      panelClass="common-panel"
      disableOptionCentering
    >
      <mat-option value="None">None</mat-option>
      <mat-option value="createPackage">Purchase new package</mat-option>
      <mat-divider></mat-divider>
      <span class="packages-label">AVAILABLE PACKAGES</span>
      <mat-option *ngFor="let pack of filteredPackages" [value]="pack.id"
        >{{ pack.name }} ({{
          pack.numberOfCandidatesRemaining!
        }}
        left)</mat-option
      >
    </mat-select>
  </mat-form-field>
</section>
