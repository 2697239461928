import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-redirect-to-first-package',
  template: '',
})
export class RedirectToFirstPackageComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.pipe(first()).subscribe((data) => {
      if (data.packages[0]) {
        this.router.navigate(['.', data.packages[0].id], {
          queryParamsHandling: 'preserve',
          relativeTo: this.route,
          replaceUrl: true,
        });
      } else {
        this.router.navigate(['./create'], {
          queryParamsHandling: 'preserve',
          relativeTo: this.route,
          replaceUrl: true,
        });
      }
    });
  }
}
