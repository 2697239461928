import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RiskPerMarketColumnStateService } from './services/risk-per-market-state-service';
import { RiskPerMarketWidgetComponent } from './risk-per-market-widget/risk-per-market-widget.component';
import { RiskPerMarketMarketsFilterComponent } from './risk-per-market-markets-filter/risk-per-market-markets-filter.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { RiskPerMarketFilterComponent } from './risk-per-market-filter/risk-per-market-filter.component';
import { RiskPerMarketClassesFilterComponent } from './risk-per-market-classes-filter/risk-per-market-classes-filter.component';
import { RiskPerMarketComponent } from './risk-per-market/risk-per-market.component';
import { RiskPerMarketDialogComponent } from './risk-per-market-dialog/risk-per-market-dialog.component';
import { RiskPerMarketDescriptionComponent } from './risk-per-market-description/risk-per-market-description.component';
import { UtilModule } from '../util/util.module';
import { MatIconModule } from '@angular/material/icon';
import { WidgetHeaderComponent } from './widget-header/widget-header.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { PreferenceStorageService } from '../util/services/preference-storage.service';
import { RiskPerMarketChipConverterService } from './services/risk-per-market-chip-converter-service';
import { FormatClassesPipe } from '../util/pipes/format-classes.pipe';
import { WidgetComponent } from './widget/widget.component';
import { WidgetCardComponent } from './widget-card/widget-card.component';
import { AddWidgetDialogComponent } from './add-widget-dialog/add-widget-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    UtilModule,
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatMenuModule,
    MatIconModule,
    MatAutocompleteModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatTooltipModule,
  ],
  declarations: [
    WidgetComponent,
    WidgetHeaderComponent,
    WidgetCardComponent,
    AddWidgetDialogComponent,
    RiskPerMarketComponent,
    RiskPerMarketFilterComponent,
    RiskPerMarketWidgetComponent,
    RiskPerMarketDialogComponent,
    RiskPerMarketDescriptionComponent,
    RiskPerMarketMarketsFilterComponent,
    RiskPerMarketClassesFilterComponent,
  ],
  providers: [
    RiskPerMarketColumnStateService,
    PreferenceStorageService,
    RiskPerMarketChipConverterService,
    FormatClassesPipe,
  ],
  exports: [
    WidgetComponent,
    RiskPerMarketWidgetComponent,
    WidgetHeaderComponent,
    RiskPerMarketDescriptionComponent,
    RiskPerMarketFilterComponent,
    RiskPerMarketComponent,
  ],
})
export class WidgetsModule {}
