import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResultsDelivery } from '@compumark/bla-backend-client';
import { MatDialog } from '@angular/material/dialog';
import {
  RiskPerMarketDialogData,
  RiskPerMarketDialogComponent,
} from '../risk-per-market-dialog/risk-per-market-dialog.component';
import { WIDGETS } from '../widget/widget.component';
import { RiskPerMarketItem } from '../risk-per-market/risk-per-market.component';
import { RedirectSelectedRiskPerMarketService } from '../services/redirect-selected-risk-per-market-service';

@Component({
  selector: 'app-risk-per-market-widget',
  templateUrl: './risk-per-market-widget.component.html',
  styleUrls: ['./risk-per-market-widget.component.scss'],
})
export class RiskPerMarketWidgetComponent {
  @Output() removeEvent = new EventEmitter<string>();
  resultsDelivery: ResultsDelivery;
  hasData = true;

  constructor(
    route: ActivatedRoute,
    public dialog: MatDialog,
    private redirectSelectedRiskPerMarketService: RedirectSelectedRiskPerMarketService,
  ) {
    this.resultsDelivery = route.snapshot.data.resultsDelivery;
  }

  get classes(): string[] {
    return this.resultsDelivery.strategy!.classes!.sort(
      (a, b) => parseInt(a, 10) - parseInt(b, 10),
    );
  }

  expand(): void {
    this.dialog
      .open(RiskPerMarketDialogComponent, {
        data: {
          resultsDeliveryId: this.resultsDelivery.id,
          classes: this.resultsDelivery.strategy!.classes,
        } as RiskPerMarketDialogData,
        autoFocus: false,
        width: '100%',
        hasBackdrop: true,
        panelClass: 'risk-per-market-dialog',
      })
      .afterClosed();
  }

  selectedItemsEvent(selectedItems: RiskPerMarketItem[]): void {
    this.redirectSelectedRiskPerMarketService.redirectToAllThreats(
      this.resultsDelivery.id,
      selectedItems,
    );
  }

  hasDataEvent(state: boolean): void {
    this.hasData = state;
  }

  remove(): void {
    this.removeEvent.emit(WIDGETS.RISK_PER_MARKET);
  }
}
