<span [ngClass]="['bubble', getBubbleType()]">
  <ng-container>
    <span
      *ngIf="
        label !== 'Geographic Location' &&
        label !== 'Dictionary Meaning' &&
        !identicalMatch
      "
      class="material-icons"
      >gavel</span
    >
    <span *ngIf="label === 'Geographic Location'" class="material-icons"
      >language</span
    >
    <span *ngIf="label === 'Dictionary Meaning'" class="menu_book"></span>
    <span *ngIf="identicalMatch" class="material-symbols-outlined"
      >medication</span
    >

    <span class="number-of-cases" [ngClass]="{ zero: numberOfCases === 0 }">{{
      numberOfCases
    }}</span>
  </ng-container>
  <ng-container *ngIf="numberOfCases === 0 && showZeroValue"> </ng-container>
  <span class="label">{{ label }}</span>
</span>
