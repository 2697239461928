<div class="case-law-dialog">
  <div class="title" mat-dialog-title>
    Absolute Grounds -
    {{ data.label[0].toUpperCase() + data.label.substr(1).toLowerCase() }}
  </div>

  <mat-dialog-content>
    <app-absolute-grounds-item
      *ngFor="let item of data.cards"
      [item]="item"
      [resultsDeliveryId]="data.resultsDeliveryId"
    >
      <ng-container *ngIf="!!data.cards">
        <mat-card-header>
          <mat-card-title *ngIf="!!item">
            {{ item.trademarkName }}
          </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <div class="row" *ngIf="item.trademarkType">
            <span class="label">Type of mark</span><br />
            <span class="value">{{
              getTrademarkType(item.trademarkType)
            }}</span>
          </div>
          <div class="row" *ngIf="item.description">
            <span class="label">Description</span><br />
            <span class="value">{{ item.description }}</span>
          </div>
          <div class="row" *ngIf="item.jurisdiction">
            <span class="label">Country</span><br />
            <span class="value">{{ item.jurisdiction }}</span>
          </div>
          <div class="row" *ngIf="item.court">
            <span class="label">Court</span><br />
            <span class="value">{{ item.court }}</span>
          </div>
          <div class="row" *ngIf="item.pointofLaw">
            <span class="label">No Of Cases</span><br />
            <span class="value">{{ item.pointofLaw }}</span>
          </div>
          <div class="row" *ngIf="item.niceClass">
            <span class="label">Class</span><br />
            <span class="value">{{ item.niceClass }}</span>
          </div>
          <div class="row" *ngIf="item.pointOfLawType">
            <span class="label">Match</span><br />
            <span class="value">{{ getMatchType(item.pointOfLawType) }}</span>
          </div>
        </mat-card-content>
      </ng-container>
    </app-absolute-grounds-item>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      mat-stroked-button
      color="primary"
      class="cdx-but-lg"
      mat-dialog-close
    >
      Close
    </button>
  </mat-dialog-actions>
</div>
