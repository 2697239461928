import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FeatureLevel,
  Price,
  PricePackage,
  PricePackageService,
  PricingService,
} from '@compumark/bla-backend-client';
import { Observable } from 'rxjs';
import { map, pairwise } from 'rxjs/operators';

import { NavigateDialogService } from '../../services/navigate-dialog.service';
import { PriceConfirmationOpeningService } from '../../services/price-confirmation-opening.service';
import { PriceFormattingService } from '../../services/price-formatting.service';
import { PriceOverviewOpeningService } from '../../services/price-overview-opening-service';
import { LeaveConfirmationDialogComponent } from '../leave-confirmation-dialog/leave-confirmation-dialog.component';

@Component({
  selector: 'app-purchase-package',
  templateUrl: './purchase-package.component.html',
  styleUrls: ['./purchase-package.component.scss'],
})
export class PurchasePackageComponent implements OnInit {
  @ViewChild('packageNameInput') packageNameElement!: ElementRef;

  packages$: Observable<PricePackage[]>;
  price$!: Observable<Price>;

  packageForm: UntypedFormGroup;
  packageName: string | null = null;
  date = new Date();
  disabledSubmit = true;
  individualRegion = false;
  packOf5 = false;
  packOf10 = false;
  packEU = false;

  constructor(
    public priceOverview: PriceOverviewOpeningService,
    private fb: UntypedFormBuilder,
    route: ActivatedRoute,
    public priceFormattingService: PriceFormattingService,
    private router: Router,
    private pricePackageService: PricePackageService,
    private pricingService: PricingService,
    private navigateDialogService: NavigateDialogService,
    private dialog: MatDialog,
    private priceConfirmationService: PriceConfirmationOpeningService,
    private ref: ChangeDetectorRef,
  ) {
    this.packages$ = route.data.pipe(map((data) => data.packages));
    this.packageForm = this.form;
    this.price$ = this.pricingService.calculatePrice(this.pricePackage);
  }

  async canDeactivate(): Promise<boolean> {
    const pack = this.pricePackage;
    if (
      pack.name === null &&
      pack.numberOfCandidates === 1 &&
      JSON.stringify(pack.numberOfMarkets) === JSON.stringify({}) &&
      pack.featureLevel === FeatureLevel.EXPERT
    ) {
      return true;
    }

    const dialogRef = this.dialog.open(LeaveConfirmationDialogComponent, {
      autoFocus: false,
    });
    const response: any = await dialogRef.afterClosed().toPromise();
    return response === 'LEAVE_PAGE';
  }

  submit(price: Price): void {
    this.priceConfirmationService.openConfirmationPopup(price!).subscribe(() =>
      this.pricePackageService.createPricePackage(this.pricePackage).subscribe({
        next: (pack: PricePackage) => {
          this.navigateDialogService
            .openNavigationPopup(pack.id!)
            .subscribe((result) => {
              this.clearForm();
              if (result === 'CONTINUE') {
                this.router.navigate(['/'], {
                  queryParams: {
                    packageId: pack.id!,
                  },
                });
              } else {
                this.router.navigate(['/account/packages/create']);
              }
            });
        },
      }),
    );
  }

  public get packageNameControl(): AbstractControl {
    return this.packageForm.get('packageName')!;
  }

  get numberOfCandidatesControl(): AbstractControl {
    return this.packageForm.get('numberOfCandidates')!;
  }

  get individualRegionControl(): AbstractControl {
    return this.packageForm.get('individualRegion')!;
  }

  get numberIndividualRegionControl(): AbstractControl {
    return this.packageForm.get('numberIndividualRegion')!;
  }

  get packOf5Control(): AbstractControl {
    return this.packageForm.get('packOf5')!;
  }

  get numberPackOf5Control(): AbstractControl {
    return this.packageForm.get('numberPackOf5')!;
  }

  get packOf10Control(): AbstractControl {
    return this.packageForm.get('packOf10')!;
  }

  get numberPackOf10Control(): AbstractControl {
    return this.packageForm.get('numberPackOf10')!;
  }

  get globalControl(): AbstractControl {
    return this.packageForm.get('global')!;
  }

  get packEUControl(): AbstractControl {
    return this.packageForm.get('packEU')!;
  }

  get featureLevelControl(): AbstractControl {
    return this.packageForm.get('featureLevel')!;
  }

  private get pricePackage(): PricePackage {
    const pack: PricePackage = {
      name: this.packageNameControl.value,
      numberOfCandidates: this.numberOfCandidatesControl.value,
      numberOfMarkets: this.markets,
      featureLevel: this.featureLevelControl.value,
    };

    return pack;
  }

  private get markets(): any {
    const markets: any = {};
    if (this.globalControl.value) {
      markets.GLOBAL = 1;
    } else {
      if (this.packEUControl.value) {
        markets.BLEU = 1;
      }
      if (this.numberIndividualRegionControl.value) {
        markets.ONE_COUNTRY = this.numberIndividualRegionControl.value;
      }
      if (this.numberPackOf5Control.value) {
        markets.PACK_OF_5 = this.numberPackOf5Control.value;
      }
      if (this.numberPackOf10Control.value) {
        markets.PACK_OF_10 = this.numberPackOf10Control.value;
      }
    }
    return markets;
  }

  private get form(): UntypedFormGroup {
    return this.fb.group({
      packageName: [null, [this.packageNameExistsValidator()]],
      numberOfCandidates: 1,
      numberIndividualRegion: 0,
      numberPackOf5: 0,
      numberPackOf10: 0,
      individualRegion: false,
      packOf5: false,
      packOf10: false,
      packEU: false,
      global: false,
      featureLevel: FeatureLevel.EXPERT,
    });
  }

  clearForm(): void {
    this.packageForm = this.form;
    this.ngOnInit();
    this.individualRegion = false;
    this.packOf5 = false;
    this.packOf10 = false;
    this.packEU = false;
    this.disabledSubmit = true;
    this.packageName = null;
    this.price$ = this.pricingService.calculatePrice(this.pricePackage);
  }

  ngOnInit(): void {
    this.packageForm.valueChanges
      .pipe(pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        this.disabledSubmit =
          this.packageNameControl.value === null ||
          !this.packageNameControl.value.trim() ||
          this.doesPackageNameExists(this.packageNameControl) ||
          (this.numberIndividualRegionControl.value === 0 &&
            this.numberPackOf5Control.value === 0 &&
            this.numberPackOf10Control.value === 0 &&
            !this.packEUControl.value &&
            !this.globalControl.value);
        this.packageName = this.packageNameControl.value;
        this.individualRegion = this.individualRegionControl.value;
        this.packOf5 = this.packOf5Control.value;
        this.packOf10 = this.packOf10Control.value;
        this.packEU = this.packEUControl.value;

        if (
          prev.numberIndividualRegion !== next.numberIndividualRegion ||
          (next.numberPackOf5 !== undefined &&
            prev.numberPackOf5 !== next.numberPackOf5) ||
          (next.numberPackOf10 !== undefined &&
            prev.numberPackOf10 !== next.numberPackOf10) ||
          prev.global !== next.global ||
          prev.packEU !== next.packEU ||
          prev.featureLevel !== next.featureLevel ||
          prev.numberOfCandidates !== next.numberOfCandidates
        ) {
          this.price$ = this.pricingService.calculatePrice(this.pricePackage);
          this.ref.detectChanges();
        }
      });
  }

  clearMarkets(): void {
    this.individualRegionControl.setValue(false, { emitEvent: false });
    this.packOf5Control.setValue(false, { emitEvent: false });
    this.packOf10Control.setValue(false, { emitEvent: false });
    this.packEUControl.setValue(false, { emitEvent: false });
  }

  clearGlobalPackage(): void {
    this.globalControl.setValue(false);
  }

  packageNameExistsValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      return this.doesPackageNameExists(control)
        ? { nameAlreadyExists: true }
        : null;
    };
  }

  doesPackageNameExists(control: AbstractControl): boolean {
    let nameAlreadyExists = false;
    this.packages$
      .pipe(
        map((packages) =>
          packages.filter((packege) => packege.name === control?.value),
        ),
      )
      .subscribe((packages) => (nameAlreadyExists = packages.length > 0));
    return nameAlreadyExists;
  }
}
