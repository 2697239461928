import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { WIDGETS } from '../../widgets/widget/widget.component';
import {
  Globality,
  OwnerDataRequest,
  OwnerDataService,
} from '@compumark/brand-context-components-backend-client';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Applicant, OptimizedOwner } from '@compumark/bla-backend-client';
import { OwnerFilterService } from 'src/app/owner-results/services/owner-filter.service';

@Component({
  selector: 'app-owner-globality',
  templateUrl: './owner-globality.component.html',
  styleUrls: ['./owner-globality.component.scss'],
})
export class OwnerGlobalityComponent implements OnDestroy {
  @Output() removeEvent = new EventEmitter<string>();
  ownerData$: Subject<Globality> = new BehaviorSubject<Globality>({});
  showLoadingIndicator$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  selectedOwners: Observable<OptimizedOwner[] | undefined> | undefined;

  constructor(
    private ownerdataservice: OwnerDataService,
    ownerFilterService: OwnerFilterService,
  ) {
    this.selectedOwners = ownerFilterService.owners();
    this.selectedOwners.subscribe((data) => {
      this.filter(data!);
    });
  }
  ngOnDestroy(): void {
    this.selectedOwners = undefined;
  }

  filter(owners: OptimizedOwner[]): void {
    if (this.selectedOwners) {
      this.showLoadingIndicator$.next(true);
      const applicants: Applicant[] = owners?.map(
        (owner) =>
          ({
            optimizedApplicant: {
              normName: owner.optimizedName,
              countryCode: owner.countryCode,
            },
          } as Applicant),
      );

      this.ownerdataservice
        .calculateOwnerGlobality({
          brandContext: {
            parentId: '',
            trademarkId: '',
            trademarkName: '',
            register: '',
            applicants,
          },
          knownCompetitors: [],
        } as OwnerDataRequest)
        .subscribe((globality) => {
          this.ownerData$.next(globality);
          this.showLoadingIndicator$.next(false);
        });
    }
  }

  remove(): void {
    this.removeEvent.emit(WIDGETS.OWNER_GLOBALITY);
  }
}
