import { Injectable } from '@angular/core';
import { FlagsService } from '@compumark/bla-backend-client';
import _ from 'lodash';
import { RowNode } from '@ag-grid-community/core';

@Injectable({
  providedIn: 'root',
})
export class FlagService {
  constructor(private flagsService: FlagsService) {}

  initializeFlags(resultsDelivery: any, node: RowNode<any>): any[] {
    if (!node) {
      return [];
    }

    const flags = _.cloneDeep(resultsDelivery?.flagDefinitions?.flags) || [];
    const selectedFlagsIds =
      node.data.flags?.filter((f: any) => f.selected).map((f: any) => f.id) ||
      [];
    return flags.map((flag: any) => {
      if (selectedFlagsIds.includes(flag.id)) {
        flag.selected = true;
      }
      return flag;
    });
  }

  toggleActive(
    id: string,
    resultsDelivery: any,
    node: RowNode<any>,
    gridApi: any,
    bulkSelectedValue?: boolean,
  ): void {
    const flags = this.initializeFlags(resultsDelivery, node);
    const flag = flags.find((f) => f.id === id);
    if (flag) {
      flag.selected =
        bulkSelectedValue !== undefined ? bulkSelectedValue : !flag.selected;

      this.addThreatsFlag(flag, node);
      if (gridApi) {
        gridApi.redrawRows({ rowNodes: [node] });
      }

      this.flagsService
        .setThreatFlagAssociatedWithResultsDelivery(
          flag,
          resultsDelivery?.id,
          node.data.id,
        )
        .subscribe();
    }
  }

  private addThreatsFlag(flag: any, node: RowNode<any>): void {
    if (node.data.flags) {
      const selectedFlagIndex = node.data.flags.findIndex(
        (f: any) => f.id === flag.id,
      );
      if (selectedFlagIndex !== -1) {
        node.data.flags[selectedFlagIndex] = flag;
      } else {
        node.data.flags.push(flag);
      }
    } else {
      node.data.flags = [flag];
    }
  }
}
