<header>
  <span class="header"
    ><h2 class="title">
      {{ title }}
    </h2>
    <mat-icon
      *ngIf="tooltip"
      [matTooltip]="tooltip!"
      matTooltipPosition="above"
    >
      help_outline
    </mat-icon></span
  >

  <div class="actions">
    <ng-content></ng-content>
    <div
      routerLinkActive="active"
      (mouseenter)="
        targetMenuTrigger = createPlanmenuTrigger;
        mouseEnter(createPlanmenuTrigger)
      "
      (mouseleave)="mouseLeave(createPlanmenuTrigger)"
    >
      <div
        class="button"
        [ngClass]="{ opened: isOpened }"
        #createPlanmenuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="widgetMenu"
      >
        <mat-icon fontSet="material-symbols-outlined"> more_vert </mat-icon>
      </div>
      <mat-menu
        class="widget-menu"
        #widgetMenu="matMenu"
        [hasBackdrop]="false"
        [overlapTrigger]="false"
      >
        <div
          (mouseenter)="mouseEnter(targetMenuTrigger)"
          (mouseleave)="mouseLeave(targetMenuTrigger)"
        >
          <button
            mat-menu-item
            class="widget-actions-menu-item"
            (click)="remove()"
          >
            Remove Widget
          </button>
        </div>
      </mat-menu>
    </div>
  </div>
</header>
