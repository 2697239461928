import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { BreadcrumbModule } from 'xng-breadcrumb';

import { CustomBreadcrumbComponent } from './breadcrumb.component';
import { BreadcrumbService } from './breadcrumb.service';
import { WCLMenuItemComponent } from './wcl-menu-item.component';
import { NavigationModule } from '../navigation/navigation.module';

@NgModule({
  declarations: [CustomBreadcrumbComponent, WCLMenuItemComponent],
  imports: [
    BreadcrumbModule,
    CommonModule,
    MatIconModule,
    MatMenuModule,
    RouterModule,
    MatProgressSpinnerModule,
    NavigationModule,
  ],
  exports: [CustomBreadcrumbComponent],
  providers: [BreadcrumbService],
})
export class CustomBreadcrumbModule {}
