import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, Input } from '@angular/core';
import { PocaScore } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-poca-score-indicator',
  templateUrl: './poca-score-indicator.component.html',
  styleUrls: [],
})
export class PocaScoreIndicatorComponent {
  @Input() pocaScore: PocaScore = {} as PocaScore;

  agInit(params: ICellRendererParams): void {
    this.pocaScore = params?.data?.pocaScore || ({} as PocaScore);
  }
  getPocaScoreTooltip(): string {
    return (
      'Orthographic & Phonetic: ' +
      this.pocaScore.average +
      '\n' +
      'Phonetic: ' +
      this.pocaScore.phonetic +
      '\n' +
      'Orthographic: ' +
      this.pocaScore.orthographic
    );
  }
}
