import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, Input, OnChanges } from '@angular/core';
import { DateType, GracePeriod } from '@compumark/bla-backend-client';
import moment from 'moment';

@Component({
  selector: 'app-grace-period-indicator',
  templateUrl: './grace-period-indicator.component.html',
  styleUrls: ['./grace-period-indicator.component.scss'],
})
export class GracePeriodIndicatorComponent implements OnChanges {
  @Input() gracePeriod!: GracePeriod;
  expired = false;

  agInit(params: ICellRendererParams): void {
    this.gracePeriod = params?.data?.gracePeriod;
    this.setExpiredStatusOnExistingExpirationDate();
  }

  ngOnChanges(): void {
    this.setExpiredStatusOnExistingExpirationDate();
  }

  getGracePeriodInfo(): string {
    let info = '';

    this.expired
      ? (info = 'Non-Use Grace Period has lapsed. ')
      : (info = 'Non-Use Grace Period has not lapsed. ');

    info =
      info +
      'The Non-Use Grace Period for ' +
      this.gracePeriod!.country +
      ' ends ' +
      this.gracePeriod!.period +
      ' years after ' +
      this.getDateType(this.gracePeriod!.dateType!) +
      ' date.';
    return info;
  }

  getDateType(dateType: DateType): string {
    switch (dateType) {
      case 'APPLICATION':
        return 'application';

      case 'RENEWAL':
        return 'renewal';

      default:
        return 'registration';
    }
  }

  getColorClass(): string {
    return this.expired ? 'green' : 'orange';
  }

  setExpiredStatusOnExistingExpirationDate(): void {
    if (this.gracePeriod?.expirationDate) {
      this.expired = this.getExpiredStatus(this.gracePeriod);
    }
  }

  getExpiredStatus(gracePeriod: GracePeriod): boolean {
    const now = moment();
    const expirationDate = moment(gracePeriod.expirationDate, 'YYYY, MM, DD');
    return now.isAfter(expirationDate) ? true : false;
  }
}
