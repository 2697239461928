<div class="tile">
  <div class="top-tile-row">
    <mat-checkbox
      #selectionCheckbox
      class="selection-checkbox"
      color="primary"
      (change)="onSelect()"
      [checked]="checkSelected()"
    ></mat-checkbox>
  </div>
  <div class="image-container" [ngStyle]="{ height: imgSize }">
    <app-image
      [fillAvailable]="true"
      [imgId]="imageId"
      [ngStyle]="{ height: imgSize }"
    ></app-image>
  </div>

  <div class="info">
    <span
      *ngIf="isInactive(threat?.trademarkStatus!)"
      class="warning"
      matTooltip="Inactive"
    >
    </span>
    <div class="name" [ngClass]="{ unreviewed: !threat!.reviewed }">
      {{ threat!.trademarkJurisdictionCode }} -
      {{
        !!threat!.registrationNumber
          ? threat!.registrationNumber
          : threat!.applicationNumber
      }}
    </div>
  </div>

  <div class="dashed-divider"></div>
  <div class="actions">
    <div class="left">
      <app-flags
        [resultsDelivery]="resultsDelivery!"
        [threat]="threat!"
        (click)="$event.stopPropagation()"
      ></app-flags>
    </div>
    <div class="right">
      <div class="container">
        <span class="comment">
          <lib-threat-comment
            [threatId]="threat!.id"
            [userId]="user?.id"
            [userName]="user?.name"
            (click)="$event.stopPropagation()"
          ></lib-threat-comment>
        </span>
        <button mat-icon-button class="actions-export-button">
          <span class="icon-wrapper">
            <ng-container *ngIf="!threat?.selectedForExport">
              <span
                class="material-symbols-outlined file-download file-download-empty"
                matTooltip="Select for export"
              >
                file_download
              </span>
            </ng-container>
            <ng-container *ngIf="threat?.selectedForExport">
              <mat-icon
                fontSet="material-symbols-outlined"
                class="file-download"
                matTooltip="Added to export report"
              >
                file_download
              </mat-icon>
              <mat-icon
                fontSet="material-symbols-outlined"
                style="font-variation-settings: 'FILL' 1"
                class="check-circle"
              >
                check_circle
              </mat-icon>
            </ng-container>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
