import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import {
  ContentType,
  ResultsDelivery,
  WclService,
} from '@compumark/bla-backend-client';
import { Observable, Subscription, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UserService } from 'src/app/security/services/user.service';

@Component({
  selector: 'app-all-threats-tab-nav-bar',
  templateUrl: './all-threats-tab-nav-bar.component.html',
  styleUrls: ['./all-threats-tab-nav-bar.component.scss'],
})
export class AllThreatsTabNavBarComponent implements OnInit {
  resultsDelivery$: Observable<ResultsDelivery>;
  checkStatus$?: Subscription;
  data!: Data;
  activeLink!: string;
  loaded = true;

  constructor(
    private router: Router,
    route: ActivatedRoute,
    private wclService: WclService,
    private userService: UserService,
  ) {
    this.resultsDelivery$ = route.data.pipe(
      map((data) => data.resultsDelivery),
    );
    route.data.subscribe((data: Data) => (this.data = data));
    this.wclService
      .getWCLThreatsStatusWithResultsDelivery(this.data!.resultsDelivery.id)
      .subscribe((result) => {
        this.loaded = result.loaded!;
      });
  }

  showTrademark(): boolean {
    return (
      this.activeLink.startsWith('all-threats') ||
      this.activeLink.startsWith('bulk')
    );
  }

  showPharma(resultsDelivery: ResultsDelivery): boolean {
    const userRoles: any = this.userService.getCurrentUser()?.roles!;
    return (
      resultsDelivery?.strategy?.markets!.find(
        (m) => m.type === ContentType.PHARMA,
      ) !== undefined && userRoles?.includes('PHARMA')
    );
  }
  showCommonLaw(resultsDelivery: ResultsDelivery): boolean {
    const userRoles: any = this.userService.getCurrentUser()?.roles!;
    return (
      resultsDelivery?.strategy?.markets!.find(
        (m) => m.type === ContentType.COMMON_LAW,
      ) !== undefined &&
      userRoles?.includes('SHOW_ALL_COMMON_LAW_IN_SEPARATE_TAB')
    );
  }
  showCompanyNames(resultsDelivery: ResultsDelivery): boolean {
    const userRoles: any = this.userService.getCurrentUser()?.roles!;
    return (
      resultsDelivery?.strategy?.markets!.find(
        (m) => m.type === ContentType.COMPANY_NAMES,
      ) !== undefined && userRoles?.includes('COMPANY_NAMES')
    );
  }
  showDomainNames(resultsDelivery: ResultsDelivery): boolean {
    const userRoles: any = this.userService.getCurrentUser()?.roles!;
    return (
      resultsDelivery?.strategy?.markets!.find(
        (m) => m.type === ContentType.DOMAIN_NAMES,
      ) !== undefined && userRoles?.includes('DOMAIN_NAMES')
    );
  }

  ngOnInit(): void {
    this.activeLink = this.router.url.split('/').pop()!;

    this.checkStatus$ = timer(0, 10000)
      .pipe(
        switchMap(() => {
          return this.wclService.getWCLThreatsStatusWithResultsDelivery(
            this.data!.resultsDelivery.id,
          );
        }),
      )
      .subscribe((result) => {
        if (result.loaded) {
          this.loaded = result.loaded;
          this.checkStatus$?.unsubscribe();
        }
      });
  }

  /* istanbul ignore next */
  get wclPath(): string {
    return this.hasBulkActionsEnabled ? '../../wcl' : '../wcl';
  }

  /* istanbul ignore next */
  get pharmaPath(): string {
    return this.hasBulkActionsEnabled
      ? '../../pharma-threats'
      : '../pharma-threats';
  }
  get commonLawPath(): string {
    return this.hasBulkActionsEnabled ? '../../common-law' : '../common-law';
  }

  /* istanbul ignore next */
  get domainPath(): string {
    return this.hasBulkActionsEnabled
      ? '../../domain-names'
      : '../domain-names';
  }

  /* istanbul ignore next */
  get companyPath(): string {
    return this.hasBulkActionsEnabled
      ? '../../company-names'
      : '../company-names';
  }

  private get hasBulkActionsEnabled(): boolean {
    return this.router.url.endsWith('bulk');
  }
}
