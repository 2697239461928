import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { ThreatsFilter, ThreatsService } from '@compumark/bla-backend-client';
import { GlasspaneService } from '@compumark/brand-context-components';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ThreatDatasource } from 'src/app/threats-tables/services/interfaces/threat-datasource.interface';

@Injectable({
  providedIn: 'root',
})
export class ImageResultsDatasourceService implements ThreatDatasource {
  constructor(
    private threatsService: ThreatsService,
    private glasspaneService: GlasspaneService,
  ) {}

  getThreatTableColumnsName(): string {
    return 'imageResultsThreatTableColumns';
  }

  getTotalThreatCount(resultsDeliveryId: string): Observable<number> {
    return this.threatsService
      .getImageThreatsAssociatedWithResultsDelivery(
        { contentType: 'IMAGE' },
        resultsDeliveryId,
        0,
        1,
      )
      .pipe(map((r) => r.totalCount));
  }

  createDatasource(resultsDeliveryId: string): IServerSideDatasource {
    const threatsService = this.threatsService;
    const glasspaneService = this.glasspaneService;

    return {
      getRows(params: IServerSideGetRowsParams): void {
        threatsService
          .getImageThreatsAssociatedWithResultsDelivery(
            {
              name: params.request.filterModel?.trademarkName?.filter,
              actions: params.request.filterModel?.actions?.filter,
              applicantName:
                params.request.filterModel?.trademarkApplicantName?.filter,
              knownCompetitor:
                params.request.filterModel?.trademarkApplicantName
                  ?.showCompetitors,
              jurisdictionNames:
                params.request.filterModel?.trademarkJurisdictionCode?.values,
              statuses: params.request.filterModel?.trademarkStatus?.filter,
              contentType: 'IMAGE',
              freeText: params.request.filterModel?.freeText?.filter,
              flagIds: params.request.filterModel?.flags?.ids,
            } as ThreatsFilter,
            resultsDeliveryId,
            params.request.startRow!,
            params.request.endRow!,
          )
          .pipe(first())
          .subscribe((threats) => {
            params.success({
              rowData: threats.threats,
              rowCount: threats.totalCount,
            });

            glasspaneService.hideGlasspane();
          });
      },
    };
  }
}
