import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatClasses',
})
export class FormatClassesPipe implements PipeTransform {
  transform(classList: Array<number>, separator: string): string {
    const classes: Array<string> = [];
    let classStr = '';
    let isRange = false;
    for (let i = 0; i < classList.length; i++) {
      if (classList[i] + 1 === classList[i + 1] && isRange) {
        isRange = true;
        continue;
      } else if (isRange) {
        classStr = classStr + '-' + classList[i];
        isRange = false;
      } else {
        classStr = i === 0 ? '' + classList[i] : classStr + '' + classList[i];
        if (classList[i] + 1 === classList[i + 1]) {
          isRange = true;
        }
      }
      if (!!classStr && !isRange) {
        classes.push(classStr);
        classStr = '';
      }
    }

    if (!!classes && classes.length > 0) {
      return classes.join(separator);
    }

    return '';
  }
}
