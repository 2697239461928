import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-number-picker',
  templateUrl: './number-picker.component.html',
  styleUrls: ['./number-picker.component.scss'],
})
export class NumberPickerComponent implements OnInit, OnChanges {
  decreaseButtonDisabled = false;
  increaseButtonDisabled = false;
  @Input() control!: UntypedFormControl;

  @Input() disable = false;
  @Input() min = 0;
  @Input() max = 200;
  @Input() default = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disable) {
      if (changes.disable.currentValue) {
        if (this.control) {
          this.control.patchValue(0);
          this.control.disable({ emitEvent: false });
        }
        this.decreaseButtonDisabled = true;
      } else {
        this.control.setValue(1, { emitEvent: false });
        this.control.enable();
      }
    }
  }

  ngOnInit(): void {
    if (this.disable) {
      this.control.disable({ emitEvent: false });
    }
    this.setDefaultValue();
    this.checkButtons();
  }

  increase(event: any): void {
    event.preventDefault();
    if (this.control.value < this.max) {
      this.control.patchValue(this.control.value + 1);
    }
    this.checkButtons();
  }

  decrease(event: any): void {
    event.preventDefault();
    if (this.control.value > this.min) {
      this.control.patchValue(this.control.value - 1);
    }
    this.checkButtons();
  }

  checkButtons(): void {
    this.increaseButtonDisabled = false;
    this.decreaseButtonDisabled = this.control.value <= this.min;
    if (this.control.value >= this.max) {
      this.increaseButtonDisabled = true;
    }
  }

  setDefaultValue(): void {
    if (this.default === null || this.default === undefined) {
      this.control.patchValue(0);
    } else {
      this.control.patchValue(this.default);
    }
  }
}
