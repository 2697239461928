<div class="brandlandscape-navigation">
  <nav mat-tab-nav-bar class="navigation-tabs">
    <a mat-icon-button mat-tab-link class="home" queryParamsHandling="preserve">
      <app-choose-product></app-choose-product>
    </a>
    <a mat-tab-link [disabled]="true">
      <div class="delimiter">|</div>
      <div class="owner-tab">Owner Analyzer</div>
    </a>

    <div class="summary-tab">
      <a
        mat-tab-link
        routerLinkActive
        #rla="routerLinkActive"
        [active]="rla.isActive"
        routerLink="/initiate/owner-analyzer"
        queryParamsHandling="preserve"
      >
        Initiate a search
      </a>
    </div>

    <a
      mat-tab-link
      routerLinkActive
      #rla="routerLinkActive"
      [active]="isActiveTab('insights')"
      [routerLinkActiveOptions]="{ exact: false }"
      queryParamsHandling="preserve"
      (click)="openInsightskPage()"
    >
      Insights
    </a>

    <a
      mat-tab-link
      routerLinkActive
      #rla="routerLinkActive"
      [active]="isActiveTab('trademarks')"
      [routerLinkActiveOptions]="{ exact: false }"
      queryParamsHandling="preserve"
      (click)="openTrademarkPage()"
    >
      Trademarks
    </a>
  </nav>
</div>
