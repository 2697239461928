import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ContentType,
  FullTextService,
  GoodsAndServicesText,
  ThreatsService,
} from '@compumark/bla-backend-client';
import { Observable } from 'rxjs';
import { TableSettingsService } from 'src/app/threats-tables/services/table-settings.service';

@Component({
  selector: 'app-full-text',
  templateUrl: './full-text.component.html',
  styleUrls: ['./full-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FullTextComponent implements OnChanges, OnInit {
  @Input() splitViewData: any;
  data$?: Observable<any>;
  goodsAndServicesData?: GoodsAndServicesText[];
  @Output() navigate = new EventEmitter<string>();
  loadingGoods = false;
  @Input() contentType?: ContentType;
  @Input() threat: any;

  constructor(
    private fullTextService: FullTextService,
    private route: ActivatedRoute,
    private threatsService: ThreatsService,
    public tableSettingsService: TableSettingsService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  ngOnChanges(): void {
    this.loadData();
  }

  private loadData(): void {
    this.loadingGoods = true;
    this.getFulltextData();
    this.getGoodsAndServicesData();
  }

  getFulltextData(): void {
    if (!this.splitViewData?.threat?.threatId) {
      return;
    }

    switch (this.contentType) {
      case 'TRADEMARK':
        this.data$ = this.fullTextService.getFulltextByTrademarkIdAsReviewed(
          this.splitViewData?.threat?.threatId,
          this.resultsDeliveryId,
          this.splitViewData?.threat?.trademarkId,
        );
        break;

      case 'COMMON_LAW':
      case 'PHARMA':
      case 'COMPANY_NAMES':
        this.data$ = this.fullTextService.getFulltextForThreatAssociatedWithResultsDelivery(
          this.splitViewData?.threat?.threatId,
          this.resultsDeliveryId,
          this.splitViewData?.threat?.trademarkId,
        );
        break;
    }
  }

  getGoodsAndServicesData(): void {
    if (
      !!this.splitViewData?.threat?.threatId &&
      this.contentType === 'TRADEMARK'
    ) {
      this.threatsService
        .getLatestGoodsAndServicesTextForThreatAssociatedWithResultsDelivery(
          this.resultsDeliveryId,
          this.splitViewData?.threat?.threatId,
        )
        .subscribe((data) => {
          this.goodsAndServicesData = data;
          this.loadingGoods = false;
        });
    }
  }

  get resultsDeliveryId(): string {
    return this.route.snapshot.params.resultsDeliveryId;
  }
}
