import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, OnChanges } from '@angular/core';
import { ResultsDelivery } from '@compumark/bla-backend-client';
import { FlagService } from 'src/app/threats-tables/services/flag.service';
import _ from 'lodash';

@Component({
  selector: 'app-flags-table',
  templateUrl: '../flag-shared-template/flag-template.html',
  styleUrls: ['../flag-shared-template/flag-template.scss'],
})
export class FlagsTableComponent implements OnChanges {
  flags = [] as any[];
  color = '#FF00FF';
  resultsDelivery!: ResultsDelivery;
  threat!: any;
  gridApi: any;
  node: any;
  params: any;

  constructor(private flagService: FlagService) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.resultsDelivery = params.colDef?.cellRendererParams.resultsDelivery;
    this.threat = params.data;
    this.gridApi = params.api;
    this.node = this.gridApi.getRowNode(this.threat.id);
    this.ngOnChanges();
  }
  onIconClick(event: any): void {
    event.stopPropagation();
    this.params?.onClick?.(this.params);
  }
  ngOnChanges(): void {
    this.flags = this.flagService.initializeFlags(
      this.resultsDelivery,
      this.node,
    );
  }

  toggleActive(id: string): void {
    this.flagService.toggleActive(
      id,
      this.resultsDelivery,
      this.node,
      this.gridApi,
    );
  }
}
