import { AgFilterComponent } from '@ag-grid-community/angular';
import { IDoesFilterPassParams, IFilterParams } from '@ag-grid-community/core';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { NameFilterType } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-custom-text-filter-component',
  templateUrl: './customTextFilter.component.html',
  styleUrls: ['./customTextFilter.component.scss'],
})
export class CustomTextFilterComponent implements AgFilterComponent {
  params: IFilterParams | undefined;
  filterText: string | null = '';
  filterType = 'customText';
  type: NameFilterType = 'CONTAINS';
  subFilter: any;

  hidePopup: any;
  valueAccessor: ((date: IDoesFilterPassParams) => string) | undefined;
  @ViewChild('input') inputElement: ElementRef | undefined;

  agInit(params: IFilterParams): void {
    this.params = params;
    const colId = params.colDef.field as string;
    this.valueAccessor = (params1: IDoesFilterPassParams) =>
      params1.data[colId];
  }

  isFilterActive(): boolean {
    return this.filterText != null && this.filterText !== '';
  }

  getModel(): any {
    return {
      filter: this.filterText,
      filtertType: this.filterType,
      type: this.type,
      subFilter: this.subFilter,
    };
  }

  setModel(model: any): void {
    this.filterText = model?.filter!;
    this.filterType = model?.filterType!;
    this.type = model?.type!;
    this.subFilter = model?.subFilter;
  }

  onApply(): void {
    this.params!.filterChangedCallback();
    this.hidePopup();
  }

  onClear(): void {
    this.filterText = null;
  }

  afterGuiAttached(params: any): void {
    this.hidePopup = params?.hidePopup;
    this.inputElement?.nativeElement.focus();
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    if (Array.isArray(this.valueAccessor!(params)!)) {
      return (
        this.valueAccessor!(params)![0]
          .toLowerCase()
          .indexOf(this.filterText!.toLowerCase()) >= 0
      );
    }

    return (
      this.valueAccessor!(params)!
        .toLowerCase()
        .indexOf(this.filterText!.toLowerCase()) >= 0
    );
  }

  onEnter(): void {
    this.onApply();
  }
}
