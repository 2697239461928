<div class="reportDate" [ngClass]="{ 'padding-on-customize': customize }">
  <div class="label" *ngIf="!customize">{{ data.reportDate!.label }}</div>
  <mat-form-field
    *ngIf="customize"
    appearance="outline"
    class="custom-label cdx-input-default"
  >
    <input
      matInput
      #input
      [(ngModel)]="data.reportDate!.label"
      [disabled]="disableSubsection"
    />
  </mat-form-field>
  <div class="datepicker">
    <mat-form-field appearance="outline">
      <input
        matInput
        [matDatepicker]="picker"
        [(ngModel)]="reportDate"
        (dateChange)="addEvent($event)"
        title="Please choose a Date"
        placeholder="Choose a date"
        [disabled]="disableSubsection"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="picker"
        role="button"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
</div>
