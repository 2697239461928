import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-risk-level-renderer',
  templateUrl: './risk-level-renderer.component.html',
  styleUrls: ['./risk-level-renderer.component.scss'],
})
export class RiskLevelRendererComponent {
  riskLevel?: string;

  agInit(params: ICellRendererParams): void {
    this.riskLevel = params.value;
  }
}
