<div class="number-picker">
  <button
    mat-icon-button
    (click)="decrease($event)"
    [disabled]="decreaseButtonDisabled"
  >
    <mat-icon>remove_circle_outline</mat-icon>
  </button>
  <mat-form-field>
    <input
      matInput
      [readonly]="true"
      [formControl]="control"
      name="numberPickerInputField"
    />
  </mat-form-field>
  <button
    mat-icon-button
    (click)="increase($event)"
    [disabled]="increaseButtonDisabled || disable"
  >
    <mat-icon>add_circle_outline</mat-icon>
  </button>
</div>
