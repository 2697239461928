import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { QuillModule } from 'ngx-quill';

import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CustomDropzonePreviewComponent } from './components/custom-dropzone-preview/custom-dropzone-preview.component';
import { CustomLogoUploadComponent } from './components/custom-logo-upload/custom-logo-upload.component';
import { CustomizeSkinComponent } from './components/customize-skin/customize-skin.component';
import { DatabasesAndSourcesComponent } from './components/databases-and-sources/databases-and-sources.component';
import { DynamicPropertyComponent } from './components/dynamic-property/dynamic-property.component';
import { ExportComponent } from './components/export-button/export.component';
import { GraphicSelectComponent } from './components/graphic-select/graphic-select.component';
import { IncludeFulltextComponent } from './components/include-fulltext/include-fulltext.component';
import { InputDatePickerComponent } from './components/input-datepicker/input-datepicker.component';
import { InputSearchStrategyComponent } from './components/input-search-strategy/input-search-strategy.component';
import { PropertyInputComponent } from './components/property-input/property-input.component';
import { ReportEditorComponent } from './components/report-editor/report-editor.component';
import { ReportSectionContentComponent } from './components/report-section-content/report-section-content.component';
import { ReportSectionComponent } from './components/report-section/report-section.component';
import { ResultGraphicSelectComponent } from './components/result-graphic-select/result-graphic-select.component';
import { SummaryGraphicSelectComponent } from './components/summary-graphic-select/summary-graphic-select.component';
import { TableSummaryComponent } from './components/table-summary/table-summary.component';
import { TextAreaComponent } from './components/text-area/text-area.component';
import { ToggleFormatComponent } from './components/toggle-format/toggle-format.component';
import { TopThreatsComponent } from './components/top-threats/top-threats.component';
import { ScrollSpyDirective } from './directive/scroll-spy.directive';
import { SaveTemplateComponent } from './components/save-template/save-template.component';
import { ExportConfirmationDialogComponent } from './components/confirmation-dialog/confimation-dialog.component';
import { CustomTooltipModule } from 'src/app/custom-tooltip/custom-tooltip.module';
import { MatSelectModule } from '@angular/material/select';
import { NameInputDialogComponent } from './components/name-input-dialog/name-input-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TextAreaWithBoilerplateComponent } from './components/text-area-with-boilerplate/text-area-with-boilerplate.component';

@NgModule({
  imports: [
    CustomTooltipModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    PortalModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatStepperModule,
    MatSlideToggleModule,
    TextFieldModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    NgxDropzoneModule,
    MatProgressBarModule,
    MatTooltipModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    MatSelectModule,
    MatFormFieldModule,
  ],
  declarations: [
    ExportConfirmationDialogComponent,
    ReportEditorComponent,
    ExportComponent,
    ScrollSpyDirective,
    ReportSectionComponent,
    ReportSectionContentComponent,
    PropertyInputComponent,
    CustomizeSkinComponent,
    DynamicPropertyComponent,
    TextAreaComponent,
    DatabasesAndSourcesComponent,
    SummaryGraphicSelectComponent,
    ResultGraphicSelectComponent,
    GraphicSelectComponent,
    TableSummaryComponent,
    CheckboxComponent,
    TopThreatsComponent,
    IncludeFulltextComponent,
    InputDatePickerComponent,
    InputSearchStrategyComponent,
    ToggleFormatComponent,
    CustomLogoUploadComponent,
    CustomDropzonePreviewComponent,
    SaveTemplateComponent,

    NameInputDialogComponent,
    TextAreaWithBoilerplateComponent,
  ],
  exports: [ExportComponent],
})
export class ExportModule {}
