<mat-card-header
  ><mat-card-title>
    Expiry Date:
    {{ convertDate(contract!.endDate!) | date: 'dd&nbsp;MMMM&nbsp;yyyy' }}
  </mat-card-title>
  <mat-card-subtitle
    >Start Date:
    {{
      convertDate(contract!.startDate!) | date: 'dd MMMM yyyy'
    }}</mat-card-subtitle
  >
</mat-card-header>
<mat-card-content class="expiry-date">
  <div>
    <mat-icon class="material-icons-outlined"> calendar_month </mat-icon>
  </div>
  <div class="days-remaining">{{ getDaysRemaining() }} Days</div>

  <div class="bottom-text">Remaining</div>
</mat-card-content>
