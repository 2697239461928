<ng-container class="report-editor-dialog">
  <div class="title" mat-dialog-title>
    <h2>Report Editor</h2>
    <button class="close" mat-icon-button tabindex="0">
      <mat-icon class="closeicon" mat-dialog-close>close</mat-icon>
    </button>
  </div>
  <div class="divider"></div>
  <mat-dialog-content *ngIf="exportTemplate.sections">
    <div class="left">
      <nav class="sections">
        <div>
          <div class="sections-header">
            <h3>Report Sections</h3>
            <button
              class="add-btn"
              mat-icon-button
              (click)="addSection(exportTemplate.sections)"
              tabindex="0"
            >
              <mat-icon matTooltip="Add report section" class="add-icon"
                >add</mat-icon
              >
            </button>
          </div>
          <div
            cdkDropList
            [cdkDropListData]="exportTemplate!.sections!"
            (cdkDropListDropped)="reorder($event, exportTemplate!.sections!)"
            [cdkDropListLockAxis]="'y'"
          >
            <div
              cdkDropListGroup
              class="section-container"
              *ngFor="
                let section of exportTemplate!.sections!;
                let sectionIndex = index
              "
              cdkDrag
              [cdkDragData]="section.subSections"
            >
              <app-report-section
                [section]="section"
                [sectionNumber]="sectionIndex + 1"
                [selectedSection]="selectedSection!"
                [showHeader]="true"
                [sections]="exportTemplate!.sections!"
                (click)="selectSection(section)"
                (sectionSelected)="onChangeSelectedSection($event)"
                (subSectionSelected)="onChangeSelectedSubSection($event)"
                (customizeMode)="onChangeCustomizeMode($event)"
              >
                <ol
                  *ngIf="section.subSections!.length > 1"
                  class="sub-sections"
                  cdkDropList
                  [cdkDropListData]="section.subSections!"
                  (cdkDropListDropped)="reorder($event, section.subSections!)"
                  [cdkDropListLockAxis]="'y'"
                >
                  <ng-container
                    *ngFor="
                      let item of featureLevelSubSectionFilterService.filteredSubsection(
                        section.subSections!,
                        featureLevel
                      );
                      let subSectionIndex = index
                    "
                  >
                    <li
                      cdkDrag
                      [cdkDragData]="item"
                      (click)="selectSubSection(item); $event.stopPropagation()"
                      [ngClass]="{
                        'report-editor-disabled-div': !(
                          item?.require || item?.include
                        )
                      }"
                      *ngIf="item?.require || item?.include || customizeMode"
                    >
                      <div class="header-item">
                        <mat-icon
                          class="drag-icon"
                          matTooltip="Change sequence of subsection within this section with drag and drop"
                          >drag_handle</mat-icon
                        >
                        <div
                          class="threat-name"
                          [ngClass]="{ selected: selectedSubSection === item }"
                        >
                          {{ item.label }}
                        </div>
                      </div>
                      <div>
                        <mat-icon
                          *ngIf="item.name === 'customSubSection'"
                          matTooltip="Remove report subsection"
                          class="material-symbols-outlined"
                          (click)="
                            openConfirmationPopup(
                              section,
                              item,
                              subSectionIndex
                            )
                          "
                          >delete</mat-icon
                        >
                      </div>
                    </li>
                  </ng-container>
                </ol>
              </app-report-section>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <div class="right">
      <div class="customize-toggle">
        <span></span>
        <mat-slide-toggle
          role="button"
          color="primary"
          [(ngModel)]="customizeMode"
          (change)="toggleChange($event)"
        >
          Customize</mat-slide-toggle
        >
      </div>

      <app-report-section-content
        #content
        [exportTemplate]="exportTemplate"
        [selectedSection]="selectedSection!"
        [selectedSubSection]="selectedSubSection!"
        [topThreats]="data.topThreats"
        [resultsDeliveryId]="data.resultsDeliveryId"
        [featureLevel]="featureLevel"
        [customizeMode]="
          selectedSection!.name !== 'reportSetup' ? customizeMode : false
        "
        (wheel)="onMouseScroll($event)"
      ></app-report-section-content>
    </div>
  </mat-dialog-content>
  <div class="divider"></div>
  <mat-dialog-actions align="end">
    <div class="left-button">
      <button
        class="back"
        mat-stroked-button
        color="primary"
        [ngClass]="{ hide: isFirst() }"
        (click)="goBack()"
      >
        Back
      </button>
    </div>

    <div class="right-button">
      <button
        class="save"
        mat-stroked-button
        color="primary"
        (click)="saveReportTemplate()"
      >
        Save
      </button>
      <button
        class="next"
        mat-flat-button
        color="primary"
        [ngClass]="{ hide: isLast() }"
        (click)="goNext()"
      >
        Next
      </button>
      <button
        mat-dialog-close
        class="create-report"
        mat-raised-button
        color="primary"
        [ngClass]="{ show: isLast() }"
        (click)="createReport()"
      >
        Create report
      </button>
    </div>
  </mat-dialog-actions>
</ng-container>
