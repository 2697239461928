import { Component, Input } from '@angular/core';
import {
  ExportService,
  SelectForExportRequest,
} from '@compumark/bla-backend-client';

@Component({
  selector: 'app-absolute-grounds-item',
  templateUrl: './absolute-grounds-item.component.html',
  styleUrls: ['./absolute-grounds-item.component.scss'],
})
export class AbsoluteGroundsItemComponent {
  @Input() item!: any;
  @Input() resultsDeliveryId!: string;

  constructor(private exportService: ExportService) {}

  toggleSelection(id: string, selected: boolean): void {
    this.exportService
      .selectForExport(
        {
          ids: [id],
          parentId: this.resultsDeliveryId,
          selectionType: 'ABSOLUTE_GROUNDS',
          selectedForExport: !selected,
        } as SelectForExportRequest,
        this.resultsDeliveryId,
      )
      .subscribe({
        complete: () => {
          this.item.selectedForExport = !this.item.selectedForExport;
        },
      });
  }
}
