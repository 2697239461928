import { Injectable } from '@angular/core';
import { GridApi } from '@ag-grid-community/core';
import { NotificationService } from '@compumark/brand-context-components';
import { FlagService } from 'src/app/threats-tables/services/flag.service';
import { ActionRendererService } from 'src/app/threats-tables/services/action-renderer.service';
import {
  ExportService,
  SelectForExportRequest,
} from '@compumark/bla-backend-client';

@Injectable({
  providedIn: 'root',
})
export class BulkActionService {
  constructor(
    private flagService: FlagService,
    private actionRendererService: ActionRendererService,
    private notificationService: NotificationService,
    private exportService: ExportService,
  ) {}

  handleFlagAction(
    selectedThreats: Set<string>,
    gridApi: GridApi,
    resultsDeliveryId: string,
    event: { type: string; value?: any },
  ): void {
    selectedThreats.forEach((threatId) => {
      const flagId = event.value.id;
      const rowNode = gridApi?.getRowNode(threatId);
      if (rowNode) {
        this.flagService.toggleActive(
          flagId,
          resultsDeliveryId,
          rowNode,
          gridApi,
          event.value.checked,
        );
      }
    });
  }

  handleTopThreatAction(
    selectedThreats: Set<string>,
    gridApi: GridApi,
    resultsDeliveryId: string,
    event: { type: string; value?: any },
    selectionType: any,
  ): void {
    const previousState: { [key: string]: boolean } = {};
    selectedThreats.forEach((threatId) => {
      const row = gridApi?.getRowNode(threatId);
      previousState[threatId] = row ? row.data.selectedForExport : false;
      this.actionRendererService
        .toggleSelectForExport(
          threatId,
          resultsDeliveryId,
          !event.value,
          gridApi,
          true,
          selectionType,
        )
        .subscribe(() => {
          if (row) {
            gridApi?.redrawRows({ rowNodes: [row] });
          }
        });
    });
    setTimeout(() => {
      const snackbarRef = this.notificationService.showSnackbar(
        'The selected citations have been added for export',
        'undo',
        3000,
      );
      snackbarRef.onAction().subscribe(() => {
        selectedThreats.forEach((threatId) => {
          const row = gridApi?.getRowNode(threatId);
          const undoRequest: SelectForExportRequest = {
            ids: [threatId],
            parentId: resultsDeliveryId,
            selectionType,
            selectedForExport: previousState[threatId],
          };
          this.exportService
            .selectForExport(undoRequest, resultsDeliveryId)
            .subscribe(() => {
              if (row) {
                row.data.selectedForExport = previousState[threatId];
                gridApi?.redrawRows({ rowNodes: [row] });
              }
            });
        });
      });
    }, 300);
  }

  handleCommentAction(selectedThreats: Set<string>, gridApi: GridApi): void {
    this.actionRendererService.addCommentToMultipleThreats(
      gridApi?.getSelectedNodes() || [],
      gridApi,
      Array.from(selectedThreats),
    );
  }
}
