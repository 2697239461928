import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeatureLevel, PricePackage } from '@compumark/bla-backend-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-packages-history',
  templateUrl: './packages-history.component.html',
  styleUrls: ['./packages-history.component.scss'],
})
export class PackagesHistoryComponent {
  packages$: Observable<PricePackage[]>;
  selectedPackage$: Observable<PricePackage>;

  constructor(route: ActivatedRoute) {
    this.packages$ = route.data.pipe(map((data) => data.packages));
    this.selectedPackage$ = route.data.pipe(
      map(
        (data) =>
          data.packages.filter(
            (pack: PricePackage) =>
              pack.id === route.snapshot.paramMap.get('packageId'),
          )[0],
      ),
    );
  }

  featureLevelDescription(pack: PricePackage): string {
    return pack.featureLevel === FeatureLevel.STANDARD ? 'Standard' : 'Expert';
  }

  marketsDescription(pack: PricePackage): string {
    if (!!pack.numberOfMarkets!.GLOBAL) {
      return 'Global';
    } else if (!!pack.numberOfMarkets!.BLEU) {
      return 'EU package';
    } else {
      return (
        (!!pack.numberOfMarkets!.ONE_COUNTRY
          ? pack.numberOfMarkets!.ONE_COUNTRY
          : 0) +
        (!!pack.numberOfMarkets!.PACK_OF_5
          ? pack.numberOfMarkets!.PACK_OF_5 * 5
          : 0) +
        (!!pack.numberOfMarkets!.PACK_OF_10
          ? pack.numberOfMarkets!.PACK_OF_10 * 10
          : 0) +
        ' markets'
      );
    }
  }
}
