import { Component, Input, OnInit } from '@angular/core';
import { DatabasesAndSources } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-databases-and-sources',
  templateUrl: './databases-and-sources.component.html',
  styleUrls: ['./databases-and-sources.component.scss'],
})
export class DatabasesAndSourcesComponent implements OnInit {
  @Input() data!: DatabasesAndSources;
  @Input() customize!: boolean;
  @Input() disableSubsection!: boolean;
  constructor() {}

  ngOnInit(): void {}
}
