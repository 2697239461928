import { Injectable } from '@angular/core';
import {
  FilterChangeService,
  FilterChipStateService,
} from '@compumark/brand-context-components';
import _ from 'lodash';

@Injectable()
export class ClearableFilterChangeService extends FilterChangeService {
  constructor(protected filterChipStateService: FilterChipStateService) {
    super(filterChipStateService);
  }

  clearAppliedFilters(): void {
    this.appliedFilters = undefined;
  }
}
