import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxColorsModule } from 'ngx-colors';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlagDefinitionDialogComponent } from './components/flag-definitions-dialog/flag-definitions-dialog.component';
import { FlagDefinitionsButtonComponent } from './components/flag-definitions-button/flag-definitions-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FlagDefinitionComponent } from './components/flag-definition/flag-definition.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlagFilterComponent } from './components/flag-filter/flag-filter.component';
import { FilterChipsModule } from '@compumark/brand-context-components';
import { FlagsComponent } from './components/flags/flags.component';
import { FlagsTableComponent } from './components/flags-table/flags-table.component';

@NgModule({
  declarations: [
    FlagDefinitionsButtonComponent,
    FlagDefinitionDialogComponent,
    FlagDefinitionComponent,
    FlagsComponent,
    FlagsTableComponent,
    FlagFilterComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    NgxColorsModule,
    MatTooltipModule,
    FilterChipsModule,
  ],
  exports: [
    FlagDefinitionsButtonComponent,
    FlagsComponent,
    FlagsTableComponent,
  ],
})
export class FlagModule {}
