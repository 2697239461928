import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CappedAccountComponent } from './components/capped-account/capped-account.component';
import { PackagesHistoryComponent } from './components/packages-history/packages-history.component';
import { PurchasePackageComponent } from './components/purchase-package/purchase-package.component';
import { RedirectToFirstPackageComponent } from './components/redirect-to-first-package/redirect-to-first-package.component';
import { UncappedAccountComponent } from './components/uncapped-account/uncapped-account.component';
import { LeaveGuard } from './guards/leave.guard';
import { AccountStatisticsResolver } from './resolvers/account-statistics.resolver';
import { PackageFromBackendResolver } from './resolvers/packages-from-backend.resolver';

const routes: Routes = [
  {
    path: 'packages',
    component: RedirectToFirstPackageComponent,
    resolve: {
      packages: PackageFromBackendResolver,
    },
    data: {
      breadcrumb: {
        skip: true,
      },
    },
  },
  {
    path: 'capped',
    component: CappedAccountComponent,
    resolve: {
      stats: AccountStatisticsResolver,
    },
    data: {
      breadcrumb: {
        skip: true,
      },
    },
  },
  {
    path: 'uncapped',
    component: UncappedAccountComponent,
    resolve: {
      stats: AccountStatisticsResolver,
    },
    data: {
      breadcrumb: {
        skip: true,
      },
    },
  },
  {
    path: 'packages/create',
    component: PurchasePackageComponent,
    runGuardsAndResolvers: 'always',
    resolve: {
      packages: PackageFromBackendResolver,
    },
    canDeactivate: [LeaveGuard],
    data: {
      breadcrumb: {
        skip: true,
      },
    },
  },
  {
    path: 'packages/:packageId',
    component: PackagesHistoryComponent,
    resolve: {
      packages: PackageFromBackendResolver,
    },
    data: {
      breadcrumb: {
        skip: true,
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [AccountStatisticsResolver, PackageFromBackendResolver],
  exports: [RouterModule],
})
export class PricingRoutingModule {}
