import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ResultsDelivery,
  UpdateThreatSelection,
} from '@compumark/bla-backend-client';
import { Observable } from 'rxjs';
import { TopThreatsCounterService } from 'src/app/threats-tables/services/top-threats-counter.service';

@Component({
  selector: 'app-brandlandscape-navigation-bar',
  templateUrl: './brandlandscape-navigation-bar.component.html',
  styleUrls: ['./brandlandscape-navigation-bar.component.scss'],
})
export class BrandlandscapeNavigationBarComponent implements OnInit {
  @Input() resultsDelivery?: ResultsDelivery | undefined;
  currentCount!: number;
  public count$: Observable<number>;

  updatedThreatsSelection: UpdateThreatSelection[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public counterService: TopThreatsCounterService,
  ) {
    this.count$ = this.counterService.getCount();
  }

  isActiveTab(): any {
    if (
      this.router.url.includes('all-threats') ||
      this.router.url.includes('pharma-threats') ||
      this.router.url.includes('common-law') ||
      this.router.url.includes('wcl') ||
      this.router.url.includes('company-names') ||
      this.router.url.includes('domain-names')
    ) {
      return 'all-threats';
    } else if (this.router.url.includes('top-threats')) {
      return 'top-threats';
    } else if (this.router.url.includes('owned-brands')) {
      return 'owned-brands';
    }
  }

  ngOnInit(): void {
    this.counterService.restoreCountFromPreference(this.resultsDeliveryId);
  }

  isExpert(): boolean {
    return (
      this.route.snapshot.data.resultsDelivery.strategy.featureLevel ===
      'EXPERT'
    );
  }

  get resultsDeliveryId(): string {
    return this.route.snapshot.params.resultsDeliveryId;
  }

  openIsolatePage(): void {
    this.counterService.selectedTopThreats = new Set([]);
    this.counterService.updateCount(this.resultsDeliveryId);
    const url =
      '/summary/isolate/results-delivery/' +
      this.resultsDeliveryId +
      '/top-threats';
    this.router.navigate([url], {
      queryParams: {
        refresh: true,
      },
    });
  }

  openAllThreatsPage(): void {
    this.router.navigate([
      '/summary/isolate/results-delivery/' +
        this.resultsDeliveryId +
        '/all-threats',
    ]);
  }

  openOwnBrandsPage(): void {
    this.router.navigate([
      '/summary/isolate/results-delivery/' +
        this.resultsDeliveryId +
        '/owned-brands',
    ]);
  }
}
