import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ResultsDeliveryService,
  ResultsDeliveryStatus,
} from '@compumark/bla-backend-client';
import { NotificationService } from '@compumark/brand-context-components';
import { timer } from 'rxjs';
import { Observable } from 'rxjs';
import { distinct, switchMap, takeWhile, tap } from 'rxjs/operators';

export function isNotTerminalStatus(status: ResultsDeliveryStatus): boolean {
  return !isTerminalStatus(status);
}

export function isTerminalStatus(status: ResultsDeliveryStatus): boolean {
  return [
    ResultsDeliveryStatus.COMPLETED,
    ResultsDeliveryStatus.ERROR,
    ResultsDeliveryStatus.TOO_MANY_RESULTS,
    ResultsDeliveryStatus.TOE_NOT_FOUND,
    ResultsDeliveryStatus.SEARCH_FAILED,
    ResultsDeliveryStatus.RISK_ASSESSMENT_FAILED,
  ].includes(status);
}

export function isErrorStatus(status: ResultsDeliveryStatus): boolean {
  return [
    ResultsDeliveryStatus.ERROR,
    ResultsDeliveryStatus.TOO_MANY_RESULTS,
    ResultsDeliveryStatus.TOE_NOT_FOUND,
    ResultsDeliveryStatus.SEARCH_FAILED,
    ResultsDeliveryStatus.RISK_ASSESSMENT_FAILED,
  ].includes(status);
}

export function isGenericErrorStatus(status: ResultsDeliveryStatus): boolean {
  return [
    ResultsDeliveryStatus.ERROR,
    ResultsDeliveryStatus.SEARCH_FAILED,
    ResultsDeliveryStatus.RISK_ASSESSMENT_FAILED,
  ].includes(status);
}

@Injectable()
export class ResultsDeliveryStatusFollowerService {
  constructor(
    private resultsDeliveryService: ResultsDeliveryService,
    private notification: NotificationService,
    private router: Router,
  ) {}

  followStatusOfResultsDelivery(
    resultsDeliveryId: string,
    showInfoMessage: boolean,
    pollingInterval = 10000,
  ): Observable<ResultsDeliveryStatus> {
    return timer(0, pollingInterval).pipe(
      tap((timerIteration) => {
        if (timerIteration === 0 && showInfoMessage) {
          this.notification
            .showSnackbar(
              "Your analysis should be ready shortly. If you'd rather not wait, a link to the results should be sent to your email within minutes (complex or detailed searches may take longer).",
              'Initiate another search',
            )
            .onAction()
            .subscribe(() => {
              this.router.navigate(['/initiate']).then(() => {
                window.location.reload();
              });
            });
        }
      }),
      switchMap(() => {
        return this.resultsDeliveryService.getResultsDeliveryStatus(
          resultsDeliveryId,
        );
      }),
      takeWhile(isNotTerminalStatus, true),
      distinct(),
    );
  }
}
