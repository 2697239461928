<div class="container">
  <span class="comment">
    <lib-threat-comment
      [threatName]="name"
      [threatId]="id"
      [userId]="userId"
      [userName]="userName"
      [comments]="comments"
      (iconClick)="onIconClick($event)"
    ></lib-threat-comment>
  </span>

  <button mat-icon-button class="actions-export-button">
    <span class="icon-wrapper">
      <ng-container *ngIf="!selectedForExport">
        <span
          class="material-symbols-outlined file-download file-download-empty"
          matTooltip="Select for export"
          (click)="onSelectionChanged()"
          (click)="onIconClick($event)"
        >
          file_download
        </span>
      </ng-container>
      <ng-container *ngIf="selectedForExport">
        <mat-icon
          fontSet="material-symbols-outlined"
          class="file-download"
          matTooltip="Added to export report"
          (click)="onSelectionChanged()"
          (click)="onIconClick($event)"
        >
          file_download
        </mat-icon>
        <mat-icon
          fontSet="material-symbols-outlined"
          style="font-variation-settings: 'FILL' 1"
          class="check-circle"
          (click)="onSelectionChanged()"
          (click)="onIconClick($event)"
        >
          check_circle
        </mat-icon>
      </ng-container>
    </span>
  </button>
</div>
