<mat-dialog-content> What do you want to do next? </mat-dialog-content>

<mat-dialog-actions align="center">
  <button
    mat-flat-button
    color="primary"
    aria-label="cdx primary medium button"
    class="cdx-but-md"
    mat-dialog-close
  >
    Purchase another package
  </button>
  <button
    mat-flat-button
    color="primary"
    aria-label="cdx primary medium button"
    class="cdx-but-md"
    mat-dialog-close="CONTINUE"
  >
    Initiate a search
  </button>
</mat-dialog-actions>
