<ng-container *ngIf="showTitle">
  <div ngClass="title">Top Threats</div>
</ng-container>

<highcharts-chart
  [ngClass]="{ 'custom-size': customSize }"
  [Highcharts]="Highcharts"
  [options]="options"
  *ngIf="options"
  constructorType="mapChart"
></highcharts-chart>
