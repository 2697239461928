<div *ngIf="!!threat" class="domain-name-container">
  <img
    *ngIf="!!threat?.screenshot"
    [src]="threat?.screenshot"
    class="screenshot"
  />

  <div class="rows">
    <div class="row">
      <div class="label">Domain Name</div>
      <div class="link" click="openDomainUrl()">
        <a [href]="getUrl()" target="_blank">{{ threat?.name }}</a>
      </div>
    </div>

    <div class="whois-data">
      <div class="label">WHOIS Data</div>
      <div *ngIf="!whoisRetrieved" class="whois-button">
        <button
          mat-stroked-button
          color="primary"
          class="cdx-but-lg"
          (click)="getWhoisData()"
        >
          Retrieve WHOIS Information
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="whoisRetrieved">
    <div [innerHTML]="threat?.whois | safeHtml" class="whois-info"></div>
  </ng-container>

  <div class="web-page-info" *ngIf="showWebPageInfo()">
    <label>Web Page Information</label>
    <table>
      <tr *ngIf="!!threat.title">
        <th>HTML Title</th>
        <td>{{ threat.title }}</td>
      </tr>
      <tr *ngIf="!!threat.keywords">
        <th>Keywords</th>
        <td>{{ threat.keywords }}</td>
      </tr>
      <tr *ngIf="!!threat.description">
        <th>Description</th>
        <td>{{ threat.description }}</td>
      </tr>
      <tr *ngIf="!!threat.status">
        <th>Status</th>
        <td>{{ threat.status }}</td>
      </tr>
    </table>
  </div>
</div>
