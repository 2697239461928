import { AgGridModule } from '@ag-grid-community/angular';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { DataGridModule } from '../data-grid/data-grid.module';
import { AppFooterModule } from '../footer/footer.module';

import { DownloadReportComponent } from './components/download-report/download-report.component';
import { GoToSummaryRendererComponent } from './components/go-to-summary-renderer/go-to-summary-renderer.component';
import { HistoryLinkComponent } from './components/history-link/history-link.component';
import { HistoryComponent } from './components/history/history.component';
import { HistoryRoutingModule } from './history-routing.module';
import { HistoryDatasource } from './services/history.datasource';

ModuleRegistry.registerModules([ServerSideRowModelModule]);

@NgModule({
  imports: [
    AgGridModule,
    AppFooterModule,
    CommonModule,
    DataGridModule,
    HistoryRoutingModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    FormsModule,
  ],
  declarations: [
    HistoryComponent,
    GoToSummaryRendererComponent,
    DownloadReportComponent,
    HistoryLinkComponent,
  ],
  providers: [HistoryDatasource, DatePipe],
  exports: [HistoryLinkComponent, HistoryComponent],
})
export class HistoryModule {}
