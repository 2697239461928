import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { Module } from '@ag-grid-community/core';
import { formatDate } from '@angular/common';
import {
  Component,
  Inject,
  LOCALE_ID,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  ExportJasperTemplate,
  ExportService,
} from '@compumark/bla-backend-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-export-admin',
  templateUrl: './export-admin.component.html',
  styleUrls: ['./export-admin.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExportAdminComponent implements OnInit {
  modules: Module[] = [ClientSideRowModelModule];

  exportTemplates$?: Observable<ExportJasperTemplate[]>;
  updatedTemplatesInfo?: ExportJasperTemplate[];
  disableUpload = false;

  columnDefs = [{ field: 'id' }, { field: 'templateId' }, { field: 'name' }];

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private exportService: ExportService,
  ) {
    this.getTemplateInfo();
  }

  ngOnInit(): void {}

  uploadTemplates(): void {
    this.disableUpload = true;
    this.exportService.updateTemplates().subscribe((et) => {
      this.updatedTemplatesInfo = et;
      this.getTemplateInfo();
      this.disableUpload = false;
    });
  }

  getTemplateInfo(): void {
    this.exportTemplates$ = this.exportService
      .getTemplateInfo()
      .pipe(map((et) => et));
  }

  getDate(date: any): string {
    return formatDate(
      new Date(parseInt(date, 10)),
      'dd/MM/yyyy HH:mm:ss',
      this.locale,
    );
  }
}
