import { ITooltipAngularComp } from '@ag-grid-community/angular';
import { ITooltipParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
@Component({
  selector: 'app-material-tooltip',
  templateUrl: './material-tooltip.component.html',
  styleUrls: ['./material-tooltip.component.scss'],
})
export class MaterialTooltipComponent implements ITooltipAngularComp {
  tooltipText: string | null = '';

  agInit(params: ITooltipParams): void {
    const doc = new DOMParser().parseFromString(params.value, 'text/html');
    this.tooltipText = doc.documentElement.textContent;
  }
}
