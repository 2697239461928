import { Component, Input } from '@angular/core';
import { Row } from '@compumark/bla-backend-client';

@Component({
  selector: 'app-property-input',
  templateUrl: './property-input.component.html',
  styleUrls: ['./property-input.component.scss'],
})
export class PropertyInputComponent {
  @Input() data!: Row;
  @Input() customize = false;
  @Input() disableSubsection = false;
}
