import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RiskPerMarketItem } from '../risk-per-market/risk-per-market.component';
import { RedirectSelectedRiskPerMarketService } from '../services/redirect-selected-risk-per-market-service';

export interface RiskPerMarketDialogData {
  resultsDeliveryId: string;
  classes: string[];
}

@Component({
  selector: 'app-risk-per-market-dialog',
  templateUrl: './risk-per-market-dialog.component.html',
  styleUrls: ['./risk-per-market-dialog.component.scss'],
})
export class RiskPerMarketDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RiskPerMarketDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RiskPerMarketDialogData,
    private redirectSelectedRiskPerMarketService: RedirectSelectedRiskPerMarketService,
  ) {}

  selectedItemsEvent(selectedItems: RiskPerMarketItem[]): void {
    this.redirectSelectedRiskPerMarketService.redirectToAllThreats(
      this.data.resultsDeliveryId,
      selectedItems,
    );

    this.dialogRef.close();
  }
}
