import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import {
  ThreatsService,
  UpdateThreatSelection,
  UpdateThreatSelectionAction,
} from '@compumark/bla-backend-client';
import { UserService } from 'src/app/security/services/user.service';
import { NotificationService } from '@compumark/brand-context-components';
import { TopThreatsCounterService } from 'src/app/threats-tables/services/top-threats-counter.service';

@Component({
  selector: 'app-actions-renderer',
  templateUrl: './actions-renderer.component.html',
  styleUrls: ['./actions-renderer.component.scss'],
})
export class ActionsRendererComponent {
  id!: string;
  includedInTopThreats = false;
  name?: string;
  resultsDeliveryId!: string;
  gridApi: any;
  selectedTopThreats: Set<string> = new Set<string>();
  updatedThreatsSelection: UpdateThreatSelection[] = [];
  comments!: any[];
  params: any;

  constructor(
    public userService: UserService,
    private notification: NotificationService,
    private countService: TopThreatsCounterService,
    private threatsService: ThreatsService,
  ) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.id = params.data.id;
    this.includedInTopThreats = params.data.includedInTopThreats;
    this.name = params.data.trademarkName;
    this.resultsDeliveryId =
      params.colDef?.cellRendererParams.resultsDelivery.id;
    this.gridApi = params.api;
    this.comments = params.data.comments;
  }
  onIconClick(event: any): void {
    event.stopPropagation();
    this.params?.onClick?.(this.params);
  }
  get userName(): string {
    return this.userService.getCurrentUser()?.name!;
  }

  get userId(): string {
    return this.userService.getCurrentUser()?.id!;
  }

  onSelectionChanged(): void {
    if (this.id) {
      if (!this.includedInTopThreats) {
        this.addThreatToSelection();

        this.notification
          .showSnackbar(
            'The citation has been added to Top Citations and selected for export',
            'undo',
            3000,
          )
          .onAction()
          .subscribe(() => {
            this.removeThreatFromSelection();
          });
      } else {
        this.removeThreatFromSelection();

        this.notification
          .showSnackbar(
            'The citation has been removed from Top Citations and deselected from export',
            'undo',
            3000,
          )
          .onAction()
          .subscribe(() => {
            this.addThreatToSelection();
          });
      }
    }
  }

  addThreatToSelection(): void {
    this.includedInTopThreats = !this.includedInTopThreats;
    this.selectedTopThreats.add(this.id);
    this.countService.addThreat(this.resultsDeliveryId, this.id);
    this.updateThreatsSelection(
      Array.from(this.selectedTopThreats),
      UpdateThreatSelectionAction.ADD,
    );
    this.updateTopThreats(this.updatedThreatsSelection);
  }

  removeThreatFromSelection(): void {
    this.includedInTopThreats = !this.includedInTopThreats;
    this.selectedTopThreats.add(this.id);
    this.updateThreatsSelection(
      Array.from(this.selectedTopThreats),
      UpdateThreatSelectionAction.REMOVE,
    );
    this.updateTopThreats(this.updatedThreatsSelection);
    this.selectedTopThreats.delete(this.id);
    this.countService.removeThreat(this.resultsDeliveryId, this.id);
  }

  updateTopThreats(threats: UpdateThreatSelection[]): void {
    this.gridApi.forEachNode((node: any) => {
      const foundThreat = threats.find((t) => t.threatId === node.data.id);
      if (!!foundThreat) {
        node.data.includedInTopThreats =
          foundThreat?.action === UpdateThreatSelectionAction.ADD;
        node.setData(node.data);
        this.gridApi.refreshCells({ rowNodes: [node], force: false });
      }
    });

    threats.forEach((threat) => threat);
    this.threatsService
      .updateTopThreatsForResultsDelivery(
        this.resultsDeliveryId,
        this.updatedThreatsSelection,
      )
      .subscribe(() => {});
  }

  public updateThreatsSelection(
    threats: string[],
    action: UpdateThreatSelectionAction,
  ): void {
    threats.forEach((threatUpdate) => {
      const index = this.updatedThreatsSelection.findIndex(
        (threat) => threat.threatId === threatUpdate,
      );
      this.updatedThreatsSelection.push({
        threatId: threatUpdate,
        action,
      });

      if (index >= 0) {
        this.updatedThreatsSelection.splice(index, 1);
      } else {
        this.updatedThreatsSelection.push({
          threatId: threatUpdate,
          action,
        });
      }
    });

    this.updatedThreatsSelection = Array.from(
      new Set(
        this.updatedThreatsSelection.map((threat) => JSON.stringify(threat)),
      ),
    ).map((threat) => JSON.parse(threat));
  }
}
