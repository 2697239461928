<div class="container">
  <span *ngIf="showAddToTopThreats">
    <button
      id="AddToTopThreats"
      mat-icon-button
      [disabled]="disabled"
      (click)="onclick('top-threat')"
      class="actions-export-button"
      [matTooltip]="'Add to Top Citations'"
    >
      <mat-icon fontSet="material-symbols-outlined" class="star-icon">{{
        true ? 'star_border' : 'star'
      }}</mat-icon>
    </button>
  </span>
  <span>
    <button
      id="AddComment"
      class="icon-size comment-icon actions-export-button"
      mat-icon-button
      [disabled]="disabled"
      (click)="onclick('comment')"
      matTooltip="Add a comment"
    >
      <mat-icon fontSet="material-symbols-outlined" class="small-icon"
        >mode_comment</mat-icon
      >
    </button>
  </span>
  <span>
    <button
      id="SelectForExport"
      class="icon-size actions-export-button"
      mat-icon-button
      [disabled]="disabled"
      (click)="onExport('top-threat')"
      matTooltip="Select for export"
    >
      <mat-icon fontSet="material-symbols-outlined" class="download-icon"
        >file_download</mat-icon
      >
    </button>
  </span>
  <span class="span-border-style" *ngIf="flagsEnabled">
    <span class="flags-wrapper">
      <ng-container *ngFor="let flag of flags">
        <button
          [id]="'Flag_' + flag.label"
          class="round-button"
          [ngStyle]="{
            'background-color': flag.checked ? flag.color : 'unset',
            'border-color': flag.checked ? flag.color : ''
          }"
          [disabled]="disabled"
          (click)="flagClick(flag)"
          [matTooltip]="flag.label"
        ></button>
      </ng-container>
    </span>
  </span>
</div>
