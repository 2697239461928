import { Component, Input, OnInit } from '@angular/core';
import {
  CoexistencePerMarketColumn,
  CoexistencePerMarketPatternType,
  CoexistencePerMarketRow,
  CoexistencePerMarketSearchPattern,
  CoexistencePerMarketTable,
} from '@compumark/bla-backend-client';
import { Observable } from 'rxjs';
import {
  CoexistencePerMarketColumnStateService,
  CoexistencePerMarketFilters,
  CoexistencePerMarketResult,
} from '../../services/coexistence-per-market-state-service';

@Component({
  selector: 'app-coexistence-per-market',
  templateUrl: './coexistence-per-market.component.html',
  styleUrls: ['./coexistence-per-market.component.scss'],
})
export class CoexistencePerMarketComponent implements OnInit {
  @Input()
  resultsDeliveryId?: string;
  @Input()
  limitNumberOfRows = false;
  @Input()
  keywords?: string[];
  @Input()
  classes?: string[];

  result$?: Observable<CoexistencePerMarketResult>;

  constructor(private stateService: CoexistencePerMarketColumnStateService) {}

  ngOnInit(): void {
    const filter = {
      keywords: this.keywords,
      classes: this.classes,
    } as CoexistencePerMarketFilters;
    this.result$ = this.stateService.result;
    if (this.limitNumberOfRows) {
      this.stateService.loadInitialData(this.resultsDeliveryId!, filter);
    }
  }

  getTable(result: CoexistencePerMarketResult): CoexistencePerMarketTable {
    return this.limitNumberOfRows ? result.filteredTable : result.table;
  }

  getColumns(rows: CoexistencePerMarketRow[]): CoexistencePerMarketColumn[] {
    return rows[0].columns!;
  }

  getColumnHeader(searchPattern: CoexistencePerMarketSearchPattern): string {
    let title: string;
    if (
      searchPattern.type ===
      CoexistencePerMarketPatternType.EXACT_TRADEMARK_EQUALS
    ) {
      title = searchPattern.keywords!.join(' ');
    } else if (
      searchPattern.type === CoexistencePerMarketPatternType.TRADEMARK_EQUALS &&
      !!searchPattern.keywords &&
      searchPattern.keywords.length === 1
    ) {
      title = '+' + searchPattern.keywords[0] + '+';
    } else {
      title = searchPattern.keywords!.join(' + ');
    }

    return title;
  }

  getBackgroundClass(count: number): string {
    if (count >= 37) {
      return 'color-5';
    } else if (count >= 25 && count <= 36) {
      return 'color-4';
    } else if (count >= 13 && count <= 24) {
      return 'color-3';
    } else if (count >= 1 && count <= 12) {
      return 'color-2';
    } else if (count === 0) {
      return 'color-1';
    }
    return 'color-1';
  }
}
