import { Component, ElementRef, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';

import { UserService } from '../../security/services/user.service';

@Component({
  selector: 'app-clarivate-header',
  templateUrl: './clarivate-header.component.html',
  styleUrls: ['./clarivate-header.component.scss'],
})
export class ClarivateHeaderComponent {
  @ViewChild('logoutForm', { static: false }) logoutForm?: ElementRef;

  userName: string | undefined;

  constructor(private userService: UserService) {
    this.userService.getCurrentUserObservable().subscribe((user) => {
      this.userName = user.fullName;
    });
  }

  logout(): void {
    this.logoutForm!.nativeElement.submit();
  }

  getLogoutAction(): string {
    return environment.backendBaseUrl + '/logout';
  }
}
