<div class="content">
  <div class="form">
    <mat-form-field class="field-input" appearance="outline">
      <mat-label>What is the user name?</mat-label>
      <input
        matInput
        ngModel
        #userIdInput="ngModel"
        placeholder="What is the user name?"
        [(ngModel)]="userId"
      />
    </mat-form-field>

    <button
      class="cdx-but-lg"
      color="primary"
      (click)="onSearch()"
      mat-flat-button
    >
      Search roles
    </button>
  </div>

  <div class="table-div" *ngIf="userSelected">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef>Role name</th>
        <td class="role-name-cell" mat-cell *matCellDef="let element">
          {{ element.role }}
        </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="delete-cell">
          <span
            class="material-symbols-outlined"
            (click)="deleteRole(element.role)"
            >delete</span
          >
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div class="list" *ngIf="userSelected">
    <mat-form-field>
      <mat-label>Add new role</mat-label>
      <mat-select
        [(ngModel)]="selectedRole"
        (selectionChange)="onAddRole($event)"
      >
        <mat-option *ngFor="let role of rolesToAdd" [value]="role">{{
          role
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
