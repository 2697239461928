import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

import { PurchasePackageComponent } from '../components/purchase-package/purchase-package.component';

@Injectable()
export class LeaveGuard implements CanDeactivate<PurchasePackageComponent> {
  canDeactivate(component: any): boolean {
    return component.canDeactivate();
  }
}
